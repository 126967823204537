import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteTeamData,
  getFundraisingsData,
  getTeamsData,
  postFundraisingsData,
  postTeamData,
  putFundraisingsData,
  putTeamData,
} from 'src/apis/FundraisingAPI';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import Debug from 'src/lib/Debug';
import { Team } from 'src/models/FundraisingModel';

export const SET_FUNDRAISING_LIST = 'SET_FUNDRAISING_LIST';
export const RESET_FUNDRAISING_LIST = 'RESET_FUNDRAISING_LIST';
export const SET_TEAM_LIST = 'SET_TEAM_LIST';
export const RESET_TEAM_LIST = 'RESET_TEAM_LIST';
export const ADD_TEAM = 'ADD_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';

export const setFundraisingList = makeActionCreator(SET_FUNDRAISING_LIST);
export const resetFundraisingList = makeActionCreator(RESET_FUNDRAISING_LIST);
export const setTeamList = makeActionCreator(SET_TEAM_LIST);
export const resetTeamList = makeActionCreator(RESET_TEAM_LIST);
export const addTeam = makeActionCreator(ADD_TEAM);
export const deleteTeam = makeActionCreator(UPDATE_TEAM);
export const updateTeam = makeActionCreator(DELETE_TEAM);

export const getTeamListAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getTeamsData(organisation);
      if (response.success && (!!response.data)) {
        dispatch(setTeamList(response.data));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const postTeamAction: (team: Team) => ThunkedAction<State> =
  (team: Team) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postTeamData(organisation, team);
      if (response.success && (!!response.data)) {
        dispatch(addTeam(response.data));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const putTeamAction: (id: number, team: Team) => ThunkedAction<State> =
  (id: number, team: Team) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putTeamData(id, organisation, team);
      if (response.success && (!!response.data)) {
        dispatch(updateTeam(response.data));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const deleteTeamAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteTeamData(id, organisation);
      if (response.success) {
        dispatch(deleteTeam(id));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getFundraisingListAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getFundraisingsData(organisation);
      if (response.success && (!!response.data)) {
        dispatch(setFundraisingList(response.data));
      }
    } catch (e) { /* Log the error here */
    }
  };

type Callback = (saved: boolean) => void;
export const postFundraisingsAction: (
  payload: any, setSaved: Callback) => ThunkedAction<State> =
  (payload: any, setSaved: Callback) => async (dispatch: any, getState: any) => {
    try {
      setSaved(false);
      const organisation = getState().currentOrganisation.id;
      const response = await postFundraisingsData(organisation, payload);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          message: 'Fundraising successfully saved',
        }));
        setSaved(true);
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: 'Error saving fundraising',
      }));
    } catch (e) { /* Log the error here */
      Debug.errorLog(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: 'Error saving fundraising',
      }));
    }
  };

export const putFundraisingsAction: (payload: any, setSaved: Callback) => ThunkedAction<State> =
  (payload: any, setSaved: Callback) => async (dispatch: any, getState: any) => {
    try {
      setSaved(false);
      const organisation = getState().currentOrganisation.id;
      const response = await putFundraisingsData(organisation, payload);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          message: 'Fundraising successfully saved',
        }));
        setSaved(true);
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: 'Error saving fundraising',
      }));
    } catch (e) { /* Log the error here */
      Debug.errorLog(e);
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: 'Error saving fundraising',
      }));
    }
  };
