import contact from 'src/i18n/fr/forms/contact';
import payments from 'src/i18n/fr/forms/payments';
import organisations from 'src/i18n/fr/forms/organisations';
import organisation from 'src/i18n/fr/forms/organisation';
import invoices from 'src/i18n/fr/forms/invoices';
import relations from 'src/i18n/fr/forms/relations';
import customfields from 'src/i18n/fr/forms/customfields';
import groups from 'src/i18n/fr/forms/groups';
import creditcards from 'src/i18n/fr/forms/creditcards';
import bankaccounts from 'src/i18n/fr/forms/bankaccounts';
import products from 'src/i18n/fr/forms/products';
import fundraising from 'src/i18n/fr/forms/fundraising';
import { tax } from 'src/i18n/fr/forms/tax';

const forms = {
  bankaccounts,
  relations,
  creditcards,
  groups,
  contact,
  customfields,
  organisations,
  organisation,
  invoices,
  payments,
  products,
  fundraising,
  tax,
};

export default forms;
