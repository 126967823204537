import { combineReducers } from 'redux';
import authReducer, { AuthState } from 'src/redux/reducers/AuthReducer';
import autocompleteReducer, { AutocompleteState } from 'src/redux/reducers/AutocompleteReducer';
import alertReducer, { AlertState } from 'src/redux/reducers/AlertReducer';
import errorReducer, { ErrorsState } from 'src/redux/reducers/ErrorReducer';
import customfieldsReducer, { CustomfieldsState } from 'src/redux/reducers/CustomfieldsReducer';
import UserContactReducer, { UserContactState } from 'src/redux/reducers/UserContactReducer';
import currentOrganisationReducer, {
  CurrentOrganisationState,
} from 'src/redux/reducers/CurrrentOrganisationReducer';
import dataReducer, { DataState } from 'src/redux/reducers/DataReducer';
import formStatusReducer, { FormStatusState } from 'src/redux/reducers/FormReducer';
import languageReducer, { LanguageState } from 'src/redux/reducers/LanguageReducer';
import organisationReducer, { OrganisationState } from 'src/redux/reducers/OrganisationReducer';
import organisationListReducer, {
  OrganisationListState,
} from 'src/redux/reducers/OrganisationListReducer';
import organisationsUserReducer, {
  OrganisationsUserState,
} from 'src/redux/reducers/OrganisationsUserReducer';
import querybuilderReducer, { QuerybuilderState } from 'src/redux/reducers/QuerybuilderReducer';
import routeReducer, { RouteState } from 'src/redux/reducers/RouteReducer';
import userReducer, { UserState } from 'src/redux/reducers/UserReducer';
import interfaceReducer, { InterfaceState } from 'src/redux/reducers/InterfaceReducer';
import PresetReducer, { PresetState } from 'src/redux/reducers/PresetReducer';
import selectionReducer, { SelectionState } from 'src/redux/reducers/SelectionReducer';
import modalReducer, { ModalState } from 'src/redux/reducers/ModalReducer';
import contactsReducer, { ContactsState } from 'src/redux/reducers/ContactsReducer';
import contactGroupReducer, { ContactGroupState } from 'src/redux/reducers/ContactGroupReducer';
import invoiceReducer, { InvoicesState } from 'src/redux/reducers/InvoiceReducer';
import paymentReducer, { PaymentsState } from 'src/redux/reducers/PaymentReducer';
import taxesReducer, { TaxesState } from 'src/redux/reducers/TaxReducer';
import bankAccountsReducer, { BankAccountsState } from 'src/redux/reducers/BankAccountReducer';
import DialogReducer, { DialogState } from 'src/redux/reducers/DialogReducer';
import CurrentEntityReducer, { CurrentEntityState } from 'src/redux/reducers/CurrentEntityReducer';
import OrganisationBankAccountReducer, {
  OrganisationBankAccountState,
} from 'src/redux/reducers/OrganisationBankAccountReducer';
import ContactCreditCardReducer, {
  ContactCreditCardState,
} from 'src/redux/reducers/ContactCreditCardReducer';
import ContactBankAccountReducer, {
  ContactBankAccountState,
} from 'src/redux/reducers/ContactBankAccountReducer';
import advancedSearchReducer, {
  AdvancedSearchState,
} from 'src/redux/reducers/AdvancedSearchReducer';
import selectedPresetReducer, {
  CurrentPresetState,
} from 'src/redux/reducers/SelectedPresetReducer';
import productsReducer, { ProductsState } from 'src/redux/reducers/ProductsReducer';
import gatewaysReducer, { GatewaysState } from 'src/redux/reducers/GatewaysReducer ';
import adminsReducer, { AdminListState } from 'src/redux/reducers/AdminsReducer';
import creditCardsReducer from 'src/redux/reducers/CreditCardReducer';
import menuReducer, { MenuState } from 'src/redux/reducers/MenuReducer';
import { EntityStatusState } from 'src/models/FormModel';
import EntityStatusReducer from 'src/redux/reducers/EntityStatusReducer';
import fundraisingReducer, { FundraisingListState } from 'src/redux/reducers/FundraisingReducer';
import TeamsReducer, { TeamsState } from 'src/redux/reducers/TeamReducer';
import guessInvoiceReducer, { GuessInvoicesState } from 'src/redux/reducers/GuessInvoiceReducer';
import UserCreditCardReducer, {
  UserCreditCardState,
} from 'src/redux/reducers/UserCreditCardReducer';
import UserBankAccountReducer, {
  UserBankAccountState,
} from 'src/redux/reducers/UserBankAccountReducer';
import QuickSearchReducer, { QuickSearchState } from 'src/redux/reducers/QuickSearchReducer';
import settingsReducer, { SettingsState } from './SettingReducer';
//
export interface State {
  advancedSearch: AdvancedSearchState;
  admins: AdminListState;
  alert: AlertState;
  auth: AuthState;
  autocomplete: AutocompleteState,
  bankAccounts: BankAccountsState;
  contacts: ContactsState;
  contactBankAccounts: ContactBankAccountState;
  contactCreditcards: ContactCreditCardState;
  contactGroup: ContactGroupState,
  currentOrganisation: CurrentOrganisationState,
  currentEntity: CurrentEntityState,
  customfields: CustomfieldsState,
  data: DataState,
  dialog: DialogState,
  errors: ErrorsState;
  entityStatus: EntityStatusState,
  formStatus: FormStatusState,
  fundraisingList: FundraisingListState,
  gateways: GatewaysState,
  invoices: InvoicesState;
  guessInvoices: GuessInvoicesState,
  interface: InterfaceState,
  language: LanguageState;
  menu: MenuState,
  modals: ModalState,
  organisation: OrganisationState;
  organisationList: OrganisationListState;
  organisationsUser: OrganisationsUserState;
  organisationBankAccounts: OrganisationBankAccountState;
  payments: PaymentsState;
  products: ProductsState;
  presets: PresetState,
  querybuilder: QuerybuilderState;
  route: RouteState;
  selected: SelectionState;
  selectedPreset: CurrentPresetState,
  settings: SettingsState;
  taxes: TaxesState;
  teams: TeamsState;
  user: UserState;
  userContact: UserContactState;
  userCreditCards: UserCreditCardState;
  userBankAccounts: UserBankAccountState;
  quickSearch: QuickSearchState;
}

export const RootReducer = {
  advancedSearch: advancedSearchReducer,
  admins: adminsReducer,
  alert: alertReducer,
  auth: authReducer,
  autocomplete: autocompleteReducer,
  bankAccounts: bankAccountsReducer,
  contacts: contactsReducer,
  contactBankAccounts: ContactBankAccountReducer,
  contactCreditcards: ContactCreditCardReducer,
  contactGroup: contactGroupReducer,
  creditcards: creditCardsReducer,
  currentEntity: CurrentEntityReducer,
  currentOrganisation: currentOrganisationReducer,
  customfields: customfieldsReducer,
  data: dataReducer,
  dialog: DialogReducer,
  entityStatus: EntityStatusReducer,
  errors: errorReducer,
  formStatus: formStatusReducer,
  fundraisingList: fundraisingReducer,
  gateways: gatewaysReducer,
  interface: interfaceReducer,
  invoices: invoiceReducer,
  guessInvoices: guessInvoiceReducer,
  language: languageReducer,
  menu: menuReducer,
  modals: modalReducer,
  organisation: organisationReducer,
  organisationList: organisationListReducer,
  organisationsUser: organisationsUserReducer,
  organisationBankAccounts: OrganisationBankAccountReducer,
  payments: paymentReducer,
  presets: PresetReducer,
  products: productsReducer,
  querybuilder: querybuilderReducer,
  route: routeReducer,
  selected: selectionReducer,
  selectedPreset: selectedPresetReducer,
  settings: settingsReducer,
  taxes: taxesReducer,
  teams: TeamsReducer,
  user: userReducer,
  userContact: UserContactReducer,
  userCreditCards: UserCreditCardReducer,
  userBankAccounts: UserBankAccountReducer,
  quickSearch: QuickSearchReducer,
};

export default combineReducers<State>(RootReducer);
