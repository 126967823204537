import fields from 'src/i18n/en/fields';
import forms from 'src/i18n/en/forms';
import messages from 'src/i18n/en/messages';
import misc from 'src/i18n/en/misc';
import pages from 'src/i18n/en/pages';
import search from 'src/i18n/en/search';
import validations from 'src/i18n/en/validations';
import menus from 'src/i18n/en/menus';
import errors from 'src/i18n/en/errors';

const en = {
  errors,
  fields,
  forms,
  messages,
  misc,
  pages,
  search,
  validations,
  menus,
};

export default en;
