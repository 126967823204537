import { State } from 'src/redux/reducers/RootReducer';
import {
  cancelPaymentData,
  getPaymentData,
  postPaymentData,
  postPurchaseData,
  putPaymentData,
  refundPaymentData,
} from 'src/apis/PaymentAPI';
import { CancelPaymentItem, PaymentModel, PurchasePayload } from 'src/models/PaymentModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setError } from 'src/redux/actions/errorsActions';
import { getEntitiesAction } from 'src/redux/actions/querybuilderActions';
import { MODALS } from 'src/models/ModalModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { closeModal } from 'src/redux/actions/modalActions';
import makeActionCreator from 'src/lib/makeActionCreator';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import Debug from 'src/lib/Debug';

export const SET_PAYMENT = 'SET_PAYMENT';
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const DELETE_PAYMENTS = 'DELETE_PAYMENTS';

export const setPayment = makeActionCreator(SET_PAYMENT);
export const updatePaymentAction = makeActionCreator(UPDATE_PAYMENT);
export const deletePaymentsAction = makeActionCreator(DELETE_PAYMENTS);

export const normalizePayment = (data: any) => {
  const payment = { ...data } as PaymentModel;
  payment.paid_date = data.paid_date ? moment(data.paid_date) : null;
  payment.processed_date = data.processed_date ? moment(data.processed_date) : null;
  payment.scheduled_date = data.scheduled_date ? moment(data.scheduled_date) : null;
  return payment;
};

export const cancelPaymentAction: (paiements:CancelPaymentItem[]) => ThunkedAction<State> =
  (payments:CancelPaymentItem[]) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.cancel_payment]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await cancelPaymentData(payments, organisation);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.cancel_payment]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.cancel_payment',
        }));
        dispatch(deletePaymentsAction(payments));
        dispatch(closeModal({ modal: MODALS.cancelPayments }));
        dispatch(getEntitiesAction(ENTITIES.payments));
        dispatch(resetSelections({ entity: ENTITIES.payments }));
        dispatch(closeDialog({ dialog: DIALOGS.cancelPayments }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.cancel_payment',
        }));
        dispatch(closeModal({ modal: MODALS.cancelPayments }));
        dispatch(setFormStatus({ [FORM.cancel_payment]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const refundPaymentAction: (id: number) => ThunkedAction<State> =
(id: number) => async (dispatch: any, getState: any) => {
  if (!id) {
    return;
  }
  try {
    dispatch(setFormStatus({ [FORM.refund_payment]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await refundPaymentData(id, organisation);
    if (response.success) {
      const { data } = response;
      dispatch(updatePaymentAction({ id, payment: normalizePayment(data) }));
      dispatch(setFormStatus({ [FORM.refund_payment]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.payment_saved',
      }));
    } else if (response.status === 400) {
      dispatch(setError({ [FORM.refund_payment]: response.errors.fields }));
      dispatch(setFormStatus({ [FORM.refund_payment]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors)[0],
      }));
    } else {
      dispatch(setFormStatus({ [FORM.refund_payment]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.refund_payment]: FORM_STATUS.error }));
  }
};

export const postPaymentAction:
(payload: PaymentModel, close: boolean) => ThunkedAction<State> =
  (payload: PaymentModel, close) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setFormStatus({ [FORM.make_payment]: FORM_STATUS.processing }));
      const organisation = getState().currentOrganisation.id;
      const response = await postPaymentData(organisation, payload);
      if (response.success) {
        dispatch(getEntitiesAction(ENTITIES.invoices));
        dispatch(setFormStatus({ [FORM.make_payment]: FORM_STATUS.success }));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.payment_saved',
        }));
        if (close) {
          dispatch(closeModal({ modal: MODALS.makePayment }));
        }
      } else if (response.status === 400) {
        dispatch(setError({ [FORM.make_payment]: response.errors.fields }));
        dispatch(setFormStatus({ [FORM.make_payment]: FORM_STATUS.error }));
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: Object.values(response.errors)[0],
        }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.invoice_saved_error',
        }));
        dispatch(setFormStatus({ [FORM.reset_password]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getPaymentAction: (id: number) => ThunkedAction<State> =
(id: number) => async (dispatch: any, getState: any) => {
  if (!id) {
    return;
  }
  try {
    dispatch(setFormStatus({ [FORM.get_payment]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getPaymentData(id, organisation);
    if (response.success) {
      const { data } = response;
      dispatch(updatePaymentAction({ id, payment: normalizePayment(data) }));
      dispatch(setFormStatus({ [FORM.get_payment]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_payment]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_payment]: FORM_STATUS.error }));
  }
};

export const putPaymentAction: (id: number, payment: PaymentModel) => ThunkedAction<State> =
(id: number, payment: PaymentModel) => async (dispatch: any, getState: any) => {
  if (!id) {
    return;
  }
  try {
    dispatch(setFormStatus({ [FORM.put_payment]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await putPaymentData(id, payment, organisation);
    if (response.success) {
      const { data } = response;
      dispatch(updatePaymentAction({ id, payment: normalizePayment(data) }));
      dispatch(setFormStatus({ [FORM.put_payment]: FORM_STATUS.success }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.payment_update_success',
      }));
      dispatch(closeModal({ modal: MODALS.editPayment }));
    } else if (response.status === 400) {
      dispatch(setError({ [FORM.put_payment]: response.errors.fields }));
      dispatch(setFormStatus({ [FORM.put_payment]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors)[0],
      }));
    } else {
      dispatch(setFormStatus({ [FORM.put_payment]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.put_payment]: FORM_STATUS.error }));
  }
};

export const postPurchaseAction: (
  purchase: PurchasePayload, modal?: MODALS
) => ThunkedAction<State> =
(purchase: PurchasePayload, modal?: MODALS) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.post_purchase]: FORM_STATUS.processing }));
    dispatch(setFormStatus({ [FORM.admin_donation]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await postPurchaseData(organisation, purchase);
    if (response.success) {
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.purchased_success',
      }));
      dispatch(setFormStatus({ [FORM.post_purchase]: FORM_STATUS.success }));
      dispatch(setFormStatus({ [FORM.admin_donation]: FORM_STATUS.success }));
      dispatch(getEntitiesAction(ENTITIES.payments));
      if (!!modal) {
        dispatch(closeModal({ modal }));
      }
    } else {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.purchased_error',
      }));
      Debug.errorLog(response);
      dispatch(setFormStatus({ [FORM.post_purchase]: FORM_STATUS.error }));
      dispatch(setFormStatus({ [FORM.admin_donation]: FORM_STATUS.error }));
    }
  } catch (e: unknown) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.post_purchase]: FORM_STATUS.error }));
    dispatch(setFormStatus({ [FORM.admin_donation]: FORM_STATUS.error }));
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.purchased_error',
    }));
    if (e instanceof Error) {
      Debug.errorLog(e.message);
      return;
    }
    Debug.errorLog(e);
  }
};
