import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { FORM } from 'src/constants/Form';
import { Team } from 'src/models/FundraisingModel';
import TeamValidator from 'src/validations/TeamValidator';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import FundraisingLanguageSelector from 'src/components/Control/FundraisingLanguageSelector';
import ContactSelector from 'src/components/Control/ContactSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import FormUploadImage from 'src/components/Control/FormControls/FormUploadImage';
import Row from 'src/components/UI/Row';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void;
  onSave: (team: Team) => void;
  team: Team;
}

const EditTeamForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    onClose, onSave, team,
  } = props;

  const lang = useSelector((state: Store) => state.language.language);
  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const [state, setState] = useState({} as Team);
  const form = FORM.fundraising_teams;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  useEffect(() => {
    if (!!team.id) {
      setState(team);
    }
  }, []);

  const slugify = (value: string) => value.toLowerCase().replace(' ', '-').replace(/[^0-9a-z-]/gi, '');

  const onValueChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const onContactChange = (id: number) => {
    onValueChange(id, 'contact_id');
  };

  const onNameChange = (value: string) => {
    setState({
      ...state,
      name: value,
      slug: slugify(value),
    });
  };

  const onSlugChange = (value: string) => {
    onValueChange(slugify(value), 'slug');
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const onSaveAndClose = () => {
    if (onClose) {
      onClose();
    }
    onSave(state);
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      TeamValidator().validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.fundraising.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return;
    }
    onSaveAndClose();
  };

  const getAvatar = () => `${organisation}/teams/${state.slug}/avatar.png`;

  return (
    <Form>
      <Row>
        <ContactSelector
          autocompleteId={AUTOCOMPLETE_ID.edit_fundraising_team}
          onChange={(id: number) => onContactChange(id)}
          label={t(lang, 'form.fundraising.contact')}
          form={form}
          name="contact_id"
          contactId={state.contact_id}
          hideEdit
          style={{ width: '100%' }} />
      </Row>
      <Row>
        <FormTextField
          form={form}
          name="name"
          onChange={onNameChange}
          value={state.name}
          label={t(lang, 'form.fundraising.team_name')} />
      </Row>
      <Row>
        <FormTextField
          form={form}
          name="slug"
          onChange={onSlugChange}
          value={state.slug}
          label={t(lang, 'form.fundraising.team_slug')} />
      </Row>
      <Row>
        <FormRichTextEditor
          label={t(lang, 'form.fundraising.team_description')}
          form={form}
          name="description"
          onChange={onValueChange}
          value={state.description || ''} />
      </Row>
      <Row>
        <FundraisingLanguageSelector
          form={form}
          name="default_language"
          onChange={onValueChange}
          value={state.default_language}
          label={t(lang, 'form.fundraising.team_default_language')} />
      </Row>
      <Row style={{ alignItems: 'start', marginTop: '16px' }}>
        {t(lang, 'form.fundraising.avatar')}:
        <FormUploadImage
          name="avatar.png"
          height={110}
          width={576}
          path={getAvatar()} />
      </Row>
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button-team">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key="close-button-team">
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default EditTeamForm;
