import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { openDialog } from 'src/redux/actions/dialogActions';
import { getTaxesAction } from 'src/redux/actions/taxActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { TaxItemModel } from 'src/models/TaxListModel';
import EditTaxForm from 'src/components/Forms/EditTaxForm';
import AddTaxForm from 'src/components/Forms/AddTaxForm';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import Scroller from 'src/components/UI/Scroller';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageOrganisationTaxes = () => {
  const dispatch = useAppDispatch();
  const taxes = useSelector((state: Store) => state.taxes);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentTax, setcurrentTax] = useState({} as TaxItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  useEffect(() => {
    dispatch(getTaxesAction());
  }, []);

  const handleDelete = (tax: TaxItemModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteTax, payload: tax }));
  };

  const handleEdit = (tax: TaxItemModel) => {
    setcurrentTax(tax);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentTax({} as TaxItemModel);
    setCurrentState(STATE.add);
  };

  const ListItems = () => {
    const list = taxes.map((tax: TaxItemModel) => {
      if (currentFilter) {
        if (!tax[`name_${lang}`].includes(currentFilter)) return null;
      }
      return (
        <Item key={`tax-${tax.id}`}>
          <span><b>{tax[`name_${lang}`]}</b></span>
          <EditButton onClick={() => handleEdit(tax)} title={t(lang, 'menus.edit_taxe')} />
          <DeleteButton onClick={() => handleDelete(tax)} title={t(lang, 'menus.delete_taxe')} />
        </Item>
      );
    });
    return (<>{list}</>);
  };

  const EditForm = () => (
    <EditTaxForm
      tax={currentTax}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const AddForm = () => (
    <AddTaxForm
      onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'forms.organisation.filter')}
          onChange={setCurrentFilter} />
      )}
      <Scroller height="597px">
        { currentState === STATE.list && <ListItems /> }
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </Scroller>
    </>
  );
};

export default ManageOrganisationTaxes;
