import {
  ENTITIES,
  GridColumDefinition,
  GridColumns,
  QuerbuilderPayloadColumns,
} from 'src/models/QuerybuilderModel';
import { ListItem, Menu } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { f } from 'src/lib/language';
import { findIndex } from 'lodash';
import { setColumns } from 'src/redux/actions/querybuilderActions';
import FieldVisibilityOff from 'src/components/Elements/FieldVisibilityOff';
import FieldVisibilityOn from 'src/components/Elements/FieldVisibilityOn';
import Scroller from 'src/components/UI/Scroller';

interface Props {
  entity: ENTITIES,
  anchor: null | HTMLElement,
  setAnchor: (anchor: null | HTMLElement) => void
}

const ColumnSelectionMenu = (props: Props) => {
  const dispatch = useAppDispatch();

  const { entity, anchor, setAnchor } = props;

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const customfields = useSelector((state: Store) => state.customfields);
  const columns = useSelector(
    (state: Store) => (
      state.querybuilder[entity] && state.querybuilder[entity].columns
    ) || [] as GridColumns,
  );

  const handleClose = () => {
    setAnchor(null);
  };

  const toggleColumnVisibility = (field: string) => {
    const newColumns = [...columns];
    const columnIndex = findIndex(newColumns, (column) => column.field === field);
    const newColumn = {
      ...columns[columnIndex],
      hide: !columns[columnIndex].hide,
    };
    newColumns.splice(columnIndex, 1, newColumn);

    dispatch(setColumns({
      entity,
      columns: newColumns,
    } as QuerbuilderPayloadColumns));
  };

  const DisplayIcon = (column: GridColumDefinition) => {
    const { field, hide } = column;
    if (hide) {
      return (
        <FieldVisibilityOff onClick={() => toggleColumnVisibility(field)} />
      );
    }
    return (
      <FieldVisibilityOn onClick={() => toggleColumnVisibility(field)} />
    );
  };

  const DisplayCaption = (column: GridColumDefinition) => {
    if (column.display) {
      return column.display;
    }
    const [table, property] = column.field.split('.');
    return (table === 'custom_fields' && !!customfields[property])
      ? customfields[property][`display_${lang}`]
      : f(lang, column.field);
  };

  const getItems = () => columns.map((column) => (
    <ListItem style={{ width: '256px' }} key={`column-item-${column.field}`}>
      { DisplayIcon(column) }
      <span style={column.hide ? { color: 'rgba(0, 0, 0, 0.5)' } : {}}>
        { DisplayCaption(column) }
      </span>
    </ListItem>
  ));

  return (
    <Menu
      style={{ marginTop: '30px' }}
      id="customized-menu"
      anchorEl={anchor}
      keepMounted
      open={Boolean(anchor)}
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Scroller height="512px">
        {getItems()}
      </Scroller>
    </Menu>
  );
};

export default ColumnSelectionMenu;
//
