import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
}

const FundraiserHeader = (props: Props) => {
  const {
    children,
  } = props;

  const styles: CSSProperties = {
    marginTop: '8px',
    border: 'solid 1px #eee',
    backgroundColor: 'rgba(174, 46, 111, 0.1)',
    fontSize: '16px',
    minHeight: '48px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
  };
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default FundraiserHeader;
