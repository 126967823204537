import { Teams } from 'src/models/FundraisingModel';
import { Reducer } from 'redux';
import {
  ADD_TEAM,
  DELETE_TEAM,
  RESET_TEAM_LIST,
  SET_TEAM_LIST,
  UPDATE_TEAM,
} from 'src/redux/actions/fundraisingAction';
//
export type TeamsState = Teams;

const DEFAULT = [] as TeamsState;

const TeamsReducer: Reducer<TeamsState> = (state: TeamsState = DEFAULT, action) => {
  switch (action.type) {
    case SET_TEAM_LIST: {
      return [...action.payload];
    }

    case RESET_TEAM_LIST: {
      return [];
    }

    case ADD_TEAM: {
      const teams = [...state];
      teams.push(action.payload);
      return teams;
    }

    case UPDATE_TEAM: {
      const index = state.findIndex((team) => team.id === action.payload.id);
      const newArray = [...state];
      newArray[index] = action.payload;
      return newArray;
    }

    case DELETE_TEAM: {
      const { id } = action.payload;
      const teams = [...state];
      return teams.filter((team) => team.id !== id);
    }

    default:
      return state;
  }
};

export default TeamsReducer;
