import { State } from 'src/redux/reducers/RootReducer';
import { CreateOrganisationPayload } from 'src/models/OrganisationModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { createOrganisationData } from 'src/apis/OrganisationAPI';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

type CreateOrganisation = (payload: CreateOrganisationPayload) => ThunkedAction<State>;

export const createOrganisationAction:CreateOrganisation =
  (payload: CreateOrganisationPayload) => async (dispatch: any) => {
    dispatch(setFormStatus({ [FORM.createOrganisation]: FORM_STATUS.processing }));
    try {
      const response = await createOrganisationData(payload);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.organisation_created',
        }));
        dispatch(setFormStatus({ [FORM.createOrganisation]: FORM_STATUS.success }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.organisation_created_error',
        }));
        dispatch(setFormStatus({ [FORM.createOrganisation]: FORM_STATUS.error }));
      }
    } catch (error) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.organisation_created_error',
      }));
      dispatch(setFormStatus({ [FORM.createOrganisation]: FORM_STATUS.error }));
    }
  };
