import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import GroupIcon from '@material-ui/icons/Group';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { t } from 'src/lib/language';

const ManageGroupsButton = (props: ButtonProps) => {
  const { disabled } = props;
  const lang = useSelector((state: Store) => state.language.language);
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(openModal({ modal: MODALS.manageGroup }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.manage_groups')}
      placement="top-start">
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.presets}
          onClick={handleOpen}
          disabled={disabled}>
          <GroupIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default ManageGroupsButton;
