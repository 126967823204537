import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  FormControl, MenuItem, Select, Tooltip, InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getTaxesAction } from 'src/redux/actions/taxActions';
import { ErrorBag } from 'src/models/ErrorModel';
import { TaxListModel } from 'src/models/TaxListModel';
import { FORM_ID } from 'src/models/FormModel';

const useStyles = makeStyles(() => ({
  selectEmpty: {
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
}));

interface Props {
  disabled?: boolean;
  onChange: (id:number) => void;
  values?: number[];
  form: FORM_ID;
  name: string;
  size?: 'small' | 'medium';
  marginTop?: string,
}

const TaxSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    values, disabled, onChange, form, name, size, marginTop,
  } = props;

  const [valueState, setValueState] = useState(values || [] as number[]);
  const lang = useSelector((state: Store) => state.language.language || 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const onValueChange = (newValue: any) => {
    setValueState(newValue);
    onChange(newValue.map((id:string) => Number(id)));
  };

  const taxes = useSelector((store: Store) => store.taxes || [] as TaxListModel);
  useEffect(() => {
    dispatch(getTaxesAction());
  }, []);

  useEffect(() => {
    if (values) {
      setValueState(values);
    }
  }, [values]);

  const displayItems = () => {
    const elements: any[] = [];
    if (!taxes.length) {
      return elements;
    }
    return taxes.map((tax) => (
      <MenuItem
        key={`tax-item-${tax.id}`}
        value={tax.id}
        selected={valueState.indexOf(Number(tax.id)) >= 0}>
        <Tooltip
          title={tax[`name_${lang}`]}
          placement="top-start">
          <>{ tax[`abbreviation_${lang}`] }</>
        </Tooltip>
      </MenuItem>
    ));
  };
  let displayClass = '';
  if (!valueState) {
    displayClass = classes.selectLabel;
  }

  const renderValue = () => {
    if (valueState.length > 0 && !!taxes.length) {
      const taxesValue = [] as string[];
      valueState.forEach((taxid) => {
        const taxDef = taxes.find((current) => current.id === taxid);
        if (!!taxDef) {
          taxesValue.push(taxDef.abbreviation_fr);
        }
      });
      return taxesValue.join(',');
    }
    return null;
  };

  return (
    <FormControl
      variant="outlined"
      error={!!errors[name]}
      key={`form-control-${name}`}
      size={size || 'small'}
      className={classes.selectElement}
      style={marginTop ? { marginTop } : {}}>
      <InputLabel id="tax-select-label">Taxes</InputLabel>
      <Select
        value={valueState}
        onChange={(e) => onValueChange(e.target.value)}
        displayEmpty
        className={`${classes.selectEmpty} ${displayClass} ${classes.selectElement}`}
        renderValue={renderValue}
        disabled={disabled}
        label="Taes"
        labelId="tax-select-label"
        multiple>
        { displayItems() }
      </Select>
    </FormControl>
  );
};

export default TaxSelector;
//
