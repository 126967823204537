const register = {
  register: 'S\'inscrire',
  email: 'Adresse de courriel',
  password: 'Mot-de-passe',
  confirm_password: 'Confirmation du mot-de-passe',
  language: 'Language',
  different_account: 'Se connecter avec un autre compte:',
  login: 'Connexion',
  policy: 'En créant un compte, vous acceptez nos',
  privacy_policy: 'politique de confidentialité',
  tos: 'conditions de service',
};

export default register;
