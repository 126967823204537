/* eslint-disable @typescript-eslint/no-loss-of-precision */
import * as Yup from 'yup';
import { t } from 'src/lib/language';

const TaxCreationValidator = (lang:string) => Yup.object().shape({
  name_en: Yup.string()
    .max(51, t(lang, 'validations.contact.field_maximum', { max: 51 }))
    .required(),
  name_fr: Yup.string()
    .max(51, t(lang, 'validations.contact.field_maximum', { max: 51 }))
    .required(),
  description_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  description_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  abbreviation_en: Yup.string()
    .max(16, t(lang, 'validations.contact.field_maximum', { max: 16 }))
    .required(),
  abbreviation_fr: Yup.string()
    .max(16, t(lang, 'validations.contact.field_maximum', { max: 16 }))
    .required(),
  registration_number: Yup.string()
    .max(
      31,
      t(lang, 'validations.contact.field_maximum', { max: 31 }),
    )
    .required(),
  rate: Yup.number()
    .min(0)
    .max(101)
    .required(),
});

export default TaxCreationValidator;
