import { FIELD_TYPES } from 'src/constants/Fields';

const invoicesForPayment = [
  {
    field: 'invoices.id',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'invoices.title',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.total',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.paid',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.scheduled',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.sequence',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'invoices.balance',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.due_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'invoices.payment_status',
    minWidth: 150,
    type: FIELD_TYPES.invoicePaymentStatus,
  },
  {
    field: 'invoices.contact_salutation_id',
    minWidth: 150,
    type: FIELD_TYPES.salutation,
    hide: true,
  },
  {
    field: 'invoices.contact_first_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.contact_last_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.contact_company_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.state',
    minWidth: 150,
    type: FIELD_TYPES.invoiceStatus,
  },
  {
    field: 'invoices.billing_address',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.billing_suite',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.billing_city',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.billing_state',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.billing_country_id',
    minWidth: 150,
    type: FIELD_TYPES.country,
  },
  {
    field: 'invoices.billing_zip_code',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.shipping_address',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.shipping_suite',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.shipping_city',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.shipping_state',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.shipping_country_id',
    minWidth: 150,
    type: FIELD_TYPES.country,
  },
  {
    field: 'invoices.shipping_zip_code',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.issued_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'invoices.bank_account_id',
    minWidth: 150,
    type: FIELD_TYPES.organisationBankAccount,
  },
  {
    field: 'invoices.department_id',
    minWidth: 150,
    type: FIELD_TYPES.department,
  },
  {
    field: 'invoices.account_id',
    minWidth: 150,
    type: FIELD_TYPES.account,
  },
  {
    field: 'invoices.program_id',
    minWidth: 150,
    type: FIELD_TYPES.program,
  },
  {
    field: 'invoices.contact_id',
    minWidth: 150,
    type: FIELD_TYPES.contact,
    hide: true,
  },
  {
    field: 'invoices.seller_id',
    minWidth: 150,
    type: FIELD_TYPES.contact,
    hide: true,
  },
  {
    field: 'invoices.contact_payer_id',
    minWidth: 150,
    type: FIELD_TYPES.contact,
    hide: true,
  },
  {
    field: 'invoices.subtotal',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.taxes',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.deductible',
    minWidth: 150,
    type: FIELD_TYPES.money,
  },
  {
    field: 'invoices.note',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.issue_tax_receipt',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'invoices.is_sent',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'invoices.is_printed',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'invoices.created_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'invoices.updated_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
];

export default invoicesForPayment;
