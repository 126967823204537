import { Reducer } from 'redux';
import { ModalModel } from 'src/models/ModalModel';
import { CLOSE_MODAL, OPEN_MODAL, RESET_MODAL } from 'src/redux/actions/modalActions';

export type ModalState = ModalModel;

const DEFAULT: ModalState = {} as ModalState;

const modalReducer: Reducer<ModalState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      const { modal, payload } = action.payload;
      return {
        ...state,
        [modal]: {
          opened: true,
          payload,
        },
      };
    }
    case CLOSE_MODAL: {
      const { modal, payload } = action.payload;
      return {
        ...state,
        [modal]: {
          opened: false,
          payload,
        },
      };
    }
    case RESET_MODAL: {
      return DEFAULT;
    }
    default:
      return state;
  }
};

export default modalReducer;
