import * as Yup from 'yup';
import { t } from 'src/lib/language';
/* eslint-disable import/no-extraneous-dependencies */

const InvoiceOrderValidator = (orders: any, lang:string) => {
  const orderValidator = {};

  orders.forEach((order: any, index: number) => {
    orderValidator[`deductible_${index}`] = Yup.number()
      .min(0)
      .max(100)
      .nullable();
    orderValidator[`discount_${index}`] = Yup.number()
      .min(0)
      .nullable();
    orderValidator[`note_${index}`] = Yup.string()
      .max(500)
      .nullable();
    orderValidator[`paid_${index}`] = Yup.number()
      .min(0, t(lang, 'validations.contact.field_required'));
    orderValidator[`product_id_${index}`] = Yup.number()
      .min(1)
      .test('is-required', 'required', function () {
        /* eslint-disable react/no-this-in-sfc */
        return (this.parent[`product_id_${index}`]);
      })
      .integer();
    orderValidator[`quantity_${index}`] = Yup.number()
      .min(1, t(lang, 'validations.contact.field_required')).max(999)
      .integer();
  });

  return Yup.object().shape(orderValidator);
};

export default InvoiceOrderValidator;
