import { ChangeEvent } from 'react';
import { Switch } from '@material-ui/core';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

interface Props {
  setOperator: (operator: SEARCH_LOGICAL_OPERATORS) => void;
  operator: SEARCH_LOGICAL_OPERATORS;
}

const LogicalOperatorSelector = (props: Props) => {
  const { operator, setOperator } = props;
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleChange = (checked: boolean) => {
    const value = checked ? SEARCH_LOGICAL_OPERATORS.and : SEARCH_LOGICAL_OPERATORS.or;
    setOperator(value);
  };

  return (
    <div>
      <Switch
        color="primary"
        checked={operator === SEARCH_LOGICAL_OPERATORS.and}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e.target.checked)}
        name="boolean_operand" />
      <span>{operator === SEARCH_LOGICAL_OPERATORS.and ? t(lang, 'search.and') : t(lang, 'search.or')}</span>
    </div>
  );
};

export default LogicalOperatorSelector;
