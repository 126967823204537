import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import SubmenuLink from 'src/components/UI/SubmenuLink';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import FundraisingInformationForm from 'src/components/Forms/Fundraising/FundraisingInformationForm';
import {
  FundraisingEventInfo,
  FundraisingInfo,
  FundraisingMatchingdAsset,
  FundraisingMatchingInfo,
  FundraisingNotification,
  FundraisingProduct,
  FundraisingStandardAssetModel,
  FundraisingStandardInfo,
  FundraisingTeam,
} from 'src/models/FundraisingModel';
import FundraisingNotificationForm from 'src/components/Forms/Fundraising/FundraisingNotificationForm';
import FundraisingProductdForm from 'src/components/Forms/Fundraising/FundraisingProductsForm';
import FundraisingEventInfoForm from 'src/components/Forms/Fundraising/FundraisingEventInfoForm';
import FundraisingStandardInfoForm from 'src/components/Forms/Fundraising/FundraisingStandardInfoForm';
import FundraisingMatchingInfoForm from 'src/components/Forms/Fundraising/FundraisingMatchingInfoForm';
import FundraisingStandardAssetsForm from 'src/components/Forms/Fundraising/FundraisingStandardAssetsForm';
import FundraisingMatchingAssetsForm from 'src/components/Forms/Fundraising/FundraisingMatchingAssetForm';
import FundraisingTeams from 'src/components/Forms/Fundraising/FundraisingTeams';
import moment from 'moment-timezone-all';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import {
  postFundraisingsAction,
  putFundraisingsAction,
} from 'src/redux/actions/fundraisingAction';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: 'rgba(255, 0, 0, 0.50)',
  },
  spacer: {
    height: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'top',
    gap: '10px',
    marginTop: '10px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '54px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  scroller: {
    overflowY: 'auto',
    height: '66vh',
    padding: '8px',
  },
}));

export const enum TABS {
  info = 'info',
  products = 'products',
  notifications = 'notifications',
  event_info = 'event_info',
  standard_info = 'standard_info',
  matching_info = 'matching_info',
  event_assets = 'event_assets',
  matching_assets = 'matching_assets',
  standard_assets = 'standard_assets',
  teams = 'teams',
}

type ErrorState = {
  [key in TABS]: boolean
};

const AddFundraisingPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const [currentTab, setCurrentTab] = useState(TABS.info);
  const [step, setStep] = useState(1);
  const [type, setType] = useState(FundraisingTypes.standard);
  const [hasErrors, setHasErrors] = useState({} as ErrorState);
  const [saved, setSaved] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const [informationState, setInformationState] = useState({
    active: true,
    show_raised_amount: false,
    show_timer: false,
    template_id: 1,
    type: FundraisingTypes.standard,
    start_date: moment().startOf('day'),
    end_date: moment().add(1, 'week').endOf('day'),
  } as FundraisingInfo);
  const [notificationState, setNotificationState] = useState({
    contacts: [] as number[],
    groups: [] as number[],
    send_thanks_email: false,
    send_thanks_sms: false,
    thanks_email_body: '',
  } as FundraisingNotification);
  const [productState, setProductState] = useState({} as FundraisingProduct);
  const [eventInfoState, setEventInfoState] = useState({
    order: ['Poster', 'Poster slider', 'Video', 'Video slider', 'Text'],
  } as FundraisingEventInfo);
  const [standardInfoState, setStandardInfoState] = useState({
    order: ['Poster', 'Poster slider', 'Video', 'Video slider', 'Text'],
  } as FundraisingStandardInfo);
  const [matchingInfoState, setMatchingInfoState] = useState({
    order: [
      'Slider main',
      'Slider secondary',
      'Slider matcher',
      'Slider sponsor',
      'Main video',
      'Secondary video',
      'Description',
    ],
  } as FundraisingMatchingInfo);
  const [standardAssetState, setStandardAssetState] = useState({} as FundraisingStandardAssetModel);
  const [matchingAssetState, setMatchingAssetState] = useState({} as FundraisingMatchingdAsset);
  const [teamState, setTeamState] = useState([] as FundraisingTeam[]);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.addEvent }));
    setStep(1);
    setType(FundraisingTypes.standard);
  };

  const transformData = () => {
    const productsData = {
      ...productState,
      products: JSON.stringify(productState.products),
    };
    const data = {
      ...informationState,
      ...notificationState,
      ...productsData,
    };
    if (informationState.type === FundraisingTypes.standard) {
      return {
        ...data,
        standard_info: {
          ...standardInfoState,
          order: JSON.stringify(standardInfoState.order),
        },
        assets: standardAssetState,
      };
    }
    if (informationState.type === FundraisingTypes.event) {
      return {
        ...data,
        event_info: {
          ...eventInfoState,
          order: JSON.stringify(eventInfoState.order),
        },
        assets: standardAssetState,
      };
    }
    return {
      ...data,
      matching_info: {
        ...matchingInfoState,
        order: JSON.stringify(matchingInfoState.order),
      },
      assets: matchingAssetState,
      teams: JSON.stringify(teamState),
    };
  };

  const saveFundraising = () => {
    if (saved) {
      dispatch(putFundraisingsAction(transformData(), setSaved));
      return;
    }
    dispatch(postFundraisingsAction(transformData(), setSaved));
  };

  const setTabError = (tab: TABS, hasError: boolean) => {
    setHasErrors({
      ...hasErrors,
      [tab]: hasError,
    });
  };

  const changeTabCallback = (tab: TABS) => {
    setHasChanged(false);
    setCurrentTab(tab);
  };

  const changeTab = (tab: TABS) => {
    if (hasChanged) {
      dispatch(openDialog(
        { dialog: DIALOGS.leaveTab, payload: { callback: () => changeTabCallback(tab) } },
      ));
      return;
    }
    setCurrentTab(tab);
  };

  const onNextStep = (newStep: number) => {
    if (saved) {
      saveFundraising();
      return;
    }
    if (newStep > step) {
      setStep(newStep);
    }
    switch (newStep) {
      case 2:
        setCurrentTab(TABS.notifications);
        break;
      case 3:
        setCurrentTab(TABS.products);
        break;
      case 4:
        switch (type) {
          case FundraisingTypes.event:
            setCurrentTab(TABS.event_info);
            break;
          case FundraisingTypes.matching:
            setCurrentTab(TABS.matching_info);
            break;
          default:
            setCurrentTab(TABS.standard_info);
            break;
        }
        break;
      case 5:
        switch (type) {
          case FundraisingTypes.matching:
            setCurrentTab(TABS.teams);
            break;
          case FundraisingTypes.event:
            setCurrentTab(TABS.event_assets);
            break;
          default:
            setCurrentTab(TABS.standard_assets);
            break;
        }
        break;
      case 6:
        switch (type) {
          case FundraisingTypes.matching:
            setCurrentTab(TABS.matching_assets);
            break;
          default:
            saveFundraising();
            break;
        }
        break;
      case 7:
        saveFundraising();
        break;
      default:
        break;
    }
  };

  const displayForm = () => {
    switch (currentTab) {
      case TABS.products:
        return (
          <FundraisingProductdForm
            onChange={setProductState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            type={informationState.type || type}
            initialState={productState}
            setHasChanged={setHasChanged} />
        );
      case TABS.standard_info:
        return (
          <FundraisingStandardInfoForm
            onChange={setStandardInfoState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            initialState={standardInfoState}
            setHasChanged={setHasChanged} />
        );
      case TABS.matching_info:
        return (
          <FundraisingMatchingInfoForm
            onChange={setMatchingInfoState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            initialState={matchingInfoState}
            setHasChanged={setHasChanged} />
        );
      case TABS.event_info:
        return (
          <FundraisingEventInfoForm
            onChange={setEventInfoState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            slug={informationState.slug}
            initialState={eventInfoState}
            setHasChanged={setHasChanged} />
        );
      case TABS.standard_assets:
      case TABS.event_assets:
        return (
          <FundraisingStandardAssetsForm
            onChange={setStandardAssetState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            slug={informationState.slug}
            languages={informationState.languages}
            initialState={standardAssetState}
            setHasChanged={setHasChanged} />
        );
      case TABS.matching_assets:
        return (
          <FundraisingMatchingAssetsForm
            onChange={setMatchingAssetState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            slug={informationState.slug}
            languages={informationState.languages}
            initialState={matchingAssetState}
            setHasChanged={setHasChanged} />
        );
      case TABS.teams:
        return (
          <FundraisingTeams
            onChange={setTeamState}
            onNextStep={onNextStep}
            setHasChanged={setHasChanged} />
        );
      case TABS.notifications:
        return (
          <FundraisingNotificationForm
            onChange={setNotificationState}
            onNextStep={onNextStep}
            setErrors={setTabError}
            slug={informationState.slug}
            initialState={notificationState}
            setHasChanged={setHasChanged} />
        );
      default:
        return (
          <FundraisingInformationForm
            onChange={setInformationState}
            onTypeChange={(value:FundraisingTypes) => setType(value)}
            onNextStep={onNextStep}
            setErrors={setTabError}
            initialState={informationState}
            setHasChanged={setHasChanged} />
        );
    }
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.row}>
          <div className={classes.title}>Add a fundraising</div>
          <Button
            style={{ marginLeft: 'auto', marginBottom: '0px' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </div>
      </div>
      <div className={classes.row}>
        <SubmenuLink
          onClick={() => changeTab(TABS.info)}
          selected={currentTab === TABS.info}
          hasError={hasErrors[TABS.info]}
          color="#0A4DF2">
          Informations
        </SubmenuLink>
        <SubmenuLink
          onClick={() => changeTab(TABS.notifications)}
          selected={currentTab === TABS.notifications}
          disabled={(step < 2) || !informationState.slug}
          color="#0A4DF2"
          hasError={hasErrors[TABS.notifications]}>
          Notifications/Social
        </SubmenuLink>
        <SubmenuLink
          onClick={() => changeTab(TABS.products)}
          selected={currentTab === TABS.products}
          disabled={(step < 3) || !informationState.slug}
          color="#0A4DF2"
          hasError={hasErrors[TABS.products]}>
          Products
        </SubmenuLink>
        { type === FundraisingTypes.event && (
          <>
            <SubmenuLink
              onClick={() => changeTab(TABS.event_info)}
              selected={currentTab === TABS.event_info}
              disabled={(step < 4) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.event_info]}>
              Info
            </SubmenuLink>
            <SubmenuLink
              onClick={() => changeTab(TABS.event_assets)}
              selected={currentTab === TABS.event_assets}
              disabled={(step < 5) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.event_assets]}>
              Assets
            </SubmenuLink>
          </>
        )}
        { type === FundraisingTypes.standard && (
          <>
            <SubmenuLink
              onClick={() => changeTab(TABS.standard_info)}
              selected={currentTab === TABS.standard_info}
              disabled={(step < 4) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.standard_info]}>
              Info
            </SubmenuLink>
            <SubmenuLink
              onClick={() => changeTab(TABS.standard_assets)}
              selected={currentTab === TABS.standard_assets}
              disabled={(step < 5) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.standard_assets]}>
              Assets
            </SubmenuLink>
          </>
        )}
        { type === FundraisingTypes.matching && (
          <>
            <SubmenuLink
              onClick={() => changeTab(TABS.matching_info)}
              selected={currentTab === TABS.matching_info}
              disabled={(step < 4) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.matching_info]}>
              Info
            </SubmenuLink>
            <SubmenuLink
              onClick={() => changeTab(TABS.matching_assets)}
              selected={currentTab === TABS.matching_assets}
              disabled={(step < 4) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.matching_assets]}>
              Asset
            </SubmenuLink>
            <SubmenuLink
              onClick={() => changeTab(TABS.teams)}
              selected={currentTab === TABS.teams}
              disabled={(step < 6) || !informationState.slug}
              color="#0A4DF2"
              hasError={hasErrors[TABS.teams]}>
              Teams
            </SubmenuLink>
          </>
        )}
      </div>
      <div className={classes.scroller}>
        {displayForm()}
      </div>
    </>
  );
};

export default AddFundraisingPage;
