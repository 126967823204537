import { Reducer } from 'redux';
import { EntityStatusState } from 'src/models/FormModel';
import { RESET_ENTITY_STATUS, SET_ENTITY_STATUS } from 'src/redux/actions/entityStatusActions';

const DEFAULT: EntityStatusState = {} as EntityStatusState;

const EntityStatusReducer: Reducer<EntityStatusState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ENTITY_STATUS: {
      const { entity, id, status } = action.payload;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          [id]: status,
        },
      };
    }

    case RESET_ENTITY_STATUS:
      return DEFAULT;

    default:
      return state;
  }
};

export default EntityStatusReducer;
