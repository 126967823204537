import contact from 'src/i18n/en/forms/contact';
import payments from 'src/i18n/en/forms/payments';
import organisations from 'src/i18n/en/forms/organisations';
import invoices from 'src/i18n/en/forms/invoices';
import customfields from 'src/i18n/en/forms/customfields';
import groups from 'src/i18n/en/forms/groups';
import creditcards from 'src/i18n/en/forms/creditcards';
import bankaccounts from 'src/i18n/en/forms/bankaccounts';
import organisation from 'src/i18n/en/forms/organisation';
import products from 'src/i18n/en/forms/products';
import relations from 'src/i18n/en/forms/relations';
import fundraising from 'src/i18n/en/forms/fundraising';
import { tax } from 'src/i18n/en/forms/tax';

const forms = {
  bankaccounts,
  relations,
  contact,
  creditcards,
  customfields,
  groups,
  organisations,
  organisation,
  invoices,
  payments,
  products,
  fundraising,
  tax,
};

export default forms;
