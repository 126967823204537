import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';
import { keyBy, multiply } from 'lodash';
import { InvoiceModel, ProductOrderModel } from 'src/models/InvoiceModel';
import {
  Store,
  useAppDispatch,
} from 'src/redux/Store';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getInvoiceAction } from 'src/redux/actions/invoiceActions';
import { useMediaQuery } from 'react-responsive';
import GuessPageHeader from 'src/components/Elements/Guess/GuessPageHeader';
import { Button } from '@material-ui/core';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import moment from 'moment-timezone-all';
import { getS3URL } from 'src/lib/S3File';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  status: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: isTabletOrMobile ? theme.spacing(2) : theme.spacing(1),
  },
  col1: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: '100%',
    width: '100%',
    alignItems: 'start',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    alignItems: 'start',
  },
  pagination: {
    height: '4vh',
    paddingTop: '1vh',
    '& > ul': {
      justifyContent: 'center',
    },
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mainGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid black',
    borderRadius: '3px',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2)}`,
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  table: {
    width: '100%',
    '& thead': {
      backgroundColor: 'rgba(240, 240, 240, 1)',
    },
  },
}));

interface Props {
  setInvoiceId?: (id: number) => void;
  isGuess?: boolean,
}

const UserInvoiceDisplayer = (props: Props) => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState(1);
  const currentOrganisation = useSelector((state: Store) => state.organisation);

  const { setInvoiceId, isGuess } = props;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );

  const invoice = useSelector(
    (state: Store) => {
      if (!!state.invoices[selections[currentIndex] as number]) {
        return state.invoices[selections[currentIndex] as number];
      }
      return {} as InvoiceModel;
    },
  );

  const lang = useSelector((state: Store) => state.language.language);

  const countries = useSelector((state: Store) => state.data.countries);

  const handleOpen = () => {
    dispatch(openModal({
      modal: MODALS.guessPayment,
      payload: selections[0],
    }));
  };

  useEffect(() => {
    dispatch(getInvoiceAction(selections[currentIndex]));
    if (setInvoiceId) {
      setInvoiceId(selections[currentIndex]);
    }
  }, [currentIndex]);

  const displayButtons = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleOpen}
        disabled={!!!selections[0]}
        style={{ marginRight: '50px' }}>
        Pay
      </Button>
    </>
  );

  const getCountries = (id:number) => {
    let index = 0;
    while (index < countries.length) {
      if (countries[index].id === id) {
        return countries[index][`name_${lang}`];
      }
      index += 1;
    }
    return '';
  };

  const handlePageChange = (newPage:number) => {
    setPage(newPage);
    setCurrentIndex(newPage - 1);
    if (setInvoiceId) {
      setInvoiceId(selections[newPage - 1]);
    }
  };

  const displayPagination = () => {
    if (selections.length < 2) return '';
    return (
      <Pagination
        className={classes.pagination}
        color="primary"
        count={selections.length}
        variant="outlined"
        shape="rounded"
        size="small"
        page={page}
        onChange={(e, newPage) => handlePageChange(newPage)} />
    );
  };

  const displayOrderProductName = (order: ProductOrderModel) => {
    if (!!invoice.products && invoice.products.length > 0) {
      const currentProduct = keyBy((invoice.products), 'id')[order.product_id];

      if (!!currentProduct) {
        return currentProduct[`name_${lang ?? 'en'}`];
      }
    }
    return '';
  };

  const getDate = (value: any) => {
    if (!moment.isMoment(value)) return '';
    return value.format('YYYY-DD-mm');
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        {displayPagination()}
        { (isGuess) && (<GuessPageHeader />)}
        <table style={{ marginTop: '50px', width: '100%' }}>
          <tr style={{ verticalAlign: 'top' }}>
            <td style={{ width: '40%' }}>
              <b>{currentOrganisation[`name_${lang ?? 'en'}`]}</b><br />
              {currentOrganisation.address}, {currentOrganisation.suite} <br />
              {currentOrganisation.city}, {currentOrganisation.state},
              {currentOrganisation.zipCode}<br />
              {getCountries(currentOrganisation.countryId ?? 0)} <br />
              {currentOrganisation.phone}<br />
              {currentOrganisation.tollfreePhone}
            </td>
            <td style={{ width: '30%' }}>
              <img width="150px" src={`${getS3URL()}${currentOrganisation.id}/images/organisation_logo.png?${performance.now()}`} alt="-" />
            </td>
            <td style={{ width: '30%', textAlign: 'right' }}>
              <div className={classes.col2}>
                Invoice No: <strong>{invoice.invoice_number}</strong><br />
                Date: <strong>{getDate(invoice.issued_date)}</strong>  <br />
                Due Date: <strong>{getDate(invoice.due_date)}</strong>  <br />
              </div>
            </td>
          </tr>
        </table>

        <h3>Billing to</h3>
        <div className={classes.col2}>
          {invoice.billing_address} {invoice.billing_suite}<br />
          {invoice.billing_city}, {invoice.billing_state}, {invoice.billing_zip_code}<br />
          {getCountries(invoice.billing_country_id ?? 0)} <br />
        </div>
        {
        !!invoice.products && (
          <div style={{ marginTop: '32px' }}>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Qty X Price</th>
                  <th>discount</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoice.order?.map(
                  (order: ProductOrderModel) => (
                    <tr key={`product-${order.product_id}`}>
                      <td style={{ textAlign: 'center' }}>{displayOrderProductName(order)}</td>
                      <td style={{ textAlign: 'center' }}>{order.quantity}X{formatter.format(order.paid ?? 0)}</td>
                      <td style={{ textAlign: 'center' }}>{formatter.format(order.discount ?? 0)}</td>
                      <td style={{ textAlign: 'center' }}>
                        { formatter.format(
                          multiply((order.paid ?? 0) - (order.discount ?? 0), order.quantity ?? 0),
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
              <tfoot>
                <tr><td colSpan={4}><hr /></td></tr>
                <tr>
                  <td colSpan={3} className={classes.alignRight}>
                    <i>Sub-total:</i>
                  </td>
                  <td colSpan={1} className={classes.alignRight}>
                    <i>{formatter.format(invoice.subtotal ?? 0)}</i>
                  </td>
                </tr>
                <tr><td colSpan={4}><hr /></td></tr>
                <tr>
                  <td colSpan={3} className={classes.alignRight}>
                    <b>Total:</b>
                  </td>
                  <td colSpan={1} className={classes.alignRight}>
                    <b>{formatter.format(invoice.total ?? 0)}</b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )
      }
      </div>
      <div style={{ marginTop: '50px', marginBottom: '50px', textAlign: 'center' }}>
        <span style={{ marginRight: '30px' }}><b>Paid:</b> {formatter.format(invoice.paid ?? 0)}</span>
        <span style={{ marginRight: '30px' }}><b>Scheduled:</b> {formatter.format(invoice.scheduled ?? 0)}</span>
        <span><b>Balance:</b> {formatter.format(invoice.balance ?? 0)}</span>
      </div>
      <div style={{ textAlign: 'center' }}>
        {displayButtons()}
      </div>
    </>
  );
};

export default UserInvoiceDisplayer;
