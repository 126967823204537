import { Reducer } from 'redux';
import { UserModel } from 'src/models/UserModel';
import { RESET_USER_DATA, SET_USER_DATA } from 'src/redux/actions/userActions';

export type UserState = UserModel;

const DEFAULT: UserState = { } as UserModel;

const userReducer: Reducer<UserState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case RESET_USER_DATA:
      return DEFAULT;

    default:
      return state;
  }
};

export default userReducer;
