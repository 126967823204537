import { State } from 'src/redux/reducers/RootReducer';
import { getGooglePlaceData, getGooglePredictionsData } from 'src/apis/GoogleAPI';
import { AddressModel } from 'src/models/AddressModel';
import { CountryListModel } from 'src/models/CountryListModel';

const getCountryCode = (country: string, countries: CountryListModel) => {
  let searching = true;
  let result = 0;
  let index = 0;

  while (searching) {
    if (country === countries[index].ISO2) {
      result = countries[index].id;
      searching = false;
    }
    index += 1;
    if (!countries[index]) {
      searching = false;
    }
  }
  return result;
};

const normalizeGoogleResult = (result:any, countries: CountryListModel) => {
  if (!result) return {} as AddressModel;
  let streetNumber = '';
  let road = '';
  let state = '';
  let country = '';
  let zipCode = '';
  let sublocality = '';
  let locality = '';

  result.forEach((item:any) => {
    if (item.types.indexOf('street_number') >= 0) {
      streetNumber = item.short_name;
    }
    if (item.types.indexOf('route') >= 0) {
      road = item.long_name;
    }
    if (item.types.indexOf('sublocality') >= 0) {
      sublocality = item.short_name;
    }
    if (item.types.indexOf('locality') >= 0) {
      locality = item.short_name;
    }
    if (item.types.indexOf('administrative_area_level_1') >= 0) {
      state = item.short_name;
    }
    if (item.types.indexOf('country') >= 0) {
      country = item.short_name;
    }
    if (item.types.indexOf('postal_code') >= 0) {
      zipCode = item.short_name;
    }
  });
  return {
    address: `${streetNumber} ${road}`,
    city: locality || sublocality,
    state,
    country_id: getCountryCode(country, countries),
    zip_code: zipCode,
  } as AddressModel;
};

export const getGooglePredictionsAction: (guess: string) => ThunkedAction<State, any[]> =
  (guess: string) => async () => {
    try {
      const response = await getGooglePredictionsData(guess);
      if (response.success) {
        return response.data.map(
          (result: any) => ({
            description: result.description,
            place_id: result.place_id,
          }),
        );
      }
      return [] as any[];
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return [] as any[];
    }
  };

export const getGooglePlaceAction: (placeId: string) => ThunkedAction<State, AddressModel> =
  (placeId: string) => async (dispatch: any, getState: any) => {
    try {
      const { countries } = getState().data;
      const response = await getGooglePlaceData(placeId);
      if (response.success) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { address_components } = response.data;
        return normalizeGoogleResult(address_components, countries);
      }
      return {} as AddressModel;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {} as AddressModel;
    }
  };
