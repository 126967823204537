export enum Route {
  INVOICES = 'invoices',
  INVOICES_CONTACT = 'invoices_contact',
  INVOICES_PAYMENT = 'invoices_payment',
  PAYMENTS_CONTACT = 'payments_contact',
  PAYMENTS_INVOICE = 'payments_invoice',
  GUESS_INVOICE = 'guess-invoice',
  USER_INVOICES = 'user-invoices',
  USER_PAYMENTS = 'user-payments',
  USER_RECEIPTS = 'user-receipts',
  DEPOSITS = 'deposits',
  PAYMENTS = 'payments',
  PRODUCTS = 'products',
  CONTACTS = 'contacts',
  ADMIN = 'admin',
  SETTINGS = 'settings',
  USER_PAYMENT_SETTINGS = 'user-payment-settings',
  PROFILE = 'profile',
  ORGANISATIONS = 'organisations',
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  LOGOUT = 'logout',
  REGISTER = 'register',
  NOT_VERIFIED = 'not-verified',
  VERIFY = 'verify',
  RESET_PASSWORD = 'reset-password',
  ONBOARDING = 'onboarding',
  FUNDRAISING = 'events',
}

export enum Path {
  INVOICES_CONTACT = 'invoices/contact/:contact_id',
  INVOICES_PAYMENT = 'invoices/payment/:payment_id',
  PAYMENTS_CONTACT = 'payments/contact/:contact_id',
  PAYMENTS_INVOICE = 'payments/invoice/:invoice_id',
}

const routes = {
  [Route.FUNDRAISING]: {
    path: `/${Route.FUNDRAISING}`,
    name: Route.FUNDRAISING,
    content: Route.FUNDRAISING,
  },
  [Route.INVOICES]: {
    path: `/${Route.INVOICES}`,
    name: Route.INVOICES,
    content: Route.INVOICES,
  },
  [Route.INVOICES_CONTACT]: {
    path: `/${Path.INVOICES_CONTACT}`,
    name: Route.INVOICES_CONTACT,
    content: Route.INVOICES_CONTACT,
  },
  [Route.INVOICES_PAYMENT]: {
    path: `/${Path.INVOICES_PAYMENT}`,
    name: Route.INVOICES_PAYMENT,
    content: Route.INVOICES_PAYMENT,
  },
  [Route.ONBOARDING]: {
    path: `/${Route.ONBOARDING}`,
    name: Route.ONBOARDING,
    content: Route.ONBOARDING,
  },
  [Route.GUESS_INVOICE]: {
    path: `/${Route.GUESS_INVOICE}`,
    name: Route.GUESS_INVOICE,
    content: Route.GUESS_INVOICE,
  },
  [Route.USER_INVOICES]: {
    path: `/${Route.USER_INVOICES}`,
    name: Route.USER_INVOICES,
    content: Route.USER_INVOICES,
  },
  [Route.DEPOSITS]: {
    path: `/${Route.DEPOSITS}`,
    name: Route.DEPOSITS,
    content: Route.DEPOSITS,
  },
  [Route.PAYMENTS]: {
    path: `/${Route.PAYMENTS}`,
    name: Route.PAYMENTS,
    content: Route.PAYMENTS,
  },
  [Route.PAYMENTS_CONTACT]: {
    path: `/${Path.PAYMENTS_CONTACT}`,
    name: Route.PAYMENTS_CONTACT,
    content: Route.PAYMENTS_CONTACT,
  },
  [Route.PAYMENTS_INVOICE]: {
    path: `/${Path.PAYMENTS_INVOICE}`,
    name: Route.PAYMENTS_INVOICE,
    content: Route.PAYMENTS_INVOICE,
  },
  [Route.USER_PAYMENTS]: {
    path: `/${Route.USER_PAYMENTS}`,
    name: Route.USER_PAYMENTS,
    content: Route.USER_PAYMENTS,
  },
  [Route.USER_PAYMENT_SETTINGS]: {
    path: `/${Route.USER_PAYMENT_SETTINGS}`,
    name: Route.USER_PAYMENT_SETTINGS,
    content: Route.USER_PAYMENT_SETTINGS,
  },
  [Route.USER_RECEIPTS]: {
    path: `/${Route.USER_RECEIPTS}`,
    name: Route.USER_RECEIPTS,
    content: Route.USER_RECEIPTS,
  },
  [Route.PRODUCTS]: {
    path: `/${Route.PRODUCTS}`,
    name: Route.PRODUCTS,
    content: Route.PRODUCTS,
  },
  [Route.CONTACTS]: {
    path: `/${Route.CONTACTS}`,
    name: Route.CONTACTS,
    content: Route.CONTACTS,
  },
  [Route.ADMIN]: {
    path: `/${Route.ADMIN}`,
    name: Route.ADMIN,
    content: Route.ADMIN,
  },
  [Route.SETTINGS]: {
    path: `/${Route.SETTINGS}`,
    name: Route.SETTINGS,
    content: Route.SETTINGS,
  },
  [Route.PROFILE]: {
    path: `/${Route.PROFILE}`,
    name: Route.PROFILE,
    content: Route.PROFILE,
  },
  [Route.ORGANISATIONS]: {
    path: `/${Route.ORGANISATIONS}`,
    name: Route.ORGANISATIONS,
    content: Route.ORGANISATIONS,
  },
  [Route.DASHBOARD]: {
    path: '/',
    exact: true,
    name: Route.DASHBOARD,
    content: Route.DASHBOARD,
  },
  [Route.LOGIN]: {
    path: `/${Route.LOGIN}`,
    exact: false,
    name: Route.LOGIN,
    content: Route.LOGIN,
  },
  [Route.LOGOUT]: {
    path: `/${Route.LOGOUT}`,
    exact: false,
    name: Route.LOGOUT,
    content: Route.LOGOUT,
  },
  [Route.REGISTER]: {
    path: `/${Route.REGISTER}`,
    exact: false,
    name: Route.REGISTER,
    content: Route.REGISTER,
  },
  [Route.NOT_VERIFIED]: {
    path: `/${Route.NOT_VERIFIED}`,
    exact: false,
    name: Route.NOT_VERIFIED,
    content: Route.NOT_VERIFIED,
  },
  [Route.VERIFY]: {
    path: `/user/${Route.VERIFY}`,
    exact: false,
    name: Route.VERIFY,
    content: Route.VERIFY,
  },
  [Route.RESET_PASSWORD]: {
    path: '/reset/password',
    exact: false,
    name: Route.RESET_PASSWORD,
    content: Route.RESET_PASSWORD,
  },
};

export default routes;
