import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { ComponentPropsWithoutRef } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    height: 34,
    width: 34,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 2,
    marginLeft: 5,
    textAlign: 'center',
    display: 'inline-block',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const ProfileIcon = (props: ComponentPropsWithoutRef<'div'>) => {
  const classes = useStyles();
  return (
    <div className={classes.icon} {...props}>
      <PersonIcon style={{ color: 'white' }} />
    </div>
  );
};

export default ProfileIcon;
