import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const Column = (props: Props) => {
  const { children } = props;
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
    }}>
      {children}
    </div>
  );
};

export default Column;
