import * as Yup from 'yup';
/* eslint-disable import/no-extraneous-dependencies */
import { t } from 'src/lib/language';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import { PAYMENT_STATUS, PAYMENT_TYPE } from 'src/models/PaymentModel';

const isOnlinePayment = (
  value: PAYMENT_TYPE,
) => [PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(value);

const isOfflinePayment = (
  value: PAYMENT_TYPE,
) => ![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(value);

/* eslint-disable react/no-this-in-sfc */
const PaymentValidator = (lang:string) => Yup.object().shape({
  amount: Yup.number()
    .min(0.01, t(lang, 'validations.invoice.field_required'))
    .required('Required'),
  contact_id: Yup.number()
    .min(1, t(lang, 'validations.invoice.field_required'))
    .required('Required')
    .integer(),
  processed_date: Yup.date()
    .when('payment_status', {
      is: (
        status: PAYMENT_STATUS,
      ) => status === PAYMENT_STATUS.paid,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  scheduled_date: Yup.date()
    .nullable()
    .when(['payment_status'], {
      is: (
        status: PAYMENT_STATUS,
      ) => status === PAYMENT_STATUS.scheduled,
      then: (schema) => schema.min(moment().startOf('day')).required(),
      otherwise: (schema) => schema.nullable(),
    }),
  paid_date: Yup.date()
    .when(['payment_type', 'payment_status'], {
      is: (
        type: PAYMENT_TYPE,
        status: PAYMENT_STATUS,
      ) => isOfflinePayment(type) && status === PAYMENT_STATUS.paid,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  payment_info_id: Yup.number()
    .nullable()
    .when('payment_type', {
      is: (
        type: PAYMENT_TYPE,
      ) => isOnlinePayment(type),
      then: (schema) => schema.min(1).integer().required(),
    }),
  bank_account_id: Yup.number()
    .nullable()
    .min(1, t(lang, 'validations.invoice.field_required'))
    .when('payment_type', {
      is: (
        type: PAYMENT_TYPE,
      ) => isOfflinePayment(type),
      then: (schema) => schema.required()
        .integer()
        .min(1, t(lang, 'validations.invoice.field_required')),
    }),
  payment_gateway_id: Yup.number()
    .when('payment_type', {
      is: (value: PAYMENT_TYPE) => isOnlinePayment(value),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })
    .integer(),
  billing_address: Yup.string()
    .max(250, t(lang, 'validations.invoice.field_maximum', { max: 250 }))
    .nullable(),
  billing_suite: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_city: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_state: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_country_id: Yup.number()
    .min(0, 'billing_country_required').max(999)
    .integer()
    .nullable(),
  billing_zip_code: Yup.string()
    .max(7, t(lang, 'validations.invoices.field_maximum', { max: 7 }))
    .nullable(),
});

export default PaymentValidator;
