import { State } from 'src/redux/reducers/RootReducer';
import { IMAGES_TYPE } from 'src/constants/Images';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

import { putUploadImage } from 'src/apis/UploadAPI';

export const uploadFile:
(
  file: string,
  type: IMAGES_TYPE,
  extention: string,
  successmsg: string,
  errormsg: string,
) => ThunkedAction<State> =
  (
    file: string,
    type: IMAGES_TYPE,
    extension: string,
    successmsg: string,
    errormsg: string,
  ) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const path = `${organisation}/images/${type}.${extension}`;
      const payload = {
        path,
        file,
      };
      const response = await putUploadImage(organisation, payload);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          message: successmsg,
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: errormsg,
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: errormsg,
      }));
    }
  };
