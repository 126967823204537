import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import {
  ChangeEvent,
  useRef,
  useState,
} from 'react';
import { useDebounceEffect } from 'src/lib/useDebounceEffect';
import { canvasPreview } from 'src/lib/canvasPreview';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ArrowBack } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Resizer from 'react-image-file-resizer';
import { t } from 'src/lib/language';
import { MODALS } from 'src/models/ModalModel';
import { uploadFileAction } from 'src/redux/actions/uploadFileAction';
import { closeModal } from 'src/redux/actions/modalActions';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

const useStyles = makeStyles(() => ({
  row: {
    width: '100%',
    padding: '8px 0px',
    display: 'flex',
    justifyContent: 'left',
    gap: '8px',
    alignItems: 'center',
  },
  header: {
    fontSize: '1.2rem',
    fontFamily: 'Qanelas-Bold',
    marginTop: '16px',
  },
  scroller: {
    overflowY: 'auto',
    height: '72vh',
    padding: '8px',
  },
}));

interface ImageSize {
  width: number,
  height: number,
  ratio: number,
}

interface Payload {
  size: ImageSize,
  path: string,
  name: string,
  maxSize?: number,
}
const FormImageCropper = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    size, path, name,
  } = useSelector(
    (state: Store) => state.modals[MODALS.uploadImage]?.payload as Payload,
  );

  const InitialCrop: PixelCrop = {
    unit: 'px',
    x: 25,
    y: 25,
    width: size.width,
    height: size.height,
  };
  const lang = useSelector((state: Store) => state.language.language);
  const [crop, setCrop] = useState<Crop>(InitialCrop);
  const [imgSrc, setImgSrc] = useState('');
  const [image, setImage] = useState({} as File);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (!!reader.result?.toString()) {
          const newImage = new Image();
          newImage.onload = () => {
            if ((newImage.width < size.width) || (newImage.height < size.height)) {
              dispatch(setAlert({
                type: ALERT_TYPE.error,
                message: `Image too small, minimum size is ${size.width}px X ${size.height}px`,
              }));
              return;
            }
            setCrop(InitialCrop); // Makes crop preview update between images.
            // @ts-ignore
            setImgSrc(reader.result?.toString());
            // @ts-ignore
            setImage(e.target.files[0]);
          };
          newImage.src = reader.result?.toString();
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getNormalizedCrop = (pixelCrop: PixelCrop) => {
    if (imgRef.current) {
      const ratio = imgRef.current?.width / imgRef.current?.naturalWidth;
      const { width, height } = pixelCrop;
      return {
        ...pixelCrop,
        width: width * ratio,
        height: height * ratio,
      } as PixelCrop;
    }
    return InitialCrop;
  };

  const onImageLoad = () => {
    setCrop(getNormalizedCrop(InitialCrop));
    setCompletedCrop(getNormalizedCrop(InitialCrop));
  };

  const handleSettingImage = () => {};

  const resizeAndUpload = (result: any) => {
    const commaPosition = result.indexOf(',');
    dispatch(uploadFileAction(
      result.substring(commaPosition) as string,
      path,
      t(lang, 'messages.successfully_uploaded'),
      t(lang, 'messages.error_upload'),
    ));
    handleSettingImage();
  };

  const onBack = () => {
    dispatch(closeModal({ modal: MODALS.uploadImage }));
  };

  const onSave = () => {
    if (previewCanvasRef.current) {
      previewCanvasRef.current?.toBlob((blob:Nullable<Blob>) => {
        if (blob) {
          const file = new File([blob], name);
          Resizer.imageFileResizer(
            file, // Is the file of the image which will resized.
            size.width, // Is the maxWidth of the resized new image.
            size.height, // Is the maxHeight of the resized new image.
            'PNG',
            100,
            0, // Is the compressFormat of the resized new image.
            resizeAndUpload,
            'base64', // Is the output type of the resized new image.
          );
        }
      });
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        );
      }
    },
    100,
    [completedCrop, crop],
  );
  return (
    <div className="App">
      <div className={classes.header}>Cropping</div>
      <div className={classes.scroller}>
        <div className={classes.row}>
          <Button variant="contained" color="primary" onClick={onBack}>
            <ArrowBack />Back
          </Button>
          <Button variant="contained" component="label" color="primary">
            <AddIcon /> Choose a file
            <input type="file" hidden accept="image/*" onChange={onSelectFile} />
          </Button>
          <span style={{ padding: '8px' }}>{image.name}</span>
        </div>
        { Boolean(imgSrc) && (
        <ReactCrop
          crop={crop}
          minHeight={getNormalizedCrop(InitialCrop).height}
          minWidth={getNormalizedCrop(InitialCrop).width}
          onChange={(pixelCrop) => setCrop(pixelCrop)}
          onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
          aspect={size.ratio}>
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            onLoad={() => onImageLoad()} />
        </ReactCrop>
        ) }
        <div>
          { Boolean(completedCrop) && (
          <>
            <div className={classes.header}>Preview</div>
            <canvas
              ref={previewCanvasRef}
              width={size.width}
              height={size.height}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                maxWidth: size.width,
                minWidth: size.width,
                maxHeight: size.height,
                minHeight: size.height,
                marginTop: '8px',
              }} />
            <div className={classes.row}>
              <Button variant="contained" color="primary" onClick={onBack}>
                <ArrowBack />Back
              </Button>
              <Button variant="contained" color="primary" onClick={onSave}>
                <SaveIcon />Save
              </Button>
            </div>
          </>
          ) }
        </div>
      </div>
    </div>
  );
};

export default FormImageCropper;
