import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { TaxItemModel } from 'src/models/TaxListModel';

export const getTaxData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.taxes}`,
    organisation,
  });

export const putTaxData =
  (organisation: string, tax:TaxItemModel) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.tax}/${tax.id}`,
    organisation,
    data: tax,
  });

export const postTaxData =
  (organisation: string, tax:TaxItemModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.tax}`,
    organisation,
    data: tax,
  });

export const deleteTaxData =
  (organisation: string, tax:TaxItemModel) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.tax}/${tax.id}`,
    organisation,
  });
