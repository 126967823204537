import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { FORM } from 'src/constants/Form';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import ContactSelector from 'src/components/Control/ContactSelector';
import { EmptyFilter } from 'src/models/AdvanceSearchModel';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';

interface Props {
  entity: ENTITIES;
  index: number;
  autocompleteId: AUTOCOMPLETE_ID;
}

const OperandContact = (props: Props) => {
  const {
    entity,
    index,
    autocompleteId,
  } = props;

  const dispatch = useAppDispatch();

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operands];
          }
          return '';
        }
        return '';
      },
    );
  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [operator, filterList] = getOperatorAndFilters(filters);
  const contactId = filterList[index][FILTER_POSITION.operands];

  const handleChange = (operand: number) => {
    filterList[index][FILTER_POSITION.operands] = operand;
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  return (
    <div style={{ marginTop: '-8px', minWidth: '230px' }}>
      <ContactSelector
        autocompleteId={autocompleteId}
        error={error}
        name={`operand-contact-${index}`}
        onChange={handleChange}
        contactId={contactId}
        margin="dense"
        hideAdd
        hideEdit />
    </div>
  );
};

export default OperandContact;
