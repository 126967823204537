import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { useAppDispatch, Store } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { t } from 'src/lib/language';

const EditPaymentButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const { disabled } = props;
  const lang = useSelector((state: Store) => state.language.language);

  const handleOpen = () => {
    dispatch(openModal({ modal: MODALS.editPayment }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.edit_selected')}
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOpen}
            disabled={disabled}>
            <EditIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default EditPaymentButton;
