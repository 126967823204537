import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import Row from 'src/components/UI/Row';
import Header from 'src/components/UI/Header';
import Title from 'src/components/UI/Title';
import OrganisationList from 'src/components/Elements/OrganisationList';

const ChooseOrganisationForm = () => {
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.chooseOrganisation }));
  };

  return (
    <>
      <Header>
        <Row>
          <Title>{t(lang, 'misc.connect_to_organisation')}</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      <OrganisationList handleClose={handleClose} />
    </>
  );
};

export default ChooseOrganisationForm;
