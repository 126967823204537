// import { Store, useAppDispatch } from 'src/redux/Store';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import UserInvoiceDisplayer from 'src/components/Elements/User/UserInvoiceDisplayer';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import MakePaymentButton from 'src/components/DatagridToolbar/buttons/MakePaymentButton';
import DownloadSelectedInvoiceButton
  from 'src/components/DatagridToolbar/buttons/DownloadSelectedInvoiceButton';
import { useState } from 'react';
import { useAppDispatch } from 'src/redux/Store';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: MODALS_SIZE.large,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    height: '4vh',
    gap: '10px',
  },
  hide: {
    display: 'none',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(2),
    height: '66vh',
    padding: '8px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    height: '6vh',
  },
}));

const UserViewInvoiceModal = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [invoiceId, setInvoiceId] = useState(0);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.viewInvoice }));
  };

  return (
    <ModalBase
      name={MODALS.viewInvoice}
      size={MODALS_SIZE.medium}>
      <div className={classes.header}>
        <div className={classes.row}>
          <span className={classes.row}>
            <MakePaymentButton invoiceId={invoiceId} />
            <DownloadSelectedInvoiceButton invoiceId={invoiceId} />
          </span>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
      <UserInvoiceDisplayer setInvoiceId={setInvoiceId} />
    </ModalBase>
  );
};

export default UserViewInvoiceModal;
