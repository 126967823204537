import { StyleRules } from '@material-ui/styles';
import palette from 'src/styles/palette';

const formStyles: StyleRules = {
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth: '640px',
  },
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: palette.primary.light,
    padding: '8px',
    height: '48px',
  },
  title: {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.light,
    fontSize: '18px',
  },
  subtitle: {
    marginTop: '8px',
    borderBottom: 'solid #888 1px',
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
    height: '32px',
  },
  column: {
    maxWidth: '640px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  col3: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
  },
  scroller: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '604px',
    padding: '8px',
  },
  spacer: {
    height: '24px',
  },
  submit: {
    marginTop: '12px',
  },
  update: {
    marginTop: '12px',
  },
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
  hide: {
    display: 'none',
  },
  nomargin: {
    marginBottom: '0px',
  },
  clickable: {
    cursor: 'pointer',
  },
};

export default formStyles;
