export const tax = {
  name_en: 'Nom en',
  name_fr: 'Nom fr',
  abbreviation_en: 'Abbreviation en',
  abbreviation_fr: 'Abbreviation fr',
  description_en: 'Description en',
  description_fr: 'Description fr',
  rate: 'Taux',
  registration_number: 'Numero enregistrement',
  is_recoverable: 'est récupérable',
  percentage: 'Pourcentage',
};
