import { makeStyles } from '@material-ui/core/styles';
import { OrderColumn } from 'src/components/Elements/Guess/GuessOrderDisplayer';
import { ProductOrderModel } from 'src/models/InvoiceModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { ProductList } from 'src/models/ProductModel';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '30px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'end',
    flexDirection: 'row',
    gap: '10px',
  },
  actions: {
    marginRight: '4px',
    marginTop: '-8px',
    display: 'flex',
    gap: '8px',
  },
  odd: {
    backgroundColor: '#f8f8f8',
  },
  even: {
    backgroundColor: '#f0f0f0',
  },
  item: {
    padding: '0 4px',
  },
  quantity: {
    minWidth: OrderColumn.quantity,
    width: OrderColumn.quantity,
    padding: '0 4px',
  },
  product: {
    width: OrderColumn.product,
    padding: '0 4px',
  },
  price: {
    minWidth: OrderColumn.price,
    width: OrderColumn.price,
    padding: '0 4px',
  },
  taxes: {
    minWidth: OrderColumn.taxes,
    width: OrderColumn.taxes,
    padding: '0 4px',
    paddingTop: '4px',
  },
  discount: {
    minWidth: OrderColumn.discount,
    width: OrderColumn.discount,
  },
  deductible: {
    minWidth: OrderColumn.deductible,
    width: OrderColumn.deductible,
  },
  amount: {
    minWidth: OrderColumn.amount,
    width: OrderColumn.amount,
    textAlign: 'right',
  },
  divider: {
    width: '1px',
    height: '20px',
  },
  noteContainer: {
    width: '100%',
  },
}));

interface Props {
  index: number,
  order: ProductOrderModel,
  products: ProductList,
}

const GuessOrderProductItem = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles();
  const {
    index,
    order,
    products,
  } = props;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
  });

  const lang = useSelector((state: Store) => state.language);

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  const getProductName = (id:number) => {
    const product = getProduct(id);
    if (!product) return 'Product';
    return product[`name_${lang}`];
  };

  const [orderItemSubtotal, setOrderItemSubtotal] = useState(0);

  useEffect(() => {
    setOrderItemSubtotal((order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0)));
  }, [order.paid, order.quantity, order.discount]);

  const displayDiscount = () => {
    if (!order.discount) return '';
    return `-($${order.discount})`;
  };

  const showDeductible = (deductible: number | undefined) => {
    if (!deductible) return '';
    return `${deductible * 100}%`;
  };

  const showDesktop = () => (
    <div className={`${classes.container} ${index % 2 ? classes.odd : classes.even}`}>
      <div className={classes.items}>
        <div className={`${classes.item} ${classes.product}`}>
          {order.quantity ?? 1} X {getProductName(order.product_id)}
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.deductible}`}>
          {showDeductible(order.deductible)}
        </div>
        <div className={classes.divider} />
        <div className={`${classes.item} ${classes.price}`}>
          ${order.paid} {displayDiscount()}
        </div>
        <div className={classes.divider} />
        <div
          className={`${classes.item} ${classes.amount}`}>
          {formatter.format(orderItemSubtotal)}
        </div>
      </div>
    </div>
  );

  const showTablet = () => (
    <div className={`${classes.container} ${index % 2 ? classes.odd : classes.even}`}>
      <div className={classes.items}>
        <div className={`${classes.item} ${classes.product}`}>
          { order.quantity ?? 1 } X { getProductName(order.product_id) }
        </div>
        <div className={`${classes.item} ${classes.amount}`}>
          {formatter.format(orderItemSubtotal)}
        </div>
      </div>
    </div>
  );

  return isTabletOrMobile ? showTablet() : showDesktop();
};

export default GuessOrderProductItem;
