import DownloadContactResultDialog from 'src/components/Dialogs/DownloadContactResultDialog';
import DownloadContactSelectedDialog from 'src/components/Dialogs/DownloadContactSelectedDialog';
import DownloadInvoiceDialog from 'src/components/Dialogs/DownloadInvoiceDialog';
import DownloadPaymentDialog from 'src/components/Dialogs/DownloadPaymentDialog';
import ExportInvoiceDialog from 'src/components/Dialogs/ExportInvoiceDialog';
import ExportPaymentDialog from 'src/components/Dialogs/ExportPaymentDialog';
import DeleteContactDialog from 'src/components/Dialogs/DeleteContactDialog';
import DeleteCreditCardDialog from 'src/components/Dialogs/DeleteCreditCardDialog';
import DeleteBankAccountDialog from 'src/components/Dialogs/DeleteBankAccountDialog';
import DeleteCustomfieldDialog from 'src/components/Dialogs/DeleteCustomfieldDialog';
import DeletePresetDialog from 'src/components/Dialogs/DeletePresetDialog';
import ConfirmPaymentDialog from 'src/components/Dialogs/ConfirmPaymentDialog';
import DeleteGroupDialog from 'src/components/Dialogs/DeleteGroupDialog';
import CantCancelOnlinePayment from 'src/components/Dialogs/CantCancelOnlinePayment';
import CantRefundOfflinePayment from 'src/components/Dialogs/CantRefundOfflinePayment';
import CantRefundUnpaidPayment from 'src/components/Dialogs/CantRefundUnpaidPayment';
import DeleteTaxDialog from 'src/components/Dialogs/DeleteTaxDialog';
import DeleteProductDialog from 'src/components/Dialogs/DeleteProductDialog';
import DeleteContactRelationDialog from 'src/components/Dialogs/DeleteContactRelationDialog';
import DeleteRelationDialog from 'src/components/Dialogs/DeleteRelationDialog';
import RefundPaymentDialog from 'src/components/Dialogs/RefundPaymentDialog';
import CancelPaymentDialog from 'src/components/Dialogs/CancelPaymentDialog';
import ChangeEmailDialog from 'src/components/Dialogs/ChangeEmailDialog';
import ChangePasswordDialog from 'src/components/Dialogs/ChangePasswordDialog';
import DeleteAccountDialog from 'src/components/Dialogs/DeleteAccountDialog';
import DeleteDepartmentDialog from 'src/components/Dialogs/DeleteDepartmentDialog';
import DeleteProgramDialog from 'src/components/Dialogs/DeleteProgramDialog';
import CancelInvoiceDialog from 'src/components/Dialogs/CancelInvoiceDialog';
import ConfirmLeaveTabDialog from 'src/components/Dialogs/ConfirmLeaveTabDialog';
import LoginDialog from 'src/components/Dialogs/LoginDialog';

const DialogContainer = () => (
  <>
    <DeletePresetDialog />
    <CantCancelOnlinePayment />
    <CantRefundOfflinePayment />
    <CantRefundUnpaidPayment />
    <DeleteContactDialog />
    <DeleteGroupDialog />
    <DeleteAccountDialog />
    <DeleteDepartmentDialog />
    <DeleteProgramDialog />
    <DeleteCustomfieldDialog />
    <DeleteCreditCardDialog />
    <DeleteBankAccountDialog />
    <DownloadContactResultDialog />
    <DownloadContactSelectedDialog />
    <DownloadInvoiceDialog />
    <DownloadPaymentDialog />
    <ExportInvoiceDialog />
    <ExportPaymentDialog />
    <ConfirmPaymentDialog />
    <DeleteTaxDialog />
    <DeleteProductDialog />
    <DeleteContactRelationDialog />
    <DeleteRelationDialog />
    <RefundPaymentDialog />
    <CancelPaymentDialog />
    <ChangeEmailDialog />
    <ChangePasswordDialog />
    <CancelInvoiceDialog />
    <ConfirmLeaveTabDialog />
    <LoginDialog />
  </>
);

export default DialogContainer;
