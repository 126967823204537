import { ContactsModel } from 'src/models/ContactModel';
import { Reducer } from 'redux';
import {
  DELETE_CONTACTS,
  REMOVE_CONTACT,
  RESET_CONTACT,
  UPDATE_CONTACT,
} from 'src/redux/actions/contactActions';
import { omit } from 'lodash';

export type ContactsState = ContactsModel;

const DEFAULT = {} as ContactsState;

const contactsReducer: Reducer<ContactsState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case UPDATE_CONTACT: {
      const { id, data } = action.payload;
      return {
        ...state,
        [id]: {
          ...data,
        },
      };
    }
    case REMOVE_CONTACT: {
      const { id } = action.payload;
      const { [id]: contact, ...rest } = state;
      return rest;
    }
    case DELETE_CONTACTS: {
      const { ids } = action.payload;
      return omit(state, ids);
    }
    case RESET_CONTACT: {
      return DEFAULT;
    }
    default:
      return state;
  }
};

export default contactsReducer;
