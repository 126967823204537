import { FundraisingNotification } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { Store, useAppDispatch } from 'src/redux/Store';
import { MouseEvent, useState } from 'react';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import Button from '@material-ui/core/Button';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import ContactSelector from 'src/components/Control/ContactSelector';
import { useSelector } from 'react-redux';
import { getContactByIdAction } from 'src/redux/actions/contactActions';
import { indexOf } from 'lodash';
import ContactGroupSelector from 'src/components/Control/ContactGroupSelector';
import { Chip } from '@material-ui/core';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormUploadImage from 'src/components/Control/FormControls/FormUploadImage';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import FundraisingNotificationValidator from 'src/validations/FundraisingNotificationValidator';
import { ErrorBag } from 'src/models/ErrorModel';
import { subscribeEvent } from 'src/models/EventHelper';
import { EventType } from 'src/models/EventType';
import Debug from 'src/lib/Debug';
import { t } from 'src/lib/language';
import Form from 'src/components/UI/Form';
import Row from 'src/components/UI/Row';
import Subtitle from 'src/components/UI/Subtitle';
import BottomRow from 'src/components/UI/BottomRow';
import FundraiserTitle from 'src/components/UI/FundRaiserTitle';

interface Props {
  onChange: (notifications: FundraisingNotification) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  slug: string,
  initialState: FundraisingNotification,
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingNotificationForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    slug,
    onChange,
    onNextStep,
    setErrors,
    initialState,
    setHasChanged,
  } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const [validationError, setValidationError] = useState({} as ErrorBag);
  const [notificationState, setNotificationState] = useState(initialState);
  const [contactIds, setContactIds] = useState([] as number[]);
  const [contactId, setContactId] = useState<number | undefined>(0);
  const [groupdIds, setGroupdIds] = useState([] as number[]);
  const [emailBody, setEmailBody] = useState('');
  const [upload, setUpload] = useState(0);

  const contacts = useSelector((state: Store) => state.contacts);
  const lang = useSelector((state: Store) => state.language.language);

  const forceReload = () => setUpload(upload + 1);
  subscribeEvent(EventType.uploadComplete, () => forceReload());

  const onValueChange = (value: any, field: any) => {
    setHasChanged(true);
    setNotificationState({
      ...notificationState,
      [field]: value,
    });
  };

  const onGroupChange = (group:any) => {
    setGroupdIds(group);
    onValueChange(group, 'group_id_list');
  };

  const onContactChange = (id: number) => {
    setContactId(id);
    if (!contacts[id]) {
      dispatch(getContactByIdAction(id));
    }
  };

  const onAddContact = () => {
    if (!contactId) return;
    setContactIds([
      ...contactIds,
      contactId,
    ]);
    setContactId(undefined);
    onValueChange('contact_id_list', contactIds);
  };

  const removeContact = (id:number) => {
    const index = indexOf(contactIds, id);
    contactIds.splice(index, 1);
    setContactIds([...contactIds]);
    onValueChange('contact_id_list', contactIds);
  };

  const displayContacts = () => contactIds.map((id:number) => {
    if (!contacts[id]) return undefined;
    const { contact } = contacts[id];
    let label = `${contact.first_name} ${contact.last_name}`;
    if (contact.company_name && contact.company_name !== '') {
      label = contact.company_name;
    }
    label = `${label}(${contact.email})`;
    return (
      <Chip
        variant="outlined"
        label={label}
        onDelete={() => removeContact(id)} />
    );
  });

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.notifications, false);
    const errorBag = {} as ErrorBag;
    dispatch(setError({ [FORM.fundraising_notification]: {} }));
    notificationState.thanks_email_body = emailBody;
    notificationState.contacts = contactIds;
    notificationState.groups = groupdIds;
    try {
      FundraisingNotificationValidator().validateSync(notificationState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      if (!validationErrors.inner) {
        Debug.log(validationErrors);
        return;
      }
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_notification]: errorBag }));
      setValidationError(errorBag);
      setErrors(TABS.info, true);
      return;
    }
    onNextStep(3);
    onChange(notificationState);
  };

  const displayError = (label: string, field: string) => {
    if (validationError[field]?.length) {
      return (
        <div style={{ color: 'red' }}>
          {label} <i>{validationError[field].join(',')}</i>
        </div>
      );
    }
    return (<>{label}</>);
  };

  // @ts-ignore
  return (
    <div>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Row height="auto">
            <FundraiserTitle>Notifications/Social</FundraiserTitle>
          </Row>
          <Subtitle>
            { displayError(t(lang, 'forms.fundraising.contacts_to_notify'), 'contacts') }
          </Subtitle>
          <Row height="auto">
            <ContactSelector
              autocompleteId={AUTOCOMPLETE_ID.add_invoice_contact}
              onChange={(id: number) => onContactChange(id)}
              label={t(lang, 'forms.fundraising.add_contact')}
              form={FORM.fundraising_notification}
              name="add_contact"
              contactId={contactId}
              hideEdit />
            <Button
              style={{ marginBottom: '24px' }}
              variant="contained"
              color="primary"
              onClick={onAddContact}
              key="add-contact-button"
              disabled={!contactId}>
              {t(lang, 'forms.fundraising.add_contact_btn')}
            </Button>
          </Row>
          <Row height="auto">
            {displayContacts()}
          </Row>
          <Subtitle>
            { displayError(t(lang, 'forms.fundraising.groups_to_notify'), 'groups') }
          </Subtitle>
          <Row height="auto">
            <ContactGroupSelector
              onChange={onGroupChange}
              values={groupdIds} />
          </Row>
          <Subtitle>
            {t(lang, 'forms.fundraising.notifications')}
          </Subtitle>
          <FormCheckbox
            label={t(lang, 'forms.fundraising.send_thanks_email')}
            name="send_thanks_email"
            onChange={onValueChange}
            checked={notificationState.send_thanks_email} />
          { notificationState.send_thanks_email && (
            <FormRichTextEditor
              label={t(lang, 'forms.fundraising.thanks_email_body')}
              form={FORM.fundraising_notification}
              name="thanks_email_body"
              onChange={setEmailBody}
              value={notificationState.thanks_email_body ?? ''} />
          )}
          <FormCheckbox
            label={t(lang, 'forms.fundraising.send_thanks_sms')}
            name="send_thanks_sms"
            onChange={onValueChange}
            checked={notificationState.send_thanks_sms} />
          { notificationState.send_thanks_sms && (
            <FormTextField
              form={FORM.fundraising_notification}
              name="thanks_sms_text"
              onChange={onValueChange}
              value={notificationState.thanks_sms_text}
              minRows={3}
              maxRows={3}
              multiline
              label={t(lang, 'forms.fundraising.thanks_sms_text')} />
          )}
          <Subtitle>
            {t(lang, 'forms.fundraising.facebook_information')}
          </Subtitle>
          <Row style={{ width: '50%' }}>
            <FormTextField
              form={FORM.fundraising_notification}
              name="fb_description"
              onChange={onValueChange}
              value={notificationState.fb_description}
              minRows={3}
              maxRows={3}
              multiline
              label={t(lang, 'forms.fundraising.fb_description')} />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.fb_keyword')}
              name="fb_keyword"
              onChange={onValueChange}
              value={notificationState.fb_keyword} />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.media_title')}
              name="media_title"
              onChange={onValueChange}
              value={notificationState.media_title} />
            <FormTextField
              form={FORM.fundraising_notification}
              label={t(lang, 'forms.fundraising.media_text')}
              name="media_text"
              onChange={onValueChange}
              value={notificationState.media_text} />
          </Row>
          <FormUploadImage
            name="media_image_preview.png"
            height={110}
            width={576}
            path={`${organisation}/${slug}/images/media_image_preview.png`} />
        </div>
        <BottomRow>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            key="submit-button">
            {t(lang, 'forms.fundraising.next_step')}
          </Button>
        </BottomRow>
      </Form>
    </div>
  );
};

export default FundraisingNotificationForm;
//
