import * as Yup from 'yup';
import { t } from 'src/lib/language';

const CreateAdminValidator = (lang:string) => Yup.object().shape({
  password: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  email: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
});

export default CreateAdminValidator;
