import { Reducer } from 'redux';
import { UserCreditCardModel } from 'src/models/CreditCardModel';
import {
  SET_USER_CREDITCARDS,
  ADD_USER_CREDITCARD,
  DELETE_USER_CREDITCARD,
} from 'src/redux/actions/paymentInfoAction';

export type UserCreditCardState = UserCreditCardModel;

const DEFAULT = {} as UserCreditCardState;

const UserCreditCardReducer: Reducer<UserCreditCardState> = (state = DEFAULT, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_CREDITCARDS:
      return [...payload];
    case ADD_USER_CREDITCARD:
      return [
        ...state,
        ...payload,
      ];
    case DELETE_USER_CREDITCARD: {
      const cards = Object.values(state).filter((card) => (card.id !== payload));
      return [...cards];
    }
    default:
      return state;
  }
};

export default UserCreditCardReducer;
