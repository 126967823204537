import { useAppDispatch } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import TextLink from 'src/components/UI/TextLink';
import SubmenuLink from 'src/components/UI/SubmenuLink';
import UserCreditCardDisplayer from 'src/components/Elements/User/UserCreditCardDisplayer';
import UserBankAccountDisplayer from 'src/components/Elements/User/UserBankAccountDisplayer';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'bottom',
    height: '4vh',
    gap: '10px',
  },
  header: {
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    maxWidth: '640px',
  },
  subheader: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    marginTop: '12px',
    marginBottom: '4px',
  },
}));

const UserPaymentSettingsPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addUserCreditCard,
    }));
  };

  return (
    <div>
      <div className={`${classes.row} ${classes.header}`}>
        <SubmenuLink
          noMargin>
          <span>Payment Information</span>
        </SubmenuLink>
      </div>
      <div className={classes.subheader}>Credit Cards</div>
      <UserCreditCardDisplayer />
      <TextLink onClick={() => openAddCreditCardModal()}>Add a credit card</TextLink>
      <br />
      <div className={classes.subheader}>Bank Accounts</div>
      <UserBankAccountDisplayer />
    </div>
  );
};

export default UserPaymentSettingsPage;
