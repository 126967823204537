import { Reducer } from 'redux';
import { SET_ORGANISATIONS_USER_DATA } from 'src/redux/actions/organisationActions';
import { UserOrganisationListModel } from 'src/models/UserOrganisationListModel';

export type OrganisationsUserState = UserOrganisationListModel;

const DEFAULT: OrganisationsUserState = {} as OrganisationsUserState;

const organisationsUserReducer: Reducer<OrganisationsUserState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ORGANISATIONS_USER_DATA:
      return action.payload;

    default:
      return state;
  }
};

export default organisationsUserReducer;
