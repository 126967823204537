import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserMakeDonationForm from 'src/components/Forms/User/UserMakeDonationForm';

const UserDonationModal = () => (
  <ModalBase
    name={MODALS.userDonation}
    size={MODALS_SIZE.small}>
    <UserMakeDonationForm />
  </ModalBase>
);

export default UserDonationModal;
