import { Reducer } from 'redux';
import { RouteModel } from 'src/models/RouteModel';
import { SET_ROUTE_DATA } from 'src/redux/actions/routeActions';

export type RouteState = RouteModel;

const DEFAULT: RouteState = { } as RouteModel;

const routeReducer: Reducer<RouteState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ROUTE_DATA:
      return action.payload;

    default:
      return state;
  }
};

export default routeReducer;
