const invoices = {
  accounts: 'Comptes',
  allow_installments: 'Plusieurs versements',
  account: 'Compte interne',
  amount: 'Montant',
  bank_account: 'Compte Bancaire',
  billing_address: 'Address de facturation',
  create_invoice: 'Créer une facture',
  contacts: 'Contacts',
  contact: 'Contact',
  deductible: 'Rabais Tax',
  discount: 'Rabais',
  departments: 'Départements',
  department: 'Département',
  due_date: 'Date limite',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  price: 'Prix',
  receipt: 'Reçu',
  qty: 'Qté',
  product: 'Produit',
  delete_invoice: 'Supprimer la facture',
  edit_invoices: 'Modification de factures',
  invoice_informations: 'Informations de la facture',
  issued_date: 'Date d\'émission',
  issue_tax_receipt: 'Émettre recu d\'impot',
  make_payment: 'Effectuer un paiement',
  make_offline_payment: 'Effectuer un paiement hors-ligne',
  note: 'Note',
  number_of_installments: 'Nombre de versement(s):',
  orders: 'Commandes',
  payment_status: 'Statut du paiement',
  invoice_status: 'Statut de la facture',
  payment_type: 'Méthode de paiement',
  programs: 'Programmes',
  paid_amount: 'Payé',
  view_note: 'Voir note',
  hide_note: 'Masquer note',
  payer: 'Payeur',
  program: 'Programme',
  products: 'Produits',
  quantity: 'Qté',
  seller: 'Vendeur',
  shipping_address: 'Addresse de Livraison',
  state: 'État de la facture',
  subtotal: 'Sous-total',
  taxes: 'Taxes',
  title: 'Titre',
  draft: 'Brouillon',
  final: 'Final',
  legacy: 'Ancien Format',
  error: 'Erreur',
  canceled: 'Annullée',
  abandoned: 'Abdandonnée',
  not_paid: 'Impayée',
  paid: 'Payée',
  scheduled: 'Prévue',
  scheduled_due: 'Schédulée due',
  partially_paid: 'Partiellement payée',
};

export default invoices;
