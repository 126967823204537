import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import {
  InvoiceModel, OrderList, OrderModel, ProductOrderModel,
} from 'src/models/InvoiceModel';
import { getInvoiceAction, putInvoiceAction } from 'src/redux/actions/invoiceActions';
import { INVOICE_STATE } from 'src/constants/Invoices';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import BottomRow from 'src/components/UI/BottomRow';

const DeleteInvoiceForm = () => {
  const dispatch = useAppDispatch();
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );
  const invoice = useSelector(
    (state: Store) => {
      if (!!state.invoices[selections[0] as number]) {
        return state.invoices[selections[0] as number];
      }
      return {} as InvoiceModel;
    },
  );
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    if (selections[0]) {
      dispatch(getInvoiceAction(selections[0] as number));
    }
  }, []);

  const handleCancel = () => {
    dispatch(closeModal({ modal: MODALS.deleteInvoice }));
  };

  const getTaxesForOrder = (order: ProductOrderModel):string => JSON.stringify(order.taxes);

  const denormalizeOrders = ():OrderList => {
    if (!invoice.order) return [] as OrderList;
    return {
      ...invoice.order.map((order: ProductOrderModel) => ({
        ...order,
        taxes: getTaxesForOrder(order),
      } as OrderModel)),
    };
  };

  const handleOk = () => {
    const payload = {
      invoice: {
        ...invoice,
        state: INVOICE_STATE.canceled,
      },
      order: denormalizeOrders(),
    };
    dispatch(putInvoiceAction(selections[0] as number, payload));
    dispatch(closeModal({ modal: MODALS.deleteInvoice }));
  };

  return (
    <>
      <Header height="50px">
        <Row>
          <Title>{t(lang, 'forms.invoices.delete_invoice')}&nbsp;</Title>
        </Row>
      </Header>
      <BottomRow style={{ alignItems: 'top' }}>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          onClick={handleOk}
          key="submit-button">
          {t(lang, 'misc.delete')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          onClick={handleCancel}
          key="submit-button-group-">
          {t(lang, 'misc.cancel')}
        </Button>
      </BottomRow>
    </>
  );
};

export default DeleteInvoiceForm;
