import { FIELD_TYPES } from 'src/constants/Fields';

const contacts = [
  {
    field: 'contacts.id',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'contacts.locale_id',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'contacts.salutation_id',
    minWidth: 150,
    type: FIELD_TYPES.salutation,
  },
  {
    field: 'contacts.email',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.email_status',
    minWidth: 150,
    type: FIELD_TYPES.emailStatus,
  },
  {
    field: 'contacts.email_allow_notification',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'contacts.secondary_email',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.secondary_email_allow_notification',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'contacts.company_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.first_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.last_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.gender',
    minWidth: 150,
    type: FIELD_TYPES.gender,
  },
  {
    field: 'contacts.billing_address',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.billing_city',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.billing_state',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.billing_suite',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.billing_zip_code',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.billing_country_id',
    minWidth: 150,
    type: FIELD_TYPES.country,
  },
  {
    field: 'contacts.shipping_address',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.shipping_city',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.shipping_state',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.shipping_suite',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.shipping_zip_code',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.shipping_country_id',
    minWidth: 150,
    type: FIELD_TYPES.country,
  },
  {
    field: 'contacts.phone_home',
    header: 'Phome Home',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'contacts.phone_home_allow_notification',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'contacts.phone_mobile',
    minWidth: 150,
    type: FIELD_TYPES.phone,
  },
  {
    field: 'contacts.phone_mobile_allow_notification',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'contacts.phone_office',
    minWidth: 150,
    type: FIELD_TYPES.phone,
  },
  {
    field: 'contacts.phone_office_allow_notification',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'contact_groups.id',
    minWidth: 150,
    type: FIELD_TYPES.group,
    hide: true,
  },
  {
    field: 'contacts.created_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'contacts.updated_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'contacts.deleted_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
    hide_in_search: true,
  },
];

export default contacts;
