import { Reducer } from 'redux';
import { InterfaceModel } from 'src/models/IntefaceModel';
import { SET_INTERFACE } from 'src/redux/actions/interfaceActions';

export type InterfaceState = InterfaceModel;

const DEFAULT: InterfaceState = {} as InterfaceState;

const interfaceReducer: Reducer<InterfaceState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_INTERFACE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default interfaceReducer;
