import { ReactNode } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: ReactNode,
  style?: CSSProperties,
}

const Content = (props: Props) => {
  const { children, style } = props;
  return (
    <div style={style}>{children}</div>
  );
};

export default Content;
