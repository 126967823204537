export enum FIELDS {
  password = 'password',
  password_confirmation = 'password_confirmation',
  email = 'email',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum FIELD_TYPES {
  number = 'number',
  organisationBankAccount = 'organisationBankAccount',
  department = 'department',
  account = 'account',
  program = 'program',
  string = 'string',
  date = 'date',
  boolean = 'boolean',
  country = 'country',
  salutation = 'salutation',
  gender = 'gender',
  group = 'group',
  listSingle = 'single-list',
  listMultiple = 'multiple-list',
  money = 'money',
  percent = 'percent',
  phone = 'phone',
  invoiceStatus = 'invoice_status',
  invoicePaymentStatus = 'invoice_payment_status',
  paymentType = 'payment_type',
  paymentStatus = 'payment_status',
  contact = 'contact',
  datetime = 'datetime',
  emailStatus = 'emailStatus',
  eventType = 'eventType',
}
