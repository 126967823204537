// @ts-nocheck
import { createTheme } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const paletteTheme = createTheme({
  palette: {
    primary: {
      main: '#0A4DF2',
      light: lighten('#0A4DF2', 0.3),
      dark: darken('#0A4DF2', 0.3),
      contrastText: '#FFF',
    },
    secondary: {
      main: '#A51760',
      light: lighten('#A51760', 0.3),
      dark: darken('#A51760', 0.3),
      contrastText: '#FFF',
    },
    action: {
      main: '#18a90f',
      light: lighten('#18a90f', 0.3),
      dark: darken('#18a90f', 0.3),
      contrastText: '#FFF',
    },
    disabled: {
      main: '#18a90f',
      light: lighten('#18a90f', 0.3),
      dark: darken('#18a90f', 0.3),
      contrastText: '#FFF',
    },
    warning: {
      main: '#ea6410',
      light: lighten('#ea6410', 0.3),
      dark: darken('#ea6410', 0.3),
      contrastText: '#FFF',
    },
    background: {
      paper: '#FFF',
      default: '#eaeff1',
    },
  },
});

// A custom theme for this app
const theme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
        width: '100%',
      },
      editor: {
        minHeight: '50px',
        overflow: 'auto',
      },
      container: {
        padding: '8px',
        '&:hover': {
          background: '#f8f8f8',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: undefined,
        minWidth: 32,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: paletteTheme.palette.primary.main,
          color: paletteTheme.palette.primary.contrastText,

        },
        '&$selected:hover': {
          backgroundColor: paletteTheme.palette.primary.light,
          color: paletteTheme.palette.primary.contrastText,
        },
      },
      gutters: {
        paddingLeft: '12px',
        paddingRight: '12px',
      },
      button: {
        '&:hover': {
          backgroundColor: paletteTheme.palette.primary.light,
          color: paletteTheme.palette.primary.contrastText,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiTypography: {
      subtitle2: {
        fontFamily: 'Qanelas-Bold',
        fontWeight: 'bold',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'white',
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
}, paletteTheme);

export default theme;
