import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import AddContactButton from 'src/components/DatagridToolbar/buttons/AddContactButton';
import DeleteContactButton from 'src/components/DatagridToolbar/buttons/DeleteContactButton';
import MergeContactMenubutton from 'src/components/DatagridToolbar/buttons/MergeContactMenuButton';
import EditContactButton from 'src/components/DatagridToolbar/buttons/EditContactButton';
import DownloadContactResultButton
  from 'src/components/DatagridToolbar/buttons/DownloadContactResultButton';
import DownloadContactSelectedButton
  from 'src/components/DatagridToolbar/buttons/DownloadSelectedContactButton';
import SelectAllMenubutton from 'src/components/DatagridToolbar/buttons/SelectAllMenuButton';
import ConfigureColumnsButton from 'src/components/DatagridToolbar/buttons/ConfigureColumnsButton';
import ShowInvoicesForContactButton from 'src/components/DatagridToolbar/buttons/ShowInvoicesForContactButton';
import ShowPaymentsForContactButton
  from 'src/components/DatagridToolbar/buttons/ShowPaymentsForContactButton';

const ContactToolbar = () => {
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.contacts] || [],
  );
  const resultCount = useSelector(
    (state: Store) => (
      (!!state.querybuilder.contacts && !!state.querybuilder.contacts.result)
        ? state.querybuilder.contacts.result.count : 0
    ),
  );

  return (
    <>
      <AddContactButton />
      <EditContactButton
        disabled={!selections.length} />
      <DeleteContactButton
        disabled={!selections.length} />
      <MergeContactMenubutton
        disabled={selections.length !== 2} />
      <ConfigureColumnsButton
        entity={ENTITIES.contacts} />
      <UnselectAllMenubutton
        entity={ENTITIES.contacts}
        disabled={!selections.length} />
      <SelectAllMenubutton
        entity={ENTITIES.contacts} />
      <ShowInvoicesForContactButton
        disabled={selections.length !== 1}
        contactId={selections[0]} />
      <ShowPaymentsForContactButton
        disabled={selections.length !== 1}
        contactId={selections[0]} />
      <DownloadContactResultButton disabled={resultCount === 0} />
      <DownloadContactSelectedButton />
    </>
  );
};
export default ContactToolbar;
//
