import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorBag } from 'src/models/ErrorModel';
import { Store } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { FORM_ID } from 'src/models/FormModel';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import MomentUtils from '@date-io/moment';

interface Props {
  disabled?: boolean,
  minDate?: Date,
  label: string,
  name: string,
  onChange:any,
  value?: Nullable<Moment>,
  required?: boolean,
  form: FORM_ID,
  error?: boolean,
}

const FormDatePicker = (props: Props) => {
  const {
    label, name, onChange, value, required, form, disabled, minDate, error,
  } = props;

  const errors: ErrorBag =
  useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        minDate={minDate}
        required={!!required}
        autoOk
        variant="inline"
        format="MM/DD/YYYY"
        margin="normal"
        id={`date-picker-${name}`}
        label={label}
        value={value || null}
        onChange={onChange}
        disabled={disabled}
        error={error || !!errors[name]}
        helperText={errors[name] && errors[name].join(',')}
        key={`datepicker-${name}`} />
    </MuiPickersUtilsProvider>
  );
};

export default FormDatePicker;
