import * as Yup from 'yup';

const FundraisingProductValidator = () => Yup.object().shape({
  department_id: Yup.number()
    .required()
    .min(1)
    .integer(),
  account_id: Yup.number()
    .required()
    .min(1)
    .integer(),
  program_id: Yup.number()
    .required()
    .min(1)
    .integer(),
  bank_account_id: Yup.number()
    .required()
    .min(1)
    .integer(),
  gateway_id: Yup.number()
    .required()
    .min(1)
    .integer(),
  products: Yup.array().of(Yup.number())
    .required(),
  max_installment: Yup.number()
    .when('allow_installment', {
      is: true,
      then: Yup.number().required('Must enter the maximum installments'),
    }),
});

export default FundraisingProductValidator;
