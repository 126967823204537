import { Reducer } from 'redux';
import {
  ADD_ORGANISATION_BANK_ACCOUNT,
  DELETE_ORGANISATION_BANK_ACCOUNT,
  SET_ORGANISATION_BANK_ACCOUNTS,
  UPDATE_ORGANISATION_BANK_ACCOUNT,
} from 'src/redux/actions/paymentInfoAction';
import { ContactBankAccountModel } from 'src/models/BankAccountModel';

export type OrganisationBankAccountState = ContactBankAccountModel;

const DEFAULT = {} as OrganisationBankAccountState;

const OrganisationBankAccountReducer: Reducer<OrganisationBankAccountState> =
  (state = DEFAULT, action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_ORGANISATION_BANK_ACCOUNTS:
        return {
          ...state,
          [payload.id]: payload.bankAccounts,
        };
      case ADD_ORGANISATION_BANK_ACCOUNT: {
        return {
          ...state,
          [payload.id]: [
            ...state[payload.id],
            payload.bankAccount,
          ],
        };
      }
      case UPDATE_ORGANISATION_BANK_ACCOUNT: {
        const bankAccounts = state[payload.id];
        const newBankAccounts = bankAccounts.filter(
          (bankAccount) => bankAccount.id !== payload.bankAccount.id,
        );
        return {
          ...state,
          [payload.id]: [
            ...newBankAccounts,
            payload.bankAccount,
          ],
        };
      }
      case DELETE_ORGANISATION_BANK_ACCOUNT: {
        const bankAccounts = state[payload.organisation];
        const newBankAccounts = bankAccounts.filter(
          (bankAccount) => bankAccount.id !== payload.accountId,
        );
        return {
          ...state,
          [payload.organisation]: newBankAccounts,
        };
      }
      default:
        return state;
    }
  };

export default OrganisationBankAccountReducer;
