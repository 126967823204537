import { CustomFieldsModel } from 'src/models/CustomFieldsModel';
import { Reducer } from 'redux';
import {
  ADD_CUSTOM_FIELD,
  DELETE_CUSTOM_FIELD,
  SET_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS,
} from 'src/redux/actions/customfieldActions';

export type CustomfieldsState = CustomFieldsModel;

const DEFAULT_CUSTOM_FIELDS = {} as CustomfieldsState;

const customfieldsReducer: Reducer<CustomfieldsState> = (state = DEFAULT_CUSTOM_FIELDS, action) => {
  switch (action.type) {
    case SET_CUSTOM_FIELDS:
      return {
        ...action.payload,
      };
    case UPDATE_CUSTOM_FIELDS: {
      return {
        ...state,
        [action.payload.name]: action.payload,
      };
    }
    case ADD_CUSTOM_FIELD:
      return {
        ...state,
        [action.payload.name]: action.payload,
      };
    case DELETE_CUSTOM_FIELD: {
      const { payload } = action;
      const accumulator = {} as CustomfieldsState;
      Object.keys(state).forEach((name) => {
        if (state[name].id !== payload.customfieldId) {
          accumulator[name] = state[name];
        }
      });
      return accumulator;
    }
    default:
      return state;
  }
};

export default customfieldsReducer;
