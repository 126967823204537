import { Reducer } from 'redux';
import { OrganisationListModel } from 'src/models/OrganisationListModel';
import { SET_ORGANISATION_LIST_DATA } from 'src/redux/actions/organisationActions';

export type OrganisationListState = OrganisationListModel;

const DEFAULT: OrganisationListState = {} as OrganisationListModel;

const organisationListReducer: Reducer<OrganisationListState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ORGANISATION_LIST_DATA:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default organisationListReducer;
