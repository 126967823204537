import { Reducer } from 'redux';
import { AdvancedSearchModel, Filter } from 'src/models/AdvanceSearchModel';
import { RESET_ADVANCED_SEARCH, SET_ADVANCED_SEARCH } from 'src/redux/actions/advancedSearchAction';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';

export type AdvancedSearchState = AdvancedSearchModel;

const DEFAULT = {} as AdvancedSearchState;

const getFilter = (filters: Filter[]) => {
  const newFilters = [] as Filter[];
  filters.forEach((filter) => {
    newFilters.push([...filter]);
  });
  return newFilters;
};

const advancedSearchReducer: Reducer<AdvancedSearchState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ADVANCED_SEARCH: {
      const { entity, filters } = action.payload;
      const [operator, filterList] = getOperatorAndFilters(filters);
      return {
        ...state,
        [entity]: {
          [operator]: getFilter(filterList),
        },
      };
    }
    case RESET_ADVANCED_SEARCH: {
      const { entity } = action.payload;
      return {
        ...state,
        [entity]: { and: [['', '', '']] },
      };
    }
    default:
      return state;
  }
};

export default advancedSearchReducer;
