import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const Frame = (props: Props) => {
  const { children } = props;
  return (
    <div style={{ border: 'solid 1px #eee' }}>{children}</div>
  );
};

export default Frame;
