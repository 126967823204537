import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { AUTOCOMPLETE_ID, AUTOCOMPLETE_TYPE } from 'src/constants/Autocomplete';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getEntitiesData } from 'src/apis/QuerybuilderAPI';
import { createContactAutocompleteFilter } from 'src/lib/QueryBuilderHelper';
import { FiltersPayload } from 'src/models/AdvanceSearchModel';
import { postUserSearchData } from 'src/apis/UserAPI';

export const SET_AUTOCOMPLETE_RESULT = 'SET_AUTOCOMPLETE_REST';
export const RESET_AUTOCOMPLETE_RESULT = 'RESET_AUTOCOMPLETE_REST';

export const setAutoCompleteResult = makeActionCreator(SET_AUTOCOMPLETE_RESULT);
export const resetAutoCompleteResult = makeActionCreator(RESET_AUTOCOMPLETE_RESULT);

export const getAutoCompleteAction:
(
  autocompleteId: AUTOCOMPLETE_ID,
  query: string, type: AUTOCOMPLETE_TYPE
) => ThunkedAction<State, any[]> =
  (
    autocompleteId: AUTOCOMPLETE_ID,
    query: string,
    type: AUTOCOMPLETE_TYPE,
  ) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      switch (type) {
        case AUTOCOMPLETE_TYPE.product:
          return [] as any[];
        case AUTOCOMPLETE_TYPE.user: {
          const response = await postUserSearchData(organisation, query);
          if (response.success) {
            const { result } = response.data;
            return result.map((contact: any) => (
              { id: contact['contacts.user_id'], email: contact['contacts.email'] }
            ));
          }
          return [] as any[];
        }
        default: {
          const payload = {
            fields: [
              'contacts.id@id',
              'contacts.first_name@firstName',
              'contacts.last_name@lastName',
              'contacts.company_name@companyName',
              'contacts.billing_address@address',
            ],
            filters: {
              and: createContactAutocompleteFilter(query),
            } as FiltersPayload,
            pagination: {
              size: 50,
              page: 1,
            },
          };
          const response = await getEntitiesData(organisation, ENTITIES.contacts, payload);
          const { result } = response.data;
          return result;
        }
      }
    } catch (e) {
      return [] as any[];
    }
  };
