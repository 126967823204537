import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { t } from 'src/lib/language';
import ContactModel, { ContactRelation } from 'src/models/ContactModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import ContactSelector from 'src/components/Control/ContactSelector';
import ContactRelationSelector from 'src/components/Control/ContactRelationSelector';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import { putContactAction } from 'src/redux/actions/contactActions';
import { keyBy } from 'lodash';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void,
  contact: ContactModel,
  contactRelationIndex: number,
}

const EditContactRelationForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const formStatus = useSelector(
    (state: Store) => state.formStatus[FORM.edit_contact_relation] ?? null,
  );
  const relations = useSelector((state: Store) => keyBy(state.data.relations, 'id'));
  const contacts = useSelector((state: Store) => state.contacts);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose, contact, contactRelationIndex } = props;

  const [state, setState] = useState({} as ContactRelation);

  const form = FORM.edit_contact;

  useEffect(() => {
    if (contact.contact_relations) {
      setState(contact.contact_relations[contactRelationIndex]);
    }
    dispatch(setError({ [form]: {} }));
  }, []);

  useEffect(() => {
    if (formStatus === FORM_STATUS.success) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      onClose && onClose();
      dispatch(setFormStatus({ [FORM.edit_contact_relation]: null }));
    }
  }, [formStatus]);

  const getName = (obj: ContactModel) => {
    if (obj.company_name) {
      return obj.company_name;
    }
    return `${obj.last_name} ${obj.first_name}`;
  };

  const displayRelationString = () => {
    if (state.type_id && !!contacts[state.related_contact_id]) {
      const relationName = relations[state.type_id][`name_${lang}`];
      const { contact: related } = contacts[state.related_contact_id];
      return (
        <span>
          {getName(contact)}&nbsp;<i>is the</i>
          &nbsp;<b>{relationName}</b>
          &nbsp;<i>of </i>&nbps;{getName(related)}
        </span>
      );
    }
    return '';
  };

  const displayReverseRelationString = () => {
    if (state.reciprocity_id && !!contacts[state.related_contact_id]) {
      const relationName = relations[state.reciprocity_id][`name_${lang}`];
      const { contact: related } = contacts[state.related_contact_id];
      return (
        <span>
          {getName(related)}&nbsp;<i>is the</i>
          &nbsp;<b>{relationName}</b>
          &nbsp;<i>of </i>&nbps;{getName(contact)}
        </span>
      );
    }
    return '';
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    const currentRelations = contact.contact_relations ?? [];
    currentRelations[contactRelationIndex] = state;
    const formattedContactRelations = currentRelations.map((relation: ContactRelation) => ({
      related_contact_id: relation.related_contact_id,
      type_id: relation.type_id,
      reciprocity_id: relation.reciprocity_id,
    } as ContactRelation));
    event.preventDefault();
    dispatch(putContactAction(contact.id ?? 0, {
      contact,
      contact_relations: [
        ...formattedContactRelations,
      ],
    }));
    return true;
  };

  return (
    <Form>
      <ContactSelector
        autocompleteId={AUTOCOMPLETE_ID.edit_contact_relation}
        onChange={(id:number) => setState({
          ...state,
          related_contact_id: id,
        })}
        label="Contact"
        form={FORM.edit_contact}
        name="contact"
        contactId={state.related_contact_id}
        hideEdit
        hideAdd />
      <ContactRelationSelector
        onChange={(id:number) => setState({
          ...state,
          type_id: id,
        })}
        label="Relation"
        form={FORM.edit_contact}
        name="type_id"
        value={state.type_id} />
      {displayRelationString()}
      <ContactRelationSelector
        onChange={(id:number) => setState({
          ...state,
          reciprocity_id: id,
        })}
        label="Relation Inverse"
        form={FORM.edit_contact}
        name="reciprocity_id"
        value={state.reciprocity_id} />
      {displayReverseRelationString()}
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key={`submit-button-group-${state.type_id}`}>
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default EditContactRelationForm;
