import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const contentPageStyle = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontFamily: 'Qanelas-Bold',
  },
  logo: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  arrowContainer: {
    marginLeft: '3px',
    marginRight: '3px',
    display: 'inline-block',
  },
  arrow: {
    position: 'relative',
    top: '1px',
    height: '20px',
    width: '20px',
  },
}));

const LogoLarge = () => {
  const classes = contentPageStyle();
  return (
    <Typography
      variant="h4"
      color="primary"
      noWrap
      className={classes.title}>
      <a href="https://www.perfectdeed.com" className={classes.logo}>Perfect
        <div className={classes.arrowContainer}>
          <img className={classes.arrow} src="/images/arrow-black.svg" alt="PerfectDeed" />
        </div>Deed
      </a>
    </Typography>
  );
};

export default LogoLarge;
