import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getUserBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountModel } from 'src/models/BankAccountModel';

const useStyles = makeStyles((theme) => ({
  paymentInfo: {
    fontSize: '0.875rem',
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center',
  },
  deletePaymentInfo: {
    color: theme.palette.action.active,
    margin: '0 4px',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const UserBankAccountDisplayer = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.userBankAccounts) {
        return state.userBankAccounts as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  useEffect(() => {
    dispatch(
      getUserBankAccountAction(),
    );
  }, []);

  const displayBankAccount = () => {
    if (!bankAccounts.length) return (<></>);
    return bankAccounts.map((account: BankAccountModel, index: number) => (
      <div
        className={classes.paymentInfo}
        key={`bank-account-${index}`}>
        <span>{`${account.bank}-${account.branch}-${account.account} (${account.bank_holder_name})`}</span>
      </div>
    ));
  };

  return (
    <>{displayBankAccount()}</>
  );
};

export default UserBankAccountDisplayer;
