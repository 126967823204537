import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { CreditCardPayload, UserCreditCardPayload } from 'src/models/CreditCardModel';

export const getUserBankAccountData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: API_URL.user_bank_accounts,
    organisation,
  });

export const getContactBankAccountData =
  (organisation: string, contact_id: number) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.contact_bank_accounts}/${contact_id}`,
    organisation,
  });

export const getOrganisationBankAccountData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.organisation_bank_accounts}`,
    organisation,
  });

export const postContactBankAccountData =
  (organisation: string, contact_id: number, data: any) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.bank_account}`,
    organisation,
    data: {
      ...data,
      contact_id,
      organisation_id: undefined,
    },
  });
export const postOrganisationBankAccountData =
  (organisation: string, data: any) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.bank_account}`,
    organisation,
    data: {
      organisation_id: organisation,
      contact_id: undefined,
      ...data,
    },
  });

export const putBankAccountData =
  (id: number, bankAccount: any, organisation: string) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.bank_account}/${id}`,
    organisation,
    data: bankAccount,
  });

export const deleteBankAccountData =
  (organisation: string, id: number) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.bank_account}/${id}`,
    organisation,
  });

export const getUserCreditCardData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: API_URL.user_credit_cards,
    organisation,
  });

export const postUserCreditCardData =
  (organisation: string, contactPayload: UserCreditCardPayload) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact_credit_card}`,
    organisation,
    data: {
      organisation_id: organisation,
      ...contactPayload,
    },
  });

export const getContactCreditCardData =
  (organisation: string, contact_id: number) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.contact_credit_cards}/${contact_id}`,
    organisation,
  });

export const postContactCreditCardData =
  (organisation: string, contactPayload: CreditCardPayload) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact_credit_card}`,
    organisation,
    data: {
      organisation_id: organisation,
      ...contactPayload,
    },
  });

export const putContactCreditCardData =
  (
    organisation: string,
    contactPayload: CreditCardPayload,
    creditCardId: number,
  ) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.contact_credit_card}/${creditCardId}`,
    organisation,
    data: {
      organisation_id: organisation,
      ...contactPayload,
    },
  });

export const deleteContactCreditCardData =
  (organisation: string, id: number) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.contact_credit_card}/${id}`,
    organisation,
  });
