const creditcards = {
  name: 'Nom a afficher',
  first_name: 'Prenom',
  last_name: 'Nom',
  card_number: '# de carte',
  expiration_month: 'Mois (MM)',
  expiration_year: 'Année (YY)',
  card_type: 'Type de carte',
  cvv: 'CVV',
  validation_errors: 'Erreurs de validation. SVP verifiez vos données.',
};

export default creditcards;
