import { Reducer } from 'redux';
import { RESET_ALERT, SET_ALERT } from 'src/redux/actions/alertActions';
import { AlertModel } from 'src/models/AlertModel';

export type AlertState = AlertModel;

const DEFAULT: AlertState = {} as AlertState;

const alertReducer: Reducer<AlertState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ALERT:
      return DEFAULT;
    default:
      return state;
  }
};

export default alertReducer;
