import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { AsYouType } from 'libphonenumber-js';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { GridCellParams } from '@material-ui/x-grid';
import { FIELD_TYPES } from 'src/constants/Fields';
import { t } from 'src/lib/language';
import {
  INVOICE_PAYMENT_STATUS,
  INVOICE_PAYMENT_STATUS_COLOR,
  INVOICE_STATUS,
  STATUS_COLOR,
} from 'src/constants/Invoices';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { formatDate, formatDatetime, getJSLocale } from 'src/lib/FieldHelper';

const useStyles = makeStyles(() => ({
  datagridBooleam: {
    width: '100%',
    textAlign: 'center',
  },
  root: {
    '&.MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
  datagridCell: {
    display: 'block',
    overflow: 'hidden',
    whiteSoace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0 10px',
  },
}));

interface Props {
  params: GridCellParams,
}

const DatagridCell = (props: Props) => {
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const locale = useSelector((state: Store) => getJSLocale(state.language.locale));
  const countries = useSelector((state: Store) => state.data.countries || []);
  const departments = useSelector((state: Store) => state.data.departments || []);
  const accounts = useSelector((state: Store) => state.data.accounts || []);
  const programs = useSelector((state: Store) => state.data.programs || []);
  const contacts = useSelector((state: Store) => state.contacts || []);
  const salutations = useSelector((state: Store) => state.data.salutations || []);
  const customfields = useSelector((state: Store) => state.customfields) || [];
  const organisationId = useSelector((state: Store) => state.currentOrganisation.id);
  const organisationBankAccounts = useSelector(
    (state: Store) => state.organisationBankAccounts[organisationId] || [],
  );
  const getCountry = (id: number) => {
    const country = countries.find((element) => element.id === id);
    if (country) {
      return country[`name_${lang}`];
    }
    return 'Invalid';
  };

  const getDepartment = (id: number) => {
    const department = departments.find((element) => element.id === id);
    if (department) {
      return department[`name_${lang}`];
    }
    return 'Invalid';
  };

  const getOrganisationBankAccount = (id: number) => {
    if (!id) return '';
    const organisationBankAccount = organisationBankAccounts
      .find((element: BankAccountModel) => element.id === id);
    if (organisationBankAccount) {
      return organisationBankAccount.account;
    }
    return 'Invalid';
  };

  const getAccount = (id: number) => {
    if (!id) return '';
    const account = accounts.find((element) => element.id === id);
    if (account) {
      return account[`name_${lang}`];
    }
    return 'Invalid';
  };

  const getProgram = (id: number) => {
    if (!id) return '';
    const program = programs.find((element) => element.id === id);
    if (program) {
      return program[`name_${lang}`];
    }
    return 'Invalid';
  };

  const getContact = (id: number) => ((!!contacts[id])
    ? `${contacts[id].contact.first_name} ${contacts[id].contact.last_name}`
    : 'Invalid');

  const getSalutation = (id: number) => {
    const salutation = salutations.find((element) => element.id === id);
    if (salutation) {
      return salutation.name;
    }
    return '';
  };

  const formatPhoneNumber =
    (phone: string) => new AsYouType('US').input(phone ?? '');

  const formatBoolean = (booleanValue: number) => (
    <div className={classes.datagridBooleam}>
      { booleanValue
        ? <CheckIcon fontSize="small" color="primary" />
        : <CloseIcon fontSize="small" color="disabled" /> }
    </div>
  );

  const formatDateList = (list: string) => {
    const result: string[] = [];
    list.split(',').forEach((date: string) => {
      result.push(formatDate(date, locale));
    });
    const content = result.join(', ');
    return content;
  };

  const formatList = (type: string, list: string) => {
    switch (type) {
      case 'date':
        return formatDateList(list);
      default:
        return list;
    }
  };

  const formatMoney = (moneyValue: number) => {
    const value = Number(moneyValue) ?? 0;
    const result = value.toFixed(2);
    return `$${result}`;
  };

  const formatPercent = (percentValue: number) => {
    const value = Number(percentValue) ?? 0;
    const percent = value * 100;
    const result = percent.toFixed(2);
    return `${result}%`;
  };

  const formatInvoiceStatus = (fieldStatus: INVOICE_STATUS) => (
    <span style={{ color: STATUS_COLOR[fieldStatus] }}>
      <b>{t(lang, `forms.invoices.${fieldStatus}`)}</b>
    </span>
  );

  const formatInvoicePaymentStatus = (fieldStatus: INVOICE_PAYMENT_STATUS) => (
    <span style={{ color: INVOICE_PAYMENT_STATUS_COLOR[fieldStatus] }}>
      <b>{t(lang, `forms.invoices.${fieldStatus}`)}</b>
    </span>
  );

  const formatField = (type: FIELD_TYPES, fieldValue: any) => {
    switch (type) {
      case FIELD_TYPES.country:
        return getCountry(fieldValue as number);
      case FIELD_TYPES.department:
        return getDepartment(fieldValue as number);
      case FIELD_TYPES.account:
        return getAccount(fieldValue as number);
      case FIELD_TYPES.program:
        return getProgram(fieldValue as number);
      case FIELD_TYPES.contact:
        return getContact(fieldValue as number);
      case FIELD_TYPES.organisationBankAccount:
        return getOrganisationBankAccount(fieldValue as number);
      case FIELD_TYPES.phone:
        return formatPhoneNumber(fieldValue as string);
      case FIELD_TYPES.salutation:
        return getSalutation(fieldValue as number);
      case FIELD_TYPES.date:
        return fieldValue ? formatDate(fieldValue as string, locale) : '';
      case FIELD_TYPES.datetime:
        return fieldValue ? formatDatetime(fieldValue as string, locale) : '';
      case FIELD_TYPES.money:
        return fieldValue ? formatMoney(fieldValue as number) : '$0.00';
      case FIELD_TYPES.boolean:
        return formatBoolean(fieldValue as number);
      case FIELD_TYPES.invoiceStatus:
        return formatInvoiceStatus(fieldValue as INVOICE_STATUS);
      case FIELD_TYPES.invoicePaymentStatus:
        return formatInvoicePaymentStatus(fieldValue as INVOICE_PAYMENT_STATUS);
      case FIELD_TYPES.percent:
        return fieldValue ? formatPercent(fieldValue as number) : '0%';
      default:
        return fieldValue;
    }
  };

  const formatCustomField = (property: string, customFieldValue: any) => {
    switch (customfields[property].type_id) {
      case 'single-list':
      case 'multiple-list':
        return formatList(customfields[property].list_type_id as string, customFieldValue);
      case 'date':
        return (
          formatDate(customFieldValue as string, locale)
        );
      case 'boolean':
        return formatBoolean(customFieldValue as number);
      default:
        return customFieldValue;
    }
  };

  // eslint-disable-next-line react/destructuring-assignment
  const { params } = props;
  const { colDef, value } = params;

  const { type = FIELD_TYPES.string, field } = colDef;
  const [table, property] = field.split('.');

  const result = table === 'customfields'
    ? formatCustomField(property, value)
    : formatField(type as FIELD_TYPES, value);

  const title = typeof result === 'object' ? '' : result;

  return (
    <div title={title} style={{ width: colDef.computedWidth }} className={classes.datagridCell}>
      {result}
    </div>
  );
};
//
export default DatagridCell;
