export interface ProductModel {
  id?: number,
  name_fr?: string,
  name_en?: string,
  description_en?: string,
  description_fr?: string,
  note?: string,
  type?: ProductType,
  picture?: string,
  price?: number,
  cost?: number,
  quantity?: number,
  back_order?: number,
  discount?: number,
  deductible?: number,
  taxes: number[],
  sku?: string,
  department_id: number,
  bank_account_id: number,
  program_id: number,
  account_id: number,
  payment_gateway_eft_id?: number,
  payment_gateway_cc_id?: number,
  is_managed?: boolean,
  is_public?: boolean,
  is_modifiable?: boolean,
  last_name_required?: boolean,
  first_name_required?: boolean,
  email_required?: boolean,
  phone_required?: boolean,
  identifier_required?: boolean,
  identifiers?: string,
  allow_installment?: number,
  number_allowed_installments?: number,
  deleted_at?: Date,
}

export interface ProductPayload {
  id?: number,
  name_fr?: string,
  name_en?: string,
  description_en?: string,
  description_fr?: string,
  payment_gateway_eft_id?: number,
  payment_gateway_cc_id?: number,
  picture?: string,
  price?: number,
  cost?: number,
  quantity?: number,
  back_order?: number,
  discount?: number,
  deductible?: number,
  taxes: string,
  sku?: string,
  department_id?: number,
  bank_account_id?: number,
  program_id?: number,
  account_id?: number,
  is_managed?: boolean,
  is_public?: boolean,
  is_modifiable?: boolean,
  last_name_required?: boolean,
  first_name_required?: boolean,
  email_required?: boolean,
  phone_required?: boolean,
  identifier_required?: boolean,
  identifiers?: string,
  allow_installment?: number,
  number_allowed_installments?: number,
  type?: string,
  note?: string,
}

export enum ProductType {
  ticket = 'ticket',
  donation = 'donation',
  product = 'product',
  fee = 'fee',
  all = '',
}

export default ProductModel;

export type ProductList = Array<ProductModel>;
