import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { FundraisingTeam, Team } from 'src/models/FundraisingModel';
import { getTeamListAction } from 'src/redux/actions/fundraisingAction';
import Button from '@material-ui/core/Button';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FundraiserTitle from 'src/components/UI/FundRaiserTitle';
import Row from 'src/components/UI/Row';
import Scroller from 'src/components/UI/Scroller';
import BottomRow from 'src/components/UI/BottomRow';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

interface Props {
  onChange: (teams: FundraisingTeam[]) => void,
  onNextStep: (step: number) => void,
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingTeams = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    onChange,
    onNextStep,
    setHasChanged,
  } = props;

  useEffect(() => {
    dispatch(getTeamListAction());
  }, []);

  const [currentFilter, setCurrentFilter] = useState('');
  const [teamList, setTeamList] = useState([] as FundraisingTeam[]);

  const teams = useSelector((state: Store) => state.teams);
  const lang = useSelector((state: Store) => state.language.language);

  const addTeam = (id: number) => {
    setHasChanged(true);
    setTeamList([...teamList, { id, goal: 0 }]);
  };

  const removeTeam = (id: number) => {
    setHasChanged(true);
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    teamList.splice(index, 1);
    setTeamList([...teamList]);
  };

  const handleSubmit = () => {
    onChange(teamList);
    onNextStep(6);
  };

  const getSelected = (id: number | undefined) => {
    if (id === undefined) return false;
    return !!teamList.filter((obj) => obj.id === id).length;
  };

  const toggleSelected = (value:boolean, id: number | undefined) => {
    if (!id) return;
    if (value) {
      addTeam(id);
      return;
    }
    removeTeam(id);
  };

  const sanitizeInput = (value: string) => {
    const sanitizedString = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedString.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1].substring(0, 2);
      return Number(`${parts[0]}.${decimalPart}`);
    }
    return Number(sanitizedString);
  };

  const setTeamGoal = (value: any, id: number) => {
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    teamList[index].goal = sanitizeInput(value);
    setTeamList([...teamList]);
  };

  const getTeamGoal = (id: number) => {
    const index = teamList.findIndex((obj: FundraisingTeam) => obj.id === id);
    if (index === -1) return 0;
    return teamList[index].goal;
  };

  const getRowStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const displayTeam = () => teams.map((team: Team, index: number) => {
    const style = {
      ...getRowStyle(index),
      display: 'flex',
      alignItems: 'center',
      height: '53px',
      gap: '10px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    };

    if (currentFilter) {
      if (!team.name) return null;
      if (!team.name.toLowerCase().includes(currentFilter)) return null;
    }
    return (
      <div style={style} key={`team-${index}`}>
        <div style={{ width: '75%', marginTop: '5px', paddingLeft: '8px' }}>
          <FormCheckbox
            checked={getSelected(team.id)}
            label={team.name}
            name=""
            onChange={(value:boolean) => toggleSelected(value, team.id)} />
        </div>
        <div style={{
          width: '25%', marginTop: '-10px', marginBottom: '-4px', paddingRight: '8px',
        }}>
          <FormTextField
            form={FORM.fundraising_teams}
            name={`team-goal-${team.id}`}
            onChange={(value:string) => setTeamGoal(value, team.id as number)}
            value={getTeamGoal(team.id as number)}
            margin="dense"
            label={t(lang, 'forms.fundraising.goal')} />
        </div>
      </div>
    );
  });

  return (
    <>
      <FundraiserTitle>Choose Teams</FundraiserTitle>
      <Row height="auto">
        <TextField
          margin="dense"
          variant="outlined"
          label={t(lang, 'forms.fundraising.goal')}
          onChange={(e) => setCurrentFilter(e.target.value)} />
      </Row>
      <Scroller height="50vh">
        {displayTeam()}
      </Scroller>
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'forms.fundraising.next_step')}
        </Button>
      </BottomRow>
    </>
  );
};

export default FundraisingTeams;
