import EditInvoiceModal from 'src/components/Modals/EditInvoiceModal';
import EditPaymentModal from 'src/components/Modals/EditPaymentModal';
import EditContactModal from 'src/components/Modals/EditContactModal';
import AddContactModal from 'src/components/Modals/AddContactModal';
import AddInvoiceModal from 'src/components/Modals/AddInvoiceModal';
import MergeContactModal from 'src/components/Modals/MergeContactModal';
import AddCustomfieldModal from 'src/components/Modals/AddCustomfieldModal';
import InvoicesForContactModal from 'src/components/Modals/InvoicesForContactModal';
import PaymentsForInvoiceModal from 'src/components/Modals/PaymentsForInvoiceModal';
import InvoicesForPaiementModal from 'src/components/Modals/InvoicesForPaiementModal';
import AddCreditCardModal from 'src/components/Modals/AddCreditCardModal';
import AddBankAccountModal from 'src/components/Modals/AddBankAccountModal';
import CustomFieldManagementModal from 'src/components/Modals/CustomFieldManagementModal';
import MakePaymentModal from 'src/components/Modals/MakePaymentModal';
import GuessPaymentModal from 'src/components/Modals/GuessPaymentModal';
import GroupManagementModal from 'src/components/Modals/GroupManagementModal';
import DepartmentManagementModal from 'src/components/Modals/DepartmentManagementModal';
import ProgramManagementModal from 'src/components/Modals/ProgramManagementModal';
import AccountManagementModal from 'src/components/Modals/AccountManagementModal';
import RelationsManagementModal from 'src/components/Modals/RelationsManagementModal';
import ProductManagementModal from 'src/components/Modals/ProductManagementModal';
import AdminUserManagementModal from 'src/components/Modals/AdminUserManagementModal';
import UploadImageModal from 'src/components/Modals/UploadImageModal';
import ChooseOrganisationModal from 'src/components/Modals/ChooseOrganisationModal';
import UserViewInvoiceModal from 'src/components/Modals/UserViewInvoiceModal';
import UserDonationModal from 'src/components/Modals/UserDonationModal';
import AdminDonationModal from 'src/components/Modals/AdminDonationModal';
import ChangeEmailDialog from 'src/components/Dialogs/ChangeEmailDialog';
import ChangePasswordModal from 'src/components/Modals/ChangePasswordModal';
import DeleteInvoiceModal from 'src/components/Modals/DeleteInvoiceModal';
import PaymentsForContactModal from 'src/components/Modals/PaiementsForContactModal';
import TicketIdentifiersModal from 'src/components/Modals/TicketsIdentifiersModal';
import AddEventModal from 'src/components/Modals/AddEventModal';
import EditEventModal from 'src/components/Modals/EditEventModal';
import TeamManagementModal from 'src/components/Modals/TeamManagementModal';
import EditCreditCardModal from 'src/components/Modals/EditCreditCardModal';
import EditBankAccountdModal from 'src/components/Modals/EditBankAccountModal';
import MakeUserPaymentModal from 'src/components/Modals/MakeUserPaymentModal';
import UserAddCreditCardModal from 'src/components/Modals/UserAddCreditCardModal';
import UserEditPaymentModal from 'src/components/Modals/UserEditPaymentModal';
import UserEditCreditCardModal from 'src/components/Modals/UserEditCreditCardModal';

const ModalsContainer = () => (
  <>
    <EditBankAccountdModal />
    <CustomFieldManagementModal />
    <GroupManagementModal />
    <AccountManagementModal />
    <DepartmentManagementModal />
    <ProgramManagementModal />
    <ProductManagementModal />
    <AddCreditCardModal />
    <AddBankAccountModal />
    <EditInvoiceModal />
    <EditContactModal />
    <AddContactModal />
    <AddInvoiceModal />
    <AddCustomfieldModal />
    <PaymentsForInvoiceModal />
    <PaymentsForContactModal />
    <InvoicesForPaiementModal />
    <InvoicesForContactModal />
    <EditPaymentModal />
    <DeleteInvoiceModal />
    <MergeContactModal />
    <MakePaymentModal />
    <GuessPaymentModal />
    <AdminUserManagementModal />
    <UploadImageModal />
    <ChooseOrganisationModal />
    <UserViewInvoiceModal />
    <UserDonationModal />
    <AdminDonationModal />
    <RelationsManagementModal />
    <ChangeEmailDialog />
    <ChangePasswordModal />
    <TicketIdentifiersModal />
    <AddEventModal />
    <EditEventModal />
    <UploadImageModal />
    <TeamManagementModal />
    <EditCreditCardModal />
    <MakeUserPaymentModal />
    <UserAddCreditCardModal />
    <UserEditPaymentModal />
    <UserEditCreditCardModal />
  </>
);

export default ModalsContainer;
