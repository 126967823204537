import * as Yup from 'yup';
/* eslint-disable import/no-extraneous-dependencies */
import { t } from 'src/lib/language';

const UserDonationValidator = (lang:string) => Yup.object().shape({
  paid: Yup.number()
    .moreThan(0, t(lang, 'validations.invoice.field_required'))
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      return (this.parent.paid);
    }),
  bank_account_id: Yup.number()
    .min(1, t(lang, 'validations.invoice.field_required'))
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      return (this.parent.bank_account_id);
    })
    .integer(),
  payment_info_id: Yup.number()
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      return !!this.parent.payment_info_id && this.parent.payment_info_id > 0;
    })
    .integer(),
  product_id: Yup.number()
    .min(1, t(lang, 'validations.invoice.field_required'))
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      return (this.parent.product_id);
    })
    .integer(),
});

export default UserDonationValidator;
