import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import AddInvoiceForm from 'src/components/Forms/AddInvoiceForm';

const AddInvoiceModal = () => (
  <ModalBase
    name={MODALS.addInvoice}
    size={MODALS_SIZE.large}>
    <AddInvoiceForm />
  </ModalBase>
);

export default AddInvoiceModal;
