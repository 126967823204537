import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { t } from 'src/lib/language';
import { FORM_ID } from 'src/models/FormModel';
import { useEffect } from 'react';
import { getCurrenciesAction } from 'src/redux/actions/dataActions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface Props {
  disabled?: boolean
  form: FORM_ID,
  name: string,
  onChange: (id:number) => void,
  value?: number,
}

interface CurrencyItem {
  id: number,
  label?: string,
}

const CurrenciesSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    disabled, form, name, onChange, value,
  } = props;

  const currencies = useSelector((state: Store) => state.data.currencies);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  useEffect(() => {
    dispatch(getCurrenciesAction());
  }, []);

  const getCurrenciesItems = () => {
    const items:CurrencyItem[] = [{ id: 0, label: undefined }];
    if (!currencies.length) {
      return items;
    }
    currencies.forEach((item: any) => {
      items.push({
        id: item.id,
        label: item.name,
      });
    });
    return items;
  };

  const onValueChange = (item:CurrencyItem) => {
    if (!item || !item.id) {
      onChange(0);
      return null;
    }
    onChange(item.id as number);
    return null;
  };

  const getValue = (initialValue?: number) => {
    let result = {
      id: 0,
      label: undefined,
    } as CurrencyItem;
    if (!currencies.length || !initialValue) {
      return result;
    }
    currencies.forEach((item: any) => {
      if (item.id === initialValue) {
        result = {
          id: item.id,
          label: item.name,
        } as CurrencyItem;
      }
    });
    return result;
  };

  const getOptionLabel = (option: CurrencyItem) => option.label || '';

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      key={`form-control-${name}`}>
      <Autocomplete
        disabled={disabled}
        value={getValue(value)}
        onChange={(e, item) => onValueChange(item as CurrencyItem)}
        options={getCurrenciesItems() || []}
        getOptionLabel={(option: CurrencyItem) => getOptionLabel(option)}
        getOptionSelected={(option, item) => option.id === item.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={t(lang, 'forms.contact.currencies')}
            error={!!errors[name]} />
        )}
        key={`autocomplete-${name}`} />
    </FormControl>
  );
};

export default CurrenciesSelector;
//
