import * as Yup from 'yup';
import { t } from 'src/lib/language';
import { CustomFieldType } from 'src/models/CustomfieldModel';

const CustomfieldCreationValidator = (lang:string) => Yup.object().shape({
  display_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  display_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  type_id: Yup.mixed().oneOf(Object.values(CustomFieldType)).nullable().required(),
  list: Yup.string().when(
    'type_id',
    {
      is: (list_type: CustomFieldType) => {
        if (!list_type) return false;
        return list_type === CustomFieldType.multipleList
          || list_type === CustomFieldType.singleList;
      },
      then: (schema) => schema.required('Field is required'),
    },
  )
    .nullable(),
});

export default CustomfieldCreationValidator;
