import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import GetAppIcon from '@material-ui/icons/GetAppOutlined';
// import { t } from 'src/lib/language';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { postDownloadSelectedEntitesAction } from 'src/redux/actions/querybuilderActions';
import { DELIVERY_TYPE, FORMAT, ORIENTATION } from 'src/models/DownloadModel';

const UserDownloadReceiptButton = (props: ButtonProps) => {
  const dispatch = useAppDispatch();
  const { disabled } = props;

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const isDisabled = useSelector(
    (state: Store) => {
      if (!selections.length) { return true; }
      const hasUnpaid = selections.reduce(
        (result: boolean, id: number) => {
          if (!state.payments[id]) return result;
          return result || state.payments[id].payment_status !== 'paid';
        },
        false,
      );
      return hasUnpaid || disabled;
    },
  );

  const handleOpen = () => {
    dispatch(postDownloadSelectedEntitesAction(
      ENTITIES.taxReceipts,
      selections,
      {
        file: 'payment_receipt.pdf',
        orientation: ORIENTATION.portrait,
        format: FORMAT.pdf,
        delivery: DELIVERY_TYPE.download,
        paymentReceipt: 0,
      },
      'payment_id',
    ));
  };

  return (
    <>
      <Tooltip
        title="Download receipt"
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOpen}
            disabled={isDisabled}>
            <GetAppIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default UserDownloadReceiptButton;
