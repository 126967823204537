import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import {
  Box, FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import Copyright from 'src/components/UI/Copyright';
import { ErrorBag } from 'src/models/ErrorModel';
import { FORM } from 'src/constants/Form';
import { Store, useAppDispatch } from 'src/redux/Store';
import { setError } from 'src/redux/actions/errorsActions';
import { RegisterData } from 'src/apis/UserAPI';
import { registerUserAction } from 'src/redux/actions/userActions';
import LanguageSelector from 'src/components/Control/LanguageSelectorLogin';
import { t } from 'src/lib/language';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  policy: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.775rem',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  copyright: {
    position: 'absolute',
    bottom: theme.spacing(2),
  },
  inlineText: {
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-block',
  },
  language: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(4),
  },
}));

type OnChangeEvent = ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

interface Props {
  changePage: (page: string) => void,
}

const LoginPage = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setError({ [FORM.register]: {} }));
  }, []);

  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[FORM.register] || {} as ErrorBag);

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const { changePage } = props;

  const [state, setState] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    localeId: 'en_CA',
  });

  const onEmailChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      email: event.target.value,
    });
  };

  const onConfirmPasswordChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      password_confirmation: event.target.value,
    });
  };

  const onPasswordChange = (event: OnChangeEvent) => {
    setState({
      ...state,
      password: event.target.value,
    });
  };

  const onLocaleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setState({
      ...state,
      localeId: event.target.value as string,
    });
  };

  const schema: any = Yup.object().shape({
    email: Yup.string()
      .email(t(lang, 'validations.login.invalid_email'))
      .required(t(lang, 'validations.login.email_required')),
    password: Yup.string()
      .required(t(lang, 'validations.login.password_required'))
      .min(6, t(lang, 'validations.login.password_min')),
    password_confirmation: Yup.string()
      .test(t(lang, 'validations.login.passwords-match'), 'password_must_match', function (value) {
        /* eslint-disable react/no-this-in-sfc */
        return this.parent.password === value;
      })
      .required(t(lang, 'validations.login.confirm_password_required')),
    localeId: Yup.string()
      .required(t(lang, 'validations.login.locale_required'))
      .oneOf(['en_CA', 'fr_CA'], 'locale_dont_exists'),
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.register]: {} }));
    try {
      schema.validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.register]: errorBag }));
      return;
    }
    dispatch(registerUserAction({
      email: state.email,
      password: state.password,
      password_confirmation: state.password_confirmation,
      locale_id: state.localeId,
    } as RegisterData));
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        {t(lang, 'pages.register.register')}
      </Typography>
      <div className={classes.language}>
        <span style={{ marginRight: '-5px' }}>Language:</span><LanguageSelector />
      </div>
      <form className={classes.form} noValidate onSubmit={(event) => onSubmit(event)}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t(lang, 'pages.register.email')}
          name="email"
          autoComplete="email"
          autoFocus
          onChange={(event) => onEmailChange(event)}
          error={!!errors.email}
          helperText={errors.email && errors.email.join(',')} />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label={t(lang, 'pages.register.password')}
          type="password"
          id="password"
          onChange={(event) => onPasswordChange(event)}
          error={!!errors.password}
          helperText={errors.password && errors.password.join(',')} />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label={t(lang, 'pages.register.confirm_password')}
          type="password"
          id="password_confirmation"
          onChange={(event) => onConfirmPasswordChange(event)}
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation && errors.password_confirmation.join(',')} />
        <FormControl
          variant="outlined"
          className={classes.formControl}
          error={!!errors.localeId}>
          <InputLabel id="language-label">Language</InputLabel>
          <Select
            labelId="language-labell"
            id="languageLabel"
            name="language"
            value={state.localeId}
            onChange={(event) => onLocaleChange(event)}
            label={t(lang, 'pages.register.language')}
            fullWidth>
            <MenuItem value="en_CA">En</MenuItem>
            <MenuItem value="fr_CA">Fr</MenuItem>
          </Select>
          <FormHelperText>{errors.localeId && errors.localeId.join(',')}</FormHelperText>
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}>
          {t(lang, 'pages.register.register')}
        </Button>

      </form>
      <div>

        <Link
          component="button"
          variant="body2"
          onClick={() => changePage('login')}
          className={classes.pointer}>
          <div className={classes.inlineText}>{t(lang, 'pages.register.different_account')}</div>
          &nbsp;{t(lang, 'pages.register.login')}
        </Link>
      </div>
      <div className={classes.policy}>
        {t(lang, 'pages.register.policy')}<br />
        <Link href="/privacy" variant="body2">{t(lang, 'pages.register.privacy_policy')}</Link>
        &nbsp;&&nbsp;
        <Link href="/service" variant="body2">{t(lang, 'pages.register.tos')}</Link>.
      </div>
      <Box mt={5} className={classes.copyright}>
        <Copyright />
      </Box>
    </>
  );
};

export default LoginPage;
