import { AUTOCOMPLETE_ID, AUTOCOMPLETE_TYPE } from 'src/constants/Autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce, TextField } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { getAutoCompleteAction } from 'src/redux/actions/autoCompleteActions';
import { UserOption, UserOptions } from 'src/models/AutocompleteModel';
import { makeStyles } from '@material-ui/core/styles';
import { useAppDispatch } from 'src/redux/Store';

interface Props {
  onChange: (option:UserOption) => void,
  currentValue?: UserOption,
  disabled?: boolean,
}

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    minWidth: '300px',
    width: '100%',
  },
  icon: {
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  iconButton: {
    padding: '2px',
    marginLeft: 0,
  },
  InputAdornment: {
    marginLeft: 0,
  },
}));

const UserSelector = (props: Props) => {
  const {
    onChange,
    currentValue,
    disabled,
  } = props;
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [userOptions, setUserOptions] = useState([] as UserOptions);
  const [userOption, setUserOption] = useState(
    currentValue ||
    {
      id: 0,
      email: '',
    },
  );

  const getUserOptions = async (search: string) => {
    const options = await dispatch(getAutoCompleteAction(
      AUTOCOMPLETE_ID.add_admin_user,
      search,
      AUTOCOMPLETE_TYPE.user,
    ));
    setUserOptions(options);
  };
  //
  const searchContact = useCallback(
    debounce((search: string) => {
      getUserOptions(search);
    }, 1000),
    [], // will be created only once initially
  );

  const onInputChange = (search: string) => {
    if (search.length < 3) { return; }
    searchContact(search);
  };

  const onValueChange = (value:any) => {
    if (!value) {
      onChange({} as UserOption);
      return;
    }
    onChange(value);
    setUserOption(value);
  };

  const displayLabel = (option: UserOption) => (option.email ? `${option.email} (${option.id})` : '');

  return (
    <Autocomplete
      forcePopupIcon={false}
      className={classes.autocomplete}
      onChange={(e, value) => onValueChange(value)}
      onInputChange={(e, value) => onInputChange(value)}
      options={userOptions || [] as UserOption[]}
      getOptionLabel={(option: UserOption) => displayLabel(option)}
      getOptionSelected={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      disabled={disabled}
      value={userOption}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            style={{ paddingRight: '8px' }}
            margin="dense"
            variant="outlined"
            label="Email"
            placeholder="email" />
        </>
      )} />
  );
};

export default UserSelector;
