import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { Typography } from '@material-ui/core';
import { ContactGroupModel } from 'src/models/ContactGroupModel';
import { AccountItemModel } from 'src/models/AccountListModel';
import AddAccountForm from 'src/components/Forms/AddAccountForm';
import EditAccountForm from 'src/components/Forms/EditAccountForm';
import { getAccountsAction } from 'src/redux/actions/dataActions';
import { sortBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import EditButton from 'src/components/UI/EditButton';
import Item from 'src/components/Elements/Item';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Scroller from 'src/components/UI/Scroller';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageAccounts = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAccountsAction());
  }, []);

  const accounts = useSelector((state: Store) => state.data.accounts);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageAccount }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentAccount, setCurrentAccount] = useState({} as AccountItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(accounts).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(accounts).slice(0, 20));
  }, [accounts]);

  const handleDelete = (account: ContactGroupModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteAccount, payload: account }));
  };

  const handleEdit = (account: AccountItemModel) => {
    setCurrentAccount(account);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentAccount({} as AccountItemModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(accounts, (account) => account[`name_${lang}`]);
    let result = sorted;
    if (currentFilter) {
      result = sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(accounts, (account) => account[`name_${lang}`]);
    let result = sorted;
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_account');
      case STATE.edit:
        return t(lang, 'menus.edit_account');
      default:
        return t(lang, 'menus.list_account');
    }
  };

  const getItemStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items.map(
    (account: AccountItemModel, index: number) => (
      <Item
        key={`account-${index}`}
        style={getItemStyle(index)}>
        <span><b>{ account[`name_${lang}`] }</b></span>
        <EditButton onClick={() => handleEdit(account)} title={t(lang, 'menus.edit_account')} />
        <DeleteButton onClick={() => handleDelete(account)} title={t(lang, 'menus.delete_account')} />
      </Item>
    ),
  );

  const ListItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}>
      {getItems()}
    </InfiniteScroll>
  );

  const EditForm = () => (
    <Scroller height="597px">
      <EditAccountForm
        account={currentAccount}
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  const AddForm = () => (
    <Scroller height="597px">
      <AddAccountForm
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  return (
    <>
      <Header height="56px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'menus.add_account')}
          onChange={handleSearch} />
      )}
      <div>
        { currentState === STATE.list && <ListItems />}
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </div>
    </>
  );
};

export default ManageAccounts;
