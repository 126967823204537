import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  centered: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 'fit-content',
  },
  nobreaking: {
    display: 'inline-block',
  },
}));

interface Props {
  children: any;
  centered?: boolean;
  nobreaking?: boolean;
}

const Line = (props: Props) => {
  const classes = useStyles();
  const { nobreaking, children, centered } = props;

  return (
    <>
      <div
        className={`
        ${classes.line} 
        ${centered ? classes.centered : ''}
        ${nobreaking ? classes.nobreaking : ''}
      `}>
        {children}
      </div>
    </>
  );
};

export default Line;
