import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { getGatewayListData } from 'src/apis/GatewayAPI ';

export const SET_GATEWAY_LIST = 'SET_GATEWAY_LIST';

export const setGatewayListData = makeActionCreator(SET_GATEWAY_LIST);

export const getGatewayListAction: () => ThunkedAction<State> =
() => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await getGatewayListData(organisation);
    if (response.success && (!!response.data)) {
      dispatch(setGatewayListData(response.data));
    }
  } catch (e) { /* Log the error here */
  }
};
