const creditcards = {
  name: 'Name to display',
  first_name: 'First Name',
  last_name: 'Last Name',
  card_number: 'Card Number',
  expiration_month: 'Month (MM)',
  expiration_year: 'Year (YY)',
  card_type: 'Card Type',
  cvv: 'CVV',
  validation_errors: 'Validation error(s). Please review your data.',
};

export default creditcards;
