import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import PauseIcon from '@material-ui/icons/Pause';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { useState } from 'react';
import ColumnSelectionMenu from 'src/components/Elements/ColumnSelectionMenu';

interface CustomProps {
  entity: ENTITIES,
}

type Props = CustomProps & ButtonProps;

const ConfigureColumnsButton = (props: Props) => {
  const { entity } = props;

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const lang = useSelector((state: Store) => state.language.language);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.show_hide_columns')}
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.selection}
            onClick={handleClick}>
            <PauseIcon />
          </ActionButton>
        </span>
      </Tooltip>
      <ColumnSelectionMenu
        anchor={anchor}
        setAnchor={setAnchor}
        entity={entity} />
    </>
  );
};

export default ConfigureColumnsButton;
