import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const SliderContent = (props: Props) => {
  const { children } = props;
  return (
    <div style={{
      padding: '4px 8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }}>
      {children}
    </div>
  );
};

export default SliderContent;
