import { Reducer } from 'redux';
import { DialogModel } from 'src/models/DialogModel';
import { CLOSE_DIALOG, OPEN_DIALOG, RESET_DIALOG } from 'src/redux/actions/dialogActions';

export type DialogState = DialogModel;

const DEFAULT: DialogState = {} as DialogState;

const DialogReducer: Reducer<DialogState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case OPEN_DIALOG: {
      const { payload, dialog } = action.payload;
      return {
        ...state,
        [dialog]: {
          opened: true,
          payload,
        },
      };
    }
    case CLOSE_DIALOG: {
      const { payload, dialog } = action.payload;
      return {
        ...state,
        [dialog]: {
          opened: false,
          payload,
        },
      };
    }
    case RESET_DIALOG: {
      return DEFAULT;
    }
    default:
      return state;
  }
};

export default DialogReducer;
