import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserMakePaymentForm from 'src/components/Forms/User/UserMakePaymentForm';

const MakeUserPaymentModal = () => (
  <ModalBase
    name={MODALS.makeUserPayment}
    size={MODALS_SIZE.small}>
    <UserMakePaymentForm />
  </ModalBase>
);

export default MakeUserPaymentModal;
