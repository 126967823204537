import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { FilePayload } from 'src/constants/Images';

export const putUploadImage =
  (organisation: string, payload: FilePayload) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.upload}`,
    organisation,
    data: payload,
  });
