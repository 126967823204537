export const tax = {
  name_en: 'English name',
  name_fr: 'French name',
  abbreviation_en: 'English abbreviation',
  abbreviation_fr: 'French abbreviation',
  description_en: 'English description',
  description_fr: 'French description',
  rate: 'Rate',
  registration_number: 'Registration number',
  is_recoverable: 'Is recoverable',
  percentage: 'Percentage',
};
