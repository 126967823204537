import { shallowEqual, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  GridCellParams,
  GridColumnHeaderParams,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridRowData,
  GridRowParams,
  LicenseInfo,
  XGrid,
} from '@material-ui/x-grid';
import { makeStyles } from '@material-ui/core/styles';

import {
  getEntitiesAction,
  setColumns,
  setFilters,
  setPagination,
  setQueryBuilderResult,
} from 'src/redux/actions/querybuilderActions';

import {
  ENTITIES,
  GridColumDefinition,
  GridColumns,
  QuerbuilderPayloadColumns,
  QuerbuilderPayloadPagination,
  QuerybuilderOrders,
  QuerybuilderResult,
  QuerybuilderResultValue,
} from 'src/models/QuerybuilderModel';
import { Store, useAppDispatch } from 'src/redux/Store';
import DatagridHeaderCell from 'src/components/Datagrid/DatagridHeaderCell';
import DatagridCell from 'src/components/Datagrid/DatagridCell';
import DatagridOverlay from 'src/components/Datagrid/DatagridOverlay';
import { resetSelections, setSelections } from 'src/redux/actions/selectionActions';
import { resetCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { updateInvoiceAction } from 'src/redux/actions/invoiceActions';
import { updatePaymentAction } from 'src/redux/actions/paymentActions';
import { PRESET_TYPES } from 'src/models/PresetModel';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';
import { denormalizeFilters, getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { Filter, Filters } from 'src/models/AdvanceSearchModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';
import { getOrganisationBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import {
  getAccountsAction,
  getDepartmentsAction,
  getProgramsAction,
} from 'src/redux/actions/dataActions';

LicenseInfo.setLicenseKey(
  '75a5b9338b8a9b4b41e8bc3516c8986aT1JERVI6Mjg1NzksRVhQSVJZPTE2NjEzNjMyMzYwMDAsS0VZVkVSU0lPTj0x',
);

interface Props {
  entity: ENTITIES;
  dontReloadEntities?: boolean;
}

const useStyles = makeStyles(() => ({
  datagridContainer: {
    height: '600px',
    position: 'relative',
    overflowX: 'hidden',
    marginTop: '16px',
  },
  datagridCell: {
    padding: '0px !important',
    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
  },
  datagridHeaderCell: {
    backgroundColor: '#f8f8f8',
    width: '100% !important',
    padding: '0 !important',
    '&:focus': {
      outline: 'none !important',
    },
    '&:focus-within': {
      outline: 'none !important',
    },
  },
}));

const Datagrid = (props: Props) => {
  const classes = useStyles();

  const { entity, dontReloadEntities } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation, shallowEqual);
  const customfields = useSelector((state: Store) => state.customfields);

  const columns = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].columns)
      || [] as GridColumns,
  );

  // GET DEFAULT FILTERS
  const { defaultPresetFilters, defaultPresetFilterId } = useSelector(
    (state: Store) => {
      const result = { defaultPresetFilters: null, defaultPresetFilterId: 0 };
      const type = PRESET_TYPES.filter;
      if (!state.presets[entity]) return result;
      if (!state.presets[entity][type]) return result;
      const defaultPreset = state.presets[entity][type].find((preset) => preset.is_default);
      if (defaultPreset) {
        return {
          defaultPresetFilters: defaultPreset.filter,
          defaultPresetFilterId: defaultPreset.id,
        };
      }
      return result;
    },
  );

  const dispatch = useAppDispatch();

  const purgeCustomfields = (filters: Filter[]) => {
    const customfieldsFields = Object.keys(customfields);
    return filters.filter((filter: Filter) => {
      const [filterEntity, field] = filter[FILTER_POSITION.field].split('.');
      if (filterEntity === ENTITIES.customfields) {
        if (customfieldsFields.indexOf(field)) {
          return true;
        }
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (dontReloadEntities) return;
    dispatch(resetSelections({ entity }));
    dispatch(resetCurrentEntity());
    dispatch(setQueryBuilderResult({
      entity,
      result: {
        ids: [] as number[],
        count: 0,
        list: [],
      } as QuerybuilderResult,
    }));
    dispatch(setPagination({
      entity,
      pagination: { size: 50, page: 1 },
    } as QuerbuilderPayloadPagination));
    if (!dontReloadEntities) {
      dispatch(setFilters({
        entity,
        filters: null,
      }));
    }
    if (defaultPresetFilterId) {
      const [operator, filterList] = getOperatorAndFilters(defaultPresetFilters as Filters);
      const purgedFilters = purgeCustomfields(filterList);
      dispatch(setAdvancedSearch({
        entity,
        filters: {
          [operator]: denormalizeFilters([...purgedFilters]),
        },
      }));
      dispatch(setFilters({
        entity,
        filters: {
          [operator]: denormalizeFilters([...purgedFilters]),
        },
      }));
      dispatch(setSelectedPresets({
        entity,
        type: PRESET_TYPES.filter,
        id: defaultPresetFilterId,
      }));
    }
  }, []);

  useEffect(() => {
    if (!dontReloadEntities) {
      dispatch(getAccountsAction());
      dispatch(getDepartmentsAction());
      dispatch(getProgramsAction());
      dispatch(getOrganisationBankAccountAction());
      dispatch(getEntitiesAction(entity));
    }
  }, [organisation]);

  const data = useSelector(
    (state: Store) => (state.querybuilder[entity] && { ...state.querybuilder[entity].result })
      || {} as QuerybuilderResult,
  );

  const pagination = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].pagination)
      || { size: 50, page: 1 },
  );

  const selections = useSelector((state: Store) => {
    if (!!state.selected[entity]) {
      return [...state.selected[entity]];
    }
    return [];
  });

  const order:QuerybuilderOrders = useSelector(
    (state: Store) => (state.querybuilder[entity] && state.querybuilder[entity].order)
      || [] as QuerybuilderOrders,
  );

  const [pageNumber, setPageNumber] = useState(pagination.page);

  const [pageSize, setPageSize] = useState(pagination.size);

  const normalizeForForm = (row: any) => {
    const result = {} as any;
    Object.keys(row).forEach((field) => {
      result[field.split('.')[1]] = row[field];
    });
    return result;
  };

  const handleOnRowClick = (params: GridRowParams) => {
    const { id } = params;
    const index = selections.indexOf(id);

    switch (entity) {
      case ENTITIES.invoices: {
        const invoice = (normalizeForForm(params.row));
        dispatch(updateInvoiceAction({ id: invoice.id, invoice }));
        break;
      }
      case ENTITIES.payments: {
        const payment = (normalizeForForm(params.row));
        dispatch(updatePaymentAction({ id: payment.id, payment }));
        break;
      }
      default:
    }

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity,
      selections,
    }));
  };

  const getRows = () => {
    const rows:GridRowData[] = [];
    if (!data.list) {
      return rows;
    }
    data.list.forEach((row: QuerybuilderResultValue) => {
      rows.push({
        ...row,
        id: row[`${entity}.id`],
      });
    });
    return rows;
  };

  const getColumns = () => {
    const columnsDefinition:GridColumns = [];
    if (!columns) {
      return columnsDefinition;
    }
    columns.forEach((column: GridColumDefinition) => {
      columnsDefinition.push({
        ...column,
        renderHeader: (params: GridColumnHeaderParams) => (
          <DatagridHeaderCell params={params} order={order} entity={entity} />
        ),
        renderCell: (params: GridCellParams) => (
          <DatagridCell params={params} />
        ),
        cellClassName: classes.datagridCell,
        headerClassName: classes.datagridHeaderCell,
        sortable: false,
      });
    });
    return columnsDefinition;
  };

  const handleColumnWidthChange = (params: GridColumnResizeParams) => {
    const {
      field, width, minWidth, hide, sortable, resizable,
    } = params.colDef;

    const newColumns = [
      ...columns,
      {
        field,
        width,
        minWidth,
        hide,
        sortable,
        resizable,
      },
    ];
    dispatch(setColumns({
      entity,
      columns: newColumns,
    } as QuerbuilderPayloadColumns));
  };

  const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
    const { targetIndex, oldIndex } = params;
    const columnToMove = columns[oldIndex];
    columns.splice(oldIndex, 1);
    columns.splice(targetIndex, 0, columnToMove);
    dispatch(setColumns({
      entity,
      columns,
    } as QuerbuilderPayloadColumns));
  };

  const handlePageSizeChange = (size:number) => {
    dispatch(setPagination({
      entity,
      pagination: { size, page: pageNumber },
    } as QuerbuilderPayloadPagination));
    setPageSize(size);
    dispatch(getEntitiesAction(entity));
  };

  const handlePageChange = (newPage: number) => {
    dispatch(setPagination({
      entity,
      pagination: { size: pageSize, page: newPage + 1 },
    } as QuerbuilderPayloadPagination));
    setPageNumber(newPage + 1);
    dispatch(getEntitiesAction(entity));
  };

  return (
    <>
      <div className={classes.datagridContainer}>
        <XGrid
          disableColumnMenu
          rows={getRows()}
          columns={getColumns()}
          onColumnWidthChange={handleColumnWidthChange}
          onColumnOrderChange={handleColumnOrderChange}
          paginationMode="server"
          rowCount={data.count}
          pagination
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
          onPageChange={handlePageChange}
          onRowClick={handleOnRowClick}
          selectionModel={selections} />
        <DatagridOverlay />
      </div>
    </>
  );
};

export default Datagrid;
//
