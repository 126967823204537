import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import MergeContactForm from 'src/components/Forms/MergeContactForm';

const MergeContactModal = () => (
  <ModalBase
    name={MODALS.mergeContacts}
    size={MODALS_SIZE.small}>
    <MergeContactForm />
  </ModalBase>
);

export default MergeContactModal;
