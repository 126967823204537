import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
  },
  h1: {
    maxWidth: '900px',
    margin: '10px auto',
  },
}));

const GuessPageHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <h1 className={classes.h1}>Invoice</h1>
    </div>
  );
};

export default GuessPageHeader;
