import { Video } from 'src/models/FundraisingModel';
import Debug from 'src/lib/Debug';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { useState } from 'react';
import VideoPlatformSelector from 'src/components/Control/VideoPlatformSelector';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { useSelector } from 'react-redux';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import AddIcon from '@material-ui/icons/Add';
import { ErrorBag } from 'src/models/ErrorModel';
import { getS3URL } from 'src/lib/S3File';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
  },
  buttons: {
    padding: '0px 8px',
  },
}));

interface Props {
  errors?: ErrorBag,
  language: FundraisingLanguages,
  name: string,
  onChange: (video: Video) => void;
  slug: string,
  value: Video,
}

const VideoChooser = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    errors,
    language,
    name,
    onChange,
    slug,
    value,
  } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);

  const [video, setVideo] = useState(value as Video);

  Debug.log([onChange], true);

  const onValueChange = (newValue: string, field: string) => {
    setVideo({
      ...video,
      [field]: newValue,
    });
  };

  const onAddImage = () => {
    const filename = `${name}_${language}.png`;
    const url = `${organisation}/${slug}/images/${filename}`;
    dispatch(openModal({
      modal: MODALS.uploadImage,
      payload: {
        path: url,
        size: {
          width: 1010,
          height: 576,
          ratio: 1010 / 576,
        },
        name: filename,
      },
    }));
  };

  const getError = (field: string) => {
    if (errors && errors[field]) {
      return errors[field].join(',');
    }
    return '';
  };

  const getCategory = () => {
    if (!errors) return MENU_BUTTON_CATEGORY.action;
    return MENU_BUTTON_CATEGORY.error;
  };

  const displayThumbnail = () => {
    if (video.thumbnail) {
      return (
        <Tooltip
          title={(
            <img
              alt="slider"
              src={`${getS3URL()}${video.thumbnail}`}
              height={1010}
              width={576} />
          )}
          placement="top-start">
          <VisibilityIcon />
        </Tooltip>
      );
    }
    return (
      <>
        <ActionButton
          variant="outlined"
          category={getCategory()}
          onClick={() => onAddImage()}
          style={{ width: '320px', border: '1px solid red' }}>
          <span className={classes.buttons}>Upload Image</span>
          <AddIcon />
        </ActionButton>
      </>
    );
  };

  return (
    <div className={classes.row}>
      <FormTextField
        form={FORM.fundraising_standard_assets}
        name="url"
        onChange={onValueChange}
        value={video.url}
        error={getError('url')} />
      <VideoPlatformSelector
        onChange={(platform) => onValueChange(platform, 'platform')}
        form={FORM.fundraising_standard_assets}
        name="platform"
        value={video.platform}
        error={getError('platform')} />
      {displayThumbnail()}
    </div>
  );
};

export default VideoChooser;
