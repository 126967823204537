import { MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { Menu, MenuItem, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LanguageModel, LOCALES } from 'src/models/LanguageModel';
import { setLanguage, setUserLocaleData } from 'src/redux/actions/languageActions';

const useStyles = makeStyles((theme: Theme) => createStyles({
  selector: {
    height: 34,
    width: 35,
    marginLeft: 5,
    paddingTop: 8,
    cursor: 'pointer',
  },
  contain: {
    textAlign: 'center',
    width: '100%',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));

const LanguageSelectorLogin = () => {
  const classes = useStyles();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const languageData = useSelector((state: Store) => state.language);
  const logged = useSelector((state: Store) => state.auth.accessToken);
  const dispatcher = useAppDispatch();

  const currentLanguage = languageData.language || 'en';

  const handleItemClick = (language: LanguageModel) => {
    dispatcher(setLanguage(language));
    if (logged) {
      dispatcher(setUserLocaleData(language.locale));
    }
    setAnchorElement(null);
  };

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorElement(null);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div
        className={classes.selector}
        onClick={openMenu}
        onKeyPress={() => {
        }}
        role="button"
        title="Current language"
        tabIndex={0}>
        <div className={classes.contain}>{ currentLanguage.toUpperCase() }</div>
      </div>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <MenuItem
          key="language-menu-item-fr"
          onClick={() => handleItemClick({ language: 'fr', locale: LOCALES.fr_CA })}>
          FR
        </MenuItem>
        <MenuItem
          key="language-menu-item-en"
          onClick={() => handleItemClick({ language: 'en', locale: LOCALES.en_CA })}>
          EN
        </MenuItem>
      </Menu>
    </div>
  );
};
export default LanguageSelectorLogin;
