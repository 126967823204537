import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { InvoiceModel } from 'src/models/InvoiceModel';
import { useEffect, useState } from 'react';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATE } from 'src/constants/Invoices';

interface OwnProps {
  invoiceId: number,
}

type Props = OwnProps & ButtonProps;
const DeleteInvoiceButton = (props: Props) => {
  const { disabled, invoiceId } = props;
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const handleOpen = () => {
    dispatch(openDialog({ dialog: DIALOGS.cancelInvoice, payload: { invoiceId } }));
  };

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );

  const invoices = useSelector(
    (state: Store) => selections.map(
      (id: number) => state.invoices[id],
    ) || [] as InvoiceModel[],
  );

  const [disableBtnState, setDisableBtnState] = useState(false);

  useEffect(() => {
    if (invoices && invoices.length === 1) {
      const currentInvoice = {
        ...invoices[0],
      };

      setDisableBtnState(
        (currentInvoice.payment_status === INVOICE_PAYMENT_STATUS.paid)
        && (currentInvoice.state === INVOICE_STATE.final),
      );
    }
  }, [invoices.length]);

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.delete_selected_invoice')}
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOpen}
            disabled={disabled || disableBtnState}>
            <DeleteIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default DeleteInvoiceButton;
//
