import * as Yup from 'yup';
import { t } from 'src/lib/language';

type BaseSchema = {
  contact_id: Yup.NumberSchema<number | undefined>;
  first_name: Yup.StringSchema<string | undefined>;
  last_name: Yup.StringSchema<string | undefined>;
  card_number: Yup.StringSchema<string | undefined>;
  expiration_year: Yup.StringSchema;
  expiration_month: Yup.StringSchema;
  cvv: Yup.StringSchema;
};

const CreditCardValidator = (lang: string, isAdmin = false) => {
  const baseSchema = {
    first_name: Yup.string().max(128).required(),
    last_name: Yup.string().max(128).required(),
    card_number: Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .max(16, t(lang, 'validations.creditcard.exact_digit_12'))
      .required(),
    expiration_year: Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .max(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .test('is-greater-or-equal', t(lang, 'validations.creditcard.year_must_be_future'),
        (value) => {
          if (!!value) {
            return parseInt(value, 10) >= new Date().getFullYear() % 100;
          }
          return true;
        })
      .required(),
    expiration_month: Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .matches(/^(0[0-9]|1[0-2]|[0-9])$/, 'Value must be between 0 and 12')
      .min(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .max(2, t(lang, 'validations.creditcard.exact_digit_2'))
      .required(),
    cvv: Yup.string()
      .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
      .min(3, t(lang, 'validations.creditcard.exact_digit_3'))
      .max(4, t(lang, 'validations.creditcard.exact_digit_3'))
      .required(),
  } as BaseSchema;

  if (isAdmin) {
    baseSchema.contact_id = Yup.number()
      .min(1, t(lang, 'validations.creditcard.field_required'))
      .required();
  }

  return Yup.object().shape(baseSchema);
};

export default CreditCardValidator;
