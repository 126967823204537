const login = {
  invalid_email: 'The email field is not valid',
  email_required: 'The email field is required',
  password_required: 'The password field is required',
  password_min: 'The password field must have a minimum of 6 characters',
  password_must_match: 'The password and confirmation fields must match',
  confirm_password_required: 'The confirmation password field is required',
  locale_required: 'You must choose your language.',
};

export default login;
