import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormMultipleSelect from 'src/components/Control/FormControls/FormMultipleSelect';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { PAYMENT_TYPE, PAYMENT_TYPE_LIST } from 'src/models/PaymentModel';

interface Props {
  values: PAYMENT_TYPE[],
  onChange: (field: string, paymentStatus:any) => void
}
const PaymentTypeSelector = (props: Props) => {
  const { values, onChange } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const getPaymentTypes = () => PAYMENT_TYPE_LIST.map(
    (status: PAYMENT_TYPE) => ({
      label: status.toUpperCase(),
      id: status,
    }),
  );

  return (
    <FormMultipleSelect
      form={FORM.admin_donation}
      name="paymentStatus"
      label={t(lang, 'forms.invoices.payment_type')}
      onChange={(status: string) => onChange('paymentType', status)}
      value={values}
      items={getPaymentTypes()}
      noMarginTop />
  );
};

export default PaymentTypeSelector;
