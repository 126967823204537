import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorBag } from 'src/models/ErrorModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { v4 as uuid } from 'uuid';
import { FORM_ID } from 'src/models/FormModel';
import { CSSProperties } from 'react';

export interface SelectItem {
  id?: number | string,
  display: string,
}
//
interface Props {
  disabled?: boolean;
  form: FORM_ID;
  items: Array<SelectItem>;
  label: string;
  name: string;
  onChange:any;
  value: string | number | undefined;
  margin?: 'dense' | 'normal' | undefined,
  noMarginTop?: boolean,
  style?: CSSProperties,
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 220,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectElement: {
    width: '100%',
  },
  selectLabel: {
    color: 'rgba(0, 0, 0, 0.50)',
  },
  selectLabelError: {
    color: theme.palette.error.main,
  },
}));

const FormSingleSelect = (props:Props) => {
  const {
    disabled, form, items, label, name, onChange, value, margin, noMarginTop, style,
  } = props;
  const classes = useStyles();
  const errors: ErrorBag =
    useSelector((state:Store) => state.errors[form] || {} as ErrorBag);

  const onClickItem = (itemValue: string | number | undefined) => {
    if (value === itemValue) {
      onChange('', name);
    }
  };

  const displayItems = () => {
    const elements = [] as any[];
    if (!items) {
      return items;
    }
    items.map((item) => {
      elements.push(
        <MenuItem
          key={uuid()}
          value={item.id}
          selected={item.id === value}
          onClick={() => onClickItem(item.id)}>{ item.display }
        </MenuItem>,
      );
      return elements;
    });
    return elements;
  };

  let displayClass = '';
  if (!value) {
    displayClass = classes.selectLabel;
  }
  if (errors[name]) {
    displayClass = classes.selectLabelError;
  }

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      error={!!errors[name]}
      key={`form-control-${name}`}
      margin={margin || 'normal'}
      style={style}>
      <InputLabel id={`select-${name}-label`}>{label}</InputLabel>
      <Select
        value={value}
        labelId={`select-${name}-label`}
        onChange={(e) => onChange(e.target.value as number, name)}
        displayEmpty
        className={`${noMarginTop ? '' : classes.selectEmpty} ${displayClass} ${classes.selectElement}`}
        disabled={disabled}
        key={`select-${name}`}
        label={label}>
        { displayItems() }
      </Select>
    </FormControl>
  );
};

export default FormSingleSelect;
