import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import { TaxItemModel } from 'src/models/TaxListModel';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { postTaxAction } from 'src/redux/actions/taxActions';
import TaxCreationValidator from 'src/validations/TaxCreationValidator';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void;
}

const AddTaxForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose } = props;

  const processing = useSelector(
    (state: Store) => state.formStatus[FORM.post_tax] === FORM_STATUS.processing,
  );

  const [state, setState] = useState({
    is_recoverable: false,
  } as TaxItemModel);
  const form = FORM.post_tax;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      TaxCreationValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }

    dispatch(postTaxAction({
      ...state,
      rate: state.rate / 100,
    }));
    return true;
  };

  return (
    <Form>
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.name_en')}
        name="name_en"
        onChange={onChange}
        required
        value={state.name_en}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.name_fr')}
        name="name_fr"
        onChange={onChange}
        required
        value={state.name_fr}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.abbreviation_en')}
        name="abbreviation_en"
        onChange={onChange}
        required
        value={state.abbreviation_en}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.abbreviation_fr')}
        name="abbreviation_fr"
        onChange={onChange}
        required
        value={state.abbreviation_fr}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.description_en')}
        name="description_en"
        onChange={onChange}
        required
        value={state.description_en}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.description_fr')}
        name="description_fr"
        onChange={onChange}
        required
        value={state.description_fr}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.percentage')}
        name="rate"
        onChange={onChange}
        required
        value={state.rate}
        disabled={processing} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.tax.registration_number')}
        name="registration_number"
        onChange={onChange}
        required
        value={state.registration_number}
        disabled={processing} />
      <FormCheckbox
        checked={state.is_recoverable}
        label={t(lang, 'forms.tax.is_recoverable')}
        name="is_recoverable"
        onChange={onChange}
        disabled={processing} />
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button"
          disabled={processing}>
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key={`submit-button-group-${state.id}`}>
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default AddTaxForm;
