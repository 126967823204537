import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';

interface Props {
  disabled?: boolean,
  onClick: () => void,
  title:string,
}

const DeleteButton = (props: Props) => {
  const { disabled, onClick, title } = props;
  return (
    <Tooltip
      title={title}
      placement="top-start">
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={() => onClick()}
          disabled={disabled}>
          <DeleteIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default DeleteButton;
