// eslint-disable-next-line @typescript-eslint/naming-convention
export enum AUTOCOMPLETE_ID {
  add_invoice_contact = 'add_invoice_contact',
  add_payment_contact = 'add_payment_contact',
  add_payment_seller = 'add_payment_seller',
  add_invoice_seller = 'add_invoice_seller',
  add_invoice_payer = 'add_invoice_payer',
  edit_invoice_contact = 'add_invoice_contact',
  edit_invoice_seller = 'add_invoice_seller',
  edit_invoice_payer = 'add_invoice_payer',
  edit_contact_relation = 'edit_contact_relation',
  edit_fundraising_team = 'edit_fundraising_team',
  product = 'product',
  add_admin_user = 'add_admin_user',
  payment_contact = 'payment_contact',
  receipt_contact = 'receipt_contact',
  search_contact = 'search_contact',
  search_seller = 'search_seller',
  search_payer = 'search_payer',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum AUTOCOMPLETE_TYPE {
  product = 'product',
  user = 'user',
  contact = 'contact',
}
