import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  icon: {
    '&:hover': {
      cursor: 'pointer',
      color: 'rgba(0, 0, 0, 0.33)',
    },
  },
}));

interface Props {
  children: ReactNode,
}

const Cursor = (props: Props) => {
  const classes = useStyles();
  const {
    children,
  } = props;

  return (
    <span className={classes.icon}>
      {children}
    </span>
  );
};

export default Cursor;
