/* eslint-disable max-len */

import { API_URL } from 'src/constants/Endpoints';
import makeApiRequest from 'src/lib/makeApiRequest';
import { DepartmentItemModel, DepartmentListModel } from 'src/models/DepartmentListModel';
import { AccountItemModel, AccountListModel } from 'src/models/AccountListModel';
import { ProgramItemModel, ProgramListModel } from 'src/models/ProgramListModel';
import { TimeZoneListModel } from 'src/models/TimeZoneListModel';
import { ContactRelationListModel } from 'src/models/ContactModel';

export const getAccountsData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.accounts}`,
  organisation,
});

export const getRelationsData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.relations}`,
  organisation,
});

export const postAccountsData = (organisation: string, data: AccountItemModel) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.accounts}`,
  organisation,
  data,
});

export const putAccountsData = (organisation: string, data: AccountItemModel) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.accounts}/${data.id}`,
  organisation,
  data,
});

export const deleteAccountsData = (organisation: string, id: number) => makeApiRequest({
  method: 'DELETE',
  url: `${API_URL.accounts}/${id}`,
  organisation,
});

export const getDepartmentsData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.departments}`,
  organisation,
});

export const postDepartmentsData = (organisation: string, data: DepartmentItemModel) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.departments}`,
  organisation,
  data,
});

export const putDepartmentsData = (organisation: string, data: DepartmentItemModel) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.departments}/${data.id}`,
  organisation,
  data,
});

export const deleteDepartmentsData = (organisation: string, id: number) => makeApiRequest({
  method: 'DELETE',
  url: `${API_URL.departments}/${id}`,
  organisation,
});

export const getProgramsData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.programs}`,
  organisation,
});

export const postProgramsData = (organisation: string, data: ProgramItemModel) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.programs}`,
  organisation,
  data,
});

export const putProgramsData = (organisation: string, data: ProgramItemModel) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.programs}/${data.id}`,
  organisation,
  data,
});

export const deleteProgramsData = (organisation: string, id: number) => makeApiRequest({
  method: 'DELETE',
  url: `${API_URL.programs}/${id}`,
  organisation,
});

export const getTimeZoneData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.timezones}`,
  organisation: 'aic',
});

export const getCurrenciesData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.currencies}`,
  organisation: 'aic',
});

export const normalizeDepartmentListData =
  (data: any): DepartmentListModel => data as DepartmentListModel;

export const normalizeAccountListData =
  (data: any): AccountListModel => data as AccountListModel;

export const normalizeProgramListData =
(data: any): ProgramListModel => data as ProgramListModel;

export const normalizeTimeZonesData =
(data: any): TimeZoneListModel => {
  const result = {} as TimeZoneListModel;
  data.forEach((tz:any) => { result[tz.id] = tz.name; });
  return result;
};

export const normalizeRelationsData =
(data: any): ContactRelationListModel => data as ContactRelationListModel;
