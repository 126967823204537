const reset_password = {
  reset_password: 'Réinitialiser le mot de passe',
  init_password: 'Initialiser le mot de passe',
  language: 'Language',
  password: 'Mot de passe',
  confirm_password: 'Confirmation du mot de passe',
  policy: 'En créant un compte vous acceptez nos',
  privacy_policy: 'politique de confidentialité',
  tos: 'conditions de service',
};

export default reset_password;
//
