import { FIELD_TYPES } from 'src/constants/Fields';

const payments = [
  {
    field: 'payments.id',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'payments.contact_id',
    minWidth: 150,
    type: FIELD_TYPES.contact,
    hide: true,
  },
  {
    field: 'payments.seller_id',
    minWidth: 150,
    type: FIELD_TYPES.contact,
    hide: true,
  },
  {
    field: 'payments.amount',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'payments.deductible',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'payments.department_id',
    minWidth: 150,
    type: FIELD_TYPES.department,
  },
  {
    field: 'payments.account_id',
    minWidth: 150,
    type: FIELD_TYPES.account,
  },
  {
    field: 'payments.program_id',
    minWidth: 150,
    type: FIELD_TYPES.program,
  },
  {
    field: 'payments.bank_account_id',
    minWidth: 150,
    type: FIELD_TYPES.organisationBankAccount,
  },
  {
    field: 'payments.scheduled_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'payments.paid_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'payments.processed_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'payments.payment_action',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.payment_status',
    minWidth: 150,
    type: FIELD_TYPES.paymentStatus,
  },
  {
    field: 'payments.payment_type',
    minWidth: 150,
    type: FIELD_TYPES.paymentType,
  },
  {
    field: 'payments.title',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.description',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.reference',
    minWidth: 150,
    type: FIELD_TYPES.string,
    hide_in_search: true,
  },
  //
  {
    field: 'payments.contact_first_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.contact_last_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.contact_company_name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_address',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_city',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_state',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_suite',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_zip_code',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'payments.billing_country_id',
    minWidth: 150,
    type: FIELD_TYPES.country,
  },
  {
    field: 'payments.is_expense',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'payments.is_refund',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'payments.issue_tax_receipt',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    header: 'Tax Receipt Issued',
    field: 'payments.tax_receipt_issued',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    header: 'Receipt Sent',
    field: 'payments.payment_receipt_sent',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    header: 'Receipt Printed',
    field: 'payments.payment_receipt_printed',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
];

export default payments;
