import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  style?: CSSProperties,
}

const FlexContainer = (props: Props) => {
  const {
    children,
    style,
  } = props;

  const styles: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    ...style,
  };
  return (
    <div style={styles}>
      <b>{children}</b>
    </div>
  );
};

export default FlexContainer;
