import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';

export enum DateType {
  start = 'start',
  end = 'end',
}

export const getDateValue = (input:MaterialUiPickersDate, type: DateType) => {
  if (!input && type === 'start') return moment().startOf('day');
  if (!input && type === 'end') return moment().endOf('day');
  if (input) return input;
  return moment();
};
