import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ENTITIES } from 'src/models/QuerybuilderModel';

import { BankAccountModel } from 'src/models/BankAccountModel';
import { DIALOGS } from 'src/models/DialogModel';
import { MODALS } from 'src/models/ModalModel';
import { getContactBankAccountAction } from 'src/redux/actions/paymentInfoAction';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { openDialog } from 'src/redux/actions/dialogActions';
import { openModal } from 'src/redux/actions/modalActions';
import DeleteIcon from 'src/components/UI/DeleteIcon';
import EditIcon from 'src/components/Elements/EditIcon';
import ItalicTextAccent from 'src/components/UI/ItalicTextAccent';

interface Props {
  contactId: number;
}

const ContactBankAccountDisplayer = (props: Props) => {
  const { contactId } = props;
  const dispatch = useAppDispatch();

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.contactBankAccounts[contactId]) {
        return state.contactBankAccounts[contactId] as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  useEffect(() => {
    dispatch(
      getContactBankAccountAction(contactId),
    );
  }, []);

  const handleDeleteBankAccount = (id: number) => {
    dispatch(setCurrentEntity({ entity: ENTITIES.bankAccounts, id }));
    dispatch(openDialog(
      { dialog: DIALOGS.deleteBankAccount, payload: { accountId: id, contactId } },
    ));
  };

  const handleEditBankAccount = (account: BankAccountModel) => {
    dispatch(openModal({
      modal: MODALS.editBankAccount,
      payload: {
        account,
        contactId,
      },
    }));
  };

  const DisplayBankAccount = () => bankAccounts.map((account: BankAccountModel, index: number) => (
    <ItalicTextAccent key={`bank-account-${index}`}>
      <span>{`${account.bank}-${account.branch}-${account.account} (${account.bank_holder_name})`}</span>
      <DeleteIcon onClick={() => handleDeleteBankAccount(account.id)} />
      <EditIcon onClick={() => handleEditBankAccount(account)} />
    </ItalicTextAccent>
  ));

  return (
    <>{DisplayBankAccount()}</>
  );
};

export default ContactBankAccountDisplayer;
