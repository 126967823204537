import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import AddInvoiceButton from 'src/components/DatagridToolbar/buttons/AddInvoiceButton';
import EditInvoiceButton from 'src/components/DatagridToolbar/buttons/EditInvoiceButton';
import DeleteInvoiceButton from 'src/components/DatagridToolbar/buttons/DeleteInvoiceButton';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import DownloadInvoiceButton from 'src/components/DatagridToolbar/buttons/DownloadInvoiceButton';
import MakePaymentButton from 'src/components/DatagridToolbar/buttons/MakePaymentButton';
import { useEffect, useState } from 'react';
import { INVOICE_PAYMENT_STATUS } from 'src/constants/Invoices';
import { keyBy } from 'lodash';
import ExportInvoiceButton from 'src/components/DatagridToolbar/buttons/ExportInvoiceButton';

const useStyles = makeStyles((theme: Theme) => ({
  actionMenuContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderTop: 'none',
    border: '1px solid #eee',
    padding: theme.spacing(1),
    backgroundColor: '#f8f8f8',
  },
}));

const InvoiceForPaymentToolbar = () => {
  const classes = useStyles();
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );

  const invoices = useSelector(
    (state: Store) => (
      (!!state.querybuilder.invoices && !!state.querybuilder.invoices.result)
        ? keyBy(state.querybuilder.invoices.result.list, 'invoices.id') : []
    ),
  );

  const resultCount = useSelector(
    (state: Store) => (
      (!!state.querybuilder.invoices && !!state.querybuilder.invoices.result)
        ? state.querybuilder.invoices.result.count : 0
    ),
  );

  const [disablePaymentState, setDisablePaymentState] = useState(false);

  useEffect(() => {
    let shouldDisable = false;

    if (!selections.length) {
      setDisablePaymentState(true);
      return;
    }

    selections.forEach((invoiceId: number) => {
      if (!!invoices[invoiceId] && !!invoices[invoiceId]['invoices.payment_status']) {
        if (invoices[invoiceId]['invoices.payment_status'] === INVOICE_PAYMENT_STATUS.paid) {
          shouldDisable = true;
        }
      }

      setDisablePaymentState(shouldDisable);
    });
  }, [selections]);

  return (
    <div className={classes.actionMenuContainer}>
      <AddInvoiceButton />
      <EditInvoiceButton
        disabled={!selections.length} />
      <DeleteInvoiceButton
        disabled={!selections.length || selections.length > 1}
        invoiceId={selections[0]} />
      <MakePaymentButton
        disabled={disablePaymentState} />
      <ExportInvoiceButton disabled={resultCount > 1000 || resultCount === 0} />
      <DownloadInvoiceButton />
    </div>
  );
};
export default InvoiceForPaymentToolbar;
