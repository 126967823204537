import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import RoundIcon from 'src/components/UI/RoundIcon';

const useStyles = makeStyles(() => ({
  card: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.75)',
    color: 'rgba(0, 0, 0, 0.75)',
    borderRadius: '5px',
    marginBottom: '8px',
    padding: '8px 16px',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'baseline',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.5)',
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  text: {
    paddingLeft: '8px',
  },
}));

interface Props {
  key: string,
  name: string,
  onClick: () => void,
}

const OrganisationCard = (props: Props) => {
  const classes = useStyles();
  const {
    key, name, onClick,
  } = props;
  return (
    <Box className={classes.card} key={key} onClick={onClick}>
      <div className={classes.icon}>
        <RoundIcon>
          {name.substr(0, 1).toUpperCase()}
        </RoundIcon>
      </div>
      <div className={classes.text}>
        <Typography>
          <b>{name}</b>
        </Typography>
      </div>
    </Box>
  );
};

export default OrganisationCard;
