interface Props {
  children: any,
}
const BoldTextAccent = (props: Props) => {
  const { children } = props;

  return (
    <div style={{
      fontSize: '0.75rem',
      fontWeight: 'bold',
      marginTop: '12px',
      marginBottom: '4px',
    }}>
      {children}
    </div>
  );
};

export default BoldTextAccent;
