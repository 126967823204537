import * as Yup from 'yup';

const testGroupContact = (groups: number[] | null, contacts: number[] | null): boolean => {
  if (!groups || !contacts) {
    return false;
  }
  return groups.length > 0 || contacts.length > 0;
};

// const testFacebooks = (
//   fb_description: string,
//   fb_keyword: string,
//   media_title: string,
//   media_image_preview: string,
//   media_text: string,
// ) => {
//   console.log(fb_description, !fb_keyword);
//   if (fb_description && (!fb_keyword || !media_title || !media_image_preview || !media_text)) {
//     return false;
//   }
//   if (fb_keyword && (!fb_description || !media_title || !media_image_preview || !media_text)) {
//     return false;
//   }
//   if (media_title && (!fb_description || !fb_keyword || !media_image_preview || !media_text)) {
//     return false;
//   }
//   if (media_image_preview && (!fb_description || !fb_keyword || !media_title || !media_text)) {
//     return false;
//   }
//   if (media_text && (!fb_description || !fb_keyword || !media_title || !media_image_preview)) {
//     return false;
//   }
//   return true;
// };

const FundraisingNotificationValidator = () => Yup.object().shape({
  contacts: Yup.array().of(Yup.number())
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        groups, contacts, send_thanks_email, send_thanks_sms,
      } = this.parent;
      if (send_thanks_email || send_thanks_sms) {
        testGroupContact(groups, contacts);
      }
      return true;
    }),
  groups: Yup.array().of(Yup.number())
    .test('is-required', 'required', function () {
      /* eslint-disable react/no-this-in-sfc */
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        groups, contacts, send_thanks_email, send_thanks_sms,
      } = this.parent;
      if (send_thanks_email || send_thanks_sms) {
        if (send_thanks_email || send_thanks_sms) {
          testGroupContact(groups, contacts);
        }
      }
      return true;
    }),
  send_thanks_email: Yup.boolean(),
  thanks_email_body: Yup.string()
    .test('is-required', 'Must enter the body of the message', function () {
      /* eslint-disable react/no-this-in-sfc */
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { send_thanks_email, thanks_email_body } = this.parent;
      if (send_thanks_email) {
        return !!thanks_email_body.replace(/<[^>]+>/g, '');
      }
      return true;
    }),
  send_thanks_sms: Yup.boolean(),
  thanks_sms_text: Yup.string()
    .when('send_thanks_sms', {
      is: true,
      then: Yup.string().required('Must enter the SMS message'),
    }),
  fb_description: Yup.string()
    /* eslint-disable react/no-this-in-sfc */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .test('is-required', function () {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        fb_description, media_title, media_text, fb_keyword,
      } = this.parent;
      if (!!media_title || !!media_text || !!fb_keyword) {
        return !!fb_description;
      }
      return true;
    }),
  media_title: Yup.string()
    /* eslint-disable react/no-this-in-sfc */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .test('is-required', function () {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        fb_description, media_title, media_text, fb_keyword,
      } = this.parent;
      if (!!fb_description || !!media_text || !!fb_keyword) {
        return !!media_title;
      }
      return true;
    }),
  media_text: Yup.string()
    /* eslint-disable react/no-this-in-sfc */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .test('is-required', function () {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        fb_description, media_title, media_text, fb_keyword,
      } = this.parent;
      if (!!media_title || !!fb_description || !!fb_keyword) {
        return !!media_text;
      }
      return true;
    }),
  fb_keyword: Yup.string()
    /* eslint-disable react/no-this-in-sfc */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .test('is-required', function () {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        fb_description, media_title, media_text, fb_keyword,
      } = this.parent;
      if (!!media_title || !!fb_description || !!media_text) {
        return !!fb_keyword;
      }
      return true;
    }),
  media_image_preview: Yup.string(),
});

export default FundraisingNotificationValidator;
