import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { EditCustomfieldFormType, FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { Customfield, CustomFieldsPayload, CustomFieldType } from 'src/models/CustomfieldModel';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import CustomfieldTypeSelector from 'src/components/Control/CustomfieldTypeSelector';
import { putCustomFieldsAction } from 'src/redux/actions/customfieldActions';
import CustomfieldCreationValidator from 'src/validations/CustomfieldCreationValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import Form from 'src/components/UI/Form';
import Spacer from 'src/components/UI/Spacer';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  customfield: Customfield;
  onClose?: () => void;
}

const EditCustomfieldForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { customfield, onClose } = props;

  const [state, setState] = useState(customfield);
  const form = `edit-customfield-${customfield.id}` as EditCustomfieldFormType;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    try {
      CustomfieldCreationValidator(lang).validateSync(state, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.customfields.validation_errors',
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [form]: errorBag }));
      return false;
    }
    const payload = {
      name: customfield.name,
      display: {
        en_CA: state.display_en,
        fr_CA: state.display_fr,
      },
      list: state.list,
      list_type_id: CustomFieldType.string,
      type_id: state.type_id,
      required: state.required,
    } as unknown as CustomFieldsPayload;
    dispatch(putCustomFieldsAction(customfield.id, payload));
    return true;
  };

  const displayListValueField = () => {
    if (
      state.type_id === CustomFieldType.multipleList
      || state.type_id === CustomFieldType.singleList
    ) {
      return (
        <FormTextField
          form={FORM.add_customfield}
          label={t(lang, 'forms.customfields.list')}
          name="list"
          onChange={onChange}
          required
          value={state.list} />
      );
    }
    return (<></>);
  };

  return (
    <Form>
      <FormTextField
        form={form}
        label={t(lang, 'forms.customfields.display_en')}
        name="display_en"
        onChange={onChange}
        required
        value={state.display_en} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.customfields.display_fr')}
        name="display_fr"
        onChange={onChange}
        required
        value={state.display_fr} />
      <CustomfieldTypeSelector
        form={form}
        name="type_id"
        value={state.type_id || ''}
        onChange={(type_id) => onChange(type_id, 'type_id')} />
      { displayListValueField() }
      <Spacer space={1} />
      <FormCheckbox
        checked={state.required || false}
        label={t(lang, 'forms.customfields.required')}
        name="required"
        onChange={onChange} />
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key={`submit-button-custom-field-${customfield.id}`}>
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default EditCustomfieldForm;
