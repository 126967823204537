import { SelectedPreset } from 'src/models/PresetModel';
import { Reducer } from 'redux';
import { SET_DEFAULT_PRESET, SET_SELECTED_PRESET } from 'src/redux/actions/selectedPresetActions';

export type CurrentPresetState = SelectedPreset;

const DEFAULT: CurrentPresetState = {} as CurrentPresetState;

const selectedPresetReducer: Reducer<CurrentPresetState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_SELECTED_PRESET: {
      const { entity, type, id } = action.payload;

      const newState = { ...state } as CurrentPresetState;
      if (!state[entity]) {
        newState[entity] = {
          ...state[entity],
          [type]: {
            selected: id,
            default: 0,
          },
        };
        return newState;
      }
      if (!state[entity][type]) {
        newState[entity] = {
          ...state[entity],
          [type]: {
            selected: id,
            default: 0,
          },
        };
        return newState;
      }
      newState[entity][type].selected = id;
      return newState;
    }
    case SET_DEFAULT_PRESET: {
      const { entity, type, id } = action.payload;
      const newState = { ...state } as CurrentPresetState;
      if (!state[entity]) {
        newState[entity] = {
          [type]: {
            selected: 0,
            default: id,
          },
        };
        return newState;
      }
      newState[entity][type].default = id;
      return newState;
    }
    default:
      return state;
  }
};

export default selectedPresetReducer;
