import UserContactForm from 'src/components/Forms/User/UserContactForm';

// const hasContact = useSelector((state: Store) => state.user.has_contact);
// const organisationsUserList = useSelector((state: Store) => state.organisationsUser);
// const hasChooseOrganization = Object.keys(organisationsUserList).length > 0;
const ProfilePage = () => (
  <>
    <UserContactForm />
  </>
);
export default ProfilePage;
