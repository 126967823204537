export interface AddressModel {
  address?: string,
  city?: string,
  state?: string,
  country_id?: number,
  zip_code?: string,
}

export enum AddressType {
  billing = 'billing',
  shipping = 'shipping',
}
