import { InvoiceModel, InvoicesModel } from 'src/models/InvoiceModel';
import { Reducer } from 'redux';
import { RESET_INVOICE, SET_INVOICES, UPDATE_INVOICE } from 'src/redux/actions/invoiceActions';

export type InvoicesState = InvoicesModel;

const DEFAULT = {} as InvoicesState;

const invoiceReducer: Reducer<InvoicesState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_INVOICES: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_INVOICE: {
      const { id, invoice } = action.payload;
      return {
        ...state,
        [id]: {
          ...invoice as InvoiceModel,
        },
      };
    }
    case RESET_INVOICE: {
      const { invoiceId } = action.payload;
      const { [invoiceId]: removedInvoice, ...remainingInvoice } = state;
      return {
        ...remainingInvoice,
      };
    }
    default:
      return state;
  }
};

export default invoiceReducer;
