import { Slider, Sliders } from 'src/models/FundraisingModel';
import { useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { subscribeEvent } from 'src/models/EventHelper';
import { EventType } from 'src/models/EventType';
import Tooltip from '@material-ui/core/Tooltip';
import { openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { SliderErrors } from 'src/validations/FundraisingAssetsValidation';
import { getS3URL } from 'src/lib/S3File';
import Frame from 'src/components/UI/Frame';
import SliderHeader from 'src/components/UI/SliderHeader';
import SliderContent from 'src/components/UI/SliderContent';
import Row from 'src/components/UI/Row';
import Error from 'src/components/UI/Error';
import Column from 'src/components/UI/Column';

interface Props {
  errors?: Nullable<SliderErrors>,
  height: number,
  language: FundraisingLanguages;
  name: string;
  onChange: (language: FundraisingLanguages, name: string, sliders: Sliders) => void;
  slug: string;
  title: string;
  value: Sliders,
  width: number,
}

interface PanelState {
  [panel: string]: boolean,
}

const ImageSliderManager = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    errors,
    height,
    language,
    name,
    onChange,
    slug,
    title,
    value,
    width,
  } = props;

  const organisation = useSelector((state: Store) => state.currentOrganisation.id);

  const [sliders, setSliders] = useState(value ?? [[] as Slider] as Sliders);
  const [panelState, setPanelState] = useState({} as PanelState);
  const [upload, setUpload] = useState(0);

  const forceReload = () => setUpload(upload + 1);

  subscribeEvent(EventType.uploadComplete, () => forceReload());

  const onAddSlider = () => {
    if (sliders.length > 5) return;
    const newSliders = [...sliders];
    newSliders.push([''] as Slider);
    setSliders(newSliders);
    onChange(language, name, newSliders);
  };

  const onDeleteSlider = (e: any, index: number) => {
    e.stopPropagation();
    if (sliders.length === 1) return;
    const newSliders = [...sliders];
    newSliders.splice(index, 1);
    setSliders(newSliders);
    onChange(language, name, newSliders);
  };

  const onDeleteImage = (index: number, position: number) => {
    const newSliders = [...sliders];
    newSliders[index].splice(position, 1);
    setSliders(newSliders);
    onChange(language, name, newSliders);
  };

  const onAddImage = (e: any, index: number, panelName: string) => {
    e.stopPropagation();
    if (sliders[index].length > 10) return;
    const position = sliders[index].length;
    const filename = `${name}_${language}_${index}_${position}.png`;
    const url = `${organisation}/${slug}/images/${filename}`;
    const newPanelState = {
      ...panelState,
      [panelName]: true,
    };
    setPanelState(newPanelState);
    dispatch(openModal({
      modal: MODALS.uploadImage,
      payload: {
        path: url,
        size: {
          width,
          height,
          ratio: width / height,
        },
        name: filename,
      },
    }));
  };

  const onImageChange = (index: number, position: number, url: string) => {
    const newSliders = [...sliders];
    newSliders[index][position] = url;
    setSliders(newSliders);
    onChange(language, name, newSliders);
  };

  const getIndexAndPosition = (path: string) => {
    const temp = path.split('.')[0].split('_');
    const tempPosition = temp.pop();
    const tempIndex = temp.pop();

    const index = tempIndex !== undefined ? Number(tempIndex) : -1;
    const position = tempPosition !== undefined ? Number(tempPosition) : -1;

    return [index, position];
  };

  const onImageUploadComplete = (e:CustomEvent) => {
    const [index, position] = getIndexAndPosition(e.detail);
    if (index >= 0 && position >= 0) {
      onImageChange(Number(index), Number(position), e.detail);
    }
  };

  subscribeEvent(EventType.uploadComplete, (e:CustomEvent) => onImageUploadComplete(e));

  const getTitle = (text: string) => {
    if (errors && errors.length) {
      return (
        <Error>{text}</Error>
      );
    }
    return (<span>text</span>);
  };

  const displaySliderTitle = (text: string, index: number) => {
    // @ts-ignore
    if (errors && errors[index]) {
      return (
        <Error>{text} {index + 1}
          (<span style={{ fontStyle: 'italic' }}>Can not be empty</span>)
        </Error>
      );
    }
    return (<span style={{ fontWeight: 'bold' }}>{text} {index + 1}</span>);
  };

  const displayImageTitle = (text: string, index: number, position: number) => {
    if (errors && errors[index][0] === 1) {
      return (<span>{text}</span>);
    }
    // @ts-ignore
    if (errors && errors[index][position]) {
      return (
        <Error>{text}&nbsp;
          (<span style={{ fontStyle: 'italic' }}>{errors[index][position]}</span>)
        </Error>
      );
    }
    return (<span>{text}</span>);
  };

  const displayImageItem = (index: number, position: number) => {
    const filename = `${name}_${language}_${index}_${position}.png`;
    const url = `${organisation}/${slug}/images/${filename}`;
    return (
      <>
        <Row key={`slider-${index}`}>
          { !!sliders[index][position] && (
          <>
            {displayImageTitle(filename, index, position)}
            <Tooltip
              title={(
                <img
                  alt="slider"
                  src={`${getS3URL()}${url}`}
                  height={height}
                  width={width} />
            )}
              placement="top-start">
              <VisibilityIcon />
            </Tooltip>
          </>
          )}
          <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => onDeleteImage(index, position)}>
              <DeleteIcon />
            </ActionButton>
          </span>
        </Row>
      </>
    );
  };

  const displayImages =
    (index: number) => sliders[index].map(
      (img:string, position:number) => displayImageItem(index, position),
    );

  const onPanelClick = (panelName: string, expanded: boolean) => {
    const newPanelState = {
      ...panelState,
      [panelName]: expanded,
    };
    setPanelState(newPanelState);
  };

  const displaySliders = () => sliders.map((slider: Slider, index: number) => {
    const panelName = `${title}-${index}`;
    return (
      <Accordion
        expanded={panelState[panelName]}
        onChange={(e, expanded) => onPanelClick(panelName, expanded)}
        key={`${panelName}-${index}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls={panelName}
          id={panelName}>
          <Row>
            {displaySliderTitle(title, index)}
            <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => onAddImage(e, index, panelName)}
                onFocus={(e) => e.stopPropagation()}
                disabled={sliders[index].length >= 10}>
                <span style={{ padding: '0px 8px' }}>Upload Image</span>
                <AddIcon />
              </ActionButton>
              <span style={{ width: '8px' }}>&nbsp;</span>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => onDeleteSlider(e, index)}
                onFocus={(e) => e.stopPropagation()}>
                <span style={{ padding: '0px 8px' }}>Delete slider</span>
                <DeleteIcon />
              </ActionButton>
            </span>
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <Column>
            {displayImages(index)}
          </Column>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Frame>
      <SliderHeader>
        {getTitle(title)}
        <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={() => onAddSlider()}
            disabled={sliders.length >= 5}>
            <span style={{ padding: '0px 8px' }}>Add a Slider</span><AddIcon />
          </ActionButton>
        </span>
      </SliderHeader>
      <SliderContent>
        {displaySliders()}
      </SliderContent>
    </Frame>
  );
};

export default ImageSliderManager;
