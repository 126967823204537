import not_verified from 'src/i18n/fr/pages/not_verified';
import reset_password from 'src/i18n/fr/pages/reset_password';
import verified from 'src/i18n/fr/pages/verified';
import login from 'src/i18n/fr/pages/login';
import profile from 'src/i18n/fr/pages/profile';
import register from 'src/i18n/fr/pages/register';
import titles from 'src/i18n/fr/pages/titles';

const pages = {
  login,
  not_verified,
  profile,
  register,
  reset_password,
  verified,
  titles,
};

export default pages;
