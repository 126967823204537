import { PaymentsModel } from 'src/models/PaymentModel';
import { Reducer } from 'redux';
import { UPDATE_PAYMENT } from 'src/redux/actions/paymentActions';

export type PaymentsState = PaymentsModel;

const DEFAULT = {} as PaymentsState;

const paymentReducer: Reducer<PaymentsState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case UPDATE_PAYMENT: {
      const { id, payment } = action.payload;
      return {
        ...state,
        [id]: {
          ...payment,
        },
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
