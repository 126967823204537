import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteContactGroupData,
  getContactGroupData,
  normalizeGroups,
  postContactGroupData,
  putContactGroupData,
} from 'src/apis/ContactGroupAPI';
import { ContactGroupModel } from 'src/models/ContactGroupModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';

export const SET_CONTACT_GROUP = 'SET_CONTACT_GROUP';
export const RESET_CONTACT_GROUP = 'RESET_CONTACT_GROUP';

export const setContactGroup = makeActionCreator(SET_CONTACT_GROUP);
export const resetContactGroup = makeActionCreator(RESET_CONTACT_GROUP);

export const getContactGroupAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getContactGroupData(organisation);
      if (response.success) {
        const groups = normalizeGroups(response.data);
        dispatch(setContactGroup(groups));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const putContactGroupAction: (payload: ContactGroupModel) => ThunkedAction<State> =
  (payload: ContactGroupModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putContactGroupData(organisation, payload);
      if (response.success) {
        const groups = normalizeGroups(response.data.list);
        dispatch(setContactGroup(groups));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.group_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.group_saved_error',
      }));
    } catch (e) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.group_saved_error',
      }));
      /* Log the error here */
    }
  };

export const postContactGroupAction: (payload: ContactGroupModel) => ThunkedAction<State> =
  (payload: ContactGroupModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postContactGroupData(organisation, payload);
      if (response.success) {
        const groups = normalizeGroups(response.data.list);
        dispatch(setContactGroup(groups));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.group_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.group_saved_error',
      }));
    } catch (e) { /* Log the error here */
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.group_saved_error',
    }));
  };

export const deleteContactGroupAction: (payload: ContactGroupModel) => ThunkedAction<State> =
  (payload: ContactGroupModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteContactGroupData(organisation, payload);
      if (response.success) {
        const groups = normalizeGroups(response.data.list);
        dispatch(setContactGroup(groups));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.group_deleted',
        }));
        dispatch(closeDialog({ dialog: DIALOGS.deleteGroup }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.group_deleted_error',
      }));
    } catch (e) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.group_deleted_error',
      }));
    }
  };
