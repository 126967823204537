import { Key, ReactNode } from 'react';

interface Props {
  children: ReactNode,
  key?: Key,
}

const UserListItemLabel = (props: Props) => {
  const {
    children, key,
  } = props;

  return (
    <span
      style={{
        fontWeight: 'bold',
        marginRight: '8px',
      }}
      key={key}>
      {children}
    </span>
  );
};

export default UserListItemLabel;
