import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  pagination: {
    height: '64px',
    paddingTop: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > ul': {
      justifyContent: 'center',
    },
  },
}));

interface Props {
  count: number;
  page: number;
  onChange: (page: number) => void,
}

const PaginationUI = (props: Props) => {
  const classes = useStyles();
  const { count, page, onChange } = props;
  if (count < 2) return (<></>);
  return (
    <Pagination
      className={classes.pagination}
      color="primary"
      count={count}
      variant="outlined"
      shape="rounded"
      size="small"
      page={page}
      onChange={(e, newPage) => onChange(newPage)} />
  );
};

export default PaginationUI;
