import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  handleClose: () => void,
  style?: CSSProperties,
  label?: string,
}

const useStyles = makeStyles(() => ({
  close: {
    padding: 5,
    minHeight: 0,
    minWidth: 0,
    float: 'right',
    '&> span': {
      lineHeight: 0.75,
    },
  },
}));

const CloseButton = (props: Props) => {
  const classes = useStyles();

  const {
    handleClose,
    style,
    label,
  } = props;

  return (
    <Button
      style={style}
      variant="contained"
      color="primary"
      className={classes.close}
      onClick={handleClose}>
      { label ?? 'X' }
    </Button>
  );
};

export default CloseButton;
