const invoice = {
  id: 'id',
  issued_date: 'Date de Création',
  due_date: 'Date Limite',
  scheduled: 'Schédulé',
  sequence: 'Séquence',
  title: 'Titre',
  note: 'Note',
  issue_tax_receipt: 'Generer Recu impot',
  is_sent: 'Envoye',
  bank_account_id: 'Compte Bancaire',
  is_printed: 'Imprimer',
  billing_address: 'Addresse (Facturation)',
  billing_suite: 'Suite (Facturation)',
  billing_city: 'Ville (Facturation)',
  billing_state: 'État (Facturation)',
  billing_country_id: 'Pays (Facturation)',
  billing_zip_code: 'Code Postal (Facturation)',
  shipping_address: 'Addresse (Expédition)',
  shipping_suite: 'Suite  (Expédition)',
  shipping_city: 'Ville (Expédition)',
  shipping_state: 'État (Expédition)',
  shipping_country_id: 'Pays (Expédition)',
  shipping_zip_code: 'Code Postal (Expédition)',
  contact_company_name: 'Nom de la Compagnie',
  contact_salutation_id: 'Salutation',
  contact_first_name: 'Contact Prénom',
  contact_last_name: 'Contact Nom',
  account_id: 'Comptes',
  program_id: 'Programmes',
  department_id: 'Départements',
  state: 'Statut',
  payment_status: 'Statut Paiement',
  contact_id: 'Contact',
  seller_id: 'Vendeur',
  contact_payer_id: 'Payeur',
  total: 'Total',
  subtotal: 'Sous-total',
  taxes: 'Taxes',
  paid: 'Payé',
  balance: 'Balance',
  deductible: 'Rabais',
  created_at: 'Créé',
  updated_at: 'Mis-à-jour',
  deleted_at: 'Supprimé',
};

export default invoice;
