import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import ManageTeams from 'src/components/Elements/ManageTeams';

const TeamManagementModal = () => (
  <ModalBase
    name={MODALS.manageTeams}
    size={MODALS_SIZE.medium}>
    <ManageTeams />
  </ModalBase>
);

export default TeamManagementModal;
