import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  height?: string,
  style?: CSSProperties,
}

const Scroller = (props: Props) => {
  const {
    children,
    height,
    style,
  } = props;

  const styles: CSSProperties = {
    overflowY: 'auto',
    height: height ?? '604px',
    padding: '8px',
    ...style,
  };
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default Scroller;
