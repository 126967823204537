import { QuickSearchModel, QuickSearchPayload } from 'src/models/QuickSearchModel';
import { Reducer } from 'redux';
import { SET_QUICK_SEARCH } from 'src/redux/actions/querybuilderActions';

export type QuickSearchState = QuickSearchModel;

const DEFAULT:QuickSearchState = {} as QuickSearchState;

const QuickSearchReducer: Reducer<QuickSearchState> = (state = DEFAULT, action) => {
  const { entity, quicksearch } = action.payload as QuickSearchPayload || {} as QuickSearchPayload;
  switch (action.type) {
    case SET_QUICK_SEARCH: {
      return {
        ...state,
        [entity]: quicksearch,
      };
    }
    default:
      return state;
  }
};

export default QuickSearchReducer;
