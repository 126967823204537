import { PresetModel } from 'src/models/PresetModel';
import { Reducer } from 'redux';
import { RESET_PRESET, SET_PRESET, SET_PRESETS } from 'src/redux/actions/presetActions';

export type PresetState = PresetModel;

const DEFAULT = {} as PresetState;

const PresetReducer: Reducer<PresetState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_PRESET: {
      const { payload } = action;
      const { entity, type, filters } = payload;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          [type]: { ...filters },
        },
      };
    }
    case SET_PRESETS: {
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case RESET_PRESET:
      return DEFAULT;
    default:
      return state;
  }
};

export default PresetReducer;
