import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const ChangePasswordModal = () => (
  <ModalBase
    name={MODALS.changePassword}
    size={MODALS_SIZE.small}>
    Change Password
  </ModalBase>
);

export default ChangePasswordModal;
