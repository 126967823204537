import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import {
  CreatePresetPayload,
  DeletePresetPayload,
  EntityPreset,
  PresetItem,
  UpdatePresetPayload,
} from 'src/models/PresetModel';

export const getPresetsData =
  (organisation: string, entity: ENTITIES) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.presets}/${entity}`,
    organisation,
  });

export const createPresetData =
  (
    organisation: string,
    payload: CreatePresetPayload,
  ) => {
    const {
      entity, type,
    } = payload;
    return makeApiRequest({
      method: 'POST',
      url: `${API_URL.preset}/${entity}/${type}`,
      organisation,
      data: payload,
    });
  };

export const updatePresetData =
  (
    organisation: string,
    payload: UpdatePresetPayload,
  ) => {
    const {
      entity, type, id,
    } = payload;
    return makeApiRequest({
      method: 'PUT',
      url: `${API_URL.preset}/${entity}/${type}/${id}`,
      organisation,
      data: payload,
    });
  };

export const deletePresetData =
  (
    organisation: string,
    payload: DeletePresetPayload,
  ) => {
    const {
      entity, type, id,
    } = payload;
    return makeApiRequest({
      method: 'DELETE',
      url: `${API_URL.preset}/${entity}/${type}/${id}`,
      organisation,
    });
  };

export const normalizePresetsData = (data:any) => {
  const presets = {} as EntityPreset;
  data.forEach((preset:any) => {
    if (!presets[preset.type]) {
      presets[preset.type] = [] as PresetItem[];
    }
    const filter = JSON.parse(preset.filter);
    if (filter?.filter) {
      presets[preset.type].push({
        ...preset,
        ...filter,
      });
    }
  });
  return presets;
};
