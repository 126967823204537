import { AutocompleteModel } from 'src/models/AutocompleteModel';
import { Reducer } from 'redux';
import {
  RESET_AUTOCOMPLETE_RESULT,
  SET_AUTOCOMPLETE_RESULT,
} from 'src/redux/actions/autoCompleteActions';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';

export type AutocompleteState = AutocompleteModel;

const DEFAULT = {} as AutocompleteState;

const AutocompleteReducer: Reducer<AutocompleteState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_AUTOCOMPLETE_RESULT: {
      const { id, data } = action.payload;
      return {
        ...state,
        [id]: data,
      };
    }
    case RESET_AUTOCOMPLETE_RESULT: {
      const { id } = <{ id:AUTOCOMPLETE_ID }>action.payload;
      const { [id]: data, ...rest } = state;
      return rest as AutocompleteState;
    }
    default:
      return state;
  }
};

export default AutocompleteReducer;
