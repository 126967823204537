import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { useState } from 'react';
import { createContactQuickSearchFilter } from 'src/lib/QueryBuilderHelper';
import {
  getEntitiesAction,
  setFilters,
  setQuickSearch,
} from 'src/redux/actions/querybuilderActions';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { t } from 'src/lib/language';
import { ENTITIES, QuerbuilderPayloadFilters } from 'src/models/QuerybuilderModel';
import { ContactQuickSearch, QuickSearchPayload } from 'src/models/QuickSearchModel';

const useStyles = makeStyles((theme) => ({
  datagridQuickSearch: {
    width: '100%',
    border: '1px solid #eeeeee',
    borderTop: 'none',
    padding: theme.spacing(1),
  },
  datagridQuickSearchContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  datagridQuickSearchClear: {
    color: theme.palette.primary.main,
    '&:Hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  },
  datagridSearchClose: {
    lineHeight: '20px',
    height: '20px',
    textAlign: 'center',
    margin: 'auto auto',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

interface Props {
  closeTab: () => void,
  entity: ENTITIES,
}

const DatagridQuickSearchContact = (props: Props) => {
  const classes = useStyles();
  const { closeTab, entity } = props;

  const dispatch = useAppDispatch();

  const loading = useSelector(
    (state: Store) => (state.formStatus[FORM.query_builder] === FORM_STATUS.processing),
  );
  const lang = useSelector((state: Store) => state.language.language);

  const storedQuickSearch =
    useSelector(
      (state: Store) => (
        state.quickSearch && state.quickSearch[entity] as ContactQuickSearch
      )
        || {
          term: '',
        } as ContactQuickSearch,
    );

  const [contactQuickSearch, setContactQuickSearch] = useState(storedQuickSearch);

  const quickSearch = () => {
    const filters = createContactQuickSearchFilter(contactQuickSearch);
    dispatch(resetSelections({ entity }));
    dispatch(setFilters({
      entity,
      filters,
    } as QuerbuilderPayloadFilters));
    dispatch(setQuickSearch({
      entity,
      quicksearch: contactQuickSearch,
    } as QuickSearchPayload));
    dispatch(getEntitiesAction(entity));
  };

  const handleKeyDown = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      quickSearch();
    }
  };

  const clearFilter = () => {
    dispatch(setFilters({
      entity,
      filters: null,
    } as QuerbuilderPayloadFilters));

    const defaultQuickSearch = {
      term: '',
    } as ContactQuickSearch;

    setContactQuickSearch(defaultQuickSearch);

    dispatch(setQuickSearch({
      entity,
      quicksearch: defaultQuickSearch,
    } as QuickSearchPayload));
    dispatch(getEntitiesAction(entity));
  };

  const setValue = (field: string, value:any) => {
    setContactQuickSearch({
      ...contactQuickSearch,
      [field]: value,
    });
  };

  return (
    <div className={classes.datagridQuickSearch}>
      <div className={classes.datagridQuickSearchContainer}>
        <TextField
          size="small"
          variant="outlined"
          margin="normal"
          label={t(lang, 'menus.quick_search_term')}
          onChange={(e) => setValue('term', e.target.value as string)}
          onKeyDown={handleKeyDown}
          value={contactQuickSearch.term} />
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: '8px', marginTop: '8px' }}
          onClick={quickSearch}
          disabled={loading}>
          {t(lang, 'menus.quick_search')}
        </Button>
      </div>
      <div
        className={classes.datagridQuickSearchClear}
        onClick={() => clearFilter()}>
        {t(lang, 'menus.clear_filter')}
      </div>
      <div
        className={classes.datagridSearchClose}
        onClick={() => closeTab()}>
        {t(lang, 'misc.close_search')}
      </div>
    </div>
  );
};

export default DatagridQuickSearchContact;
