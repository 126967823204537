import * as Yup from 'yup';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';

const TeamValidator = () => Yup.object().shape({
  contact_id: Yup.string().required(),
  name: Yup.string().required(),
  slug: Yup.string().matches(/^[a-zA-Z0-9_-]+$/, 'Invalid Slug').required(),
  avatar: Yup.string()
    .url('Invalid URL format')
    .matches(/\.(jpeg|jpg|gif|png)$/i, 'Invalid image URL'),
  description: Yup.string().required(),
  default_language: Yup.mixed().oneOf(Object.values(FundraisingLanguages)).required(),
});

export default TeamValidator;
