import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { t } from 'src/lib/language';

interface CustomProps {
  entity: ENTITIES,
}

type Props = CustomProps & ButtonProps;

const UnselectAllMenubutton = (props: Props) => {
  const { disabled, entity } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);
  const selections = useSelector(
    (state: Store) => state.selected[entity] || [],
  );

  const handleOnClick = () => {
    dispatch(setSelections({
      entity,
      selections: [],
    }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.unselect_all')}
      placement="top-start">
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.selection}
          onClick={handleOnClick}
          disabled={disabled || !selections.length}>
          <SelectAllIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default UnselectAllMenubutton;
