const payments = {
  deductible: 'Deductible',
  processed_date: 'Processed date',
  scheduled_date: 'Scheduled date',
  cc: 'Credit Cards',
  eft: 'Bank Accounts',
  contact_cc: 'Select a Credit Card',
  contact_eft: 'Select a Bank Account',
  edit_payment: 'Edit Payment',
  make_payment: 'Make Payment',
  make_donation: 'Make Donation',
  check: 'Check',
  'in-kind': 'In Kind',
  cash: 'Cash',
  gateways: 'Gateways',
  terminal: 'Terminal',
  wired: 'Wired',
  interac: 'Interac',
  'user-balance': 'User balance',
  issue_tax_receipt: 'Issue tax receipt when applicable',
  paid_date: 'Payment date',
  cancel_payment: 'Cancel Payment(s)',
  refund_payment: 'Refund Payment(s)',
  refund_date: 'Refund date',
  cancel_date: 'Cancel date',
  start_date: 'Start date',
  number: 'Number of payments',
  contact: 'Contact',
  payer: "Payer's name",
  seller: "Seller's name",
  amount: 'Amount to pay',
  balance: 'Balance',
  payment_type: 'Payment type',
  is_installment: 'Is installment',
  installments: 'Installments',
  single_payment: 'Single payment',
  multiple_payment: 'Multiple payment',
  add_credit_card: 'Add credit card',
  add_bank_account: 'Add bank account',
  payment_accounts: 'Payment accounts',
  organisation_bank_accounts: 'Organisation bank accounts',
  invoices_has_different_contacts: 'Invoices not all from the same contacts',
  payments_of: 'installments of',
  starting: 'starting',
  refund_date_validation: 'The refund date must be after today',
  cancel_date_validation: 'The cancel date must be after today',
  required_paid_payment: 'At least one PAID or CANCELLED payment is required to download a PDF.',
};

export default payments;
