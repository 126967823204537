const contact = {
  field_required: 'This field  is required',
  field_maximum: 'This field must have a maximum of %max% characters',

  required_if_no_company_name: 'The fields first and last name are required without a company name',
  required_if_no_contact_id: 'The field organisation id is required without an contact id',
  required_if_no_organisation_id: 'The field contact id is required without an organisation id',
  required_if_no_name: 'The field company name is required without a first and last name',
  //
  invalid_email: 'Invalid email',
  //
  phone_number_format: 'Invalid phone number format',
  at_least_one_phone_required: 'At least one phone number is required',
};

export default contact;
