import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { RelationModel } from 'src/models/ContactModel';
import { postRelationAction } from 'src/redux/actions/contactActions';
import Form from 'src/components/UI/Form';
import Spacer from 'src/components/UI/Spacer';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onClose?: () => void;
}

const AddRelationForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const { onClose } = props;

  const [state, setState] = useState({} as RelationModel);
  const form = FORM.add_relation;

  useEffect(() => {
    dispatch(setError({ [form]: {} }));
  }, []);

  const onChange = (value: any, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [form]: {} }));
    dispatch(postRelationAction(state));
    return true;
  };

  return (
    <Form>
      <FormTextField
        form={form}
        label={t(lang, 'forms.relations.name_en')}
        name="name_en"
        onChange={onChange}
        required
        value={state.name_en} />
      <FormTextField
        form={form}
        label={t(lang, 'forms.relations.name_fr')}
        name="name_fr"
        onChange={onChange}
        required
        value={state.name_fr} />
      <Spacer space={3} />
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleSubmit}
          key="submit-button">
          {t(lang, 'misc.save')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '12px' }}
          onClick={handleClose}
          key="submit-button-relation">
          {t(lang, 'misc.back_to_list')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default AddRelationForm;
