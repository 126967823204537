import { Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from 'react';

interface Props {
  checked: boolean | undefined,
  disabled?: boolean,
  label: string,
  name: string,
  onChange: any,
  className?: string,
  style?: CSSProperties,
}

const useStyles = makeStyles((theme) => ({
  checkboxElement: {
    marginTop: -theme.spacing(1),
    color: 'rgb(0, 0, 0, .75)',
  },
}));

const FormCheckbox = (props: Props) => {
  const {
    checked, disabled, onChange, label, name, className, style,
  } = props;
  const classes = useStyles();

  return (
    <FormControlLabel
      className={`${classes.checkboxElement} ${className}`}
      style={style}
      control={(
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={
          (e) => onChange(e.target.checked as boolean, name)
        }
          name="officePhoneNotification"
          color="primary"
          key={`checkbox-${name}`} />
    )}
      label={label}
      key={`form-control-${name}`} />
  );
};

export default FormCheckbox;
