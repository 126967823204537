const invoice = {
  field_required: 'This field  is required',
  field_maximum: 'This field must have a maximum of %max% characters',
  date_today_minimum: 'Date must be today or later',
  date_tomorrow_maximum: 'Date must be tomorrow or earlier',

  required_if_no_company_name: 'The fields first and last name are required without a company name',
  required_if_no_name: 'The field company name is required without a first and last name',
  //
  invalid_email: 'Invalid email',
  //
  phone_number_format: 'Invalid phone number format',
  at_least_one_phone_required: 'At least one phone number is required',
};

export default invoice;
