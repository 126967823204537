import * as Yup from 'yup';
import { t } from 'src/lib/language';

const FullContactValidator = (lang: string) => Yup.object().shape({
  salutation_id: Yup.number()
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer()
    .transform((value) => (!!value ? value : null))
    .nullable(true),
  first_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when(['company_name'], {
      is: (companyName: string) => !companyName,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })),
  last_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when(['company_name'], {
      is: (companyName: string) => !companyName,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })),
  company_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when(['first_name', 'last_name'], {
      is: (
        firstName: string,
        lastName: string,
      ) => (!firstName && !lastName),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    })),
  email: Yup.string()
    .email(t(lang, 'validations.contact.invalid_email'))
    .required(t(lang, 'validations.contact.field_required')),
  secondary_email: Yup.string()
    .email(t(lang, 'validations.contact.invalid_email'))
    .nullable(),
  billing_address: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 })),
  billing_suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  billing_city: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  billing_state: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(150, t(lang, 'validations.contact.field_maximum_', { max: 150 })),
  billing_country_id: Yup.number()
    .required(t(lang, 'validations.contact.field_required'))
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer(),
  billing_zip_code: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 })),
  phone_home: Yup.lazy(() => Yup.string()
    .when(['phone_office', 'phone_mobile'], {
      is: (office: string, mobile: string) => !office && !mobile,
      then: (
        schema,
      ) => schema.matches(/^\d+$/).required(t(lang, 'validations.contact.at_least_one_phone_required')),
      otherwise: (schema) => schema.nullable(),
    })),
  phone_office: Yup.lazy(() => Yup.string()
    .when(['phone_home', 'phone_mobile'], {
      is: (home: string, mobile: string) => !home && !mobile,
      then: (
        schema,
      ) => schema.matches(/^\d+$/).required(t(lang, 'validations.contact.at_least_one_phone_required')),
      otherwise: (schema) => schema.nullable(),
    })),
  phone_mobile: Yup.lazy(() => Yup.string()
    .when(['phone_home', 'phone_office'], {
      is: (home: string, office: string) => !home && !office,
      then: (
        schema,
      ) => schema.matches(/^\d+$/).required(t(lang, 'validations.contact.at_least_one_phone_required')),
      otherwise: (schema) => schema.nullable(),
    })),

});

export default FullContactValidator;
