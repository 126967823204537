import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import { Moment } from 'moment';

export enum VideoPlatform {
  youtube = 'youtube',
  vimeo = 'vimeo',
  other = 'other',
}

export interface FundraisingInfo {
  id?: number,
  type: FundraisingTypes,
  name: string,
  slug: string,
  goal: number | string,
  raised?: number,
  active: boolean,
  start_date: Moment,
  end_date: Moment,
  template_id?: number, // place holder for future use
  languages: FundraisingLanguages[]
  default_language: FundraisingLanguages,
  show_raised_amount: boolean,
  show_timer: boolean,
}

export interface FundraisingProduct {
  display_single_product: boolean,
  cover_fee_percentage: number,
  department_id: number,
  program_id: number,
  account_id: number,
  gateway_id: number,
  bank_account_id: number,
  allow_installment: boolean,
  max_installment: number,
  products: number[],
}

export interface FundraisingNotification {
  contacts: number[],
  groups: number[],
  send_thanks_email: boolean,
  thanks_email_body?: string,
  send_thanks_sms: boolean,
  thanks_sms_text?: string,
  fb_description?: string,
  fb_keyword?: string,
  media_title?: string,
  media_image_preview?: string,
  media_text?: string,
}

export interface FundraisingEventInfo {
  title: string,
  event_date: Moment,
  location_name: string,
  location_address: string,
  contact_email: string,
  contact_phone: string,
  logo: string,
  footer_message: string,
  order: string[],
}

export interface FundraisingStandardInfo {
  order: string[],
}

export interface FundraisingMatchingInfo {
  order: string[],
  match_multiplier: number,
  match_goal: number,
  match_reached_multiplier: number,
  allow_comments: boolean,
  show_donors: boolean,
  show_teams: boolean,
  show_team_donor_amount: boolean,
  bonus_round: boolean,
  bonus_round_goal: number,
  slider_main_all_language: boolean,
  slider_secondary_all_language: boolean,
  slider_matcher_all_language: boolean,
  slider_sponsor_all_language: boolean,
  video_main_all_language: boolean,
  video_secondary_all_language: boolean,
  campaign_message_all_language: boolean
}

export type Slider = string[];

export interface Video {
  url: string;
  thumbnail: string;
  platform: VideoPlatform;
}

export type VideoSliders = Video[];

export type Sliders = Slider[];

export type Texts = string[];

export interface FundraisingStandardAsset {
  poster_portrait: string,
  poster_portrait_slider: Sliders,
  poster_landscape: string,
  poster_landscape_slider: Sliders,
  texts: Texts,
  video_slider: VideoSliders,
  video: Video,
}

export interface FundraisingStandardAssetModel {
  [language: string]: FundraisingStandardAsset,
}

export interface FundraisingMatchingdAsset {
  slider_main_portrait: Sliders,
  slider_main_landscape: Sliders,
  slider_secondary_landscape: Sliders,
  slider_secondary_portrait: Sliders,
  slider_matcher: Sliders,
  slider_sponsor: Sliders,
  video_main: Video,
  video_secondary: Video,
  campaign_message: string,
  button_label: string,
}

export interface FundraisingMatchingAssetModel {
  [language: string]: FundraisingMatchingdAsset,
}

export type FundraisingProducts = number[];

export interface FundraisingModel extends
  FundraisingInfo,
  FundraisingProduct,
  FundraisingNotification {
  event_info?: FundraisingEventInfo,
  standard_info?: FundraisingStandardInfo,
  matching_info?: FundraisingMatchingInfo,
  event_assets?: FundraisingStandardAssetModel,
  standard_assets?: FundraisingStandardAssetModel,
  matching_assets?: FundraisingMatchingAssetModel,
  teams: TeamList;
}

export interface FundraisingItem {
  id: number,
  name: string,
  slug: string,
  type: FundraisingTypes,
  active: true,
}

export type FundraisingList = FundraisingItem[];

export interface Team {
  id?: number,
  fundraising_id?: number,
  contact_id: number,
  name: string,
  slug: string,
  avatar: string,
  goal: number,
  description: string,
  default_language: FundraisingLanguages,
}

export type Teams = Team[];

export interface FundraisingTeam {
  id: number,
  goal: number,
}

export type TeamList = FundraisingTeam[];
