import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteProductData,
  getProductListData,
  normalizeProductListData,
  postProductData,
  putProductData,
} from 'src/apis/ProductAPI';
import { ProductModel, ProductType } from 'src/models/ProductModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';

export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST';

export const setProductListData = makeActionCreator(SET_PRODUCT_LIST);

export const getProductListAction: (type?: ProductType | undefined) => ThunkedAction<State> =
(type?: ProductType) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await getProductListData(organisation, type);
    if (response.success && (!!response.data.list)) {
      dispatch(setProductListData(normalizeProductListData(response.data.list)));
    }
  } catch (e) { /* Log the error here */
  }
};

export const postProductAction: (product: ProductModel) => ThunkedAction<State> =
(product: ProductModel) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const normalizedProduct = {
      ...product,
      taxes: Array.isArray(product.taxes)
        ? product.taxes.join(',')
        : product.taxes,
    };
    const response = await postProductData(organisation, normalizedProduct);
    if (response.success) {
      dispatch(setProductListData(normalizeProductListData(response.data.list)));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.product_saved',
      }));
      dispatch(setFormStatus({ [FORM.add_product]: FORM_STATUS.success }));
    } else if (response.status === 400) {
      // the code need to be a key for i18n
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors)[0],
      }));
      dispatch(setFormStatus({ [FORM.add_product]: FORM_STATUS.error }));
    } else {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.product_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.add_product]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.product_saved_error',
    }));
  }
};

export const putProductAction: (product: ProductModel) => ThunkedAction<State> =
(product: ProductModel) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const normalizedProduct = {
      ...product,
      taxes: Array.isArray(product.taxes)
        ? product.taxes.join(',')
        : product.taxes,
    };
    const response = await putProductData(organisation, normalizedProduct);
    if (response.success === true) {
      dispatch(setProductListData(normalizeProductListData(response.data.list)));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.product_saved',
      }));
      dispatch(setFormStatus({ [FORM.put_product]: FORM_STATUS.success }));
      return;
    }

    if (response.status === 400) {
      // The code need to be a key for i18n
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors)[0],
      }));
      dispatch(setFormStatus({ [FORM.put_product]: FORM_STATUS.error }));
      return;
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.product_saved_error',
    }));
    dispatch(setFormStatus({ [FORM.put_product]: FORM_STATUS.error }));
    return;
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.product_saved_error',
    }));
  }
};

export const deleteProductAction: (id: number) => ThunkedAction<State> =
(id: number) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await deleteProductData(organisation, id);
    if (response.success) {
      dispatch(setProductListData(response.data.list));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.product_deleted',
      }));
      return;
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.product_deleted_error',
    }));
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.product_deleted_error',
    }));
  }
};
