import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { putUserLocale } from 'src/apis/UserAPI';

export const SET_LANGUAGE = 'SET_LOCALE';

export const setLanguage = makeActionCreator(SET_LANGUAGE);

export const setUserLocaleData: (locale: string) => ThunkedAction<State> =
  (data) => async () => {
    await putUserLocale(data);
  };
