// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ORGANISATION_TYPES {
  school = 'school',
  business = 'business',
  church = 'church',
  general = 'general',
}

export const ORGANISATION_TYPES_LIST = [
  ORGANISATION_TYPES.school,
  ORGANISATION_TYPES.business,
  ORGANISATION_TYPES.church,
  ORGANISATION_TYPES.general,
];
