const invoices = {
  accounts: 'Accounts',
  account: 'Account',
  allow_installments: 'Allow installments',
  amount: 'Amount',
  bank_account: 'Bank Account',
  billing_address: 'Billing Address',
  create_invoice: 'Create invoice',
  contact: 'Contact',
  contacts: 'Contacts',
  department: 'Department',
  due_date: 'Due date',
  departments: 'Departments',
  deductible: 'Tax Deductible',
  price: 'Price',
  receipt: 'Receipt',
  qty: 'Qty',
  product: 'Product',
  discount: 'Discount',
  edit_invoices: 'Edit invoices',
  issued_date: 'Issue date',
  delete_invoice: 'Delete invoice',
  start_date: 'Start date',
  end_date: 'End date',
  issue_tax_receipt: 'Issue tax receipt',
  invoice_informations: 'Invoice informations',
  make_payment: 'Make payment',
  make_offline_payment: 'Make Offline Payment',
  note: 'Note',
  number_of_installments: 'Number of installment(s):',
  orders: 'Orders',
  paid_amount: 'Paid',
  products: 'Products',
  quantity: 'Qty',
  payment_status: 'Payment status',
  payment_type: 'Payment method',
  invoice_status: 'Invoice status',
  view_note: 'View note',
  hide_note: 'Hide note',
  payer: 'Payer',
  program: 'Program',
  programs: 'Programs',
  seller: 'Seller',
  shipping_address: 'Shipping Address',
  state: 'Invoice State',
  title: 'Title',
  subtotal: 'Subtotal',
  taxes: 'Taxes',
  draft: 'Draft',
  final: 'Final',
  legacy: 'Legacy',
  error: 'Error',
  canceled: 'Canceled',
  abandoned: 'Abandoned',
  not_paid: 'Not Paid',
  paid: 'Paid',
  scheduled: 'Scheduled',
  scheduled_due: 'Scheduled Due',
  partially_paid: 'Partially Paid',
};

export default invoices;
