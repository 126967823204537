import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import PaymentsForInvoiceDatagrid from 'src/components/EntityDatagrids/PaymentsForInvoiceDatagrid';

const PaymentsForInvoiceModal = () => (
  <ModalBase
    name={MODALS.paymentsForInvoice}
    size={MODALS_SIZE.large}>
    <PaymentsForInvoiceDatagrid />
  </ModalBase>
);

export default PaymentsForInvoiceModal;
