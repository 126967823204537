import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { useEffect } from 'react';
import { getSalutationsAction } from 'src/redux/actions/dataActions';
import FormSingleSelect from 'src/components/Control/FormControls/FormSingleSelect';
import { FORM_ID } from 'src/models/FormModel';

interface Props {
  disabled?: boolean,
  form: FORM_ID,
  name: string,
  onChange: (id:Nullable<number>) => void,
  value?: number | '',
}

interface SalutationItem {
  id: number,
  display: string,
}

const SalutationSelector = (props: Props) => {
  const dispatch = useAppDispatch();

  const {
    disabled, form, name, onChange, value,
  } = props;

  const salutations = useSelector((state: Store) => state.data.salutations);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  useEffect(() => {
    dispatch(getSalutationsAction());
  }, []);

  const getItems = () => {
    const items:SalutationItem[] = [
      { id: 0, display: 'none' },
    ];
    if (!salutations.length) {
      return items;
    }
    salutations.map((item: any) => {
      items.push({
        id: item.id,
        display: item.name,
      } as SalutationItem);
      return items;
    });
    return items;
  };

  const normalizeValue = (id:number) => {
    let normalValue: Nullable<number> = id;
    if (!normalValue) {
      normalValue = null;
    }
    onChange(normalValue);
  };

  return (
    <FormSingleSelect
      disabled={disabled}
      form={form}
      items={getItems()}
      label={t(lang, 'forms.contact.salutations')}
      name={name}
      onChange={normalizeValue}
      value={value || ''}
      noMarginTop />
  );
};

export default SalutationSelector;
