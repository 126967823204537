import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const ItalicTextAccent = (props: Props) => {
  const { children } = props;

  return (
    <div style={{
      fontSize: '0.875rem',
      fontStyle: 'italic',
      display: 'flex',
      alignItems: 'center',
    }}>
      {children}
    </div>
  );
};

export default ItalicTextAccent;
