import { Reducer } from 'redux';
import {
  ADD_CONTACT_BANK_ACCOUNT,
  DELETE_CONTACT_BANK_ACCOUNT,
  SET_CONTACT_BANK_ACCOUNTS,
} from 'src/redux/actions/paymentInfoAction';
import { ContactBankAccountModel } from 'src/models/BankAccountModel';

export type ContactBankAccountState = ContactBankAccountModel;

const DEFAULT = {} as ContactBankAccountState;

const ContactBankAccountReducer: Reducer<ContactBankAccountState> = (state = DEFAULT, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CONTACT_BANK_ACCOUNTS:
      return {
        ...state,
        [payload.id]: payload.bankAccounts,
      };
    case ADD_CONTACT_BANK_ACCOUNT:
      return {
        ...state,
        [payload.id]: [
          ...state[payload.id],
          payload.bankAccount,
        ],
      };
    case DELETE_CONTACT_BANK_ACCOUNT: {
      const bankAccounts = state[payload.contactId];
      const newBankAccounts = bankAccounts.filter(
        (bankAccount) => bankAccount.id !== payload.accountId,
      );
      return {
        ...state,
        [payload.contactId]: newBankAccounts,
      };
    }
    default:
      return state;
  }
};

export default ContactBankAccountReducer;
