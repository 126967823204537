import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    height: 32,
    width: 32,
    paddingTop: '6px',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    textAlign: 'center',
  },
  text: {
    color: 'white',
  },
}));

interface Props {
  children: string
}

const RoundIcon = (props: Props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.icon}>
      <Typography className={classes.text}>{children}</Typography>
    </div>
  );
};

export default RoundIcon;
