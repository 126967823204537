import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { InvoicePayload } from 'src/models/InvoiceModel';

export const postInvoiceData =
(organisation: string, payload: InvoicePayload) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.invoice}`,
  organisation,
  data: payload,
});

export const getInvoiceData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.invoice}/${id}`,
    organisation,
  });

export const getInvoiceGuessData =
  (hash: string, organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.guess_invoice}/${hash}`,
    organisation,
  });

export const putInvoiceData =
  (id: number, organisation: string, payload: InvoicePayload) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.invoice}/${id}`,
    organisation,
    data: payload,
  });

export const cancelInvoicesData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.cancel_invoice}/${id}`,
    organisation,
  });
