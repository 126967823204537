import { ProductOrderList } from 'src/models/InvoiceModel';
import { Moment } from 'moment';

export interface PaymentModel {
  id?: number,
  amount?: number | string,
  bank_account_id?: number,
  contact_id?: number,
  payment_action?: PAYMENT_ACTION_TYPE,
  payment_status?: PAYMENT_STATUS,
  payment_type: PAYMENT_TYPE,
  account_id?: number,
  billing_address?: string,
  billing_city?: string,
  billing_country_id?: number,
  billing_state?: string,
  billing_suite?: string,
  billing_zip_code?: string,
  contact_company_name?: string,
  contact_first_name?: string,
  contact_last_name?: string,
  contact_salutation_id?: number,
  deductible?: number,
  department_id?: number,
  description?: string,
  installment?: InstallmentModel,
  is_expense?: boolean,
  is_installment?: boolean,
  is_refund?: boolean,
  issue_tax_receipt?: boolean,
  paid_date?: Nullable<Moment>,
  payment_gateway_id?: number,
  payment_gateway_cc_id?: number,
  payment_gateway_eft_id?: number,
  payment_info_id?: number,
  payment_info?: PaymentInfoModel,
  payment_receipt_printed?: boolean,
  payment_receipt_sent?: boolean,
  processed_date?: Nullable<Moment>,
  program_id?: number,
  reference?: string,
  reference_type?: number,
  refund_payment_id?: number,
  result_code?: string,
  result_message?: string,
  scheduled_date?: Nullable<Moment>,
  seller_id?: number,
  start_date?: Nullable<Moment>,
  tax_receipt_issued?: number,
  title?: string,
  user_id?: number,
  invoices?: any,
}

export interface PaymentInfoModel {
  contact_id?: number,
  card_number: string,
  first_name?: string,
  last_name?: string,
  card_type?: string,
  expiration_month: string,
  expiration_year: string,
  cvv: string,
}

export interface InvoicePaymentItem {
  invoice_id: number,
  amount: number,
}

export interface InstallmentModel {
  number: number,
  start_date: Moment,
  frequency: PAYMENT_FREQUENCY,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PAYMENT_FREQUENCY {
  daily = 'daily',
  weekly = 'weekly',
  biweekly = 'biweekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export const PAYMENT_FREQUENCY_LIST: PAYMENT_FREQUENCY[] = [
  PAYMENT_FREQUENCY.daily,
  PAYMENT_FREQUENCY.weekly,
  PAYMENT_FREQUENCY.biweekly,
  PAYMENT_FREQUENCY.monthly,
  PAYMENT_FREQUENCY.yearly,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PAYMENT_ACTION_TYPE {
  debit = 'debit',
  credit = 'credit',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PAYMENT_STATUS {
  scheduled = 'scheduled',
  pending = 'pending',
  paid = 'paid',
  error = 'error',
  refunded = 'refunded',
  refunding = 'refunding',
  cancelled = 'cancelled',
  cancellation = 'cancellation',
  declined = 'declined',
  holding = 'holding',
  not_paid = 'not_paid',
}

export const PAYMENT_STATUS_LIST: PAYMENT_STATUS[] = [
  PAYMENT_STATUS.scheduled,
  PAYMENT_STATUS.pending,
  PAYMENT_STATUS.paid,
  PAYMENT_STATUS.error,
  PAYMENT_STATUS.refunded,
  PAYMENT_STATUS.refunding,
  PAYMENT_STATUS.cancelled,
  PAYMENT_STATUS.cancellation,
  PAYMENT_STATUS.declined,
  PAYMENT_STATUS.holding,
];

export const PAYMENT_STATUS_COLOR = {
  not_paid: '#383838',
  paid: '#18a90f',
  scheduled: '#4e7ff8',
  scheduled_due: '#d41d1d',
  partially_paid: '#4e7ff8',
  error: '#D4631DFF',
  declined: '#d41d1d',
  pending: '#4e7ff8',
  refunded: '#666565',
  cancelled: '#666565',
  holding: '#D4631DFF',
  cancellation: '#D4631DFF',
  refunding: '#4e7ff8',
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PAYMENT_TYPE {
  cc = 'cc',
  eft = 'eft',
  inkind = 'in-kind',
  check = 'check',
  cash = 'cash',
  terminal = 'terminal',
  wired = 'wired',
  interac = 'interac',
  userbalance = 'user-balance',
}

export const PAYMENT_TYPE_LIST: PAYMENT_TYPE[] = [
  PAYMENT_TYPE.cc,
  PAYMENT_TYPE.eft,
  PAYMENT_TYPE.inkind,
  PAYMENT_TYPE.check,
  PAYMENT_TYPE.cash,
  PAYMENT_TYPE.terminal,
  PAYMENT_TYPE.wired,
  PAYMENT_TYPE.interac,
  PAYMENT_TYPE.userbalance,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OFFLINE_PAYMENT_TYPE {
  inkind = 'in-kind',
  check = 'check',
  cash = 'cash',
  terminal = 'terminal',
  wired = 'wired',
  interac = 'interac',
  userbalance = 'user-balance',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ONLINE_PAYMENT_TYPE {
  cc = 'cc',
  eft = 'eft',
}

export default PaymentModel;

export type PaymentList = Array<PaymentModel>;

export type PaymentsModel = {
  [id: number] :PaymentModel
};

export type PurchasePayload = {
  account_id: number,
  bank_account_id: number,
  contact_id: number,
  department_id: number,
  installment?: InstallmentModel,
  is_deductible: boolean,
  is_installment: number,
  issued_date?: Moment,
  issue_tax_receipt: boolean,
  note?: string,
  orders: ProductOrderList,
  payment_id?: number,
  payment_info_id?: Nullable<number>,
  payment_gateway_id?: number,
  payment_gateway_cc_id?: number,
  payment_gateway_eft_id?: number,
  payment_type: PAYMENT_TYPE,
  program_id: number,
  start_date?: Moment,
  save_payment_info: boolean,
  seller_id: number,
  title: string,
  user_id?: number,
};

export type CancelPaymentItem = {
  payment_id: number;
  refund_date?: Moment;
  payment_date?: Moment;
};

export type CancelPaymentItems = {
  [id: number]: CancelPaymentItem
};
