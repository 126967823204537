import ContactModel from 'src/models/ContactModel';

export const getContactInfoForInvoice = (contactInfo: ContactModel) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    salutation_id: contact_salutation_id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    first_name: contact_first_name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    last_name: contact_last_name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    company_name: contact_company_name,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_city,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_country_id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_state,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_suite,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    shipping_zip_code,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_address,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_city,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_country_id,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_state,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_suite,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billing_zip_code,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    issue_tax_receipt,
  } = contactInfo;
  return {
    contact_salutation_id,
    contact_first_name,
    contact_last_name,
    contact_company_name,
    shipping_address,
    shipping_city,
    shipping_country_id,
    shipping_state,
    shipping_suite,
    shipping_zip_code,
    billing_address,
    billing_city,
    billing_country_id,
    billing_state,
    billing_suite,
    billing_zip_code,
    issue_tax_receipt,
  };
};
