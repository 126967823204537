import * as Yup from 'yup';

const FundraisingEventValidator = () => Yup.object().shape({
  title: Yup.string().max(150).required(),
  event_date: Yup.date().min(Date()),
  location_name: Yup.string().max(150),
  location_address: Yup.string().max(150),
  contact_email: Yup.string().max(150),
  contact_phone: Yup.string().max(150),
  footer_message: Yup.string().max(150),
});

export default FundraisingEventValidator;
