import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { FORM, FORM_STATUS } from 'src/constants/Form';

const useStyles = makeStyles(() => ({
  datagridOverlayProgress: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  datagridOverlay: {
    position: 'absolute',
    top: '0 !important',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1299,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const DatagridOverlay = () => {
  const classes = useStyles();
  const loading = useSelector(
    (state: Store) => (state.formStatus[FORM.query_builder] === FORM_STATUS.processing),
  );
  if (loading) {
    return (
      <div className={classes.datagridOverlay}>
        <CircularProgress color="primary" className={classes.datagridOverlayProgress} />
      </div>
    );
  }
  return (<></>);
};
//
export default DatagridOverlay;
