import { BankAccountsModel } from 'src/models/BankAccountListModel';
import { Reducer } from 'redux';
import { SET_BANK_ACCOUNTS, SET_CONTACT_BANK_ACCOUNTS } from 'src/redux/actions/bankAccountActions';
import { keyBy } from 'lodash';

export type BankAccountsState = BankAccountsModel;
const DEFAULT_BANK_ACCOUNTS: BankAccountsState = [] as BankAccountsState;
const bankAccountsReducer: Reducer<BankAccountsState> = (state = DEFAULT_BANK_ACCOUNTS, action) => {
  switch (action.type) {
    case SET_BANK_ACCOUNTS:
      return keyBy(action.payload.data, 'id');
    case SET_CONTACT_BANK_ACCOUNTS: {
      const { id, data } = action.payload;
      return {
        ...state,
        [id]: {
          ...data,
        },
      };
    }
    default:
      return state;
  }
};

export default bankAccountsReducer;
