import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getContactGroupAction } from 'src/redux/actions/contactGroupActions';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChipGroupSelector from 'src/components/Control/ChipGroupSelector';
import { ContactGroupModel } from 'src/models/ContactGroupModel';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

interface Props {
  disabled?: boolean;
  onChange: (group:number[]) => void;
  values?: number[];
}

const ContactGroupSelector = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const {
    values, disabled, onChange,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const groups = useSelector((store: Store) => store.contactGroup as ContactGroupModel);

  useEffect(() => {
    dispatch(getContactGroupAction());
  }, []);

  const handleDelete = (id:number) => {
    if (!values) return;
    const result = values.filter((value:number) => value !== id);
    onChange(result);
  };

  const handleAdd = (id:number) => {
    const result = !values ? [] : values;
    result.push(id);
    onChange([...result]);
  };

  const renderChips = () => {
    const currentValues = values || [] as number[];
    const tags = currentValues.map((id: number) => {
      const group = groups[id];
      return group && (
        <Tooltip
          title={group[`description_${lang}`]}
          placement="top-start"
          key={`chip-group-${group.id}`}>
          <Chip
            variant="outlined"
            label={group[`name_${lang}`]}
            onDelete={() => handleDelete(group.id)}
            disabled={disabled} />
        </Tooltip>
      );
    });
    tags.push(
      <ChipGroupSelector
        disabled={disabled}
        groups={groups}
        values={currentValues}
        onChange={handleAdd}
        key="chip-group-add-button" />,
    );
    return tags;
  };

  return (
    <div className={classes.root}>
      { renderChips() }
    </div>
  );
};

export default ContactGroupSelector;
//
