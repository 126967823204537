import palette from 'src/styles/palette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  height?: string,
}

const Header = (props: Props) => {
  const {
    children,
    height,
  } = props;

  const styles: CSSProperties = {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    backgroundColor: palette.primary.light,
    padding: '8px',
    height: height ?? '48px',
  };
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default Header;
