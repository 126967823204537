const misc = {
  add_preset: 'Ajout reglages',
  back_to_top: 'Retour au haut de la page',
  back_to_list: 'Retour a la liste',
  help: 'Aide',
  hide_help: 'Cacher l\'aide',
  save: 'Sauvegarder',
  custom_fields: 'Champs personnalisés',
  groups: 'Groupes',
  manage_admins: 'Gerer les administrateurs',
  close: 'Fermer',
  close_search: 'Fermer la recherche',
  delete: 'Supprimer',
  save_and_close: 'Sauvegarder et fermer',
  submit: 'Soumettre',
  yes: 'Oui',
  no: 'Non',
  y: 'O',
  n: 'N',
  cancel: 'Annuler',
  download: 'Télécharger',
  export: 'Exporter',
  update: 'Mettre à jour',
  cancel_payments: 'Annuler le paiement',
  refund_payments: 'Rembourser le(s) paiement(s)',
  refund: 'Rembourser',
  filter: 'Filtre',
  add: 'Ajouter',
  send_invite_to_become_admin: 'Envoyer une invitation pour devenir administrateur',
  invite: 'Inviter',
  address_information: 'Addresse',
  payment_information: 'Information de paiement',
  general_information: 'Information générale',
  connect_to_organisation: 'Connecter organisation',
  pay: 'Payer',
};

export default misc;
