import { ProductList } from 'src/models/ProductModel';
import { Reducer } from 'redux';
import { SET_PRODUCT_LIST } from 'src/redux/actions/productAction';

export type ProductsState = ProductList;
const DEFAULT_PRODUCT: ProductsState = [] as ProductsState;
const productsReducer: Reducer<ProductsState> = (state = DEFAULT_PRODUCT, action) => {
  switch (action.type) {
    case SET_PRODUCT_LIST:
      return [
        ...action.payload,
      ];

    default:
      return state;
  }
};

export default productsReducer;
