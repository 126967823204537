const profile = {
  has_contact: 'SVP ajoutez vos informations de contact avant de continuer.',
  has_choose_organization: 'SVP ajoutez au moins une organisation avant de continuer',
  edit_contact_info: 'Éditer vos informations de contact',
  edit_organisations_info: 'Éditer vos organisations',
  contact_info: 'Information de contact',
  organisations: 'Vos organisations',
  help_1: 'Cliquer sur le button Ajouter une organisation pour ajouter une organisation à la liste.',
  help_2: 'Cliquer sur ',
  help_3: ' pour enlever l\'organisation de la liste.',
  help_4: 'Cliquer sur la case à cocher pour partager vos informations avec cette organisation.',
};

export default profile;
