interface Props {
  children: any,
  bold?: boolean,
  italic?: boolean,
}

const Amount = (props: Props) => {
  const { bold, children, italic } = props;
  return (
    <div style={{
      width: '105px',
      display: 'inline-block',
      textAlign: 'right',
      fontWeight: bold ? 'bold' : undefined,
      textEmphasis: italic ? 'italic' : undefined,
    }}>
      { children }
    </div>
  );
};

export default Amount;
