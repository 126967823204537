import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const Error = (props: Props) => {
  const { children } = props;
  return (
    <div style={{ color: '#F00' }}>{children}</div>
  );
};

export default Error;
