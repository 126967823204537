const products = {
  price: 'Price',
  cost: 'Cost',
  deductible: 'Deductible',
  quantity: 'Quantity',
  picture: 'Picture',
  back_order: 'Back Order',
  is_managed: 'Is managed',
  is_public: 'Is public',
  is_modifiable: 'Is modifiable',
  allow_installment: 'Allow installment',
  discount: 'Discount',
  filter: 'Filter',
  last_name_required: 'Last name required',
  first_name_required: 'First name required',
  email_required: 'Email required',
  phone_required: 'Phone required',
  identifier_required: 'Identifier required',
};

export default products;
