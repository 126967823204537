// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import FundraisingTypeSelector from 'src/components/Control/FundraisingTypeSelector';
import { FORM } from 'src/constants/Form';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import { MouseEvent, useState } from 'react';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FundraisingLanguageSelector from 'src/components/Control/FundraisingLanguageSelector';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import Button from '@material-ui/core/Button';
import { FundraisingInfo } from 'src/models/FundraisingModel';
import { setError } from 'src/redux/actions/errorsActions';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ErrorBag } from 'src/models/ErrorModel';
import FundraisingInfoValidator from 'src/validations/FundraisingInformationValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import FormDateTimePicker from 'src/components/Control/FormControls/FormDateTimePicker';
import Form from 'src/components/UI/Form';
import BottomRow from 'src/components/UI/BottomRow';
import Row from 'src/components/UI/Row';
import FundraiserTitle from 'src/components/UI/FundRaiserTitle';

interface Props {
  onChange: (informations: FundraisingInfo) => void;
  onTypeChange: (value: FundraisingTypes) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  initialState: FundraisingInfo;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingInformationForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    onChange,
    onTypeChange,
    onNextStep,
    setErrors,
    initialState,
    setHasChanged,
  } = props;

  const [informationState, setInformationState] = useState(initialState);
  const [languageError, setLanguageError] = useState([] as string[]);
  const lang = useSelector((state: Store) => state.language.language);

  const setModified = () => {
    setHasChanged(true);
  };

  const onLanguageChange = (language: FundraisingLanguages, value: boolean) => {
    if (value) {
      if (!informationState.languages) informationState.languages = [];
      setInformationState({
        ...informationState,
        languages: [...informationState.languages, language],
      });
      return;
    }
    const languages = [...informationState.languages];
    languages.splice(languages.indexOf(language), 1);
    setInformationState({
      ...informationState,
      languages,
    });
  };

  const fundraisingLanguage = (language: FundraisingLanguages) => {
    if (!informationState.languages) return false;
    return informationState.languages.includes(language);
  };

  const sanitizeInput = (value: string) => {
    const sanitizedString = value.replace(/[^0-9.]/g, '');
    const parts = sanitizedString.split('.');
    if (parts.length > 1) {
      const decimalPart = parts[1].substring(0, 2);
      return `${parts[0]}.${decimalPart}`;
    }
    return sanitizedString;
  };

  const onValueChange = (value: any, field: string) => {
    setModified();
    if (field === 'type') {
      onTypeChange(value);
    }
    if (field === 'name') {
      informationState.slug = value.replace(' ', '-').toLowerCase();
    }
    if (field === 'slug') {
      setInformationState({
        ...informationState,
        [field]: value.toLowerCase().replace(' ', '-').replace(/[^0-9a-z-]/gi, ''),
      });
      return;
    }
    if (field === 'goal') {
      setInformationState({
        ...informationState,
        [field]: sanitizeInput(value),
      });
      return;
    }
    setInformationState({
      ...informationState,
      [field]: value,
    });
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(setError({ [FORM.fundraising_general_info]: {} }));
    setErrors(TABS.info, false);
    setLanguageError([] as string[]);
    const errorBag = {} as ErrorBag;
    if (informationState.goal) {
      informationState.goal = Number(informationState.goal);
    }
    try {
      FundraisingInfoValidator().validateSync(informationState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_general_info]: errorBag }));
      setErrors(TABS.info, true);
      if (errorBag.languages && errorBag.languages.length > 0) {
        setLanguageError(errorBag.languages);
      }
      return;
    }
    setHasChanged(false);
    onChange(informationState);
    onNextStep(2);
  };

  const disabled = false;

  return (
    <div>
      <FundraiserTitle>General Informations</FundraiserTitle>
      <Form>
        <div style={{ width: '50%' }}>
          <FundraisingTypeSelector
            name="type"
            form={FORM.fundraising_general_info}
            onChange={onValueChange}
            value={informationState.type}
            disabled={disabled} />
        </div>
        <FormCheckbox
          label={t(lang, 'forms.fundraising.active')}
          name="active"
          onChange={onValueChange}
          checked={!!informationState.active} />
        <FormTextField
          form={FORM.fundraising_general_info}
          label={t(lang, 'forms.fundraising.name')}
          name="name"
          onChange={onValueChange}
          value={informationState.name} />
        <FormTextField
          form={FORM.fundraising_general_info}
          label={t(lang, 'forms.fundraising.slug')}
          name="slug"
          onChange={onValueChange}
          value={informationState.slug} />
        <div style={{ width: '50%' }}>
          <FormTextField
            form={FORM.fundraising_general_info}
            label={t(lang, 'forms.fundraising.goal')}
            name="goal"
            onChange={onValueChange}
            value={informationState.goal} />
        </div>
        <div style={{ width: '50%' }}>
          <Row height="auto">
            <div style={{ width: '50%' }}>
              <FormDateTimePicker
                form={FORM.fundraising_general_info}
                required
                label={t(lang, 'forms.fundraising.start_date')}
                name="start_date"
                onChange={
                  (value: MaterialUiPickersDate) => onValueChange(
                    value,
                    'start_date',
                  )
                }
                value={informationState.start_date || moment()} />
            </div>
            <div style={{ width: '50%' }}>
              <FormDateTimePicker
                form={FORM.fundraising_general_info}
                required
                label={t(lang, 'forms.fundraising.end_date')}
                name="end_date"
                onChange={
                  (value: MaterialUiPickersDate) => onValueChange(
                    value,
                    'end_date',
                  )
                }
                value={informationState.end_date || moment()} />
            </div>
          </Row>
          <div style={{
            marginTop: '32px',
            marginBottom: '16px',
            color: languageError.length ? '#F00' : undefined,
          }}>
            <b>Languages</b>
          </div>
          <Row height="auto">
            <div style={{ width: '50%' }}>
              <FormCheckbox
                label={t(lang, 'forms.fundraising.en')}
                name="en"
                onChange={(value: boolean) => { onLanguageChange(FundraisingLanguages.en, value); }}
                checked={fundraisingLanguage(FundraisingLanguages.en)} />
            </div>
            <div style={{ width: '50%' }}>
              <FormCheckbox
                label={t(lang, 'forms.fundraising.fr')}
                name="fr"
                onChange={(value: boolean) => { onLanguageChange(FundraisingLanguages.fr, value); }}
                checked={fundraisingLanguage(FundraisingLanguages.fr)} />
            </div>
          </Row>
          <Row>
            <div style={{ width: '50%' }}>
              <FormCheckbox
                label={t(lang, 'forms.fundraising.es')}
                name="es"
                onChange={(value: boolean) => { onLanguageChange(FundraisingLanguages.es, value); }}
                checked={fundraisingLanguage(FundraisingLanguages.es)} />
            </div>
            <div style={{ width: '50%' }}>
              <FormCheckbox
                label={t(lang, 'forms.fundraising.he')}
                name="he"
                onChange={(value: boolean) => { onLanguageChange(FundraisingLanguages.he, value); }}
                checked={fundraisingLanguage(FundraisingLanguages.he)} />
            </div>
          </Row>
          <Row>
            <div style={{ width: '50%' }}>
              <FormCheckbox
                label={t(lang, 'forms.fundraising.y')}
                name="y"
                onChange={(value: boolean) => { onLanguageChange(FundraisingLanguages.y, value); }}
                checked={fundraisingLanguage(FundraisingLanguages.y)} />
            </div>
          </Row>
          <div style={{ width: '50%', marginTop: '16px' }}>
            <FundraisingLanguageSelector
              form={FORM.fundraising_general_info}
              name="default_language"
              onChange={onValueChange}
              value={informationState.default_language}
              label="Default Language"
              values={informationState.languages} />
          </div>
        </div>
        <div style={{ marginTop: '32px' }}>
          <FormCheckbox
            label={t(lang, 'forms.fundraising.show_raised_amount')}
            name="show_raised_amount"
            onChange={onValueChange}
            checked={!!informationState.show_raised_amount} />
          <FormCheckbox
            label={t(lang, 'forms.fundraising.show_timer')}
            name="show_timer"
            onChange={onValueChange}
            checked={!!informationState.show_timer} />
        </div>
        <BottomRow>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '24px 0px 16px' }}
            onClick={handleSubmit}
            key="submit-button">
            {t(lang, 'forms.fundraising.next_step')}
          </Button>
        </BottomRow>
      </Form>
    </div>
  );
};

export default FundraisingInformationForm;
