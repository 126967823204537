import { TaxListModel } from 'src/models/TaxListModel';
import { Reducer } from 'redux';
import { SET_TAXES } from 'src/redux/actions/taxActions';

export type TaxesState = TaxListModel;
const DEFAULT_TAXES: TaxesState = [] as TaxesState;
const taxesReducer: Reducer<TaxesState> = (state = DEFAULT_TAXES, action) => {
  switch (action.type) {
    case SET_TAXES:
      return [
        ...action.payload.data,
      ];
    default:
      return state;
  }
};

export default taxesReducer;
