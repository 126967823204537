import { SEARCH_OPERATORS, SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { Moment } from 'moment';

export type SingleOperand = string | number | Moment;
export type BetweenOperand = [SingleOperand, SingleOperand];
export type BetweenDateOperand = [Moment, Moment];
export type LikeOperand = string;
export type MultipleListOperand = Array<string | number>;
export type InOperand = Array<string | number>;
export type BooleanOperand = boolean;

export type Operands =
  SingleOperand |
  BetweenOperand |
  BetweenDateOperand |
  LikeOperand |
  MultipleListOperand |
  InOperand |
  BooleanOperand;

// eslint-disable-next-line @typescript-eslint/naming-convention
export type FilterOperator = SEARCH_OPERATORS | SEARCH_OPERATORS_PRIMITIVE | '';

export type Filter = [string, FilterOperator, Operands];

export type AdvancedSearchErrors = Array<[string, string, string]>;

export enum FilterLogicalOperator {
  and = 'and',
  or = 'or',
}

export type FilterValue = string | FilterOperator | Operands;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SEARCH_LOGICAL_OPERATORS {
  and = 'and',
  or = 'or',
}

export type Filters =
  {
    [SEARCH_LOGICAL_OPERATORS.and]: Filter[],
  } |
  {
    [SEARCH_LOGICAL_OPERATORS.or]: Filter[],
  } |
  {
    [SEARCH_LOGICAL_OPERATORS.and]: Filters[],
  };

export type FiltersPayload =
  {
    [SEARCH_LOGICAL_OPERATORS.and]: Filter[] | Filters[],
  } |
  {
    [SEARCH_LOGICAL_OPERATORS.or]: Filter[] | Filters[] | [Filter[]],
  };

export const EmptyFilter = {
  [SEARCH_LOGICAL_OPERATORS.and]: [['', '', ''] as Filter],
};

export type AdvancedSearchModel = {
  [entity in ENTITIES]: Filters
};
