const invoice = {
  field_required: 'Ce champ est requis',
  field_maximum: 'Ce champ accepte un maximum de %max% charactères',
  date_today_minimum: "Date doit être aujourd'hui ou plus tard",
  date_tomorrow_maximum: 'Date doit être demain ou plus tôt',

  required_if_no_company_name: 'Les champs prénom et nom de famille sont requis sans un nom de compagnie',
  required_if_no_name: 'Le champ nom de compagnie est requis sans les champs prénom et nom de famille',
  //
  invalid_email: 'Courriel invalide',
  //
  phone_number_format: 'Format de numéro de téléphone invalide',
  at_least_one_phone_required: 'Au moins un numéro de téléphone est requis',
};

export default invoice;
