import { Video, VideoSliders } from 'src/models/FundraisingModel';
import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import VideoChooser from 'src/components/Elements/VideoChooser';
import { VideoErrors } from 'src/validations/FundraisingAssetsValidation';
import { ErrorBag } from 'src/models/ErrorModel';
import Row from 'src/components/UI/Row';

interface Props {
  errors?: VideoErrors;
  label: string;
  language: FundraisingLanguages,
  name: string;
  onChange: (language: FundraisingLanguages, videos: VideoSliders) => void;
  slug: string,
  value: VideoSliders,
}

const TextSliderManager = (props: Props) => {
  const {
    errors,
    label,
    language,
    name,
    onChange,
    slug,
    value,
  } = props;

  const [videoSlider, setVideoSlider] = useState(value ?? [] as VideoSliders);
  const [panelState, setPanelState] = useState(false);

  const onDeleteVideo = (e: any, index: number) => {
    e.stopPropagation();
    const newVideos = [...videoSlider];
    newVideos.splice(index, 1);
    setVideoSlider(newVideos);
  };

  const onVideoChange = (index: number, video: Video) => {
    const newVideos = [...videoSlider];
    newVideos[index] = video;
    setVideoSlider(newVideos);
    onChange(language, newVideos);
  };

  const onAddVideo = (e: any) => {
    e.stopPropagation();
    if (videoSlider.length < 15) return;
    setPanelState(true);
    const newVideos = [...videoSlider];
    newVideos.push({} as Video);
    setVideoSlider(newVideos);
  };

  const getVideo = (index: number) => {
    if (!videoSlider[index]) {
      return {} as Video;
    }
    return videoSlider[index];
  };

  const getError = (index: number) => {
    if (!!errors && errors[index] && errors[0] !== 1) {
      return errors[index] as ErrorBag;
    }
    return undefined;
  };

  const displaySliders = () => videoSlider.map((video: Video, index: number) => (
    <Row>
      <VideoChooser
        onChange={(videoValue: Video) => onVideoChange(index, videoValue)}
        value={getVideo(index)}
        slug={slug}
        name={`${name}_${index}`}
        language={language}
        errors={getError(index)} />
      <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={(e) => onDeleteVideo(e, index)}
          onFocus={(e) => e.stopPropagation()}>
          <span style={{ padding: '0px 8px' }}>Delete Video</span>
          <DeleteIcon />
        </ActionButton>
      </span>
    </Row>
  ));

  const getLabel = () => {
    if (!!errors) {
      return (
        <span style={{ color: '#f00' }}>{label}</span>
      );
    }
    return (
      <span>{label}</span>
    );
  };

  return (
    <Accordion
      expanded={panelState}
      onChange={(e, expanded) => setPanelState(expanded)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls={name}
        id={name}>
        <Row>
          <span>{getLabel()}</span>
          <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <span style={{ width: '8px' }}>&nbsp;</span>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={(e) => onAddVideo(e)}
              onFocus={(e) => e.stopPropagation()}
              disabled={videoSlider.length >= 15}>
              <span style={{ padding: '0px 8px' }}>Add Video</span>
              <AddIcon />
            </ActionButton>
          </span>
        </Row>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}>
          {displaySliders()}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default TextSliderManager;
