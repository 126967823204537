import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const useStyles = (noMargin:boolean | undefined) => makeStyles((theme) => ({
  subtitle: {
    fontSize: '.75rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
    marginTop: noMargin ? 0 : theme.spacing(3),
    marginBottom: theme.spacing(0),
    display: 'flex',
    alignItems: 'center',
  },
}));

interface Props {
  children: any,
  noMargin?: boolean,
  style?: CSSProperties,
}

const Subtitle = (props: Props) => {
  const { children, noMargin, style } = props;
  const classes = useStyles(noMargin)();
  return (
    <div className={classes.subtitle} style={style}>
      {children}
    </div>
  );
};

export default Subtitle;
