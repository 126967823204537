import { ReactNode } from 'react';

interface Props {
  children: ReactNode,
}

const SliderHeader = (props: Props) => {
  const { children } = props;
  return (
    <div style={{
      backgroundColor: 'rgba(174, 46, 111, 0.1)',
      padding: '8px',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
    }}>
      {children}
    </div>
  );
};

export default SliderHeader;
