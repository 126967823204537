const events = {
  id: 'Id',
  name: 'Nom',
  type: 'Type',
  goal: 'But',
  raised: 'Récolter',
  active: 'Actif',
  start_date: 'Début',
  end_date: 'Fin',
  created_at: 'Créer',
  updated_at: 'Mis-à-Jour',
  deleted_at: 'Effacé',
};

export default events;
