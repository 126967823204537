import { Reducer } from 'redux';
import { CLOSE_MENU, OPEN_MENU } from 'src/redux/actions/menuActions';

export type MenuState = boolean;

const DEFAULT: MenuState = false;

const menuReducer: Reducer<MenuState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case OPEN_MENU:
      return true;
    case CLOSE_MENU:
      return false;
    default:
      return state;
  }
};

export default menuReducer;
