const fundraising = {
  en: 'English',
  fr: 'French',
  es: 'Spanish',
  he: 'Hebrew',
  y: 'Yiddish',
  active: 'Active',
  name: 'Name',
  slug: 'Fundraising slug',
  goal: 'Goal',
  start_date: 'Start Date',
  end_date: 'End Date',
  default_language: 'Default Language',
  show_raised_amount: 'Show raised amount',
  show_timer: 'Show raised amount',
  next_step: 'Next Step',
  //
  add_contact: 'Contact',
  add_contact_btn: 'Add Contact',
  send_thanks_email: 'Send en email notification',
  thanks_email_body: 'Body of the email notification',
  send_thanks_sms: 'Send a SMS notification',
  thanks_sms_text: 'Text of the SMS notification',
  facebook_information: 'Facebook informations',
  fb_description: 'Description of the Facebook event',
  fb_keyword: 'Comma separated keywords',
  media_title: 'Title of the image',
  media_text: 'Text for the image',
  notifications: 'Notifications',
  contacts_to_notify: 'Contacts to notify',
  groups_to_notify: 'Groups to notify',
  //
  product_validation_errors: 'Product validation errors',
  display_single_product: 'Display products as single choice list',
  products_list: 'Products List',
  payment: 'Payment',
  cover_fee_percentage: 'Cover fees(%)',
  allow_installment: 'Allow installments',
  max_installment: 'Max. installments',
  //
  order: 'Order',
  event_information: 'Event Information',
  event_date: 'Event Date',
  title: 'Title',
  venue_name: 'Venue Name',
  venue_address: 'Venue Address',
  contact_email: 'Contact Email',
  footer_message: 'Footer Message',
  logo: 'Logo',
  //
  filter_team: 'Filter Teams',
  //
  button_label: 'Button Label',
  campaign_message: 'Campaign Message',
  main_landscape_slider: 'Main Landscape Slider',
  secondary_landscape_slider: 'Secondary Landscape Slider',
  secondary_portrait_slider: 'Secondary portrait Slider',
  slider_matcher: 'Slider Matcher',
  slider_sponsor: 'Sponsor Matcher',
  video_main: 'Video Main',
  video_secondary: 'Video Secondary',
  //
  match_multiplier: 'Multiplier',
  match_reached_multiplier: 'Match reached multiplier',
  match_goal: 'Match goal',
  bonus_round: 'Bonus round',
  bonus_round_goal: 'Bonus round goal',
  slider_main_all_language: 'Slider main all language',
  slider_secondary_all_language: 'Slider secondary all language',
  slider_matcher_all_language: 'Slider matcher all language',
  slider_sponsor_all_language: 'Slider sponsor all language',
  video_main_all_language: 'Video main all language',
  video_secondary_all_language: 'Video secondary all language',
  campaign_message_all_language: 'Campaign message all language',
  allow_comments: 'Allow comments',
  show_team_donor_amount: 'Show team donor amount',
  show_donors: 'Show donors',
  show_teams: 'Show teams',
  //
  texts: 'Texts',
  poster_portrait: 'Poster Portrait',
  poster_portrait_slider: 'Poster Portrait Slider',
  poster_landscape: 'Poster Landscape',
  poster_landscape_slider: 'Poster Landscape Slider',
  video_slider: 'Video Sliders',
};

export default fundraising;
