import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddFundraisingPage from 'src/components/Pages/AddFundraisingPage';

const EditEventModal = () => (
  <ModalBase
    name={MODALS.editEvent}
    size={MODALS_SIZE.medium}>
    <AddFundraisingPage />
  </ModalBase>
);

export default EditEventModal;
//
