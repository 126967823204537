import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { RowIdentifiers } from 'src/components/Control/TicketIdentifier';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import Row from 'src/components/UI/Row';

interface Props {
  index: number;
  identifier: RowIdentifiers;
  onDelete?: (index: number) => void;
  onUpdate: (index: number, field: string, value: string) => void;
}

const TicketIdentifierItem = (props: Props) => {
  const {
    index, identifier, onDelete, onUpdate,
  } = props;

  const displayIcon = () => {
    if (onDelete) {
      return (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip
            title="Delete row"
            placement="top-start">
            <span>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={() => onDelete(index)}
                name="product-delete">
                <DeleteIcon />
              </ActionButton>
            </span>
          </Tooltip>
        </div>
      );
    }
    return (<div style={{ width: '56px' }} />);
  };

  return (
    <Row>
      <div style={{ display: 'flex', gap: '8px' }}>
        <FormTextField
          size="small"
          margin="dense"
          label="Row name"
          form={FORM.identifiers}
          name={`name_${index}`}
          onChange={(value) => onUpdate(index, 'name', value)}
          value={identifier.name} />
      </div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <FormTextField
          size="small"
          margin="dense"
          label="Seat start"
          form={FORM.identifiers}
          name={`start_${index}`}
          onChange={(value) => onUpdate(index, 'start', value)}
          value={identifier.start} />
      </div>
      <div style={{ display: 'flex', gap: '8px' }}>
        <FormTextField
          size="small"
          margin="dense"
          label="Seat End"
          form={FORM.identifiers}
          name={`start_${index}`}
          onChange={(value) => onUpdate(index, 'end', value)}
          value={identifier.end} />
      </div>
      {displayIcon()}
    </Row>
  );
};

export default TicketIdentifierItem;
