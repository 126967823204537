const bankaccounts = {
  bank: 'Bank #',
  branch: 'Branch #',
  routing: 'Routing #',
  account: 'Account #',
  type: 'Type',
  name: 'Account Holder Name',
  bank_name: 'Bank Name',
  validation_errors: 'Validation error(s). Please review your data.',
};

export default bankaccounts;
