import * as Yup from 'yup';
import { t } from 'src/lib/language';
import { isNull } from 'lodash';

const ProductValidator = (lang:string) => Yup.object().shape({
  name_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  name_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  note: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  bank_account_id: Yup.number()
    .integer()
    .min(0)
    .required(),
  price: Yup.number()
    .min(0)
    .required(),
  discount: Yup.number()
    .min(0)
    .required(),
  department_id: Yup.number()
    .required(),
  program_id: Yup.number()
    .required(),
  account_id: Yup.number()
    .required(),
  type: Yup.string()
    .required(),
  cost: Yup.number()
    .nullable(),
  deductible: Yup.number()
    .min(0)
    .max(100)
    .transform(
      (value, original) => (original == null || original === '' ? 0 : value),
    )
    .required(),
  taxes: Yup.mixed()
    .transform(
      (value, original) => {
        if (Array.isArray(original)) {
          return value;
        }

        return (!isNull(value))
          ? value.split(',')
          : [];
      },
    ),
  quantity: Yup.number()
    .nullable(),
  back_order: Yup.number()
    .nullable(),
  sku: Yup.string()
    .max(16, t(lang, 'validations.contact.field_maximum', { max: 16 }))
    .nullable(),
  number_allowed_installments: Yup.number()
    .test('is-required', 'required', function () {
      return (this.parent.allow_installment)
        ? this.parent.number_allowed_installments > 0
        : true;
    }),
});

export default ProductValidator;
