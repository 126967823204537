import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormMultipleSelect from 'src/components/Control/FormControls/FormMultipleSelect';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { INVOICE_STATUS, INVOICE_STATUS_LIST } from 'src/constants/Invoices';

interface Props {
  values: INVOICE_STATUS[],
  onChange: (field: string, paymentStatus:any) => void
}
const InvoiceStatusSelector = (props: Props) => {
  const { values, onChange } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const getInvoiceStatus = () => INVOICE_STATUS_LIST.map(
    (status: INVOICE_STATUS) => ({
      label: status.toUpperCase(),
      id: status,
    }),
  );

  return (
    <FormMultipleSelect
      form={FORM.admin_donation}
      name="invoiceStatus"
      label={t(lang, 'forms.invoices.invoice_status')}
      onChange={(status: string) => onChange('invoiceStatus', status)}
      value={values}
      items={getInvoiceStatus()}
      noMarginTop />
  );
};

export default InvoiceStatusSelector;
