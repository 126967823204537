import EditPaymentForm from 'src/components/Forms/EditPaymentForm';
import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';

const EditPaymentModal = () => (
  <ModalBase
    name={MODALS.editPayment}
    size={MODALS_SIZE.small}>
    <EditPaymentForm />
  </ModalBase>
);

export default EditPaymentModal;
