import { Reducer } from 'redux';
import { AuthModel } from 'src/models/AuthModel';
import { SET_AUTH_DATA } from 'src/redux/actions/userActions';
import { PersistState } from 'redux-persist/es/types';

interface PersistPartial {
  _persist?: PersistState;
}

export type AuthState = AuthModel & PersistPartial;

const DEFAULT: AuthState = {} as AuthState;

const authReducer: Reducer<AuthState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
