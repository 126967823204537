import * as Yup from 'yup';
import { t } from 'src/lib/language';

const CreateGatewayValidator = (lang:string) => Yup.object().shape({
  gateway_name: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
});

export const CreateElavonSettingValidator = (lang:string) => Yup.object().shape({
  gateway_name: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  account_id: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  user_id: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  transaction_api_key: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
});

export const CreateBamboraSettingValidator = (lang:string) => Yup.object().shape({
  gateway_name: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  merchant_id: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  batch_api_key: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  reporting_api_key: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
});

export default CreateGatewayValidator;
