import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = (noMargin:boolean | undefined, fontSize: string) => makeStyles((theme) => ({
  subheader: {
    fontSize,
    fontFamily: 'Qanelas-Bold',
    marginTop: noMargin ? theme.spacing(1) : theme.spacing(4),
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
}));

interface Props {
  children: any;
  id?: string,
  noMargin?: boolean,
  size?:SUBHEADER_SIZE,
  color?:SUBHEADER_COLOR,
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SUBHEADER_SIZE {
  small = 'small',
  large = 'large',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SUBHEADER_COLOR {
  primary = 'primary',
  secondary = 'secondary',
  textPrimary = 'textPrimary',
}

const Subheader = (props: Props) => {
  const {
    color, children, id, noMargin, size,
  } = props;

  let fontSize = '';
  switch (size) {
    case SUBHEADER_SIZE.small: {
      fontSize = '1rem';
      break;
    }
    case SUBHEADER_SIZE.large: {
      fontSize = '1.4rem';
      break;
    }
    default: {
      fontSize = '1.2rem';
    }
  }
  const classes = useStyles(noMargin, fontSize)();

  return (
    <Typography
      id={id}
      component="div"
      color={color || 'secondary'}
      className={classes.subheader}>
      {children}
    </Typography>
  );
};

export default Subheader;
