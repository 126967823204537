import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { useState } from 'react';
import { ChangePasswordData } from 'src/apis/UserAPI';
import { changeUserPassword } from 'src/redux/actions/userActions';

const useStyles = makeStyles(() => ({
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  title: {
    fontWeight: 'bold',
    width: '172px',
  },
}));

const ChangePasswordDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [payload, setPayload] = useState({
    new_password: '',
    new_password_confirmation: '',
    password: '',
  } as ChangePasswordData);

  const opened =
    useSelector((state: Store) => {
      if (state.dialog[DIALOGS.changePassword]) {
        return state.dialog[DIALOGS.changePassword].opened;
      }
      return false;
    });

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.changePassword }));
  };

  const handleOk = () => {
    dispatch(closeDialog({ dialog: DIALOGS.changePassword }));
    dispatch(changeUserPassword(payload));
  };

  const handleOnChange = (value: Value, field: string) => {
    setPayload({
      ...payload,
      [field]: value,
    });
  };

  return (
    <Dialog
      aria-labelledby="change-password"
      open={opened}
      maxWidth="sm"
      PaperProps={{
        style: {
          width: '100%',
        },
      }}>
      <DialogTitle id="change-password">Change your Password</DialogTitle>
      <DialogContent dividers>
        <div className={classes.row}>
          <span className={classes.title}>New Password:</span>
          <FormTextField
            form={FORM.changeEmail}
            label="New Password"
            name="new_password"
            onChange={handleOnChange}
            required
            value={payload.new_password}
            margin="dense" />
        </div>
        <div className={classes.row}>
          <span className={classes.title}>Confirm Password:</span>
          <FormTextField
            form={FORM.changeEmail}
            label="Confirm New Password"
            name="new_password_confirmation"
            onChange={handleOnChange}
            required
            value={payload.new_password_confirmation}
            margin="dense" />
        </div>
        <div className={classes.row}>
          <span className={classes.title}>Password:</span>
          <FormTextField
            form={FORM.changeEmail}
            label="Current Password"
            name="password"
            onChange={handleOnChange}
            required
            value={payload.password}
            margin="dense" />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Change Password
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
