import { CSSProperties, ReactNode } from 'react';

interface Props {
  children: ReactNode,
  key?: string,
  style?: CSSProperties,
}

const Item = (props: Props) => {
  const {
    children, key, style,
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '48px',
        gap: '10px',
        padding: '0px 8px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
        ...style,
      }}
      key={key}>
      {children}
    </div>
  );
};

export default Item;
