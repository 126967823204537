import { Reducer } from 'redux';
import { CurrentEntitytModel } from 'src/models/CurrentEntitytModel';
import { RESET_CURRENT_ENTITY, SET_CURRENT_ENTITY } from 'src/redux/actions/currentEntityActions';

export type CurrentEntityState = CurrentEntitytModel;

const DEFAULT: CurrentEntityState = {} as CurrentEntityState;

const CurrentEntityReducer:Reducer<CurrentEntityState> = (state = DEFAULT, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_ENTITY: {
      const { entity, id } = payload;
      return {
        ...state,
        [entity]: id,
      };
    }
    case RESET_CURRENT_ENTITY: {
      return {} as CurrentEntityState;
    }
    default:
      return state;
  }
};

export default CurrentEntityReducer;
