import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { useEffect, useState } from 'react';
import { CustomFieldsModel } from 'src/models/CustomFieldsModel';
import { FORM_ID } from 'src/models/FormModel';

interface Props {
  contactId?: number,
  form: FORM_ID,
  onChange: (value: Value, field: string) => void,
}

const CustomfieldsDisplayer = (props: Props) => {
  const { contactId, form, onChange } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const customfieldsdata = useSelector((state: Store) => state.customfields);
  const customfields = useSelector((state: Store) => {
    if (!!contactId) {
      if (!state.contacts[contactId]) {
        return {} as CustomFieldsModel;
      }
      return state.contacts[contactId].customfields;
    }
    return {} as CustomFieldsModel;
  });

  const [customfieldState, setCustomfieldState] = useState({} as CustomFieldsModel);

  useEffect(() => {
    if (!!customfields && contactId) {
      setCustomfieldState(customfields as CustomFieldsModel);
    }
  }, [customfields]);

  const onCustomfieldChange = (value: Value, field: string) => {
    setCustomfieldState({
      ...customfieldState,
      [field]: value,
    } as CustomFieldsModel);
    onChange(value, field);
  };

  const displayCustomFields = () => {
    const fields = [] as any[];
    // eslint-disable-next-line no-restricted-syntax
    for (const [name, customfield] of Object.entries(customfieldsdata)) {
      const value:any = customfieldState[name];
      switch (customfield.type_id) {
        case 'date':
          fields.push(
            <div key={`div-${name}`}>
              <FormDatePicker
                form={form}
                label={customfield[`display_${lang}`]}
                name={name}
                onChange={(date: MaterialUiPickersDate) => onCustomfieldChange(date, name)}
                value={value || null}
                key={name} /><br />
            </div>,
          );
          break;
        case 'boolean':
          fields.push(
            <div style={{ marginTop: '32px' }} key={`div-${name}`}>
              <FormCheckbox
                name={name}
                label={customfield[`display_${lang}`]}
                checked={!!value || false}
                onChange={onCustomfieldChange}
                key={name} /><br />
            </div>,
          );
          break;
        case 'single-list':
        case 'multiple-list':
          break;
        default:
          fields.push(
            <FormTextField
              form={form}
              label={customfield[`display_${lang}`]}
              name={name}
              onChange={onCustomfieldChange}
              required
              value={value as string || ''}
              key={name} />,
          );
          break;
      }
    }
    return fields;
  };

  return (
    <>{ displayCustomFields() }</>);
};

export default CustomfieldsDisplayer;
//
