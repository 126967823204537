import { MouseEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { Menu, MenuItem, Switch } from '@material-ui/core';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ProfileIcon from 'src/components/UI/ProfileIcon';
import routes, { Route } from 'src/constants/routes';
import { resetUserData, setAuthData } from 'src/redux/actions/userActions';
import { setUserContactData } from 'src/redux/actions/contactActions';
import { UIType } from 'src/constants/UIType';
import { setInterface } from 'src/redux/actions/interfaceActions';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';

const ProfileButton = () => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const user = useSelector((state: Store) => state.user);
  const ui = useSelector((state: Store) => state.interface.ui);

  const dispatch = useAppDispatch();

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const logout = () => {
    dispatch(resetUserData({}));
    dispatch(setUserContactData({}));
    dispatch(setAuthData({}));
    closeMenu();
  };

  const toggleUI = () => {
    dispatch(setInterface({
      ui: ui !== UIType.admin ? UIType.admin : UIType.user,
    }));
    closeMenu();
  };

  return (
    <div>
      <ProfileIcon
        onClick={openMenu}
        title="Profile" />
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        { user.is_admin && (
          <MenuItem
            component={RouterLink}
            to={routes[Route.PROFILE].path}>
            User UI:
            <Switch
              checked={ui !== UIType.admin}
              onChange={() => toggleUI()}
              color="primary"
              name="ui-switch" />
          </MenuItem>
        )}
        <MenuItem
          onClick={() => dispatch(openDialog({ dialog: DIALOGS.changePassword }))}>
          Change password
        </MenuItem>
        <MenuItem
          onClick={() => dispatch(openDialog({ dialog: DIALOGS.changeEmail }))}>
          Change email
        </MenuItem>
        <MenuItem
          key="profile-menu-item-logout"
          onClick={() => logout()}>
          <ExitToAppOutlinedIcon /> Logout
        </MenuItem>

      </Menu>
    </div>
  );
};

export default ProfileButton;
