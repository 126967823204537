export interface LanguageModel {
  language: string
  locale: LOCALES
}

export enum LOCALES {
  en_CA = 'en_CA',
  fr_CA = 'fr_CA',
}

export const LANGUAGES = {
  [LOCALES.en_CA]: 'en',
  [LOCALES.fr_CA]: 'fr',
};
