import { EventType } from 'src/models/EventType';

const subscribeEvent = (eventName: EventType, listener: any) => {
  document.addEventListener(eventName, listener);
};

const unsubscribeEvent = (eventName: EventType, listener: any) => {
  document.removeEventListener(eventName, listener);
};

const dispatchCustomEvent = (eventName: EventType, data:any) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export {
  dispatchCustomEvent, subscribeEvent, unsubscribeEvent,
};
