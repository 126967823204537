import * as Yup from 'yup';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';

const FundraisingInfoValidator = () => Yup.object().shape({
  type: Yup.string()
    .oneOf(Object.values(FundraisingTypes))
    .required(),
  name: Yup.string()
    .required(),
  slug: Yup.string()
    .required(),
  goal: Yup.number(),
  active: Yup.boolean()
    .required(),
  start_date: Yup.date()
    .required(),
  end_date: Yup.date()
    .required(),
  template_id: Yup.number()
    .required(),
  languages: Yup.array()
    .test('is-languages', 'not a languages', function () {
      if (!this.parent.languages) return false;
      return this.parent.languages.some(
        (value:FundraisingLanguages) => Object.values(FundraisingLanguages).includes(value),
      );
    })
    .required(),
  default_language: Yup.string()
    .required()
    .test('is-languages', 'not a languages', function () {
      return Object.values(FundraisingLanguages).includes(this.parent.default_language);
    }),
  show_raised_amount: Yup.boolean()
    .required(),
  show_timer: Yup.boolean()
    .required(),
});

export default FundraisingInfoValidator;
