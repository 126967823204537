import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { t } from 'src/lib/language';
import { keyBy } from 'lodash';

import { setError } from 'src/redux/actions/errorsActions';
import { setAlert } from 'src/redux/actions/alertActions';
import { getContactAction, putContactAction } from 'src/redux/actions/contactActions';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { openDialog } from 'src/redux/actions/dialogActions';
import { getCurrenciesAction, getRelationsAction } from 'src/redux/actions/dataActions';

import { ALERT_TYPE } from 'src/constants/AlertType';
import { FORM, FORM_STATUS } from 'src/constants/Form';

import { ENTITIES } from 'src/models/QuerybuilderModel';
import { ErrorBag } from 'src/models/ErrorModel';
import { LanguageModel, LANGUAGES } from 'src/models/LanguageModel';
import { MODALS } from 'src/models/ModalModel';
import ContactModel, {
  APIContactPayload,
  ContactPayload,
  ContactRelation,
} from 'src/models/ContactModel';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import { DIALOGS } from 'src/models/DialogModel';

import Subtitle from 'src/components/UI/Subtitle';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import LanguageSelector from 'src/components/Control/LanguageSelector';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import SimpleContactValidator from 'src/validations/SimpleContactValidator';
import SimpleContactShippingValidator from 'src/validations/SimpleContactShippingValidator';
import { CustomfieldValidationSchema } from 'src/validations/CustomfieldValidator';
import CustomfieldsDisplayer from 'src/components/Elements/CustomfieldsDisplayer';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import EmailStatusSelector from 'src/components/Control/EmailStatusSelector';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import AddressSearch from 'src/components/Control/AddressSearch';
import TextLink from 'src/components/UI/TextLink';
import ContactGroupSelector from 'src/components/Control/ContactGroupSelector';
import ContactCreditCardDisplayer from 'src/components/Elements/ContactCreditCardDisplayer';
import ContactBankAccountDisplayer from 'src/components/Elements/ContactBankAccountDisplayer';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import ManageCustomfieldButton
  from 'src/components/DatagridToolbar/buttons/ManageCustomfieldButton';
import ManageRelationsButton from 'src/components/DatagridToolbar/buttons/ManageRelationsButton';
import ManageGroupsButton from 'src/components/DatagridToolbar/buttons/ManageGroupsButton';
import ShowInvoicesForContactButton
  from 'src/components/DatagridToolbar/buttons/ShowInvoicesForContactButton';
import SubmenuLink from 'src/components/UI/SubmenuLink';
import { TABS } from 'src/components/Forms/AddContactForm';
import AddContactRelationForm from 'src/components/Forms/AddContactRelationForm';
import EditContactRelationForm from 'src/components/Forms/EditContactRelationForm';
import PaginationUI from 'src/components/UI/PaginationUI';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Button from '@material-ui/core/Button';
import Scroller from 'src/components/UI/Scroller';
import Spacer from 'src/components/UI/Spacer';
import BoldTextAccent from 'src/components/UI/BoldTextAccent';
import Form from 'src/components/UI/Form';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const EditContactForm = () => {
  const dispatch = useAppDispatch();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [page, setPage] = useState(1);

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const customfieldsData = useSelector((state: Store) => state.customfields);
  const getStatus = useSelector((state: Store) => state.formStatus[FORM.get_contact]);
  const putStatus = useSelector((state: Store) => state.formStatus[FORM.put_contact]);
  const relations = useSelector((state: Store) => keyBy(state.data.relations ?? [], 'id'));

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.contacts] || [],
  );

  const { contact, customfields, groups } = useSelector(
    (state: Store) => {
      if (!!state.contacts[selections[currentIndex] as number]) {
        return state.contacts[selections[currentIndex] as number] as ContactPayload;
      }
      return {} as ContactPayload;
    },
  );

  const [currentTab, setCurrentTab] = useState(TABS.info);
  const [contactHasError, setContactHasError] = useState(false);
  const [customfieldsHasError, setCustomfieldsHasError] = useState(false);

  const [contactState, setContactState] = useState({
    ...contact,
    sameAsBilling: false,
  });
  const [customfieldState, setCustomfieldState] = useState({ ...customfields });
  const [groupsState, setGroupsState] = useState([] as number[]);
  const [currentRelationIndex, setcurrentRelationIndex] = useState(0);

  const [currentState, setCurrentState] = useState(STATE.list);

  useEffect(() => {
    dispatch(getRelationsAction());
    dispatch(getCurrenciesAction());
  }, []);

  useEffect(() => {
    setContactState({
      ...contact,
      locale_id: 'en_CA',
      sameAsBilling: true,
    });
    setGroupsState(groups as number[]);
    setCustomfieldState({
      ...customfields,
    });
  }, [contact]);

  useEffect(() => {
    dispatch(setError({ [FORM.edit_contact]: {} }));
    if (selections[currentIndex]) {
      dispatch(getContactAction(selections[currentIndex] as number, FORM.edit_contact));
      dispatch(setCurrentEntity({ entity: ENTITIES.contacts, id: selections[currentIndex] }));
    }
  }, [currentIndex]);

  const onGroupChange = (group:number[]) => {
    setGroupsState(group);
  };

  const onContactFieldChange = (value: Value, field: string) => {
    switch (field) {
      case 'phone_home':
      case 'phone_office':
      case 'phone_mobile':
        if (value) {
          if (!value.toString().match(/^\d+$/)) {
            return;
          }
          if (value.toString().length >= 20) {
            return;
          }
        }
        break;
      default:
    }
    setContactState({
      ...contactState,
      [field]: value,
    });
  };

  const onCustomfieldChange = (value: Value, field: string) => {
    setCustomfieldState({
      ...customfieldState,
      [field]: value,
    });
  };

  const onLanguageChange = (language: LanguageModel) => {
    setContactState({
      ...contactState,
      locale_id: language.locale,
    });
  };

  const setShippingFromBilling = () => ({
    ...contactState,
    shipping_address: contactState.billing_address,
    shipping_suite: contactState.billing_suite,
    shipping_city: contactState.billing_city,
    shipping_state: contactState.billing_state,
    shipping_country_id: contactState.billing_country_id,
    shipping_zip_code: contactState.billing_zip_code,
  });

  const handlePageChange = (newPage:number) => {
    const index = newPage - 1;
    dispatch(setCurrentEntity({ entity: ENTITIES.contacts, id: selections[index] }));
    setCurrentIndex(index);
    setPage(newPage);
  };

  const handleAdd = () => {
    setCurrentState(STATE.add);
  };

  const handleEditRelation = (relationIndex: number) => {
    setcurrentRelationIndex(relationIndex);
    setCurrentState(STATE.edit);
  };

  const handleDeleteRelation = (e:any, relationIndex: number) => {
    e.preventDefault();
    const currentRelations = (contact.contact_relations ?? [])
      .filter((relation: ContactRelation, index: number) => (index !== relationIndex)
        && (relation.related_contact_id !== contact.id))
      .map((relation: ContactRelation) => ({
        related_contact_id: relation.related_contact_id,
        type_id: relation.type_id,
      } as ContactRelation));
    dispatch(openDialog({
      dialog: DIALOGS.deleteContactrelation,
      payload: {
        contact,
        contact_relations: [
          ...currentRelations,
        ],
      },
    }));
  };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editContacts }));
  };

  const validateContact = () => {
    const errorBag = {} as ErrorBag;
    try {
      let schema;
      if (!contactState.sameAsBilling) {
        schema = SimpleContactValidator(lang).concat(SimpleContactShippingValidator(lang));
      } else {
        schema = SimpleContactValidator(lang);
      }
      schema.validateSync(contactState, { abortEarly: false });
    } catch (validationErrors: any) {
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      return { contactValid: false, contactErrors: errorBag };
    }
    return { contactValid: true, contactErrors: errorBag };
  };

  const validateCustomfields = () => {
    const errorBag = {} as ErrorBag;
    try {
      const schema = CustomfieldValidationSchema(customfieldsData);
      const data = {
        ...contactState,
        ...customfieldState,
      };
      schema.validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      return { customfieldValid: false, customfieldsError: errorBag };
    }
    return { customfieldValid: true, customfieldsError: errorBag };
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>, close: boolean) => {
    event.preventDefault();
    if (!contactState.phone_home) {
      contactState.phone_home = null;
    }
    if (!contactState.phone_mobile) {
      contactState.phone_mobile = null;
    }
    if (!contactState.phone_office) {
      contactState.phone_office = null;
    }
    dispatch(setError({ [FORM.edit_contact]: {} }));
    const { contactValid, contactErrors } = validateContact();
    const { customfieldValid, customfieldsError } = validateCustomfields();
    if (!contactValid || !customfieldValid) {
      let errorBag = {} as ErrorBag;
      if (!contactValid) {
        setContactHasError(true);
        errorBag = {
          ...contactErrors,
        };
      }
      if (!customfieldValid) {
        setCustomfieldsHasError(true);
        errorBag = {
          ...errorBag,
          ...customfieldsError,
        };
      }
      dispatch(setError({ [FORM.edit_contact]: errorBag }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.contact.validation_errors',
      }));
      return;
    }
    let contactData = contactState;
    if (contactState.sameAsBilling) {
      contactData = setShippingFromBilling();
    }

    const payload = {
      contact: {
        ...contactData,
        issue_tax_receipt: contactState.issue_tax_receipt ? 1 : 0,
      },
      custom_fields: customfieldState,
      groups: groupsState,
    } as APIContactPayload;

    if (close) {
      handleClose();
    }
    dispatch(putContactAction(selections[currentIndex] as number, payload));
  };

  const processing = () => putStatus === FORM_STATUS.processing
    || getStatus === FORM_STATUS.processing;

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addCreditCard,
      payload: {
        contact_id: selections[currentIndex],
      },
    }));
  };

  const openAddBankAccountModal = () => {
    dispatch(openModal({
      modal: MODALS.addBankAccount,
      payload: {
        contact_id: selections[currentIndex],
      },
    }));
  };

  const onDelete = () => {
    dispatch(openDialog({
      dialog: DIALOGS.deleteContact,
      payload: { ids: [selections[currentIndex]] },
    }));
  };

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setContactState({
      ...contactState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: (!!address.zip_code) ? address.zip_code.replaceAll(' ', '') : '',
    });
  };

  const getTitle = () => {
    if (processing() || !contactState.email) return 'Processing ...';
    if (contactState.company_name) {
      return `${contactState.company_name} (${contactState.email})`;
    }
    return `${contactState.first_name} ${contactState.last_name} (${contactState.email})`;
  };

  const getName = (obj: ContactModel | ContactRelation) => {
    if (obj.company_name) {
      return obj.company_name;
    }
    return `${obj.last_name} ${obj.first_name}`;
  };

  const getRelationName = (relationTypeId: string | number) => {
    if (!relations[relationTypeId]) return '';
    return relations[relationTypeId][`name_${lang}`];
  };

  const displayContactRelation = () => {
    if (contact) {
      return (contact.contact_relations ?? [])
        .map(
          (contactRelation: ContactRelation, index: number) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '4vh',
                gap: '10px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
              }}
              key={`relation-${index}`}>
              <span>
                {getName(contact)}&nbsp;<i>{t(lang, 'forms.contact.is')}</i>
                &nbsp;<b>{getRelationName(contactRelation.type_id)}</b>
                &nbsp;<i>{t(lang, 'forms.contact.of')}</i>&nbsp;{getName(contactRelation)}
              </span>
              <Tooltip
                title={t(lang, 'menus.edit_product')}
                placement="top-start">
                <>
                  <span style={{ marginLeft: 'auto' }}>
                    <ActionButton
                      variant="outlined"
                      category={MENU_BUTTON_CATEGORY.action}
                      onClick={() => handleEditRelation(index)}>
                      <EditIcon />
                    </ActionButton>
                  </span>
                </>
              </Tooltip>
              <Tooltip
                title={t(lang, 'menus.delete_contact_relation')}
                placement="top-start">
                <span style={{ marginLeft: '8px' }}>
                  <ActionButton
                    variant="outlined"
                    category={MENU_BUTTON_CATEGORY.action}
                    onClick={(e) => handleDeleteRelation(e, index)}>
                    <DeleteIcon />
                  </ActionButton>
                </span>
              </Tooltip>
            </div>
          ),
        );
    }
    return '';
  };

  const displayEditRelationForm = () => (
    <EditContactRelationForm
      contact={contact}
      contactRelationIndex={currentRelationIndex}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const displayAddRelationForm = () => (
    <AddContactRelationForm
      contact={contact}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}>
      {t(lang, 'misc.close')}
    </Button>
  );

  const DeleteButton = () => (
    <Tooltip
      title={t(lang, 'menus.delete_contact')}
      placement="top-start">
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.action}
          disabled={processing()}
          onClick={onDelete}>
          <DeleteIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  const SaveButton = () => (
    <Tooltip
      title={t(lang, 'menus.save_contact')}
      placement="top-start">
      <span>
        <ActionButton
          disabled={processing()}
          category={MENU_BUTTON_CATEGORY.action}
          onClick={(e) => handleSubmit(e, false)}>
          <SaveIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );

  return (
    <>
      <Header height="108px">
        <Row>
          <Title><b>{ getTitle() }</b></Title>
          <CloseButton />
        </Row>
        <Row>
          <DeleteButton />
          <SaveButton />
          <ManageCustomfieldButton
            disabled={processing()} />
          <ManageRelationsButton
            disabled={processing()} />
          <ManageGroupsButton
            disabled={processing()} />
          <ShowInvoicesForContactButton
            contactId={selections[currentIndex]}
            disabled={processing()} />
        </Row>
        <Row>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.info)}
            selected={currentTab === TABS.info}
            hasError={contactHasError}>
            {t(lang, 'forms.contact.contact_information')}
          </SubmenuLink>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.meta)}
            selected={currentTab === TABS.meta}
            hasError={customfieldsHasError}>
            {t(lang, 'forms.contact.meta_information')}
          </SubmenuLink>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.payment)}
            selected={currentTab === TABS.payment}>
            {t(lang, 'forms.contact.payment_information')}
          </SubmenuLink>
          <SubmenuLink
            onClick={() => setCurrentTab(TABS.relations)}
            selected={currentTab === TABS.relations}>
            {t(lang, 'forms.contact.relationships')}
          </SubmenuLink>
        </Row>
      </Header>
      <PaginationUI count={selections.length} page={page} onChange={handlePageChange} />
      <Scroller>
        <Form>
          { currentTab === TABS.info && (
            <>
              <FormCheckbox
                label={t(lang, 'forms.contact.issue_tax_receipt')}
                name="issue_tax_receipt"
                onChange={onContactFieldChange}
                checked={Boolean(contactState.issue_tax_receipt)} />
              <Subtitle>Language</Subtitle>
              <div>
                Language:
                <LanguageSelector
                  onChange={onLanguageChange}
                  current={LANGUAGES[contactState.locale_id || 'en_CA']} />
              </div>
              <Subtitle>{t(lang, 'forms.contact.header_name')}</Subtitle>
              <div>
                <SalutationSelector
                  form={FORM.edit_contact}
                  name="salutation_id"
                  value={contactState.salutation_id || 0}
                  onChange={(id) => onContactFieldChange(id, 'salutation_id')}
                  disabled={processing()} />
                <FormTextField
                  disabled={processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.first_name')}
                  name="first_name"
                  onChange={onContactFieldChange}
                  required
                  value={contactState.first_name} />
                <FormTextField
                  disabled={processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.last_name')}
                  name="last_name"
                  onChange={onContactFieldChange}
                  required
                  value={contactState.last_name} />
              </div>
              <div>
                <FormTextField
                  disabled={processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.company_name')}
                  name="company_name"
                  onChange={onContactFieldChange}
                  value={contactState.company_name} />
              </div>
              <Subtitle>{t(lang, 'forms.contact.header_emails')}</Subtitle>
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.primary_email')}
                name="email"
                onChange={onContactFieldChange}
                value={contactState.email || ''} />
              <FormCheckbox
                disabled={processing()}
                name="email_allow_notification"
                label={t(lang, 'forms.contact.email_notification')}
                checked={!!contactState.email_allow_notification}
                onChange={onContactFieldChange} />
              <EmailStatusSelector
                form={FORM.edit_contact}
                name="email_status"
                value={contactState.email_status || ''}
                onChange={(id) => onContactFieldChange(id, 'email_status')} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.secondary_email')}
                name="secondary_email"
                onChange={onContactFieldChange}
                value={contactState.secondary_email || ''} />
              <FormCheckbox
                disabled={processing()}
                name="secondary_email_allow_notification"
                label={t(lang, 'forms.contact.email_notification')}
                checked={!!contactState.secondary_email_allow_notification}
                onChange={onContactFieldChange} />
              <Subtitle>{t(lang, 'forms.contact.header_billing_address')}</Subtitle>
              <AddressSearch
                disabled={processing()}
                onChange={onContactFieldChange}
                onAddressChange={
                    (addresse: AddressModel) => {
                      setAddressFromGoogle(addresse, AddressType.billing);
                    }
                }
                value={{ description: contactState.billing_address || '' }}
                name="billing_address"
                form={FORM.edit_contact} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.suite')}
                name="billing_suite"
                onChange={onContactFieldChange}
                value={contactState.billing_suite || ''} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.city')}
                name="billing_city"
                onChange={onContactFieldChange}
                value={contactState.billing_city || ''} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.state')}
                name="billing_state"
                onChange={onContactFieldChange}
                value={contactState.billing_state || ''} />
              <CountriesSelector
                form={FORM.edit_contact}
                onChange={(id) => onContactFieldChange(id, 'billing_country_id')}
                value={contactState.billing_country_id || undefined}
                name="billing_country_id"
                disabled={processing()} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.zip_code')}
                name="billing_zip_code"
                onChange={onContactFieldChange}
                value={contactState.billing_zip_code || ''} />
              <Subtitle>{t(lang, 'forms.contact.header_shipping_address')}</Subtitle>
              <Spacer space={1} />
              <FormCheckbox
                disabled={processing()}
                checked={contactState.sameAsBilling}
                label={t(lang, 'forms.contact.same_as_billing')}
                name="sameAsBilling"
                onChange={onContactFieldChange} />
              { !contactState.sameAsBilling && (
              <>
                <AddressSearch
                  disabled={processing()}
                  onChange={onContactFieldChange}
                  onAddressChange={
                    (addresse: AddressModel) => {
                      setAddressFromGoogle(addresse, AddressType.shipping);
                    }
                  }
                  value={{ description: contactState.shipping_address || '' }}
                  name="shipping_address"
                  form={FORM.edit_contact} />
                <FormTextField
                  disabled={contactState.sameAsBilling || processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.suite')}
                  name="shipping_suite"
                  onChange={onContactFieldChange}
                  value={contactState.shipping_suite || ''} />
                <FormTextField
                  disabled={contactState.sameAsBilling || processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.city')}
                  name="shipping_city"
                  onChange={onContactFieldChange}
                  value={contactState.shipping_city || ''} />
                <FormTextField
                  disabled={contactState.sameAsBilling || processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.state')}
                  name="shipping_state"
                  onChange={onContactFieldChange}
                  value={contactState.shipping_state || ''} />
                <CountriesSelector
                  form={FORM.edit_contact}
                  onChange={(id) => onContactFieldChange(id, 'shipping_country_id')}
                  value={contactState.shipping_country_id || undefined}
                  name="shipping_country_id"
                  disabled={processing()} />
                <FormTextField
                  disabled={contactState.sameAsBilling || processing()}
                  form={FORM.edit_contact}
                  label={t(lang, 'forms.contact.zip_code')}
                  name="shipping_zip_code"
                  onChange={onContactFieldChange}
                  value={contactState.shipping_zip_code || ''} />
              </>
              )}
              <Subtitle>{t(lang, 'forms.contact.header_phones')}</Subtitle>
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.phone_home')}
                name="phone_home"
                onChange={onContactFieldChange}
                value={contactState.phone_home || ''} />
              <FormCheckbox
                disabled={processing()}
                checked={!!contactState.phone_home_allow_notification}
                label={t(lang, 'forms.contact.phone_notification')}
                name="phone_home_allow_notification"
                onChange={onContactFieldChange} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.phone_mobile')}
                name="phone_mobile"
                onChange={onContactFieldChange}
                value={contactState.phone_mobile || ''} />
              <FormCheckbox
                disabled={processing()}
                checked={!!contactState.phone_mobile_allow_notification}
                label={t(lang, 'forms.contact.phone_notification')}
                name="phone_mobile_allow_notification"
                onChange={onContactFieldChange} />
              <FormTextField
                disabled={processing()}
                form={FORM.edit_contact}
                label={t(lang, 'forms.contact.phone_office')}
                name="phone_office"
                onChange={onContactFieldChange}
                value={contactState.phone_office || ''} />
              <FormCheckbox
                disabled={processing()}
                checked={!!contactState.phone_office_allow_notification}
                label={t(lang, 'forms.contact.phone_notification')}
                name="phone_office_allow_notification"
                onChange={onContactFieldChange} />
            </>
          )}
          { currentTab === TABS.meta && (
          <>
            <Subtitle>{t(lang, 'forms.contact.groups')}</Subtitle>
            <div>
              <ContactGroupSelector
                onChange={onGroupChange}
                values={groupsState}
                disabled={processing()} />
            </div>
            <Subtitle>{t(lang, 'forms.contact.custom_fields')}</Subtitle>
            <CustomfieldsDisplayer
              form={FORM.edit_contact}
              onChange={onCustomfieldChange}
              contactId={selections[currentIndex]} />
          </>
          )}
          { currentTab === TABS.payment && (
          <>
            <Subtitle>{t(lang, 'forms.contact.payment_information')}</Subtitle>
            <BoldTextAccent>{t(lang, 'forms.contact.credit_cards')}</BoldTextAccent>
            <ContactCreditCardDisplayer contactId={selections[currentIndex] as number} />
            <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.contact.add_credit_card')}</TextLink>
            <BoldTextAccent>{t(lang, 'forms.contact.bank_accounts')}</BoldTextAccent>
            <ContactBankAccountDisplayer contactId={selections[currentIndex] as number} />
            <TextLink onClick={() => openAddBankAccountModal()}>{t(lang, 'forms.contact.add_bank_account')}</TextLink>
          </>
          )}
          { currentTab === TABS.relations && (
          <>
            { currentState === STATE.list && (
            <div>
              <Row>
                <TextLink onClick={handleAdd}>
                  {t(lang, 'forms.contact.add_relationship')}
                </TextLink>
              </Row>
            </div>
            )}
            <Scroller height="640px">
              { currentState === STATE.list && displayContactRelation() }
              { currentState === STATE.add && displayAddRelationForm() }
              { currentState === STATE.edit && displayEditRelationForm() }
            </Scroller>
          </>
          )}
        </Form>
      </Scroller>
    </>
  );
};

export default EditContactForm;
