import { FIELD_TYPES } from 'src/constants/Fields';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum OPERATOR_EXTRA_TYPE {
  start_with = 'start_with',
  end_with = 'end_with',
  boolean = 'boolean',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SEARCH_OPERATORS_PRIMITIVE {
  empty = '',
  like = 'LIKE',
  not_like = 'NOT LIKE',
  between = 'BETWEEN',
  not_between = 'NOT BETWEEN',
  null = 'NULL',
  not_null = 'NOT NULL',
  date = 'DATE',
  month = 'MONTH',
  year = 'YEAR',
  in = 'IN',
  not_in = 'NOT IN',
  eq = '=',
  lte = '<=',
  gte = '>=',
  gt = '>',
  lt = '<',
  ne = '<>',
  is = 'IS',
  is_not = 'IS NOT',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum SEARCH_OPERATORS {
  empty = '',
  is_in = 'IN',
  is_not_in = 'NOT_IN',
  contain = 'CONTAIN',
  not_contain = 'NOT_CONTAIN',
  like = 'LIKE',
  not_like = 'NOT LIKE',
  start_with = 'START_WITH',
  end_with = 'END_WITH',
  is_between = 'BETWEEN',
  is_not_between = 'NOT_BETWEEN',
  is_after = 'IS_AFTER',
  is_before = 'IS_BEFORE',
  is_equal = 'IS_EQUAL',
  is_not_equal = 'IS_NOT_EQUAL',
  is_greater = 'IS_GREATER',
  is_less = 'IS_LESS',
  is_empty = 'IS_EMPTY',
  is_not_empty = 'IS_NOT_EMPTY',
  is_not_define = 'IS_NOT_DEFINE',
  is_true = 'IS_TRUE',
  is_false = 'IS_FALSE',
}
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SEARCH_TYPES = {
  [FIELD_TYPES.string]: [
    SEARCH_OPERATORS.contain, SEARCH_OPERATORS.not_contain,
    SEARCH_OPERATORS.start_with, SEARCH_OPERATORS.end_with,
    SEARCH_OPERATORS.is_equal, SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_empty, SEARCH_OPERATORS.is_not_empty,
  ],
  [FIELD_TYPES.number]: [
    SEARCH_OPERATORS.is_between,
    SEARCH_OPERATORS.is_equal, SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_greater, SEARCH_OPERATORS.is_less,
    SEARCH_OPERATORS.is_not_define,
  ],
  [FIELD_TYPES.money]: [
    SEARCH_OPERATORS.is_between,
    SEARCH_OPERATORS.is_equal, SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_greater, SEARCH_OPERATORS.is_less,
  ],
  [FIELD_TYPES.date]: [
    SEARCH_OPERATORS.is_between,
    SEARCH_OPERATORS.is_equal, SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_after, SEARCH_OPERATORS.is_before,
    SEARCH_OPERATORS.is_not_define,
  ],
  [FIELD_TYPES.datetime]: [
    SEARCH_OPERATORS.is_between,
    SEARCH_OPERATORS.is_equal, SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_after, SEARCH_OPERATORS.is_before,
    SEARCH_OPERATORS.is_not_define,
  ],
  [FIELD_TYPES.boolean]: [
    SEARCH_OPERATORS.is_not_define,
    SEARCH_OPERATORS.is_true,
    SEARCH_OPERATORS.is_false,
  ],
  [FIELD_TYPES.country]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.emailStatus]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.group]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_not_define,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.salutation]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
  [FIELD_TYPES.gender]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
  [FIELD_TYPES.listSingle]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
  [FIELD_TYPES.listMultiple]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
  [FIELD_TYPES.invoiceStatus]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.invoicePaymentStatus]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.paymentStatus]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.paymentType]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.contact]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
  [FIELD_TYPES.department]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.account]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.program]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
    SEARCH_OPERATORS.is_in,
    SEARCH_OPERATORS.is_not_in,
  ],
  [FIELD_TYPES.organisationBankAccount]: [
    SEARCH_OPERATORS.is_equal,
    SEARCH_OPERATORS.is_not_equal,
  ],
};
