import { ContactGroupModel } from 'src/models/ContactGroupModel';
import { RESET_CONTACT_GROUP, SET_CONTACT_GROUP } from 'src/redux/actions/contactGroupActions';
import { Reducer } from 'redux';

export type ContactGroupState = ContactGroupModel;

const DEFAULT: ContactGroupState = {} as ContactGroupState;

const contactGroupReducer:Reducer<ContactGroupState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_CONTACT_GROUP:
      return {
        ...action.payload,
      };
    case RESET_CONTACT_GROUP:
      return DEFAULT;
    default:
      return state;
  }
};

export default contactGroupReducer;
