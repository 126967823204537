import * as Yup from 'yup';
import { t } from 'src/lib/language';

const SimpleContactValidator = (lang:string) => Yup.object().shape({
  salutation_id: Yup.number()
    .min(0, t(lang, 'validations.contact.field_required'))
    .max(999)
    .integer()
    .nullable(),
  first_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when('company_name', {
      // Require when company_name is empty
      is: (companyName: string) => !companyName,
      then: (schema) => schema.required(t(lang, 'validations.contact.firstNameRequired')),
      otherwise: (schema) => schema.nullable(),
    })),
  last_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when('company_name', {
      // Require when company_name is empty
      is: (companyName: string) => !companyName,
      then: (schema) => schema.required(t(lang, 'validations.contact.lastNameRequired')),
      otherwise: (schema) => schema.nullable(),
    })),
  company_name: Yup.lazy(() => Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .when(['first_name', 'last_name'], {
      // Require when company_name is empty
      is: (firstName: string, lastName: string) => !firstName && !lastName,
      then: (schema) => schema.required(t(lang, 'validations.contact.companyNameRequired')),
      otherwise: (schema) => schema.nullable(),
    })),
  email: Yup.string()
    .email(t(lang, 'validations.contact.invalid_email'))
    .nullable(),
  secondary_email: Yup.string()
    .email(t(lang, 'validations.contact.invalid_email'))
    .nullable(),
  billing_address: Yup.string()
    .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 }))
    .nullable(),
  billing_suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  billing_city: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  billing_state: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  billing_country_id: Yup.number()
    .min(0, 'billing_country_required').max(999)
    .integer()
    .nullable(),
  billing_zip_code: Yup.string()
    .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 }))
    .nullable(),
  shipping_address: Yup.string()
    .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 }))
    .nullable(),
  shipping_suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_city: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_state: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_country_id: Yup.number()
    .min(0, 'billing_country_required').max(999)
    .integer()
    .nullable(),
  shipping_zip_code: Yup.string()
    .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 }))
    .nullable(),
  phone_home: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
  phone_office: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
  phone_mobile: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
});

export default SimpleContactValidator;
