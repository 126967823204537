import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import { useState } from 'react';
import { createInvoiceQuickSearchFilter } from 'src/lib/QueryBuilderHelper';
import {
  getEntitiesAction,
  setFilters,
  setQuickSearch,
} from 'src/redux/actions/querybuilderActions';
import { ENTITIES, QuerbuilderPayloadFilters } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { t } from 'src/lib/language';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateType, getDateValue } from 'src/lib/DateHelper';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATUS } from 'src/constants/Invoices';
import { MetadataType } from 'src/components/Control/MetadataSelector';
import InvoiceStatusSelector from 'src/components/Control/InvoiceStatusSelector';
import InvoicePaymentStatusSelector from 'src/components/Control/InvoicePaymentStatusSelector';
import { InvoiceQuickSearch, QuickSearchPayload } from 'src/models/QuickSearchModel';
import MetadataMultiSelector from 'src/components/Control/MetadataMultiSelector';

const useStyles = makeStyles((theme) => ({
  datagridQuickSearch: {
    width: '100%',
    border: '1px solid #eeeeee',
    borderTop: 'none',
    padding: theme.spacing(1),
  },
  datagridQuickSearchContainer: {
    maxWidth: '1080px',
    width: '100%',
  },
  datagridQuickSearchClear: {
    color: theme.palette.primary.main,
    '&:Hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.light,
      cursor: 'pointer',
    },
  },
  datagridSearchClose: {
    lineHeight: '20px',
    height: '20px',
    textAlign: 'center',
    margin: 'auto auto',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  text: {
    padding: theme.spacing(0, 1),
  },
}));

interface Props {
  closeTab: () => void,
  entity: ENTITIES,
}

const DatagridQuickSearchInvoice = (props: Props) => {
  const classes = useStyles();
  const { closeTab, entity } = props;

  const dispatch = useAppDispatch();

  const loading = useSelector(
    (state: Store) => (state.formStatus[FORM.query_builder] === FORM_STATUS.processing),
  );
  const lang = useSelector((state: Store) => state.language.language);

  const getDefaultStartDate = () => moment().subtract(18, 'months').startOf('day');

  const getDefaultEndDate = () => moment().add(6, 'months').endOf('day');

  const storedQuickSearch =
    useSelector(
      (state: Store) => (
        state.quickSearch && state.quickSearch[entity] as InvoiceQuickSearch
      )
        || {
          startDate: getDefaultStartDate(),
          endDate: getDefaultEndDate(),
          invoiceStatus: [INVOICE_STATUS.final, INVOICE_STATUS.draft, INVOICE_STATUS.error],
        } as InvoiceQuickSearch,
    );

  const [invoiceQuickSearch, setInvoiceQuickSearch] = useState(storedQuickSearch);

  const getQuickSearch = () => {
    const filters = createInvoiceQuickSearchFilter(invoiceQuickSearch);
    dispatch(resetSelections({ entity }));
    dispatch(setFilters({
      entity,
      filters,
    } as QuerbuilderPayloadFilters));
    dispatch(setQuickSearch({
      entity,
      quicksearch: invoiceQuickSearch,
    } as QuickSearchPayload));
    dispatch(getEntitiesAction(entity));
  };

  const handleKeyDown = (e: { key: string; }) => {
    if (e.key === 'Enter') {
      getQuickSearch();
    }
  };

  const clearFilter = () => {
    dispatch(setFilters({
      entity,
      filters: null,
    } as QuerbuilderPayloadFilters));

    const defaultQuickSearch = {
      startDate: getDefaultStartDate(),
      endDate: getDefaultEndDate(),
      invoiceStatus: [INVOICE_STATUS.final, INVOICE_STATUS.draft, INVOICE_STATUS.error],
    } as InvoiceQuickSearch;

    setInvoiceQuickSearch(defaultQuickSearch);

    dispatch(setQuickSearch({
      entity,
      quicksearch: defaultQuickSearch,
    } as QuickSearchPayload));

    dispatch(getEntitiesAction(entity));
  };

  const setValue = (field: string, value:any) => {
    if (field === 'start_date') {
      setInvoiceQuickSearch({
        ...invoiceQuickSearch,
        startDate: getDateValue(value, DateType.start),
      });
      return;
    }
    if (field === 'end_date') {
      setInvoiceQuickSearch({
        ...invoiceQuickSearch,
        endDate: getDateValue(value, DateType.end),
      });
      return;
    }
    setInvoiceQuickSearch({
      ...invoiceQuickSearch,
      [field]: value,
    });
  };

  return (
    <div className={classes.datagridQuickSearch}>
      <div className={classes.datagridQuickSearchContainer}>
        <div className={classes.row}>
          <span className={classes.text}> Between </span>
          <FormDatePicker
            form={FORM.invoice_contact}
            label={t(lang, 'forms.invoices.start_date')}
            name="start_date"
            onChange={
              (date: MaterialUiPickersDate) => setValue('startDate', date)
            }
            value={invoiceQuickSearch.startDate} />
          <span className={classes.text}> And </span>
          <FormDatePicker
            form={FORM.invoice_contact}
            label={t(lang, 'forms.invoices.end_date')}
            name="end_date"
            onChange={
              (date: MaterialUiPickersDate) => setValue('endDate', date)
            }
            value={invoiceQuickSearch.endDate} />
        </div>
        <div className={classes.row}>
          <div style={{ width: '33%', paddingBottom: '6px' }}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              margin="normal"
              label={t(lang, 'menus.quick_search_term')}
              onChange={(e:any) => setValue('terms', e.target.value as string)}
              onKeyDown={handleKeyDown}
              value={invoiceQuickSearch.terms} />
          </div>
          <div style={{ width: '33%' }}>
            <InvoiceStatusSelector
              onChange={setValue}
              values={invoiceQuickSearch.invoiceStatus || [] as INVOICE_STATUS[]} />
          </div>
          <div style={{ width: '33%' }}>
            <InvoicePaymentStatusSelector
              onChange={setValue}
              values={invoiceQuickSearch.paymentStatus || [] as INVOICE_PAYMENT_STATUS[]} />
          </div>
        </div>
        <div className={classes.row} />
        <div className={classes.row} style={{ alignItems: 'self-start' }}>
          <div style={{ width: '33%' }}>
            <MetadataMultiSelector
              form={FORM.invoice_search}
              name="departmentId"
              onChange={(ids: number[]) => setValue('departmentId', ids)}
              type={MetadataType.department}
              value={invoiceQuickSearch.departmentId} />
          </div>
          <div style={{ width: '33%' }}>
            <MetadataMultiSelector
              form={FORM.invoice_search}
              name="accountId"
              onChange={(ids: number[]) => setValue('accountId', ids)}
              type={MetadataType.account}
              value={invoiceQuickSearch.accountId} />
          </div>
          <div style={{ width: '33%' }}>
            <MetadataMultiSelector
              form={FORM.invoice_search}
              name="programId"
              onChange={(ids: number[]) => setValue('programId', ids)}
              type={MetadataType.program}
              value={invoiceQuickSearch.programId} />
          </div>
        </div>
        <div className={classes.row}>
          <Button
            variant="contained"
            color="primary"
            style={{
              marginLeft: '8px',
              marginTop: '8px',
            }}
            onClick={getQuickSearch}
            disabled={loading}>
            {t(lang, 'menus.quick_search')}
          </Button>
          <div
            className={classes.datagridQuickSearchClear}
            onClick={() => clearFilter()}>
            {t(lang, 'menus.clear_filter')}
          </div>
        </div>
      </div>
      <div
        className={classes.datagridSearchClose}
        onClick={() => closeTab()}>
        {t(lang, 'misc.close_search')}
      </div>
    </div>
  );
};

export default DatagridQuickSearchInvoice;
