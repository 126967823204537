import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import FormMultipleSelect from 'src/components/Control/FormControls/FormMultipleSelect';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { PAYMENT_STATUS, PAYMENT_STATUS_LIST } from 'src/models/PaymentModel';

interface Props {
  values: PAYMENT_STATUS[],
  onChange: (field: string, paymentStatus:any) => void
}
const PaymentStatusSelector = (props: Props) => {
  const { values, onChange } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const getPaymentStatus = () => PAYMENT_STATUS_LIST.map(
    (status: PAYMENT_STATUS) => ({
      label: status.toUpperCase(),
      id: status,
    }),
  );

  return (
    <FormMultipleSelect
      form={FORM.admin_donation}
      name="paymentStatus"
      label={t(lang, 'forms.invoices.payment_status')}
      onChange={(status: string) => onChange('paymentStatus', status)}
      value={values}
      items={getPaymentStatus()}
      noMarginTop />
  );
};

export default PaymentStatusSelector;
