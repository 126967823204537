import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { getInvoiceGuessData } from 'src/apis/InvoiceAPI';
import { TaxItemModel } from 'src/models/TaxListModel';
import { InvoiceModel } from 'src/models/InvoiceModel';
import moment from 'moment-timezone-all';
import { normalizeProductListData } from 'src/apis/ProductAPI';
import { setLanguage } from 'src/redux/actions/languageActions';

export const SET_GUESS_INVOICE = 'SET_GUESS_INVOICE';
export const RESET_GUESS_INVOICE = 'RESET_GUESS_INVOICE';

export const setGuessInvoice = makeActionCreator(SET_GUESS_INVOICE);
export const resetGuessInvoice = makeActionCreator(RESET_GUESS_INVOICE);

export const normalizeInvoice = (data: any) => {
  const invoice = { ...data } as InvoiceModel;
  invoice.due_date = moment(data.due_date);
  invoice.issued_date = !!data.issued_date ? moment(data.issued_date) : undefined;
  return invoice;
};

export const normalizeInvoiceTaxes = (taxes: TaxItemModel[]) => taxes.reduce((accu, tax) => {
  const current = { ...accu };
  current[tax.id] = tax;
  return current;
}, {} as { [key: number]: TaxItemModel });

// Stupid idiot from Back End doesn't know how to write clean code.
// Normally we should receive invoice, contact, taxes, so on in their own properties
// so as invoice is of type InvoiceModel and taxes is of type TaxesModel and so on
// But the guy use trait for the basic controller operations
// What an idiot.

export const getInvoiceGuessAction: (
  invoiceHash: string, organisationId: string) => ThunkedAction<State, Nullable<number>> =
  (invoiceHash: string, organisationId: string) => async (dispatch: any) => {
    if (!invoiceHash) {
      return 0;
    }
    try {
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.processing }));
      const response = await getInvoiceGuessData(invoiceHash, organisationId);
      const { data, success } = response;
      if (success) {
        const { id } = data;
        const {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          contact, seller, payer, products, organisation, taxes, billing_country, ...invoiceData
        } = data;
        const payload = {
          invoice: {
            ...normalizeInvoice(invoiceData),
            taxes: normalizeInvoiceTaxes(taxes),
          },
          contact,
          seller,
          taxes,
          products: normalizeProductListData(products),
          organisation,
          billing_country,
        };
        const language = contact.locale_id.split('_')[0];
        dispatch(setLanguage(language));
        dispatch(setGuessInvoice({ id, guessInvoice: payload }));
        return id;
      }
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
      return null;
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.get_invoice]: FORM_STATUS.error }));
      return null;
    }
  };
