import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';

interface Props {
  onClick: () => void,
  title:string,
}

const EditButton = (props: Props) => {
  const { onClick, title } = props;
  return (
    <Tooltip
      title={title}
      placement="top-start">
      <span style={{ marginLeft: 'auto' }}>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={() => onClick()}>
          <EditIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default EditButton;
