const messages = {
  cancel_payment: 'Annuler paiement',
  contact_saved: 'Information de contact sauvegarder avec succès.',
  contact_saved_error: 'Désolé une erreur s\'est produite lors de la sauvegarde des informations de contact.',
  payment_saved_error: 'Désolé une erreur s\'est produite le paiement.',
  relation_saved: 'Information de relation sauvegarder avec succès.',
  relation_saved_error: 'Désole une erreur s\'est produite lors de la sauvegarde des informations de relation.',
  contact_updated: 'Mis a jour de vos informations de contact effectuer avec succès.',
  invoice_updated: 'Facture mise à jour avec succès.',
  invoice_saved: 'Facture sauvegardée avec succès.',
  contacts_deleted: 'Contacts supprimés avec succès.',
  relation_deleted: 'Relation supprimée avec succès.',
  invoices_deleted: 'Factures annulées avec succès.',
  invoices_cancelled: 'Factures cancellées avec succès.',
  invoices_cancelled_error: 'Erreur lors de la cancellation des factures.',
  contact_updated_error: 'Désole, une erreur s\'est produite lors de la mise à jour de vos informations de contact.',
  organisation_added: '%organisation% ajouter à vos organisations.',
  organisation_added_error: 'Deole une erreur s\'est produite lors de l\'ajout de l\'organisation %organisation%.',
  organisation_removed: 'L\'organisation %organisation% a été retirée de la liste de vos organisations avec succès.',
  organisation_updated: 'Organisation mise à jour avec succès.',
  organisation_updating_error: 'Une erreur s\'est produite lors de la mise à jour du status du partage de vos information avec l\'organisation %organisation%.',
  sharing_info_updated: 'Statut du partage de vos information avec l\'organisation %organisation% mis à jour avec succès.',
  sharing_info_updated_error: 'Une erreur s\'est produite lors de la mise à jour du status du partage de vos information avec l\'organisation %organisation%.',
  wrong_credential: 'Mot de passe ou nom usagé erroné.',
  email_sent_verification: 'Un courriel de confirmation vous a été envoyé.',
  email_sent_reset: 'Un courriel contenant un lien pour réinitialiser votre mot de passe vous a été envoyé.',
  credential_error: 'Désolé une erreur s\'est produite.',
  password_changed: 'Votre mot de passe a été mis à jour avec succès.',
  filter_saved_error: 'Une erreur est survenu lors de la sauvegarde des filtres',
  filter_saved_success: 'Filtre sauvegarde avec succès.',
  contact_merged_error: 'Contacts combines avec succès.',
  contact_merged: 'Erreur lors de la combinaison des contacts',
  field_cant_be_empty: 'Ce champ ne peut être vide.',
  customfield_saved: 'Champ personnalisé sauvegardé.',
  customfield_saved_error: 'Erreur durant la sauvegarde du champ personnalisé.',
  customfield_deleted: 'Champ personnalisé supprimé.',
  customfield_deleted_error: 'Erreur durant la suppression du champ personnalisé.',
  customfield_edited: 'Champ personnalisé sauvegardé.',
  customfield_edited_error: 'Erreur durant la sauvegarde du champ personnalisé.',
  group_saved: 'Groupe sauvegardé.',
  group_saved_error: 'Erreur durant la sauvegarde du groupe.',
  group_deleted: 'Groupe supprimé.',
  group_deleted_error: 'Erreur durant la suppression du groupe.',
  credit_card_added: 'Carte de crédit sauvegardé avec succès.',
  credit_card_deleted: 'Carte de crédit supprimé avec succès.',
  bank_account_added: 'Compte de banque sauvegarde avec succès.',
  bank_account_deleted: 'Compte de banque supprime avec succès.',
  bank_account_deleted_error: 'Erreur lors de la suppression du compte banquaire.',
  saving_layout: 'Sauvegarder disposition prédéfinie',
  saving_filter: 'Sauvegarder filtre prédéfini',
  preset_layout_saved: 'Disposition prédéfinie sauvée avec succès.',
  preset_filter_saved: 'Filtre prédéfini sauvegardé avec succès.',
  preset_layout_deleted: 'Disposition prédéfinie supprime avec succès.',
  preset_filter_deleted: 'Filtre prédéfini supprimé avec succès.',
  preset_layout_deleted_error: 'Erreur durant la suppression de la disposition.',
  preset_filter_deleted_error: 'Erreur durant la suppression du filtre.',
  preset_layout_saved_error: 'Erreur durant la sauvegarde de la disposition.',
  preset_filter_saved_error: 'Erreur durant la sauvegarde dfiltre.',
  department_saved: 'Département sauvegardé',
  department_saved_error: 'Erreur durant la sauvegardé du département',
  department_deleted: 'Département supprimé',
  department_deleted_error: 'Erreur durant la suppresion du département',
  account_saved: 'Sauvegarder',
  account_saved_error: 'Erreur durant la sauvegarde du compte',
  account_deleted: 'Compte supprimé',
  account_deleted_error: 'Erreur durant la suppression du compte',
  program_saved: 'Programme sauvegardé',
  program_saved_error: 'Erreur durant la sauvegarde du programme',
  program_deleted: 'Programme supprimé',
  program_deleted_error: 'Erreur durant la suppression du programme',
  product_saved: 'Produit sauvegardé',
  product_saved_error: 'Erreur durant la sauvegarde du produit',
  product_deleted: 'Produit supprimé',
  product_deleted_error: 'Erreur durant la suppression du produit',
  organisation_info_updated: 'Mise à jour de l\'organisation',
  organisation_info_updated_error: 'Erreur durant la mise à jour de l\'organisation',
  organisation_contact_updated: 'Mise à jour du contact de l\'organisation',
  organisation_contact_updated_error: 'Erreur durant mise à jour du contact de l\'organisation',
  tax_saved: 'Taxe sauvegardée',
  tax_saved_error: 'Erreur durant la sauvegardé de la taxe',
  deleted_tax: 'Supprimé la taxe',
  deleted_tax_error: 'Erreur durant la suppression de la taxe',
  successfully_uploaded: 'Chargé avec succès',
  error_upload: 'Erreur du téléchargement',
  replace_invoice: 'Cette facture sera annulee et une nouvelle sera creee.',
  purchased_success: 'Achat effectue avec succes.',
  payment_update_success: 'Payment modifie avec succes.',
  purchased_error: 'Erreur durant l\'achat.',
  file_not_found: 'File not found.',
};

export default messages;
