import { FIELD_TYPES } from 'src/constants/Fields';

const invoicesContact = [
  {
    field: 'invoices.sequence',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'invoices.title',
    minWidth: 330,
    type: FIELD_TYPES.string,
  },
  {
    field: 'invoices.total',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'invoices.due_date',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'invoices.state',
    minWidth: 150,
    type: FIELD_TYPES.listSingle,
  },
  {
    field: 'invoices.payment_status',
    minWidth: 150,
    type: FIELD_TYPES.listSingle,
  },
  {
    field: 'invoices.is_sent',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
  {
    field: 'invoices.is_printed',
    minWidth: 150,
    type: FIELD_TYPES.boolean,
  },
];

export default invoicesContact;
