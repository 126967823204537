import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteCustomFieldsData,
  getCustomFieldsData,
  normalizeUserCustomfields,
  postCustomFieldsData,
  putCustomFieldsData,
} from 'src/apis/CustomFieldsAPI';
import { CustomFieldsPayload } from 'src/models/CustomfieldModel';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { GridColumns } from '@material-ui/x-grid';
import { FIELD_TYPES } from 'src/constants/Fields';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { CustomfieldsState } from 'src/redux/reducers/CustomfieldsReducer';
import { setColumns } from 'src/redux/actions/querybuilderActions';
import { ENTITIES, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';

export const UPDATE_CUSTOM_FIELDS = 'UPDATE_CUSTOM_FIELDS';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const ADD_CUSTOM_FIELD = 'ADD_CUSTOM_FIELD';
export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';

export const setCustomFields = makeActionCreator(SET_CUSTOM_FIELDS);
export const updateCustomField = makeActionCreator(UPDATE_CUSTOM_FIELDS);
export const addCustomFields = makeActionCreator(ADD_CUSTOM_FIELD);
export const deleteCustomfield = makeActionCreator(DELETE_CUSTOM_FIELD);

const getColumns = (customfields: CustomfieldsState) => {
  const customfieldColumns = [] as GridColumns;
  Object.values(customfields).forEach((customfield:any) => {
    customfieldColumns.push({
      field: `custom_fields.${customfield.name}`,
      minWidth: 150,
      type: FIELD_TYPES.string,
    });
  });
  return [
    ...DEFAULT_COLUMNS.contacts,
    ...customfieldColumns,
  ];
};

export const getCustomFields: () => ThunkedAction<State> =
  () => async (dispatch: any, getState:any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      dispatch(setFormStatus({ [FORM.get_customfields]: FORM_STATUS.processing }));
      const response = await getCustomFieldsData(organisation);
      if (response.success) {
        const customfields = normalizeUserCustomfields(response.data);
        dispatch(setCustomFields(customfields));
        dispatch(setFormStatus({ [FORM.get_customfields]: FORM_STATUS.success }));
      }
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.get_customfields]: FORM_STATUS.error }));
    }
  };

export const deleteCustomfieldAction: (customfieldId: number) => ThunkedAction<State> =
  (customfieldId: number) => async (dispatch: any, getState:any) => {
    const organisation = getState().currentOrganisation.id;
    const response = await deleteCustomFieldsData(customfieldId, organisation);
    try {
      if (response.success) {
        const customfields = normalizeUserCustomfields(response.data.list);
        dispatch(setCustomFields(customfields));
        dispatch(setColumns({
          entity: ENTITIES.contacts,
          columns: getColumns(getState().customfields),
        } as QuerbuilderPayloadColumns));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.customfield_deleted',
        }));
        dispatch(closeDialog({ dialog: DIALOGS.deleteCustomfield }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_deleted_error',
      }));
      dispatch(getCustomFields());
    } catch (e) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_deleted_error',
      }));
    }
  };

export const postCustomFieldsAction: (payload: CustomFieldsPayload) => ThunkedAction<State> =
  (payload: CustomFieldsPayload) => async (dispatch: any, getState:any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postCustomFieldsData(payload, organisation);
      if (response.success) {
        const customfields = normalizeUserCustomfields(response.data.list);
        dispatch(setCustomFields(customfields));
        dispatch(setColumns({
          entity: ENTITIES.contacts,
          columns: getColumns(getState().customfields),
        } as QuerbuilderPayloadColumns));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.customfield_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_saved_error',
      }));
    } catch (e) {
      dispatch(setFormStatus({ [FORM.add_customfield]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_saved_error',
      }));
    }
  };

export const putCustomFieldsAction:
(id: number, payload: CustomFieldsPayload) => ThunkedAction<State> =
  (id:number, payload: CustomFieldsPayload) => async (dispatch: any, getState:any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putCustomFieldsData(id, payload, organisation);
      if (response.success) {
        const customfields = normalizeUserCustomfields(response.data.list);
        dispatch(setCustomFields(customfields));
        dispatch(setColumns({
          entity: ENTITIES.contacts,
          columns: getColumns(getState().customfields),
        } as QuerbuilderPayloadColumns));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.customfield_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_saved_error',
      }));
    } catch (e) {
      dispatch(setFormStatus({ [FORM.add_customfield]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.customfield_saved_error',
      }));
    }
  };
