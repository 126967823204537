const invoice = {
  id: 'id',
  issued_date: 'Issued Date',
  due_date: 'Due Date',
  scheduled: 'Scheduled',
  sequence: 'Sequence',
  title: 'Title',
  note: 'Note',
  issue_tax_receipt: 'Issue Tax Receipt',
  is_sent: 'Is Sent',
  is_printed: 'Is Printed',
  billing_address: 'Billing Address',
  billing_suite: 'Billing Suite',
  billing_city: 'Billing City',
  billing_state: 'Billing State',
  billing_country_id: 'Billing Country',
  billing_zip_code: 'Billing Zip Code',
  shipping_address: 'Shipping Address',
  shipping_suite: 'Shipping Suite',
  shipping_city: 'Shipping City',
  shipping_state: 'Shipping State',
  shipping_country_id: 'Shipping Country',
  shipping_zip_code: 'Shipping Zip Code',
  contact_company_name: 'Contact Company Name',
  contact_salutation_id: 'Contact Salutation',
  contact_first_name: 'Contact First Name',
  contact_last_name: 'Contact Last Name',
  account_id: 'Account',
  program_id: 'Program',
  bank_account_id: 'Bank Account',
  department_id: 'Department',
  state: 'State',
  payment_status: 'Payment Status',
  contact_id: 'Contact',
  seller_id: 'Seller',
  contact_payer_id: 'Contact Payer',
  total: 'Total',
  subtotal: 'Subtotal',
  taxes: 'Taxes',
  paid: 'Paid',
  balance: 'Balance',
  deductible: 'Deductible',
  created_at: 'Created',
  updated_at: 'Updated',
  deleted_at: 'Deleted',
};

export default invoice;
