import { Reducer } from 'redux';
import { LanguageModel } from 'src/models/LanguageModel';
import { SET_LANGUAGE } from 'src/redux/actions/languageActions';

export type LanguageState = LanguageModel;

const DEFAULT: LanguageState = { } as LanguageState;

const languageReducer: Reducer<LanguageState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export default languageReducer;
