const fundraising = {
  en: 'Anglais',
  fr: 'Français',
  es: 'Espagnol',
  he: 'Hébreu',
  y: 'Yiddish',
  active: 'Actif',
  name: 'Nom',
  slug: 'Slug de collecte de fonds',
  goal: 'Objectif',
  start_date: 'Date de début',
  end_date: 'Date de fin',
  default_language: 'Langue par défaut',
  show_raised_amount: 'Afficher le montant collecté',
  show_timer: 'Afficher le chronomètre',
  next_step: 'Prochaine étape',
  //
  add_contact: 'Contact',
  add_contact_btn: 'Ajouter un contact',
  send_thanks_email: 'Envoyer une notification par email',
  thanks_email_body: 'Contenu du message email',
  send_thanks_sms: 'Envoyer une notification par SMS',
  thanks_sms_text: 'Texte du message SMS',
  facebook_information: 'Informations Facebook',
  fb_description: 'Description de l\'événement Facebook',
  fb_keyword: 'Mots-clés séparés par des virgules',
  media_title: 'Titre de l\'image',
  media_text: 'Texte pour l\'image',
  notifications: 'Notifications',
  contacts_to_notify: 'Contacts à notifier',
  groups_to_notify: 'Groupes à notifier',
  //
  product_validation_errors: 'Erreurs de validation du produit',
  display_single_product: 'Afficher les produits sous forme de liste à choix unique',
  products_list: 'Liste des produits',
  payment: 'Paiement',
  cover_fee_percentage: 'Couvrir les frais (%)',
  allow_installment: 'Autoriser les paiements en plusieurs fois',
  max_installment: 'Nombre maximum de versements',
  //
  order: 'Ordre',
  event_information: 'Informations sur l\'événement',
  event_date: 'Date de l\'événement',
  title: 'Titre',
  venue_name: 'Nom du lieu',
  venue_address: 'Adresse du lieu',
  contact_email: 'Email de contact',
  footer_message: 'Message de pied de page',
  logo: 'Logo',
  //
  filter_team: 'Filtrer les équipes',
  //
  button_label: 'Libellé du bouton',
  campaign_message: 'Message de la campagne',
  main_landscape_slider: 'Slider principal paysage',
  secondary_landscape_slider: 'Slider secondaire paysage',
  secondary_portrait_slider: 'Slider secondaire portrait',
  slider_matcher: 'Correspondance du slider',
  slider_sponsor: 'Sponsor du slider',
  video_main: 'Vidéo principale',
  video_secondary: 'Vidéo secondaire',
  //
  match_multiplier: 'Multiplicateur',
  match_reached_multiplier: 'Multiplicateur atteint',
  match_goal: 'Objectif de correspondance',
  bonus_round: 'Tour bonus',
  bonus_round_goal: 'Objectif du tour bonus',
  slider_main_all_language: 'Slider principal toutes langues',
  slider_secondary_all_language: 'Slider secondaire toutes langues',
  slider_matcher_all_language: 'Slider de correspondance toutes langues',
  slider_sponsor_all_language: 'Slider sponsor toutes langues',
  video_main_all_language: 'Vidéo principale toutes langues',
  video_secondary_all_language: 'Vidéo secondaire toutes langues',
  campaign_message_all_language: 'Message de campagne toutes langues',
  allow_comments: 'Autoriser les commentaires',
  show_team_donor_amount: 'Afficher le montant des donateurs par équipe',
  show_donors: 'Afficher les donateurs',
  show_teams: 'Afficher les équipes',
  //
  texts: 'Textes',
  poster_portrait: 'Affiche portrait',
  poster_portrait_slider: 'Slider d\'affiches portrait',
  poster_landscape: 'Affiche paysage',
  poster_landscape_slider: 'Slider d\'affiches paysage',
  video_slider: 'Sliders vidéo',
  //
  contact: 'Contact',
  team_name: 'Name of the Team',
  team_slug: 'Slug for the Team',
  team_description: 'Description of the Team',
  team_default_language: 'Default Language',
  avatar: 'Avatar',
};

export default fundraising;
