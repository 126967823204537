import { makeStyles } from '@material-ui/core/styles';
import DeleteIconSVG from '@material-ui/icons/Delete';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  deletePaymentInfo: {
    color: theme.palette.action.active,
    margin: '0 4px',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const DeleteIcon = (props: Props) => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <DeleteIconSVG
      className={classes.deletePaymentInfo}
      fontSize="small"
      onClick={() => onClick()} />
  );
};

export default DeleteIcon;
