import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { getBankAccountData, getContactBankAccountData } from 'src/apis/BankAccountAPI';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';

export const SET_BANK_ACCOUNTS = 'SET_BANK_ACCOUNTS';
export const SET_CONTACT_BANK_ACCOUNTS = 'SET_CONTACT_BANK_ACCOUNTS';

export const setBankAccountData = makeActionCreator(SET_BANK_ACCOUNTS);
export const setContactBankAccountData = makeActionCreator(SET_CONTACT_BANK_ACCOUNTS);

export const getBankAccountAction: () => ThunkedAction<State> =
() => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getBankAccountData(organisation);
    if (response.success) {
      const { data } = response;
      dispatch(setBankAccountData({ data }));
      dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.error }));
  }
};

export const getContactBankAccountAction: (contact_id: number) => ThunkedAction<State> =
(contact_id: number) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getContactBankAccountData(contact_id, organisation);
    if (response.success) {
      const { data } = response;
      dispatch(setContactBankAccountData({
        id: contact_id,
        bankAccounts: data,
      }));
      dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_bank_accounts]: FORM_STATUS.error }));
  }
};
