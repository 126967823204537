import { makeStyles } from '@material-ui/core/styles';
import DatagridSearchTab from 'src/components/DatagridSearch/DatagridSearchTab';
import { SEARCH_TABS } from 'src/constants/SearchTabs';
import { useState } from 'react';
import { Collapse } from '@material-ui/core';
import DatagridQuickSearchContact from 'src/components/DatagridSearch/DatagridQuickSearchContact';
import DatagridAdvancedSearch from 'src/components/DatagridSearch/DatagridAdvancedSearch';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { setFilters } from 'src/redux/actions/querybuilderActions';
import DatagridQuickSearchInvoice from 'src/components/DatagridSearch/DatagridQuickSearchInvoice';
import { PRESET_TYPES } from 'src/models/PresetModel';
import DatagridQuickSearchPayment from 'src/components/DatagridSearch/DatagridQuickSearchPayment';

const useStyles = makeStyles(() => ({
  datagridSearch: {
    width: '100%',
  },
  datagridSearchTabs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #eee',
  },
}));

interface Props {
  entity: ENTITIES,
}

const DatagridSearch = (props: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { entity } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const hasDefaultPreset = useSelector(
    (state: Store) => {
      const type = PRESET_TYPES.filter;
      if (!state.presets[entity]) return false;
      if (!state.presets[entity][type]) return false;
      return !!state.presets[entity][type].find((preset) => preset.is_default);
      return false;
    },
  );

  const [selectedTab, setSelectedTab] = useState<SEARCH_TABS | null>(
    hasDefaultPreset ? SEARCH_TABS.advanced_search : SEARCH_TABS.quick_search,
  );
  const [opened, setOpened] = useState(true);

  const selectTab = (tab:SEARCH_TABS) => {
    dispatch(setFilters({
      entity,
      filters: null,
    }));
    if (tab === selectedTab) {
      setSelectedTab(null);
      setOpened(false);
      return;
    }
    setSelectedTab(tab);
    setOpened(true);
  };

  const closeTab = () => {
    setSelectedTab(null);
    setOpened(false);
  };

  const getQuickSearch = () => {
    switch (entity) {
      case ENTITIES.contacts:
        return (<DatagridQuickSearchContact closeTab={closeTab} entity={entity} />);
      case ENTITIES.payments:
        return (<DatagridQuickSearchPayment closeTab={closeTab} entity={entity} />);
      case ENTITIES.invoices:
      default:
        return (<DatagridQuickSearchInvoice closeTab={closeTab} entity={entity} />);
    }
  };

  const displaySearchContent = () => {
    switch (selectedTab) {
      case SEARCH_TABS.quick_search:
        return getQuickSearch();
      case SEARCH_TABS.advanced_search:
        return (<DatagridAdvancedSearch closeTab={closeTab} entity={entity} />);
      default: return <></>;
    }
  };

  return (
    <div className={classes.datagridSearch}>
      <div className={classes.datagridSearchTabs}>
        <DatagridSearchTab
          display={t(lang, 'search.quickSearch')}
          first
          tab={SEARCH_TABS.quick_search}
          setSelected={selectTab}
          selected={selectedTab === SEARCH_TABS.quick_search} />
        <DatagridSearchTab
          display={t(lang, 'search.advancedSearch')}
          tab={SEARCH_TABS.advanced_search}
          setSelected={selectTab}
          selected={selectedTab === SEARCH_TABS.advanced_search} />
      </div>
      <Collapse in={opened}>
        {displaySearchContent()}
      </Collapse>
    </div>
  );
};

export default DatagridSearch;
