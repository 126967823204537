import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { useMediaQuery } from 'react-responsive';

interface Props {
  children?: any,
  minHeight?: string,
  responsive?: boolean,
  style? : CSSProperties
}

const Col2 = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const {
    children, minHeight, responsive, style,
  } = props;

  if (responsive) {
    return (
      <div style={{
        flex: isTabletOrMobile ? '100%' : '45%',
        display: 'flex',
        flexDirection: 'column',
        minHeight,
        ...style,
      }}>
        {children}
      </div>
    );
  }

  return (
    <div style={{
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      minHeight,
      ...style,
    }}>
      {children }
    </div>
  );
};

export default Col2;
