import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import {
  FormControl, FormControlLabel, Radio, RadioGroup, Typography,
} from '@material-ui/core';
import { ProductModel } from 'src/models/ProductModel';
import AddProductForm from 'src/components/Forms/AddProductForm';
import EditProductForm from 'src/components/Forms/EditProductForm';
import { getProductListAction } from 'src/redux/actions/productAction';
import { sortBy } from 'lodash';
import { ProductType } from 'src/constants/Products';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
import Item from 'src/components/Elements/Item';
import DeleteButton from 'src/components/Elements/DeleteButton';
import EditButton from 'src/components/UI/EditButton';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageProducts = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProductListAction());
  }, []);

  const products = useSelector((state: Store) => state.products);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageProducts }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentProduct, setcurrentProduct] = useState({} as ProductModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [type, setType] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(products).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(products).slice(0, 20));
  }, [products]);

  const handleDelete = (product: ProductModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteProduct, payload: product }));
  };

  const handleEdit = (product: ProductModel) => {
    setcurrentProduct(product);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setcurrentProduct({} as ProductModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(products, (product) => product[`name_${lang}`]);
    let result = sorted;
    if (type) {
      result = sorted.filter((product) => product.type === type);
    }
    if (currentFilter) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType((event.target as HTMLInputElement).value as ProductType);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_product');
      case STATE.edit:
        return t(lang, 'menus.edit_product');
      case STATE.list:
        return t(lang, 'menus.list_product');
      default:
        return t(lang, 'menus.delete_product');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(products, (product) => product[`name_${lang}`]);
    let result = sorted;
    if (type) {
      result = sorted.filter((product) => product.type === type);
    }
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const getItems = () => items.map(
    (product: ProductModel, index: number) => (
      <Item
        key={`product-${index}`}
        style={getStyle(index)}>
        <span><b>{ product[`name_${lang}`] }</b></span>
        <EditButton onClick={() => handleEdit(product)} title={t(lang, 'menus.edit_product')} />
        <DeleteButton onClick={() => handleDelete(product)} title={t(lang, 'menus.delete_product')} />
      </Item>
    ),
  );

  const ListItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}>
      {getItems()}
    </InfiniteScroll>
  );

  const EditForm = () => (
    <EditProductForm
      product={currentProduct}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const AddForm = () => (
    <AddProductForm
      onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      { currentState === STATE.list && (
        <>
          <Header>
            <Row>
              <Title>{ getTitle() }</Title>
              <Button
                style={{ marginLeft: 'auto' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={handleClose}>
                {t(lang, 'misc.close')}
              </Button>
            </Row>
          </Header>
          <ItemSearchBox
            onAdd={handleAdd}
            title={t(lang, 'menus.add_product')}
            onChange={handleSearch} />
          <Row>
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={type}
                onChange={handleTypeChange}>
                <FormControlLabel value="" control={<Radio />} label="All" />
                <FormControlLabel value={ProductType.product} control={<Radio />} label="Products" />
                <FormControlLabel value={ProductType.ticket} control={<Radio />} label="Tickets" />
                <FormControlLabel value={ProductType.fee} control={<Radio />} label="Fees" />
                <FormControlLabel value={ProductType.donation} control={<Radio />} label="Donation" />
              </RadioGroup>
            </FormControl>
          </Row>
        </>
      )}
      <div>
        { currentState === STATE.list && <ListItems /> }
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </div>
    </>
  );
};

export default ManageProducts;
