import Root from 'src/components/Root';
import '@fontsource/roboto';
import { createRoot } from 'react-dom/client';
import Debug from 'src/lib/Debug';

const container = document.getElementById('root');
const root = createRoot(container!);
try {
  root.render(<Root />);
} catch (error) {
  Debug.log(error);
}
//
