const contact = {
  filters: 'Filters',
  clear: 'Clear',
  validation_errors: 'Validation error(s). Please review your data.',
  salutations: 'Salutation',
  add_custom_fields: 'Add custom fields',
  first_name: 'First Name',
  last_name: 'Last Name',
  company_name: 'Company Name (if applicable)',
  primary_email: 'Primary',
  email_notification: 'Allow to receive email notifications at this address',
  secondary_email: 'Secondary',
  address: 'Address (ex: 371 Dupuis)',
  suite: 'Suite (if applicable)',
  city: 'City',
  custom_fields: 'Custom fields',
  add: 'Add',
  groups: 'Groups',
  state: 'State/Province',
  country: 'Country',
  currencies: 'Currencies',
  zip_code: 'Zip Code',
  same_as_billing: 'Check if the shipping address is the same as billing address',
  phone_home: 'Home',
  phone_mobile: 'Mobile',
  phone_office: 'Office',
  phone_notification: 'Allow to receive text notifications on this phone',
  header_name: 'Name Information',
  payer_name: 'Payer Information',
  header_emails: 'Emails',
  header_billing_address: 'Billing Address',
  header_shipping_address: 'Shipping Address',
  header_phones: 'Phones',
  merge_contact_title: 'Merge Contact %firstId% Into Contact %secondId%',
  contact: 'Contact',
  contact_to_merge: 'Contact to merge',
  merge_contact: 'Merge Contact',
  contact_information: 'Contact information',
  meta_information: 'Meta information',
  payment_information: 'Payment information',
  relationships: 'Relationships',
  issue_tax_receipt: 'Issue tax receipt',
  credit_cards: 'Credit cards',
  add_credit_card: 'Add credit card',
  bank_accounts: 'Bank accounts',
  add_bank_account: 'Add bank account',
  add_relationship: 'Add relationship',
  is: 'is',
  of: 'of',
  invoices_for: 'Invoices for',
  payments_for: 'Payments for',
};

export default contact;
