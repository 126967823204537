// eslint-disable-next-line @typescript-eslint/naming-convention
export enum IMAGES_TYPE {
  organisation_logo = 'organisation_logo',
  tax_receipt_signature = 'tax_receipt_signature',
  tax_receipt_header = 'tax_receipt_header',
  media_image_preview = 'media_image_preview',
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum IMAGES_KEYS {
  organisation_logo = 'organisation_logo',
  tax_receipt_header = 'tax_receipt_header',
  tax_receipt_signature = 'tax_receipt_signature',
  media_image_preview = 'media_image_preview',
}

export const ImageSizeType = {
  [IMAGES_TYPE.organisation_logo]: {
    width: 200,
    height: 200,
    ratio: 1,
  },
  [IMAGES_TYPE.tax_receipt_signature]: {
    width: 172,
    height: 80,
    ratio: 172 / 80,
  },
  [IMAGES_TYPE.tax_receipt_header]: {
    width: 950,
    height: 340,
    ratio: 950 / 340,
  },
  [IMAGES_TYPE.media_image_preview]: {
    width: 576,
    height: 110,
    ratio: 110 / 576,
  },
};

export interface ImageSize {
  width: number,
  height: number,
  ratio: number,
}

export interface FilePayload {
  path: string,
  bucket?: string,
  file: string,
}
