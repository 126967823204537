import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { GuessInvoiceModel, ProductOrderList, ProductOrderModel } from 'src/models/InvoiceModel';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useMediaQuery } from 'react-responsive';
import GuessPageHeader from 'src/components/Elements/Guess/GuessPageHeader';
import { Button } from '@material-ui/core';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import moment from 'moment-timezone-all';
import { getS3URL } from 'src/lib/S3File';
import GuessOrderDisplayer from 'src/components/Elements/Guess/GuessOrderDisplayer';
import { t } from 'src/lib/language';
import { ceil } from 'lodash';
import { TaxItemModel } from 'src/models/TaxListModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  status: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: isTabletOrMobile ? theme.spacing(2) : theme.spacing(1),
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'start',
    marginTop: theme.spacing(2),
    gap: isTabletOrMobile ? theme.spacing(2) : theme.spacing(1),
  },
  col1: {
    flex: 1,
  },
  col2: {
    flex: isTabletOrMobile ? '100%' : '49%',
  },
  amount: {
    width: '105px',
    display: 'inline-block',
    textAlign: 'right',
  },
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
}));

interface Props {
  invoiceId: Nullable<number>,
}

interface TaxListModel {
  [id: number]: TaxItemModel;
}

const GuessInvoiceDisplayer = (props: Props) => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const { invoiceId } = props;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'narrowSymbol',
  });

  const {
    invoice,
    contact,
    products,
    organisation,
    taxes,
  } = useSelector(
    (state: Store) => {
      if (!invoiceId) {
        return {} as GuessInvoiceModel;
      }
      return state.guessInvoices?.[invoiceId] ?? {} as GuessInvoiceModel;
    },
  );

  const lang = useSelector((state: Store) => state.language.language || 'en');
  const countries = useSelector((state: Store) => state.data.countries);
  const isLogged = useSelector((store: Store) => !!store.auth.accessToken);

  const getTaxList = () => {
    if (!taxes) return {} as TaxListModel;
    const result = {} as TaxListModel;
    taxes.forEach((tax) => {
      if (tax.id) result[tax.id] = tax;
    });
    return result;
  };
  const taxeList = getTaxList();

  const getTaxItem = (id: number) => taxeList[id];

  const handleOpen = () => {
    dispatch(openModal({
      modal: MODALS.guessPayment,
      payload: invoice,
    }));
  };

  const handleLogin = () => {
    dispatch(openDialog({ dialog: DIALOGS.login }));
  };

  const displayButtons = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleOpen}
        disabled={!invoiceId}
        style={{ marginRight: '50px' }}>
        Pay
      </Button>
      { !isLogged && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleLogin}
          disabled={!invoiceId}
          style={{ marginRight: '50px' }}>
          Log into account
        </Button>
      )}
    </>
  );

  const getCountries = (id:number) => {
    let index = 0;
    while (index < countries.length) {
      if (countries[index].id === id) {
        return countries[index][`name_${lang}`];
      }
      index += 1;
    }
    return '';
  };

  const getDate = (value: any) => {
    if (!moment.isMoment(value)) return '';
    return value.format('YYYY-DD-mm');
  };

  const getSingleOrderSubtotal = (order: ProductOrderModel) => (
    ceil(
      (order.quantity ?? 1) * ((order.paid ?? 0) - (order.discount ?? 0)),
      2,
    )
  );

  const calculateTaxes = () => {
    const taxesObject = invoice.taxes || {};
    let totalTaxes = 0;
    Object.keys(taxesObject).forEach((taxId) => {
      totalTaxes += Number(taxesObject[taxId].amount);
    });
    return totalTaxes;
  };

  const calculateSubtotal = () => {
    let subtotal = 0;
    if (!invoice.order) return subtotal;
    invoice.order.forEach((order) => {
      subtotal += getSingleOrderSubtotal(order);
    });
    return ceil(subtotal, 2);
  };

  const calculateTotal = () => ceil((calculateSubtotal() + calculateTaxes()), 2);

  const displayTaxes = () => {
    const taxesObject = invoice.taxes || {};
    return (
      <>
        { Object.keys(taxesObject).map((taxId: string, index) => {
          const tax = getTaxItem(Number(taxId));
          if (!tax) return null;
          return (
            <div key={`tax-item-${index}`} style={{ textAlign: 'right' }}>
              {tax[`name_${lang}`]} ({tax.rate * 100}%):&nbsp;
              <div className={classes.amount}>{formatter.format(taxesObject[taxId].amount)}</div>
            </div>
          );
        }) }
      </>
    );
  };

  const getContactName = () => {
    if (contact?.company_name) {
      return contact.company_name;
    }
    return `${contact?.first_name} ${contact?.last_name}`;
  };

  if (!invoiceId) {
    return (<></>);
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <GuessPageHeader />
        <img
          width="150px"
          src={`${getS3URL()}${organisation.id}/images/organisation_logo.png?${performance.now()}`}
          alt="-" />
        <div className={classes.rowHeader}>
          <div className={classes.col2}>
            <div>Date: <strong>{getDate(invoice.issued_date)}</strong></div>
            <div>Due Date: <strong>{getDate(invoice.due_date)}</strong></div>
          </div>
          <div className={classes.col2}>
            <div style={{ textAlign: `${isTabletOrMobile ? 'left' : 'right'}` }}>
              Invoice No: <strong>{invoice.invoice_number}</strong>
            </div>
          </div>
        </div>
        <div className={classes.rowHeader}>
          <div className={classes.col2}>
            <b>{organisation.legal_name}</b><br />
            {organisation.address}, {organisation.suite} <br />
            {organisation.city}, {organisation.state},
            {organisation.zip_code}<br />
            {getCountries(organisation.country_id ?? 0)} <br />
            {organisation.phone}<br />
            {organisation.tollfree_phone}
          </div>
        </div>

        <div className={classes.rowHeader}>
          <b>Billing to</b>
        </div>

        <div className={classes.col2}>
          {getContactName()}<br />
          {invoice.billing_address} {invoice.billing_suite}<br />
          {invoice.billing_city}, {invoice.billing_state}, {invoice.billing_zip_code}<br />
          {getCountries(invoice.billing_country_id ?? 0)} <br />
        </div>
      </div>
      <GuessOrderDisplayer
        order={invoice.order || [] as ProductOrderList}
        products={products} />
      <div className={classes.row}>
        <span style={{
          marginRight: '0px',
          marginLeft: 'auto',
        }}>
          {t(lang, 'forms.invoices.subtotal')}:&nbsp;
          <span className={classes.amount}>{formatter.format(calculateSubtotal())}</span>
        </span>
      </div>
      <div className={classes.row}>
        <span style={{
          marginRight: '0px',
          marginLeft: 'auto',
        }}>
          <i>{displayTaxes()}</i>
        </span>
      </div>
      <div className={classes.row}>
        <span style={{
          marginRight: '0px',
          marginLeft: 'auto',
        }}>
          <b>
            Total:&nbsp;
            <span className={classes.amount}>{formatter.format(calculateTotal())}</span>
          </b>
        </span>
      </div>
      <div style={{
        marginTop: '16px',
        marginBottom: '16px',
      }}>
        <div><b>Paid:</b> {formatter.format(invoice.paid ?? 0)}</div>
        <div><b>Scheduled:</b> {formatter.format(invoice.scheduled ?? 0)}</div>
        <div><b>Balance:</b> {formatter.format(invoice.balance ?? 0)}</div>
        <div><b>Tax Deductible:</b> {formatter.format(invoice.deductible ?? 0)}</div>
      </div>
      <div style={{
        textAlign: 'center',
        marginBottom: '16px',
      }}>
        {displayButtons()}
      </div>
    </>
  );
};

export default GuessInvoiceDisplayer;
