import { ReactNode } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import palette from 'src/styles/palette';
import { useTheme } from '@mui/material';
import AppBar from '@material-ui/core/AppBar';

interface Props {
  children: ReactNode,
  style?: CSSProperties,
}

const ApplicationBar = (props: Props) => {
  const { children, style } = props;
  const theme = useTheme();

  const styles = {
    zIndex: 1201,
    width: '100%',
    position: 'fixed',
    backgroundColor: palette.primary.main,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...style,
  } as CSSProperties;
  return (
    <AppBar position="absolute" style={styles}>{children}</AppBar>
  );
};

export default ApplicationBar;
