import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  minHeight?: string,
  style? : CSSProperties
}

const Col3 = (props: Props) => {
  const { children, minHeight, style } = props;
  return (
    <div style={{
      width: '33%',
      display: 'flex',
      flexDirection: 'column',
      minHeight,
      ...style,
    }}>
      { children }
    </div>
  );
};

export default Col3;
