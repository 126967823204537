import { Reducer } from 'redux';
import { SelectionModel } from 'src/models/SelectionModel';
import { RESET_SELECTIONS, SET_SELECTIONS } from 'src/redux/actions/selectionActions';

export type SelectionState = SelectionModel;

const DEFAULT: SelectionState = {} as SelectionState;

const selectionReducer: Reducer<SelectionState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_SELECTIONS: {
      const { entity, selections } = action.payload;
      return {
        ...state,
        [entity]: selections,
      };
    }
    case RESET_SELECTIONS: {
      const { entity } = action.payload;
      return {
        ...state,
        [entity]: [],
      };
    }
    default:
      return state;
  }
};

export default selectionReducer;
