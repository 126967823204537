import { FundraisingProduct } from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { FundraisingTypes } from 'src/constants/FundraisingTypes';
import ChooseProducts from 'src/components/Elements/ChooseProducts';
// import { useState } from 'react';
import Button from '@material-ui/core/Button';
import { MouseEvent, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ProductType } from 'src/models/ProductModel';
import {
  FaBoxOpen, FaDollarSign, FaHeart, FaTicketAlt,
} from 'react-icons/fa';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import ClearIcon from '@material-ui/icons/Clear';
import Debug from 'src/lib/Debug';
import FormCheckbox from 'src/components/Control/FormControls/FormCheckbox';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import MetadataSelector, { MetadataType } from 'src/components/Control/MetadataSelector';
import { INVOICE_FIELDS } from 'src/constants/Invoices';
import OrganisationBankAccountSelector
  from 'src/components/Control/OrganisationBankAccountSelector';
import GatewaySelector from 'src/components/Control/GatewaySelector';
import { ErrorBag } from 'src/models/ErrorModel';
import { setError } from 'src/redux/actions/errorsActions';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import FundraisingProductValidator from 'src/validations/FundraisingProductValidator';
import { t } from 'src/lib/language';
import Subtitle from 'src/components/UI/Subtitle';
import Row from 'src/components/UI/Row';
import Col2 from 'src/components/UI/Col2';
import BottomRow from 'src/components/UI/BottomRow';

interface Props {
  onChange: (productInfo: FundraisingProduct) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  type: FundraisingTypes,
  initialState: FundraisingProduct;
  setHasChanged: (hasChanged: boolean) => void;
}

interface ValidationError extends Error {
  inner: { path: string, message: string[] }[],
}

const FundraisingProductdForm = (props: Props) => {
  const {
    onChange, onNextStep, setErrors, type, initialState, setHasChanged,
  } = props;
  const dispatch = useAppDispatch();
  Debug.log({ onChange, setErrors }, true);

  const products = useSelector((state: Store) => state.products);
  const [productList, setProductList] = useState([] as number[]);
  const [productInfoState, setProductInfoState] = useState(initialState);

  const errors: ErrorBag =
      useSelector((state:Store) => state.errors[FORM.fundraising_product] || {} as ErrorBag);
  const lang = useSelector((state: Store) => state.language.language);
  const getProductById = (id: number) => products.filter((product) => product.id === id)[0];

  const onValueChange = (value: any, field: string) => {
    setHasChanged(true);
    setProductInfoState({
      ...productInfoState,
      [field]: value,
    });
  };

  const onAddProduct = (id: number) => {
    if (!id) return;
    if (productList.includes(id)) return;
    setProductList([...productList, id]);
    onValueChange(productList, 'products');
  };

  const onDragEnd = (dropResult: any) => {
    const { destination, source } = dropResult;
    // dropped outside the list
    if (!destination) return;

    const newProductList = Array.from(productList);
    const [removed] = newProductList.splice(source.index, 1);
    newProductList.splice(destination.index, 0, removed);
    setProductList(newProductList);
    onValueChange(productList, 'products');
  };

  const onRemoveProduct = (index: number) => {
    const newProductList = Array.from(productList);
    newProductList.splice(index, 1);
    setProductList(newProductList);
    onValueChange(productList, 'products');
  };

  const getIcon = (productType: ProductType = ProductType.product) => {
    switch (productType) {
      case ProductType.ticket:
        return (<FaTicketAlt />);
      case ProductType.donation:
        return (<FaHeart />);
      case ProductType.fee:
        return (<FaDollarSign />);
      default:
        return (<FaBoxOpen />);
    }
  };

  const validateProducts = () => {
    let hasTicket = false;
    const messages = [] as string[];
    productList.forEach((id) => {
      const currentProduct = getProductById(id);
      if (currentProduct.type === ProductType.ticket) {
        hasTicket = hasTicket || true;
      }
    });
    if (type === FundraisingTypes.event && !hasTicket) {
      messages.push('Must have at least on ticket when the fundraising type is event');
    }
    if (!!errors.length) {
      throw {
        name: 'Validation Error',
        message: t(lang, 'forms.fundraising.product_validation_errors'),
        inner: [{
          path: 'productList',
          message: messages,
        }],
      } as ValidationError;
    }
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setErrors(TABS.products, false);
    const errorBag = {} as ErrorBag;
    dispatch(setError({ [FORM.fundraising_product]: {} }));

    try {
      validateProducts();
      FundraisingProductValidator().validateSync(productInfoState, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'forms.group.validation_errors',
      }));
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      // eslint-disable-next-line prefer-destructuring
      dispatch(setError({ [FORM.fundraising_product]: errorBag }));
      setErrors(TABS.products, true);
      return;
    }
    onChange(productInfoState);
    onNextStep(4);
  };

  const ListStyle = {
    listStyleType: 'none',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px',
  };

  const ItemStyle = {
    border: '1px solid #c8c8c8',
    borderRadius: '8px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px',
    width: '480px',
    backgroundColor: 'white',
  };

  return (
    <>
      <FormCheckbox
        label={t(lang, 'forms.fundraising.display_single_product')}
        name="display_single_product"
        onChange={onValueChange}
        checked={!!productInfoState.display_single_product} />
      <ChooseProducts
        fundraisingType={type}
        onAdd={onAddProduct} />
      <Subtitle>{t(lang, 'forms.fundraising.products_list')}</Subtitle>
      { (errors.productList && !!errors.productList.length) && (
        <span style={{ color: 'red', fontStyle: 'italic' }}>
          {errors.productList.join(',')}
        </span>
      )}
      <div style={{ width: '75%' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {
                (provided: any) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={ListStyle}>
                    {productList.map((id, index) => {
                      const currentProduct = getProductById(id);
                      return (
                        <Draggable
                          key={`product-${id}`}
                          draggableId={`${id}`}
                          index={index}>
                          {(providedDraggable: any) => (
                            <li
                              style={ItemStyle}
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}>
                              {getIcon(currentProduct.type)}
                              {currentProduct.name_en}
                              <span style={{ marginRight: 0, marginLeft: 'auto' }}>
                                <ActionButton
                                  variant="outlined"
                                  category={MENU_BUTTON_CATEGORY.action}
                                  onClick={() => onRemoveProduct(index)}>
                                  <ClearIcon />
                                </ActionButton>
                              </span>
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )
              }
          </Droppable>
        </DragDropContext>
      </div>
      <Subtitle>{t(lang, 'forms.fundraising.payment')}</Subtitle>
      <div style={{ width: '50%', marginTop: '16px' }}>
        <OrganisationBankAccountSelector
          value={productInfoState.bank_account_id || undefined}
          onChange={(id: number) => onValueChange(id, 'bank_account_id')}
          form={FORM.fundraising_product}
          name="bank_account_id"
          style={{ marginTop: '0px', marginBottom: '8px' }} />
      </div>
      <div style={{ width: '50%' }}>
        <GatewaySelector
          form={FORM.fundraising_product}
          name="gateway_id"
          onChange={(value) => onValueChange(value, 'gateway_id')}
          value={productInfoState.gateway_id}
          noMarginTop />
      </div>
      <div style={{ width: '25%', marginBottom: '16px' }}>
        <FormTextField
          form={FORM.fundraising_product}
          label={t(lang, 'forms.fundraising.cover_fee_percentage')}
          name="cover_fee_percentage"
          onChange={onValueChange}
          value={productInfoState.cover_fee_percentage} />
      </div>
      <FormCheckbox
        label={t(lang, 'forms.fundraising.allow_installment')}
        name="allow_installment"
        onChange={onValueChange}
        checked={!!productInfoState.allow_installment} />
      {!!productInfoState.allow_installment && (
        <div style={{ width: '25%', marginTop: '-16px' }}>
          <FormTextField
            form={FORM.fundraising_product}
            label={t(lang, 'forms.fundraising.max_installment')}
            name="max_installment"
            onChange={onValueChange}
            value={productInfoState.max_installment} />
        </div>
      )}
      <Subtitle>Meta data</Subtitle>
      <Row height="auto">
        <Col2>
          <MetadataSelector
            type={MetadataType.program}
            onChange={(id) => onValueChange(id, INVOICE_FIELDS.program_id)}
            form={FORM.fundraising_product}
            name={INVOICE_FIELDS.program_id}
            value={productInfoState.program_id || undefined}
            noMarginTop />
        </Col2>
        <Col2>
          <MetadataSelector
            type={MetadataType.department}
            onChange={(id) => onValueChange(id, 'department_id')}
            form={FORM.fundraising_product}
            name="department_id"
            value={productInfoState.department_id || undefined}
            noMarginTop />
        </Col2>
      </Row>
      <Row height="auto" style={{ marginBottom: '16px' }}>
        <Col2>
          <MetadataSelector
            type={MetadataType.account}
            onChange={(id) => onValueChange(id, 'account_id')}
            form={FORM.fundraising_product}
            name="account_id"
            value={productInfoState.account_id || undefined}
            noMarginTop />
        </Col2>
        <Col2 />
      </Row>
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          key="submit-button">
          label={t(lang, 'forms.fundraising.next_step')}
        </Button>
      </BottomRow>
    </>
  );
};

export default FundraisingProductdForm;
