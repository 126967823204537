import { keyBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { t } from 'src/lib/language';
import { setAlert } from 'src/redux/actions/alertActions';
import { OrderColumn } from 'src/components/Elements/OrderDisplayer';
import ProductsSelector from 'src/components/Control/ProductsSelector';
import TaxSelector from 'src/components/Control/TaxSelector';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { OrderTaxModel, ProductOrderList, ProductOrderModel } from 'src/models/InvoiceModel';
import { FORM_ID } from 'src/models/FormModel';
import { ProductList, ProductModel } from 'src/models/ProductModel';
import { INVOICE_ORDER_FIELDS, INVOICE_STATE } from 'src/constants/Invoices';
import { ALERT_TYPE } from 'src/constants/AlertType';
import TextLink from 'src/components/UI/TextLink';
import Row from 'src/components/UI/Row';

interface Props {
  canDeleteProduct: boolean,
  form: FORM_ID,
  index: number,
  onDeleteProduct: (index: number) => void,
  onUpdateProduct: (value: Value, field: string, index: number) => void,
  onProductChange: (product?: ProductModel, index?: number) => void,
  order: ProductOrderModel,
  orders: ProductOrderList,
  invoiceState?: INVOICE_STATE,
  disabled?: boolean,
}

const OrderProductItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const {
    canDeleteProduct,
    form,
    index,
    onDeleteProduct,
    onUpdateProduct,
    onProductChange,
    order,
    orders,
    invoiceState,
    disabled,
  } = props;

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const lang = useSelector((state: Store) => state.language.language);
  const products = useSelector((state: Store) => state.products || [] as ProductList);
  const taxes = useSelector((state: Store) => {
    if (!state.taxes) return {};
    const result = {};
    state.taxes.forEach((tax) => {
      if (tax.id) result[tax.id] = tax;
    });
    return result;
  });

  const getProduct = (id:number) => (
    products && products.find((product) => product.id === id)
  );

  const getSelectedTax = () => Object.keys(order.taxes ?? {}).map(Number) as number[];

  const [displayNote, setDisplayNote] = useState(false);
  const [orderItemSubtotal, setOrderItemSubtotal] = useState(0);

  useEffect(() => {
    setOrderItemSubtotal((order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0)));
  }, [order.paid, order.quantity, order.discount]);

  const handleProductChange = (productId: number) => {
    const product = getProduct(productId);
    if (!productId && !product) return;

    if (
      Object.keys(keyBy(orders, 'product_id')).length
      !== Object.values(orders).length
    ) {
      dispatch(setAlert({
        type: ALERT_TYPE.warning,
        code: 'Product already present in order list',
      }));
      return;
    }
    onProductChange(product, index);
  };

  const onDeleteNotes = () => {
    onUpdateProduct('', INVOICE_ORDER_FIELDS.note, index);
    setDisplayNote(false);
  };

  const displayNoteActions = () => {
    if (!displayNote) {
      return (
        <TextLink onClick={() => setDisplayNote(true)}>{t(lang, 'forms.invoices.view_note')}</TextLink>
      );
    }
    return (
      <TextLink onClick={() => onDeleteNotes()}>{t(lang, 'forms.invoices.hide_note')}</TextLink>
    );
  };

  const formatTaxes = (taxesValue: number[]) => {
    const taxesObject = {} as OrderTaxModel;
    taxesValue.forEach((taxId: number) => {
      const subtotal = (order.quantity ?? 0) * ((order.paid ?? 0) - (order.discount ?? 0));
      taxesObject[taxId] = subtotal * taxes[taxId].rate;
    });
    return taxesObject;
  };

  const DeleteButtonRow = (indexRow:number) => {
    if (!canDeleteProduct || disabled) return (<></>);
    return (
      <Row
        style={{
          alignItems: 'center',
          minWidth: OrderColumn.actions,
          width: OrderColumn.actions,
          padding: '0 4px',
        }}>
        <Tooltip
          title="Delete that note"
          placement="top-start">
          <span>
            <ActionButton
              variant="outlined"
              category={MENU_BUTTON_CATEGORY.action}
              onClick={() => onDeleteProduct(indexRow)}
              disabled={!canDeleteProduct || disabled}
              style={{
                marginLeft: '4px',
                marginRight: '8px',
              }}>
              <DeleteIcon /> ####
            </ActionButton>
          </span>
        </Tooltip>
      </Row>
    );
  };

  const Divider = () => (
    <div style={{ width: '1px', height: '20px' }} />
  );

  const getContainerStyle = (rowIndex: number) => ({
    backgroundColor: rowIndex % 2 ? '#f8f8f8' : '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
  } as CSSProperties);

  const getColumnStyle = (column: string) => {
    switch (column) {
      case 'price':
      case 'quantity':
      case 'delete':
        return {
          minWidth: OrderColumn[column],
          width: OrderColumn[column],
          padding: '0 4px',
        } as CSSProperties;
      case 'discount':
      case 'deductible':
        return {
          minWidth: OrderColumn[column],
          width: OrderColumn[column],
        } as CSSProperties;
      case 'product':
        return {
          width: OrderColumn.product,
          padding: '0 4px',
        } as CSSProperties;
      case 'amount':
        return {
          minWidth: OrderColumn.amount,
          width: OrderColumn.amount,
          textAlign: 'right',
        } as CSSProperties;
      default:
        return {} as CSSProperties;
    }
  };

  return (
    <Row style={getContainerStyle(index)}>
      <Row style={{ height: '60px' }}>
        {DeleteButtonRow(index)}
        <Row height="60px" style={getColumnStyle('quantity')}>
          <FormTextField
            form={form}
            required
            name={`${INVOICE_ORDER_FIELDS.quantity}_${index}`}
            onChange={(value) => onUpdateProduct(
              value, INVOICE_ORDER_FIELDS.quantity, index,
            )}
            value={order.quantity ?? 1}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('product')}>
          <ProductsSelector
            size="small"
            form={form}
            name={`${INVOICE_ORDER_FIELDS.product_id}_${index}`}
            onChange={(id) => handleProductChange(id)}
            value={order.product_id}
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('price')}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.price')}
            name={`${INVOICE_ORDER_FIELDS.paid}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.paid, index)}
            value={order.paid}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('taxes')}>
          <TaxSelector
            form={form}
            name={`${INVOICE_ORDER_FIELDS.taxes}_${index}`}
            onChange={(value: any) => onUpdateProduct(
              formatTaxes(value), INVOICE_ORDER_FIELDS.taxes, index,
            )}
            values={getSelectedTax()}
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('discount')}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.discount')}
            name={`${INVOICE_ORDER_FIELDS.discount}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.discount, index)}
            value={order.discount}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('deductible')}>
          <FormTextField
            form={form}
            label={t(lang, 'forms.invoices.deductible')}
            name={`${INVOICE_ORDER_FIELDS.deductible}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.deductible, index)}
            value={order.deductible}
            margin="dense"
            size="small"
            disabled={invoiceState === INVOICE_STATE.final || disabled} />
        </Row>
        <Divider />
        <Row height="60px" style={getColumnStyle('amount')}>
          {formatter.format(orderItemSubtotal)}
        </Row>
      </Row>
      <Row height="60px">
        <span style={{
          marginRight: '4px',
          marginTop: '-8px',
          display: 'flex',
          gap: '8px',
        }}>
          {displayNoteActions()}
        </span>
      </Row>
      { displayNote && (
        <Row height="60px">
          <FormTextField
            form={form}
            label={`${INVOICE_ORDER_FIELDS.note}`}
            multiline
            minRows="4"
            name={`${INVOICE_ORDER_FIELDS.note}_${index}`}
            onChange={(value) => onUpdateProduct(value, INVOICE_ORDER_FIELDS.note, index)}
            value={order.note}
            disabled={disabled} />
        </Row>
      )}
    </Row>
  );
};

export default OrderProductItem;
