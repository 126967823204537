import { CustomfieldsState } from 'src/redux/reducers/CustomfieldsReducer';
import * as Yup from 'yup';

export const CustomfieldValidationSchema = (customfields: CustomfieldsState) => {
  const shape = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [field, customfield] of Object.entries(customfields)) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { type_id, required } = customfield;
    switch (type_id) {
      case 'number':
        shape[field] = Yup.number();
        if (required) {
          shape[field] = shape[field].required('This field is required');
          break;
        }
        break;
      case 'boolean':
        shape[field] = Yup.boolean();
        if (required) {
          shape[field] = shape[field].required('This field is required');
        }
        break;
      case 'date':
        shape[field] = Yup.date();
        if (required) {
          shape[field] = shape[field].required('This field is required');
        }
        break;
      case 'string':
      default:
        shape[field] = Yup.string();
        if (required) {
          shape[field] = shape[field].required('This field is required');
        }
    }
  }
  return Yup.object().shape(shape);
};
