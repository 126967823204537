import { FIELD_TYPES } from 'src/constants/Fields';

const fundraising = [
  {
    field: 'fundraising.id',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'fundraising.name',
    minWidth: 150,
    type: FIELD_TYPES.string,
  },
  {
    field: 'fundraising.type',
    minWidth: 150,
    type: FIELD_TYPES.eventType,
  },
  {
    field: 'fundraising.goal',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'fundraising.raised',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'fundraising.active',
    minWidth: 150,
    type: FIELD_TYPES.number,
  },
  {
    field: 'fundraising.start_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'fundraising.end_date',
    minWidth: 150,
    type: FIELD_TYPES.datetime,
  },
  {
    field: 'fundraising.created_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'fundraising.updated_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
  },
  {
    field: 'fundraising.deleted_at',
    minWidth: 150,
    type: FIELD_TYPES.date,
    hide_in_search: true,
  },
];

export default fundraising;
