import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useState } from 'react';
import { sortBy } from 'lodash';
import OrganisationCard from 'src/components/Elements/OrganisationCard';
import { postOrganisationsUserAction } from 'src/redux/actions/organisationActions';
import { TextField } from '@material-ui/core';
import Row from 'src/components/UI/Row';

interface Props {
  handleClose: () => void,
}

const OrganisationList = (props: Props) => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);

  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const organisationsUserList = useSelector((state: Store) => state.organisationsUser);
  const organisationList = useSelector((state: Store) => state.organisationList);
  const organisations = Object.values(organisationList).filter(
    (organisation) => (
      !organisationsUserList[organisation.id] && !organisation.hide
    ),
  );

  const [items, setItems] = useState(organisations);

  const { handleClose } = props;

  const handleNext = () => {
    let filtered = Object.values(organisations);
    if (currentFilter) {
      filtered = filtered.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = filtered.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(filtered.length > (page + 1) * 20);
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(organisations, (organisation) => organisation[`name_${lang}`]);
    let result = sorted;
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const handleCardClick = (id:string) => {
    dispatch(postOrganisationsUserAction(id));
    handleClose();
  };

  const DisplayAvailableOrganisations = () => {
    const elements = items.map((organisation) => (
      <OrganisationCard
        name={organisation[`name_${lang}`]}
        key={organisation.id}
        onClick={() => handleCardClick(organisation.id)} />
    ));
    return (<>{elements}</>);
  };

  return (
    <>
      <Row height="auto" style={{ marginBottom: '16px' }}>
        <TextField
          margin="dense"
          variant="outlined"
          label="Filter"
          onChange={(e) => handleSearch(e.target.value)} />
      </Row>
      <InfiniteScroll
        dataLength={items.length}
        next={handleNext}
        hasMore={hasMore}
        loader={<Typography>Loading...</Typography>}
        endMessage={<Typography>All items displayed.</Typography>}
        height={590}>
        <DisplayAvailableOrganisations />
      </InfiniteScroll>
    </>
  );
};

export default OrganisationList;
