const not_verified = {
  activate: 'Activatez votre compte',
  sent_email: 'Nous vous avons envoyé un courriel',
  confirmation_email: 'Nous vous avons envoyé un email pour confirmer votre identité. SVP vérifiez vos courriel et cliquer sur lien dans le courriel pour confirmer votre compte.',
  received_email: 'Vous n\'avez pas reçu de courriel?',
  check_spam: 'Vérifiez vos pourriels ou cliquer sur le lien ci-dessus.',
  resend_email: 'Réenvoyer le courriel de confirmation',
  different_account: 'Vous connectez avec un autre compte',
  login: 'Connexion',
};

export default not_verified;
