import { GuessInvoiceModel, GuessInvoicesModel } from 'src/models/InvoiceModel';
import { Reducer } from 'redux';
import { RESET_GUESS_INVOICE, SET_GUESS_INVOICE } from 'src/redux/actions/GuessInvoiceAction';

export type GuessInvoicesState = GuessInvoicesModel;

const DEFAULT = {} as GuessInvoicesState;

const guessInvoiceReducer: Reducer<GuessInvoicesState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_GUESS_INVOICE: {
      const { id, guessInvoice } = action.payload;
      return {
        ...state,
        [id]: {
          ...guessInvoice as GuessInvoiceModel,
        },
      };
    }
    case RESET_GUESS_INVOICE: {
      return DEFAULT;
    }
    default:
      return state;
  }
};

export default guessInvoiceReducer;
