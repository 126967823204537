import React, { useState } from 'react';
import ManageUserPayments from 'src/components/Elements/User/ManageUserPayments';
import UserPageHeader from 'src/components/Elements/User/UserPageHeader';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { useMediaQuery } from 'react-responsive';
import {
  FormControl, FormControlLabel, Radio, RadioGroup,
} from '@material-ui/core';
import UserPaymentSearch from 'src/components/Elements/User/UserPaymentSearch';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import UserDownloadReceiptButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadReceiptButton';
import UserDownloadTaxReceiptButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadTaxReceiptButton';

const useStyles = (isTabletOrMobile:boolean) => makeStyles((theme) => ({
  card: {
    border: '1px solid rgba(0, 0, 0, 0.25)',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: '5px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    flex: 1,
    width: '100%',
    maxWidth: '680px',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
  list: {
    marginTop: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mobile: {
    marginTop: '16px',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  submit: {
    marginLeft: 'auto',
    display: 'flex',
    marginBottom: '8px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    gap: '8px',
    alignItems: 'center',
  },
  col2: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '50%',
    width: '100%',
    alignItems: 'center',
  },
  col3: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '33%',
    width: '100%',
    alignItems: 'center',
  },
  col4: {
    flex: 1,
    flexDirection: 'column',
    flexBasis: isTabletOrMobile ? '100%' : '25%',
    width: '100%',
    alignItems: 'center',
  },
}));

export enum PaymentSections {
  all = 'all',
  scheduled = 'scheduled',
  error = 'error',
  paid = 'paid',
}

const UserPaymentsPage = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const classes = useStyles(isTabletOrMobile)();

  const [section, setSection] = useState(PaymentSections.all);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSection((event.target as HTMLInputElement).value as PaymentSections);
  };

  const showBottomRow = () => (
    <>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px',
          width: '100%',
          margin: '0 auto',
        }}>
          <UserPaymentSearch section={section} />
        </div>
      </div>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px',
          width: '100%',
          margin: '0 auto',
        }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={section}
              onChange={handleChange}>
              <FormControlLabel value={PaymentSections.all} control={<Radio />} label="All" />
              <FormControlLabel
                value={PaymentSections.scheduled}
                control={<Radio />}
                label="Scheduled" />
              <FormControlLabel
                value={PaymentSections.error}
                control={<Radio />}
                label="Need Attention" />
              <FormControlLabel
                value={PaymentSections.paid}
                control={<Radio />}
                label="Paid" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className={classes.row} style={{ backgroundColor: '#FFF' }}>
        <div style={{
          maxWidth: '680px',
          width: '100%',
          margin: '0 auto',
        }}>
          <UserDownloadReceiptButton />&nbsp;
          <UserDownloadTaxReceiptButton />&nbsp;
          <UnselectAllMenubutton entity={ENTITIES.payments} />
        </div>
      </div>
    </>
  );
  return (
    <>
      <UserPageHeader
        title="Payments"
        bottomRow={showBottomRow()} />
      <div style={{ height: '131px' }} />
      <ManageUserPayments section={section} />
    </>
  );
};
export default UserPaymentsPage;
