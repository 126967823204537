const events = {
  id: 'id',
  name: 'Name',
  type: 'Type',
  goal: 'Goal',
  raised: 'Raised',
  active: 'Active',
  start_date: 'Start',
  end_date: 'End',
  created_at: 'Created',
  updated_at: 'Updated',
  deleted_at: 'Deleted',
};

export default events;
