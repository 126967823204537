const bankaccounts = {
  bank: 'Banque #',
  branch: 'Branche',
  account: 'Compte',
  type: 'Type',
  name: 'Nom sur le compte',
  bank_name: 'Nom de la banque',
  validation_errors: 'Erreurs de validation. SVP vérifiez vos donnees.',
};

export default bankaccounts;
