import { Filter } from 'src/models/AdvanceSearchModel';
import { SEARCH_OPERATORS } from 'src/constants/SearchOperators';
import { t } from 'src/lib/language';

export const validateFilters = (filters:Filter[], lang: string) => {
  const errors: string[][] = [];
  let index = 0;
  filters.forEach((filter) => {
    const [field, operator, operands] = filter;
    if (!field) {
      if (!errors[index]) errors[index] = [];
      errors[index][0] = t(lang, 'search.errors.fieldEmpty');
    }
    if (operator === SEARCH_OPERATORS.empty) {
      if (!errors[index]) errors[index] = [];
      errors[index][1] = t(lang, 'search.errors.operatorEmpty');
    }
    const message = t(lang, 'search.errors.canNotBeEmpty');
    switch (operator) {
      case SEARCH_OPERATORS.is_empty:
      case SEARCH_OPERATORS.is_not_empty:
      case SEARCH_OPERATORS.is_not_define:
      case SEARCH_OPERATORS.is_true:
      case SEARCH_OPERATORS.is_false:
        break;
      case SEARCH_OPERATORS.is_between:
      case SEARCH_OPERATORS.is_not_between:

        if (!operands) {
          if (!errors[index]) errors[index] = [];
          errors[index][2] = `${message},${message}`;
        } else {
          if (!operands[0]) {
            if (!errors[index]) errors[index] = [];
            errors[index][2] = message;
          }
          if (!operands[1]) {
            if (!errors[index]) errors[index] = [];
            errors[index][2] = `${errors[index][2]},${message}`;
          }
        }
        break;
      default:
        if (!operands) {
          if (!errors[index]) errors[index] = [];
          errors[index][2] = t(lang, 'search.errors.noOperand');
        }
    }
    index += 1;
  });
  return errors;
};
