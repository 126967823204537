import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { makeStyles } from '@material-ui/core/styles';
import { DIALOGS } from 'src/models/DialogModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setError } from 'src/redux/actions/errorsActions';
import { useEffect, useState } from 'react';
import { closeDialog } from 'src/redux/actions/dialogActions';
import {
  postDownloadEntitesAction,
  postDownloadSelectedEntitesAction,
  postEmailEntitesAction,
  postEmailSelectedEntitesAction,
} from 'src/redux/actions/querybuilderActions';
import { PaymentModel, PAYMENT_STATUS } from 'src/models/PaymentModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '10px',
  },
}));

enum PaymentType {
  all = 'all',
  selected = 'selected',
}

enum PaymentRender {
  payment = 'payment',
  tax = 'tax',
}

const DownloadPaymentDialog = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.paymentDownload]?.opened || false);

  const count =
    useSelector(
      (state: Store) => (state.querybuilder[ENTITIES.payments]
        && (state.querybuilder[ENTITIES.payments].result?.count || 0)),
    );

  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const paymentsStatus = useSelector(
    (state: Store) => selections.map(
      (id: number) => {
        if (!state.payments[id]) return undefined;
        return state.payments[id].payment_status;
      },
    )
    || [] as PaymentModel[],
  );

  const [options, setOptions] = useState({
    file: 'payment_receipt.pdf',
    orientation: ORIENTATION.portrait,
    format: FORMAT.pdf,
    delivery: DELIVERY_TYPE.download,
    paymentReceipt: 1,
  } as DownloadOptionsModel);

  const [type, setType] = useState(PaymentType.selected as PaymentType);
  const [render, setRender] = useState(PaymentRender.tax as PaymentRender);

  useEffect(() => {
    if (render === PaymentRender.tax) {
      setOptions({
        ...options,
        paymentReceipt: null,
      });
    } else {
      setOptions({
        ...options,
        paymentReceipt: 1,
      });
    }
  }, [render]);

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.paymentDownload }));
  };

  const paymentList = [
    PAYMENT_STATUS.paid,
    PAYMENT_STATUS.cancelled,
    PAYMENT_STATUS.refunded,
  ];

  const handleDownload = () => {
    dispatch(setError({ [FORM.download]: {} }));

    const isPaymentTypeValid = paymentsStatus.every(
      (status: PAYMENT_STATUS) => paymentList.includes(status),
    );
    if (!isPaymentTypeValid && type === PaymentType.selected) {
      dispatch(setAlert({
        type: ALERT_TYPE.warning,
        code: t(lang, 'forms.payments.required_paid_payment'),
      }));
      return;
    }

    if (!options.file) {
      dispatch(setError({
        [FORM.download]: {
          file: ['Required'],
        },
      }));
      return;
    }

    if (!options.file) {
      options.file = 'payments';
    }

    if (type === PaymentType.all) {
      dispatch(
        (options.delivery === DELIVERY_TYPE.download)
          ? postDownloadEntitesAction(ENTITIES.taxReceipts, options)
          : postEmailEntitesAction(ENTITIES.taxReceipts, options),
      );
    }

    if (type === PaymentType.selected) {
      dispatch(
        (options.delivery === DELIVERY_TYPE.download)
          ? postDownloadSelectedEntitesAction(
            ENTITIES.taxReceipts,
            selections,
            options,
            'payment_id',
          )
          : postEmailSelectedEntitesAction(
            ENTITIES.taxReceipts,
            selections,
            options,
            'payment_id',
          ),
      );
    }

    dispatch(closeDialog({ dialog: DIALOGS.paymentDownload }));
  };

  const onChange = (value: DownloadOptionsModel) => {
    setOptions(value);
  };

  const onContactFieldChange = (value: Value, field: string) => {
    onChange({
      ...options,
      [field]: value,
    });
  };

  return (
    <Dialog
      aria-labelledby="download-receipts"
      open={opened}>
      <DialogTitle id="download-receipts">Download receipts</DialogTitle>
      <DialogContent dividers>
        <div style={{ width: '480px' }}>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Payment selection</FormLabel>
            <RadioGroup
              row
              aria-label="payments"
              name="payments"
              value={type}
              onChange={(e) => setType(e.target.value as PaymentType)}>
              <FormControlLabel value={PaymentType.all} control={<Radio />} label={`All payments (${count})`} />
              <FormControlLabel value={PaymentType.selected} control={<Radio />} label={`Selected payments (${selections.length})`} />
            </RadioGroup>
          </FormControl>
          <div className={classes.row}>
            <FormTextField
              form={FORM.download}
              label="File Name"
              name="file"
              onChange={onContactFieldChange}
              required
              value={options.file.split('.')[0]} />
            { `.${'pdf'}` }
          </div>
          <FormControl component="fieldset" style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Receipts</FormLabel>
            <RadioGroup
              row
              aria-label="render"
              name="render"
              value={render}
              onChange={(e) => setRender(e.target.value as PaymentRender)}>
              <FormControlLabel
                value={PaymentRender.payment}
                control={<Radio />}
                label={PaymentRender.payment} />
              <FormControlLabel
                value={PaymentRender.tax}
                control={<Radio />}
                label={PaymentRender.tax} />
            </RadioGroup>
          </FormControl>
          <div />
          <div />
          <FormControl
            component="fieldset"
            style={{ marginTop: '8px' }}>
            <FormLabel component="legend">Delivery Type</FormLabel>
            <RadioGroup
              row
              aria-label="Delivery Type"
              name="download"
              value={options.delivery || DELIVERY_TYPE.email}
              onChange={(e) => onContactFieldChange(e.target.value, 'delivery')}>
              <FormControlLabel
                value={DELIVERY_TYPE.email}
                control={<Radio />}
                label="Email Admin" />
              <FormControlLabel
                value={DELIVERY_TYPE.contact}
                control={<Radio />}
                label="Email Contact" />
              { ((count < 1000 && (type === PaymentType.all))
              || (selections.length > 0 && (type === PaymentType.selected))) && (
              <FormControlLabel
                value={DELIVERY_TYPE.download}
                control={<Radio />}
                label="Download" />
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          { t(lang, 'misc.cancel') }
        </Button>
        <Button onClick={handleDownload} color="primary">
          { t(lang, 'misc.download') }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadPaymentDialog;
