import WarningIcon from '@material-ui/icons/Warning';

const Warning = () => (
  <WarningIcon style={{
    color: '#d41d1d',
    marginRight: 0,
    marginLeft: 'auto',
    fontSize: '16px',
  }} />
);

export default Warning;
