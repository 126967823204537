import { t } from 'src/lib/language';
import EditIconSVG from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  onClick: () => void,
}

const useStyles = makeStyles(() => ({
  edit: {
    fontSize: '12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const EditIcon = (props: Props) => {
  const { onClick } = props;
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  return (
    <Tooltip
      title={t(lang, 'menus.edit_invoice_details')}
      placement="top-start">
      <span onClick={() => onClick()} className={classes.edit}>
        <EditIconSVG fontSize="inherit" />
      </span>
    </Tooltip>
  );
};

export default EditIcon;
