const organisation = {
  add_a_tax: 'Add a tax',
  account_type: 'Account Country',
  name_en: 'English Name',
  name_fr: 'French Name',
  legal_name: 'Legal Name',
  is_npo: 'Non profit organisation',
  is_jewish: 'Jewish',
  filter: 'Filter',
  email: 'Email',
  address: 'Address',
  city: 'City',
  suite: 'Suite',
  state: 'State',
  zipCode: 'Zip Code',
  phone: 'Phone',
  extension: 'Extension',
  organisation_type: 'Organisation Types',
  registration_number: 'Registration Number',
  tollfreePhone: 'Toll free phone',
  tollfreeExtension: 'Toll free extention',
  upload_tax_receipt_background: 'Upload tax receipt background',
  tax_receipt_background: 'Tax receipt background',
  timezone: 'Timezone',
  upload_logo: 'Upload logo',
  upload_signature: 'Upload signature',
  validation_errors: 'Validation error(s). Please review your data.',
  user_validation_errors: 'Validation error(s). Please review your data.',
  website: 'Website',
};

export default organisation;
