import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';

export const getBankAccountData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.organisation_bank_accounts}`,
    organisation,
  });

export const getContactBankAccountData =
  (contact_id: number, organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.contact_bank_accounts}/${contact_id}`,
    organisation,
  });
