import {
  GridColumns,
  QuerbuilderPayload,
  QuerbuilderPayloadColumns,
  QuerbuilderPayloadFields,
  QuerbuilderPayloadFilters,
  QuerbuilderPayloadOrder,
  QuerbuilderPayloadPagination,
  QuerbuilderPayloadResult,
  QuerybuilderFields,
  QuerybuilderModels,
  QuerybuilderOrders,
  QuerybuilderPagination,
  QuerybuilderPayloadOptions,
  QuerybuilderResult,
} from 'src/models/QuerybuilderModel';
import { Reducer } from 'redux';
import {
  RESET_FILTERS,
  RESET_QUERY_BUILDER_RESULT,
  SET_COLUMNS,
  SET_FIELDS,
  SET_FILTERS,
  SET_OPTIONS,
  SET_ORDERS,
  SET_PAGINATION,
  SET_QUERY_BUILDER_RESULT,
} from 'src/redux/actions/querybuilderActions';

export type QuerybuilderState = QuerybuilderModels;

const DEFAULT: QuerybuilderState = {} as QuerybuilderState;

const querybuilderReducer: Reducer<QuerybuilderState> = (state = DEFAULT, action) => {
  const { entity } = action.payload as QuerbuilderPayload || {} as QuerbuilderPayload;
  switch (action.type) {
    case RESET_FILTERS: {
      return {
        ...state,
        [entity]: {
          ...state[entity],
          filters: null,
        },
      };
    }
    case SET_FILTERS: {
      const { filters } = action.payload as QuerbuilderPayloadFilters
    || {} as QuerbuilderPayloadFilters;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          filters,
        },
      };
    }
    case SET_FIELDS: {
      const { fields } = action.payload as QuerbuilderPayloadFields ||
      {} as QuerbuilderPayloadFields;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          fields: fields as QuerybuilderFields,
        },
      };
    }
    case SET_ORDERS: {
      const { order } = action.payload as QuerbuilderPayloadOrder ||
      {} as QuerbuilderPayloadOrder;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          order: order as QuerybuilderOrders,
        },
      };
    }
    case SET_PAGINATION: {
      const { pagination } = action.payload as QuerbuilderPayloadPagination ||
      {} as QuerbuilderPayloadPagination;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          pagination: pagination as QuerybuilderPagination,
        },
      };
    }
    case SET_COLUMNS: {
      const { columns } = action.payload as QuerbuilderPayloadColumns;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          columns: [...columns] as GridColumns,
        },
      };
    }
    case SET_OPTIONS: {
      const { options } = action.payload as QuerybuilderPayloadOptions;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          options: { ...options },
        },
      };
    }
    case SET_QUERY_BUILDER_RESULT: {
      const { result } = action.payload as QuerbuilderPayloadResult ||
      {} as QuerbuilderPayloadResult;
      return {
        ...state,
        [entity]: {
          ...state[entity],
          result: result as QuerybuilderResult,
        },
      };
    }
    case RESET_QUERY_BUILDER_RESULT: {
      return {
        ...state,
        [entity]: {
          ...state[entity],
          result: {} as QuerybuilderResult,
        },
      };
    }
    default:
      return state;
  }
};

export default querybuilderReducer;
