import { makeStyles } from '@material-ui/core/styles';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface Props {
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: '8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const FieldVisibilityOff = (props: Props) => {
  const classes = useStyles();
  const { onClick } = props;

  return (
    <VisibilityOffIcon
      className={classes.icon}
      color="action"
      fontSize="small"
      onClick={() => onClick()} />
  );
};

export default FieldVisibilityOff;
