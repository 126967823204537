import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import Typography from '@material-ui/core/Typography';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { DepartmentItemModel } from 'src/models/DepartmentListModel';
import EditDepartmentForm from 'src/components/Forms/EditDepartmentForm';
import AddDepartmentForm from 'src/components/Forms/AddDepartmentForm';
import { getDepartmentsAction } from 'src/redux/actions/dataActions';
import { sortBy } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import Header from 'src/components/UI/Header';
import Scroller from 'src/components/UI/Scroller';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageDepartments = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDepartmentsAction());
  }, []);

  const departments = useSelector((state: Store) => state.data.departments);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageDepartment }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentDepartment, setCurrentDepartment] = useState({} as DepartmentItemModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(Object.values(departments).slice(0, 20));
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(departments).slice(0, 20));
  }, [departments]);

  const handleDelete = (department: DepartmentItemModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteDepartment, payload: department }));
  };

  const handleEdit = (department: DepartmentItemModel) => {
    setCurrentDepartment(department);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentDepartment({} as DepartmentItemModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    const sorted = sortBy(departments, (department: DepartmentItemModel) => department[`name_${lang}`]);
    let result = sorted;
    if (currentFilter) {
      result = sorted.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = result.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(result.length > (page + 1) * 20);
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(departments, (department) => department[`name_${lang}`]);
    let result = sorted;
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_department');
      case STATE.edit:
        return t(lang, 'menus.edit_department');
      default:
        return t(lang, 'menus.list_department');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items.map(
    (department: DepartmentItemModel, index: number) => (
      <Item
        key={`Department-${index}`}
        style={getStyle(index)}>
        <span><b>{ department[`name_${lang}`] }</b></span>
        <EditButton onClick={() => handleEdit(department)} title={t(lang, 'menus.edit_Department')} />
        <DeleteButton onClick={() => handleDelete(department)} title={t(lang, 'menus.delete_Department')} />
      </Item>
    ),
  );

  const ListItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}>
      {getItems()}
    </InfiniteScroll>
  );

  const EditForm = () => (
    <Scroller height="597px">
      <EditDepartmentForm
        department={currentDepartment}
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  const AddForm = () => (
    <Scroller height="597px">
      <AddDepartmentForm
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  return (
    <>
      <Header height="48px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      {currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'menus.add_customfield')}
          onChange={handleSearch} />
      )}
      <div>
        {currentState === STATE.list && <ListItems />}
        {currentState === STATE.add && <AddForm />}
        {currentState === STATE.edit && <EditForm />}
      </div>
    </>
  );
};

export default ManageDepartments;
