import { ENTITIES, GridColumns, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import { makeStyles } from '@material-ui/core/styles';
import ContactToolbar from 'src/components/DatagridToolbar/entities/ContactToolbar';
import InvoiceToolbar from 'src/components/DatagridToolbar/entities/InvoiceToolbar';
import PaymentToolbar from 'src/components/DatagridToolbar/entities/PaymentToolbar';
import EventToolbar from 'src/components/DatagridToolbar/entities/EventToolbar';
import { Theme } from '@material-ui/core';
import PresetToolbar from 'src/components/DatagridToolbar/PresetToolbar';
import { PRESET_TYPES } from 'src/models/PresetModel';
import { setColumns } from 'src/redux/actions/querybuilderActions';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useMediaQuery } from 'react-responsive';
import { FIELD_TYPES } from 'src/constants/Fields';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { setSelectedPresets } from 'src/redux/actions/selectedPresetActions';

const useStyles = makeStyles((theme: Theme) => ({
  actionsToolbar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderTop: 'none',
    border: '1px solid #eee',
    padding: theme.spacing(1),
    backgroundColor: '#f8f8f8',
    gap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface Props {
  entity: ENTITIES,
}

export const DatagridToolbar = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { entity } = props;

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });

  const customfields = useSelector((state: Store) => state.customfields);

  const { defaultPresetColumns } = useSelector(
    (state: Store) => {
      const result = { defaultPresetColumns: null, defaultPresetId: 0 };
      const type = PRESET_TYPES.layout;
      if (!state.presets[entity]) return result;
      if (!state.presets[entity][type]) return result;
      const defaultPreset = state.presets[entity][type].find(
        (preset) => preset.is_default,
      );
      if (defaultPreset) {
        return {
          defaultPresetColumns: defaultPreset.filter,
          defaultPresetId: defaultPreset.id,
        };
      }
      return result;
    },
  );

  const getColumns = () => {
    switch (entity) {
      case ENTITIES.contacts: {
        const customfieldColumns = [] as GridColumns;
        if (defaultPresetColumns) {
          return defaultPresetColumns;
        }

        Object.values(customfields).forEach((customfield:any) => {
          customfieldColumns.push({
            field: `custom_fields.${customfield.name}`,
            minWidth: 150,
            type: FIELD_TYPES.string,
          });
        });
        return [
          ...DEFAULT_COLUMNS.contacts,
          ...customfieldColumns,
        ];
      }
      default: {
        return DEFAULT_COLUMNS[entity];
      }
    }
  };

  const resetLayout = () => {
    dispatch(setColumns({
      entity,
      columns: getColumns(),
    } as QuerbuilderPayloadColumns));
    dispatch(setSelectedPresets({
      entity,
      type: PRESET_TYPES.layout,
      id: 0,
    }));
  };

  const displayActionMenu = () => {
    switch (entity) {
      case ENTITIES.contacts:
        return (<ContactToolbar />);
      case ENTITIES.invoices:
        return (<InvoiceToolbar />);
      case ENTITIES.payments:
        return (<PaymentToolbar />);
      case ENTITIES.events:
        return (<EventToolbar />);
      default:
        return (<></>);
    }
  };

  return (
    <div
      className={classes.actionsToolbar}
      style={{
        flexDirection: isTabletOrMobile ? 'column-reverse' : 'row',
      }}>
      <div style={{ width: isTabletOrMobile ? '100%' : '50%' }}>
        <PresetToolbar
          entity={entity}
          type={PRESET_TYPES.layout}
          onReset={resetLayout} />
      </div>
      <div style={{ width: isTabletOrMobile ? '100%' : '50%' }}>
        <span
          style={{
            marginLeft: 'auto',
            marginRight: isTabletOrMobile ? 'auto' : 0,
            flexDirection: isTabletOrMobile ? 'row' : 'row-reverse',
          }}
          className={classes.buttons}>
          {displayActionMenu()}
        </span>
      </div>
    </div>
  );
};

export default DatagridToolbar;
