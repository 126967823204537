const creditcard = {
  field_required: 'Ce champ est requis',
  validation_errors: 'Erreur de validation, vérifié vos données',
  exact_digit_2: 'Doit être exactement 2 chiffes',
  exact_digit_3: 'Doit être exactement 3 chiffes',
  exact_digit_5: 'Doit être exactement 5 chiffes',
  exact_digit_12: 'Doit être 12 ou 19 chiffes',
  between_5_20_digit: 'Doit etre 5 and 20 chiffres',
  only_digit: 'Ne doit contenir que des nombres',
  year_must_be_future: 'L\'année d\'expiration doit être dans le futur',
};

export default creditcard;
