import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { getSettingsData, postSettingData } from 'src/apis/SettingAPI';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { SettingModel } from 'src/models/SettingListModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setError } from 'src/redux/actions/errorsActions';

export const SET_SETTINGS = 'SET_SETTINGS';
export const RESET_SETTINGS = 'RESET_SETTINGS';

export const setSettingsData = makeActionCreator(SET_SETTINGS);
export const resetSettingsData = makeActionCreator(RESET_SETTINGS);

export const getSettingsAction: () => ThunkedAction<State> =
() => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_settings]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getSettingsData(organisation);
    if (response.success) {
      const { data } = response;
      dispatch(setSettingsData({ data }));
      dispatch(setFormStatus({ [FORM.get_settings]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_settings]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_settings]: FORM_STATUS.error }));
  }
};

export const postSettingsAction: (setting:SettingModel) => ThunkedAction<State> =
  (setting:SettingModel) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.post_setting]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postSettingData(organisation, setting);
      if (response.success) {
        dispatch(getSettingsAction());
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.setting_saved',
        }));
        dispatch(setFormStatus({ [FORM.post_setting]: FORM_STATUS.success }));
        return;
      }
      if (response.status === 400) {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.setting_saved_error',
        }));
        dispatch(setError({ [FORM.post_setting]: response.errors }));
        dispatch(setFormStatus({ [FORM.post_setting]: FORM_STATUS.error }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors).join('\n'),
      }));
      dispatch(setFormStatus({ [FORM.post_setting]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.setting_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.post_setting]: FORM_STATUS.error }));
    }
  };

export const resetSettingsAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      dispatch(resetSettingsData());
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.get_settings]: FORM_STATUS.error }));
    }
  };
