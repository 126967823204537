import { ContactGroupModel } from 'src/models/ContactGroupModel';
import { MouseEvent, useState } from 'react';
import {
  Chip, Menu, MenuItem, Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';

interface Props {
  disabled?: boolean;
  groups: ContactGroupModel;
  values: number[];
  onChange: (id:number) => void;
}
const ChipGroupSelector = (props: Props) => {
  const {
    disabled, groups, onChange, values,
  } = props;

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorElement(null);
  };

  const handleItemClick = (id:string) => {
    onChange(Number(id));
    closeMenu();
  };

  const displayItem = () => Object.keys(groups).map((id: string) => {
    const group = groups[id];
    if (values.indexOf(Number(id)) === -1) {
      return (
        <MenuItem
          key={`group-menu-item-${id}`}
          value={group.id}
          onClick={() => handleItemClick(group.id)}>
          { group[`name_${lang}`] }
        </MenuItem>
      );
    }
    return null;
  });

  return (
    <>
      <Tooltip
        title={t(lang, 'misc.add_contact_to_group')}
        placement="top-start"
        key="chip-group-add">
        <Chip
          color="primary"
          label="Add"
          disabled={disabled}
          onClick={openMenu}
          icon={<AddIcon />} />
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        id="customized-menu"
        getContentAnchorEl={null}
        keepMounted
        onClose={() => closeMenu()}
        open={!!anchorElement}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        key="chip-group-add-menu">
        {displayItem()}
      </Menu>
    </>
  );
};

export default ChipGroupSelector;
