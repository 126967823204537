import palette from 'src/styles/palette';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
}

const Title = (props: Props) => {
  const {
    children,
  } = props;

  const styles: CSSProperties = {
    color: palette.primary.contrastText,
    backgroundColor: palette.primary.light,
    fontSize: '18px',
  };
  return (
    <div style={styles}>
      <b>{children}</b>
    </div>
  );
};

export default Title;
