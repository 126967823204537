import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';

import { CreditCardModel } from 'src/models/CreditCardModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { MODALS } from 'src/models/ModalModel';
import { DIALOGS } from 'src/models/DialogModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { openModal } from 'src/redux/actions/modalActions';
import { setCurrentEntity } from 'src/redux/actions/currentEntityActions';
import { getContactCreditCardAction } from 'src/redux/actions/paymentInfoAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ItalicTextAccent from 'src/components/UI/ItalicTextAccent';

interface Props {
  contactId: number;
}

const ContactCreditCardDisplayer = (props: Props) => {
  const { contactId } = props;
  const dispatch = useAppDispatch();

  const creditCards = useSelector(
    (state: Store) => {
      if (!!state.contactCreditcards[contactId]) {
        return state.contactCreditcards[contactId] as CreditCardModel[];
      }
      return [] as CreditCardModel[];
    },
  );

  useEffect(() => {
    dispatch(
      getContactCreditCardAction(contactId),
    );
  }, []);

  const handleDeleteCreditCard = (id: number) => {
    dispatch(setCurrentEntity({ entity: ENTITIES.creditCards, id }));
    dispatch(openDialog({ dialog: DIALOGS.deleteCreditCard }));
  };

  const handleEditCreditCard = (creditCard: CreditCardModel) => {
    dispatch(openModal({
      modal: MODALS.editCreditCard,
      payload: {
        contactId,
        creditCard,
      },
    }));
  };

  const displayCreditCard = () => creditCards.map((card:CreditCardModel, index: number) => (
    <ItalicTextAccent key={`credit-card-${index}`}>
      <span>{card.masked_card_number} exp: {card.expiration_data}</span>
      <DeleteIcon onClick={() => handleDeleteCreditCard(card.id)} />
      <EditIcon onClick={() => handleEditCreditCard(card)} />
    </ItalicTextAccent>
  ));

  return (
    <>{displayCreditCard()}</>
  );
};

export default ContactCreditCardDisplayer;
