import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import UndoOutlinedIcon from '@material-ui/icons/UndoOutlined';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { PAYMENT_STATUS, PAYMENT_TYPE, PaymentModel } from 'src/models/PaymentModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { t } from 'src/lib/language';

interface OwnProps {
  paymentId: number;
}

type Props = OwnProps & ButtonProps;

const RefundPaymentButton = (props: Props) => {
  const dispatch = useAppDispatch();

  const { disabled, paymentId } = props;
  const lang = useSelector((state: Store) => state.language.language);

  const payment = useSelector(
    (state: Store) => state.payments[paymentId] ?? {} as PaymentModel,
  );

  const isOfflinePayment = () => {
    if (![PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(payment.payment_type)) {
      return true;
    }
    return false;
  };

  const containUnpaidPayment = () => {
    if (payment.payment_status !== PAYMENT_STATUS.paid) {
      return true;
    }
    return false;
  };

  const isDisabled = () => (
    disabled || isOfflinePayment() || containUnpaidPayment()
  );

  const handleOpen = () => {
    dispatch(openDialog({
      dialog: DIALOGS.refundPayments,
      payload: paymentId,
    }));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.refund_payment')}
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={handleOpen}
            disabled={isDisabled()}>
            <UndoOutlinedIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default RefundPaymentButton;
