import { Reducer } from 'redux';
import { SET_USER_CONTACT } from 'src/redux/actions/contactActions';
import ContactModel from 'src/models/ContactModel';

export type UserContactState = ContactModel;
const DEFAULT_CONTACT: UserContactState = {} as UserContactState;
const UserContactReducer: Reducer<UserContactState> = (state = DEFAULT_CONTACT, action) => {
  switch (action.type) {
    case SET_USER_CONTACT:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default UserContactReducer;
