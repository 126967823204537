import { GatewayList } from 'src/models/GatewayModel';
import { Reducer } from 'redux';
import { SET_GATEWAY_LIST } from 'src/redux/actions/gatewayAction';

export type GatewaysState = GatewayList;
const DEFAULT_GATEWAY: GatewaysState = [] as GatewaysState;
const gatewaysReducer: Reducer<GatewaysState> = (state = DEFAULT_GATEWAY, action) => {
  switch (action.type) {
    case SET_GATEWAY_LIST:
      return [
        ...action.payload,
      ];

    default:
      return state;
  }
};

export default gatewaysReducer;
