import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import EditPaymentButton from 'src/components/DatagridToolbar/buttons/EditPaymentButton';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import CancelPaymentButton from 'src/components/DatagridToolbar/buttons/CancelPaymentButton';
import RefundPaymentButton from 'src/components/DatagridToolbar/buttons/RefundPaymentButton';
import ExportPaymentButton from 'src/components/DatagridToolbar/buttons/ExportPaymentButton';
import DownloadPaymentButton from 'src/components/DatagridToolbar/buttons/DownloadPaymentButton';
import ConfigureColumnsButton from 'src/components/DatagridToolbar/buttons/ConfigureColumnsButton';
import ShowInvoicesForPaymentButton
  from 'src/components/DatagridToolbar/buttons/ShowInvoicesForPaymentButton';

const PaymentToolbar = () => {
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.payments] || [],
  );

  const getPaymentId = () => {
    if (!selections.length) return 0;
    return selections[0];
  };

  return (
    <>
      <EditPaymentButton
        disabled={!selections.length} />
      <RefundPaymentButton
        disabled={selections.length !== 1}
        paymentId={getPaymentId()} />
      <CancelPaymentButton
        disabled={!selections.length} />
      <ConfigureColumnsButton
        entity={ENTITIES.payments} />
      <UnselectAllMenubutton
        entity={ENTITIES.payments}
        disabled={!selections.length} />
      <ShowInvoicesForPaymentButton
        paymentId={selections[0]}
        disabled={selections.length !== 1} />
      <ExportPaymentButton />
      <DownloadPaymentButton />
    </>
  );
};

export default PaymentToolbar;
