import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { Store } from 'src/redux/Store';
import Copyright from 'src/components/UI/Copyright';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import { t } from 'src/lib/language';
import version from 'src/version.json';

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#CFCFCF',
    paddingTop: '16px',
    display: 'block',
    width: '100%',
    textAlign: 'center',
  },
}));

const Footer = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const lang = useSelector((state: Store) => state.language.language);
  const classes = useStyles();
  const {
    legalName,
    city,
    state,
    address,
    suite,
    zipCode,
    phone,
    extension,
    tollfreePhone,
    tollfreeExtension,
    email,
  } = useSelector(
    (store: Store) => store.organisation,
  );

  const displayAddress = () => {
    if (isTabletOrMobile) return '';
    return (
      <>
        { address } { suite }, { city }, { state }, { zipCode }<br />
        {t(lang, 'forms.organisation.phone')}: { phone } { extension }&nbsp;|&nbsp;
        {t(lang, 'forms.organisation.tollfreePhone')}: { tollfreePhone } { tollfreeExtension }
        &nbsp;|&nbsp;
        {t(lang, 'forms.organisation.email')}: { email }<br />
      </>
    );
  };

  const displayVersion = () => {
    const vers = version.version.split('.');
    return `v${vers[0]}.${vers[1]}.${vers[2]} build: ${vers[3]}`;
  };

  return (
    <div className={classes.footer}>
      <Typography variant="body2" color="textSecondary" align="center">
        <b>{ legalName }</b><br />
        {displayAddress()}
      </Typography>
      <Copyright />
      <span style={{ fontSize: '10px', color: 'rgba(0,0,0,0.5)' }}>{displayVersion()}</span>
    </div>
  );
};

export default Footer;
