export interface CustomFieldsPayload {
  name: string, // Name of the field that will be use in the custom fields section of contact
  display: {
    en_CA: string,
    fr_CA: string,
  },
  list: string,
  list_type: CustomFieldListType,
  type_id: CustomFieldType,
  required: boolean,
}

export enum CustomFieldType {
  boolean = 'boolean',
  date = 'date',
  multipleList = 'multiple-list',
  number = 'number',
  singleList = 'single-list',
  string = 'string',
}

export enum CustomFieldListType {
  multiple_list = 'multiple-list',
  single_list = 'single-list',
}

export interface Customfield {
  name?: string;
  id: number,
  display_en: string,
  display_fr: string,
  list?: string,
  list_type_id?: CustomFieldType,
  type_id: CustomFieldType,
  required: boolean,
}
