import { SEARCH_OPERATORS } from 'src/constants/SearchOperators';

const operators = {
  [SEARCH_OPERATORS.contain]: 'CONTIENT',
  [SEARCH_OPERATORS.not_contain]: 'NE CONTIENT PAR',
  [SEARCH_OPERATORS.start_with]: 'COMMENCE PAR',
  [SEARCH_OPERATORS.end_with]: 'SE TERMINE PAR',
  [SEARCH_OPERATORS.is_between]: 'ENTRE',
  [SEARCH_OPERATORS.is_not_between]: 'N\'EST PAS ENTRE',
  [SEARCH_OPERATORS.is_after]: 'EST APRES',
  [SEARCH_OPERATORS.is_before]: 'EST AVANT',
  [SEARCH_OPERATORS.is_equal]: 'EST EGALE A',
  [SEARCH_OPERATORS.is_not_equal]: 'N\'EST PAS EGALE A',
  [SEARCH_OPERATORS.is_greater]: 'EST PLUS GRAND QUE',
  [SEARCH_OPERATORS.is_less]: 'EST PLUS PETIT QUE',
  [SEARCH_OPERATORS.is_empty]: 'EST VIDE',
  [SEARCH_OPERATORS.is_not_empty]: 'N\'EST PAS VIDE',
  [SEARCH_OPERATORS.is_not_define]: 'N\'EST PAS DEFINI',
  [SEARCH_OPERATORS.is_true]: 'EST VRAI',
  [SEARCH_OPERATORS.is_false]: 'EST FAUX',
  [SEARCH_OPERATORS.is_in]: 'EST DANS',
  [SEARCH_OPERATORS.is_not_in]: 'N\'EST PAS DANS',
};

export default operators;
