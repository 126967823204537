import { useMediaQuery } from 'react-responsive';
import { Paper } from '@material-ui/core';

interface Props {
  children: any,
}

const PageBackground = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const { children } = props;

  if (isTabletOrMobile) {
    return (
      <div style={{
        padding: '32px',
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      }}>
        { children }
      </div>
    );
  }
  return (
    <Paper style={{
      width: '100%',
      minHeight: '825px',
      padding: '32px',
      backgroundColor: '#FFF',
    }}>
      { children }
    </Paper>
  );
};

export default PageBackground;
