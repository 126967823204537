import { AdminModel } from 'src/models/UserModel';
import { Reducer } from 'redux';
import { SET_ADMIN_LIST } from 'src/redux/actions/userActions';

export type AdminListState = AdminModel[];
const DEFAULT: AdminListState = [] as AdminListState;

const adminsReducer: Reducer<AdminListState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ADMIN_LIST:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export default adminsReducer;
