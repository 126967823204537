import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { TimeZoneListModel } from 'src/models/TimeZoneListModel';
import { useEffect } from 'react';
import { getTimeZoneAction } from 'src/redux/actions/dataActions';
import Row from 'src/components/UI/Row';

const OrganisationInfo = () => {
  const dispatch = useAppDispatch();

  const organisation = useSelector((state: Store) => state.organisation);
  const timezones = useSelector((store: Store) => store.data.timezones || [] as TimeZoneListModel);

  useEffect(() => {
    dispatch(getTimeZoneAction());
  }, []);

  const getTimeZone = () => {
    const result = timezones[Number(organisation.timezoneId)];
    return result ?? 'Invalid Timezone';
  };

  return (
    <Row style={{ width: '640px', marginTop: '16px' }}>
      <div style={{ fontSize: '16px', marginBottom: '8px' }}><b>{organisation.legalName}</b></div>
      <div><b>ID: </b>{organisation.id}</div>
      <div><b>Name: </b><i>{organisation.nameEn} / {organisation.nameFr}</i></div>
      <div><b>Legal Name: </b>{organisation.legalName}</div>
      <div><b>Registration #: </b>{organisation.registrationNumber}</div>
      <div><b>Type: </b>{organisation.type || 'Not set'}</div>
      <div><b>Timezone: </b>{getTimeZone()}</div>
    </Row>
  );
};

export default OrganisationInfo;
