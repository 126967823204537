import { API_URL } from 'src/constants/Endpoints';
import makeApiRequest from 'src/lib/makeApiRequest';
import { UserModel } from 'src/models/UserModel';
import { trim } from 'lodash';
import { RIGHT } from 'src/components/Elements/ManageUserAdmins';
import { UIType } from 'src/constants/UIType';
import { SOCIAL_PROVIDER } from 'src/constants/Social';

export interface LoginData {
  email: string,
  password: string,
  remember?: boolean,
}

export interface RegisterData {
  email: string,
  password: string,
  password_confirmation: string,
  locale_id: string,
}

export interface ResetPasswordData {
  token: string | string[],
  email: string | string[],
  password: string,
  password_confirmation: string,
}

export interface ChangePasswordData {
  new_password: string,
  new_password_confirmation: string,
  password: string,
}

export interface ChangeEmailData {
  email: string,
  password: string,
}

export const login = (loginData:LoginData) => makeApiRequest({
  method: 'POST',
  url: API_URL.login,
  data: loginData,
  organisation: 'aic',
});

export const register = (registerData:RegisterData) => makeApiRequest({
  method: 'POST',
  url: API_URL.register,
  data: registerData,
  organisation: 'aic',
});

export const changeEmail = (data:ChangeEmailData) => makeApiRequest({
  method: 'PUT',
  url: API_URL.changeEmail,
  data,
  organisation: 'aic',
});

export const changePassword = (data:ChangePasswordData) => makeApiRequest({
  method: 'PUT',
  url: API_URL.changePassword,
  data,
  organisation: 'aic',
});

export const socialLoginData = (provider: SOCIAL_PROVIDER, token: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.socialLogin}/${provider}/login/${token}`,
  organisation: 'aic',
});

export const resendVerification = () => makeApiRequest({
  method: 'GET',
  url: API_URL.user_resend_verification,
  organisation: 'aic',
});

export const getUserData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: API_URL.user,
  organisation,
});

export const getUserByIdData = (id: number) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.user}/${id}`,
  organisation: 'aic',
});

export const postUserSearchData = (organisation: string, query: string) => makeApiRequest({
  method: 'POST',
  url: API_URL.userSearch,
  organisation,
  data: {
    query: trim(query),
  },
});

export const getAdminsData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: API_URL.admins,
  organisation,
});

export const putAdminsData = (
  organisation: string,
  userId: number,
  right: RIGHT,
) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.adminRight}/${userId}/${right}`,
  organisation,
});

export const getCreateAdminData = (
  organisation: string,
  email: string,
  locale_id: string,
) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.adminCreate}/${email}`,
  organisation,
  data: { locale_id },
});

export const normalizeUserResponseData = (data:any):UserModel => (
  {
    id: data.id,
    localeId: data.locale_id,
    email: data.email,
    verified: data.verified,
    has_contact: data.has_contact || false,
    is_admin: !!data.is_admin || false,
    is_aic_admin: !!data.is_aic_admin || false,
    ui: !!data.is_admin ? UIType.admin : UIType.user,
  }
);

export const putUserLocale = (locale: string) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.user_locale}/${locale}`,
  organisation: 'aic',
});

export const getVerifyUser = (queryString: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.user_verify}${queryString}`,
  organisation: 'aic',
});

export const postRequestResetPassword = (email: string) => makeApiRequest({
  method: 'POST',
  url: API_URL.request_reset_password,
  organisation: 'aic',
  data: { email },
});

export const postResetPassword =
  (passwordData: ResetPasswordData, queryString: string) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.reset_password}${queryString}`,
    organisation: 'aic',
    data: passwordData,
  });

export const postUserExists =
  (email: string) => makeApiRequest({
    method: 'POST',
    url: API_URL.user_exists,
    organisation: 'aic',
    data: { email },
  });
