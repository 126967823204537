import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import UnselectAllMenubutton from 'src/components/DatagridToolbar/buttons/UnselectAllMenuButton';
import DeleteContactButton from 'src/components/DatagridToolbar/buttons/DeleteContactButton';
import SelectAllMenubutton from 'src/components/DatagridToolbar/buttons/SelectAllMenuButton';
import ConfigureColumnsButton from 'src/components/DatagridToolbar/buttons/ConfigureColumnsButton';
import AddEventButton from 'src/components/DatagridToolbar/buttons/AddEventButton';
import EditEventButton from 'src/components/DatagridToolbar/buttons/EditEventButton';

const EventToolbar = () => {
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.contacts] || [],
  );

  return (
    <>
      <AddEventButton />
      <EditEventButton
        disabled={!selections.length} />
      <DeleteContactButton
        disabled={!selections.length} />
      <ConfigureColumnsButton
        entity={ENTITIES.events} />
      <UnselectAllMenubutton
        entity={ENTITIES.events}
        disabled={!selections.length} />
      <SelectAllMenubutton
        entity={ENTITIES.events} />
    </>
  );
};
export default EventToolbar;
//
