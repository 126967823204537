import { Reducer } from 'redux';
import { CurrentOrganisationModel } from 'src/models/OrganisationModel';
import { SET_CURRENT_ORGANISATION } from 'src/redux/actions/organisationActions';

export type CurrentOrganisationState = CurrentOrganisationModel;

const DEFAULT: CurrentOrganisationState = { } as CurrentOrganisationModel;

const currentOrganisationReducer: Reducer<CurrentOrganisationState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_CURRENT_ORGANISATION:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default currentOrganisationReducer;
