import contacts from 'src/constants/columns/contacts';
import invoices from 'src/constants/columns/invoices';
import payments from 'src/constants/columns/payments';
import invoicesContact from 'src/constants/columns/invoicesContact';
import fundraising from 'src/constants/columns/fundraising';
import paymentsForInvoice from 'src/constants/columns/paymentsForInvoice';
import invoicesForPayment from 'src/constants/columns/invoicesForPayment';

const DEFAULT_COLUMNS = {
  contacts,
  invoices,
  invoicesContact,
  payments,
  events: fundraising,
  paymentsForInvoice,
  invoicesForPayment,
};

export default DEFAULT_COLUMNS;
