import { Reducer } from 'redux';
import { OrganisationModel } from 'src/models/OrganisationModel';
import { SET_ORGANISATION_DATA } from 'src/redux/actions/organisationActions';

export type OrganisationState = OrganisationModel;

const DEFAULT: OrganisationState = { } as OrganisationModel;

const organisationReducer: Reducer<OrganisationState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ORGANISATION_DATA:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default organisationReducer;
