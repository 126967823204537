import makeApiRequest, { DownloadOption } from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { ENTITIES, QuerybuilderModel } from 'src/models/QuerybuilderModel';
import { DELIVERY_TYPE, DownloadOptionsModel } from 'src/models/DownloadModel';

export const getEntitiesData =
  (organisation: string, entity: ENTITIES, query:QuerybuilderModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.querybuilder}${entity}?debug=1`,
    data: query,
    organisation: organisation || 'aic',
  });

export const postDownloadEntitesData =
  (organisation: string,
    entity: ENTITIES,
    query:QuerybuilderModel,
    options: DownloadOptionsModel,
    downloadOption?: DownloadOption) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.querybuilder}${entity}?delivery=${DELIVERY_TYPE.download}`,
    data: query,
    organisation: organisation || 'aic',
  },
  downloadOption,
  options.file);

export const postEmailEntitiesData =
  (organisation: string,
    entity: ENTITIES,
    query:QuerybuilderModel,
    options: DownloadOptionsModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.querybuilder}${entity}?delivery=${options.delivery}`,
    data: query,
    organisation: organisation || 'aic',
  });
