import { SEARCH_OPERATORS } from 'src/constants/SearchOperators';

const operators = {
  [SEARCH_OPERATORS.contain]: 'CONTAIN',
  [SEARCH_OPERATORS.not_contain]: 'DON\'T CONTAIN',
  [SEARCH_OPERATORS.start_with]: 'START WITH',
  [SEARCH_OPERATORS.end_with]: 'END WITH',
  [SEARCH_OPERATORS.is_between]: 'BETWEEN',
  [SEARCH_OPERATORS.is_not_between]: 'NOT BETWEEN',
  [SEARCH_OPERATORS.is_after]: 'IS AFTER',
  [SEARCH_OPERATORS.is_before]: 'IS BEFORE',
  [SEARCH_OPERATORS.is_equal]: 'IS EQUAL',
  [SEARCH_OPERATORS.is_not_equal]: 'IS NOT EQUAL',
  [SEARCH_OPERATORS.is_greater]: 'IS GREATER THAN',
  [SEARCH_OPERATORS.is_less]: 'IS LESS THAN',
  [SEARCH_OPERATORS.is_empty]: 'IS EMPTY',
  [SEARCH_OPERATORS.is_not_empty]: 'IS NOT EMPTY',
  [SEARCH_OPERATORS.is_not_define]: 'IS NOT DEFINE',
  [SEARCH_OPERATORS.is_true]: 'IS TRUE',
  [SEARCH_OPERATORS.is_false]: 'IS FALSE',
  [SEARCH_OPERATORS.is_in]: 'IS IN',
  [SEARCH_OPERATORS.is_not_in]: 'IS NOT IN',
};

export default operators;
