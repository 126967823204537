import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';

import DeleteIcon from '@material-ui/icons/Delete';
import Subheader from 'src/components/UI/Subheader';
import OrganisationsForm from 'src/components/Forms/OrganisationsForm';
import Line from 'src/components/UI/Line';
import HelpBox from 'src/components/UI/HelpBox';
import { t } from 'src/lib/language';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  warning: {
    border: `solid 1px ${theme.palette.warning.main}`,
    borderRadius: '5px',
    maxWidth: '640px',
    padding: theme.spacing(2),
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
    marginBottom: theme.spacing(2),
    color: theme.palette.warning.main,
  },
}));

const OrganisationsPage = () => {
  const classes = useStyles();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const hasChooseOrganization = useSelector((state: Store) => !!state.organisationsUser);

  const displayWarning = () => {
    if (!hasChooseOrganization) {
      return (
        <div className={classes.warning}>
          You must connect with an organisation before continuing
        </div>
      );
    }
    return (<></>);
  };
  return (
    <>
      <Subheader id="organisations">
        {t(lang, 'pages.profile.organisations')}
      </Subheader>
      {displayWarning()}
      <HelpBox>
        <Line>{t(lang, 'pages.profile.help_1')}</Line>
        <Line>
          {t(lang, 'pages.profile.help_2')}<DeleteIcon fontSize="small" />
          {t(lang, 'pages.profile.help_3')}
        </Line>
        <Line>{t(lang, 'pages.profile.help_4')}</Line>
      </HelpBox>
      <OrganisationsForm />
    </>
  );
};

export default OrganisationsPage;
