import { Reducer } from 'redux';
import { FundraisingList } from 'src/models/FundraisingModel';
import { RESET_FUNDRAISING_LIST, SET_FUNDRAISING_LIST } from 'src/redux/actions/fundraisingAction';

export type FundraisingListState = FundraisingList;

const DEFAULT = [] as FundraisingListState;

const FundraisingsReducer: Reducer<FundraisingListState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_FUNDRAISING_LIST: {
      const { data } = action.payload;
      return [...data];
    }
    case RESET_FUNDRAISING_LIST: {
      return [];
    }
    default:
      return state;
  }
};

export default FundraisingsReducer;
