const profile = {
  has_contact: 'Please add your contact information before continuing.',
  has_choose_organization: 'Please choose an organisation before continuing',
  edit_contact_info: 'Edit your contact informations',
  edit_organisations_info: 'Edit your organisations',
  contact_info: 'Contact information',
  organisations: 'Your organisations',
  help_1: 'Click the Add Organisation button to add an organisation to the list.',
  help_2: 'Click on the ',
  help_3: ' to remove it from your organisations list.',
  help_4: 'Click the checkbox to share your contact information with that organisation.',
};

export default profile;
