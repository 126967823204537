const customfields = {
  display_en: 'English name',
  display_fr: 'French name',
  type: 'Type of the fields',
  list: 'Values of the list',
  required: 'Required',
  boolean: 'Boolean',
  date: 'Date',
  'multiple-list': 'Multiple selection dropdown',
  number: 'Number',
  'single-list': 'Single selection dropdown',
  string: 'Text',
};

export default customfields;
