import contacts from 'src/i18n/fr/fields/contacts';
import contact_groups from 'src/i18n/fr/fields/contact_groups';
import invoices from 'src/i18n/fr/fields/invoices';
import payments from 'src/i18n/fr/fields/payments';
import fundraising from 'src/i18n/fr/fields/fundraising';

const fields = {
  contacts,
  contact_groups,
  invoices,
  payments,
  fundraising,
};

export default fields;
