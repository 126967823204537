import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import { getContactCreditCardData } from 'src/apis/CreditCardAPI';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';

export const SET_CONTACT_CREDIT_CARDS = 'SET_CONTACT_CREDIT_CARDS';

export const setContactCreditCardsData = makeActionCreator(SET_CONTACT_CREDIT_CARDS);

export const getContactCreditCardAction: (contact_id: number) => ThunkedAction<State> =
(contact_id: number) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_contact_credit_cards]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getContactCreditCardData(contact_id, organisation);
    if (response.success) {
      const { data } = response;
      dispatch(setContactCreditCardsData({ data }));
      dispatch(setFormStatus({ [FORM.get_contact_credit_cards]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_contact_credit_cards]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_contact_credit_cards]: FORM_STATUS.error }));
  }
};
