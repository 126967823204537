import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { lighten } from '@material-ui/core/styles/colorManipulator';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import {
  getPaymentFilter,
  getPaymentRangeDate,
  normalizePaymentForUser,
} from 'src/lib/PaymentHelper';
import { CSSProperties, useEffect, useState } from 'react';
import { SEARCH_LOGICAL_OPERATORS } from 'src/models/AdvanceSearchModel';
import { getEntitiesAction, setFilters } from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { PaymentSections } from 'src/components/Pages/UserPaymentsPage';
import PaymentModel, { PAYMENT_STATUS, PAYMENT_TYPE } from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getUserBankAccountAction,
  getUserCreditCardAction,
} from 'src/redux/actions/paymentInfoAction';
import { CreditCardModel } from 'src/models/CreditCardModel';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import ViewUserPaymentButton from 'src/components/DatagridToolbar/buttons/ViewUserPaymentButton';
import Row from 'src/components/UI/Row';
import UserCardItem from 'src/components/Elements/User/UserCardItem';
import palette from 'src/styles/palette';
import UserListItemLabel from 'src/components/Elements/User/UserCardLabel';

interface Props {
  section: PaymentSections,
}

const ManageUserPayments = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });
  const dispatch = useAppDispatch();

  const { section } = props;

  const MAX_ITEMS = 7;

  const selections = useSelector((state: Store) => {
    if (!!state.selected[ENTITIES.payments]) {
      return [...state.selected[ENTITIES.payments]];
    }
    return [];
  });

  const loading = useSelector(
    (state: Store) => state.formStatus[FORM.query_builder] === FORM_STATUS.processing
      || state.formStatus[FORM.get_user_credit_cards] === FORM_STATUS.processing
      || state.formStatus[FORM.get_user_bank_accounts] === FORM_STATUS.processing,
  );

  const payments = useSelector(
    (state: Store) => state.querybuilder.payments?.result?.list || [],
  );

  const creditCards = useSelector(
    (state: Store) => {
      if (!!state.userCreditCards) {
        return state.userCreditCards as CreditCardModel[];
      }
      return [] as CreditCardModel[];
    },
  );

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.userBankAccounts) {
        return state.userBankAccounts as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  const getCreditCard = (id: number) => {
    if (!id) return 'N/A';
    const result = creditCards.find((creditCard) => creditCard.id === id);
    if (!result) return 'N/A';
    return result.masked_card_number;
  };

  const getBankAccount = (id: number) => {
    if (!id) return 'N/A';
    const result = bankAccounts.find((bankAccount) => bankAccount.id === id);
    if (!result) return 'N/A';
    return result.account.slice(-4);
  };

  const getFiltered = ():PaymentModel[] => payments.map((item) => {
    const payment = normalizePaymentForUser(item);
    if (section === PaymentSections.all) {
      return payment;
    }
    if (section === PaymentSections.scheduled) {
      if (![
        PAYMENT_STATUS.scheduled,
        PAYMENT_STATUS.pending,
        PAYMENT_STATUS.holding,
      ].includes(payment.payment_status as PAYMENT_STATUS)) {
        return undefined;
      }
    }
    if (section === PaymentSections.error) {
      if (![
        PAYMENT_STATUS.error,
        PAYMENT_STATUS.declined,
      ].includes(payment.payment_status as PAYMENT_STATUS)) {
        return undefined;
      }
    }
    if (section === PaymentSections.paid) {
      if (payment.payment_status !== PAYMENT_STATUS.paid) {
        return undefined;
      }
    }
    return payment;
  }).filter((item): item is PaymentModel => item !== undefined);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(getFiltered().slice(0, MAX_ITEMS));
  const [hasMore, setHasMore] = useState(true);
  const [filteredItems, setFilteredItems] = useState(getFiltered());
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  useEffect(() => {
    if (payments.length && !!filteredItems) {
      setItems(getFiltered().slice(0, MAX_ITEMS));
    }
  }, [section]);

  useEffect(() => {
    if (payments.length && !!filteredItems) {
      const newFilteredItems = getFiltered();
      setFilteredItems(newFilteredItems);
      setItems(newFilteredItems.slice(0, MAX_ITEMS));
    }
  }, [payments]);

  useEffect(() => {
    const [startDate, endDate] = getPaymentRangeDate(section);
    const filters = getPaymentFilter(
      startDate,
      endDate,
    );
    dispatch(setFilters({
      entity: ENTITIES.payments,
      filters: { [SEARCH_LOGICAL_OPERATORS.and]: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.payments));
    dispatch(getUserCreditCardAction());
    dispatch(getUserBankAccountAction());
  }, []);

  const onClickSelect = (id?: number) => {
    if (!id) return;
    const index = selections.indexOf(id);

    if (index >= 0) {
      selections.splice(index, 1);
    } else {
      selections.push(id);
    }

    dispatch(setSelections({
      entity: ENTITIES.payments,
      selections,
    }));
  };

  const handleNext = () => {
    const newItems = filteredItems.slice(page * MAX_ITEMS, (page + 1) * MAX_ITEMS);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(filteredItems.length > (page + 1) * MAX_ITEMS);
  };

  const displayPaymentInfo = (payment: PaymentModel) => {
    if (payment.payment_type === PAYMENT_TYPE.cc) {
      return (
        <div>
          <UserListItemLabel>Credit Card Used:</UserListItemLabel>
          {getCreditCard(payment.payment_info_id as number)}
        </div>
      );
    }
    if (payment.payment_type === PAYMENT_TYPE.eft) {
      return (
        <div>
          <UserListItemLabel>Credit Card Used:</UserListItemLabel>
          ********{getBankAccount(payment.payment_info_id as number)}
        </div>
      );
    }
    return (<></>);
  };

  const displayScheduledDate = (payment: PaymentModel) => {
    if (payment.payment_status !== PAYMENT_STATUS.scheduled) return (<></>);
    if (!payment.scheduled_date) return (<></>);
    const date = moment(payment.scheduled_date).format('YYYY-MM-DD');
    return (<div><UserListItemLabel>Scheduled:</UserListItemLabel>{date}</div>);
  };

  const displayPaidDate = (payment: PaymentModel) => {
    if (!payment.paid_date) return (<></>);
    const date = moment(payment.paid_date).format('YYYY-MM-DD');
    return (<div><UserListItemLabel>Date:</UserListItemLabel>{date}</div>);
  };

  const getItemStyle = (id?: number) => {
    if (selections.indexOf(id) < 0) {
      return {};
    }
    return {
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: lighten(palette.primary.main, 0.9),
    };
  };

  const getItem = (payment: PaymentModel) => (
    <Row style={{ minWidth: '640px' }} key={payment.id}>
      <UserCardItem
        style={getItemStyle(payment.id)}
        onClick={() => onClickSelect(payment.id)}
        key={payment.id}>
        <div>
          <div style={{ display: 'inline-block' }}>
            <UserListItemLabel>ID:</UserListItemLabel>{payment.id}
          </div>
          <div style={{ display: 'inline-block', float: 'right' }}>
            <ViewUserPaymentButton payment={payment} />
          </div>
        </div>
        {displayPaidDate(payment)}
        {displayScheduledDate(payment)}
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Title:</span>{payment.title}
        </div>
        <div>
          <UserListItemLabel>Amount:</UserListItemLabel>
          {formatter.format(Number(payment.amount ?? 0))}
        </div>
        <div>
          <UserListItemLabel>Deductible:</UserListItemLabel>
          {formatter.format(payment.deductible ?? 0)}
        </div>
        <div>
          <UserListItemLabel>Method:</UserListItemLabel>{payment.payment_type}
        </div>
        {displayPaymentInfo(payment)}
        <div>
          <UserListItemLabel>Status:</UserListItemLabel>{payment.payment_status}
        </div>
      </UserCardItem>
    </Row>
  );

  const getListStyle = () => {
    if (isTabletOrMobile) {
      return {
        marginTop: '48px',
      } as CSSProperties;
    }
    return {
      marginTop: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    } as CSSProperties;
  };

  const displayItems = () => items.map((item) => getItem(item as PaymentModel));

  const showList = () => (
    <div style={getListStyle()}>
      <InfiniteScroll
        dataLength={filteredItems.length}
        next={handleNext}
        hasMore={hasMore}
        loader={<Typography />}
        endMessage={<Typography>All items displayed</Typography>}
        height={621}>
        {displayItems()}
      </InfiniteScroll>
    </div>
  );

  const showLoading = () => (
    <CircularProgress
      size={40}
      style={{
        margin: '0 auto',
        marginTop: '32px',
      }} />
  );

  return (loading ? showLoading() : showList());
};

export default ManageUserPayments;
