import { State } from 'src/redux/reducers/RootReducer';
import { putUploadImage } from 'src/apis/UploadAPI';
import { REACT_APP_BUCKET_NAME } from 'src/environments/environment';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

export const uploadFileAction:
(
  file: string,
  path:string,
  successmsg: string,
  errormsg: string,
) => ThunkedAction<State, boolean> =
  (
    file: string,
    path:string,
    successmsg: string,
    errormsg: string,
  ) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const payload = {
        path,
        file,
        bucket: REACT_APP_BUCKET_NAME,
      };
      const response = await putUploadImage(organisation, payload);
      if (response.success) {
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          message: successmsg,
        }));
        return true;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: errormsg,
      }));
      return false;
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: errormsg,
      }));
      return false;
    }
  };
//
