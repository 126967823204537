import { makeStyles } from '@material-ui/core/styles';
import EditIconSVG from '@material-ui/icons/Edit';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  onClick: () => void;
  color?: string;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme) => ({
  deletePaymentInfo: {
    color: theme.palette.action.active,
    margin: '0 4px',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
}));

const EditIcon = (props: Props) => {
  const classes = useStyles();
  const { color, onClick, style } = props;

  const styles = {
    ...style,
    color,
  };

  return (
    <EditIconSVG
      className={classes.deletePaymentInfo}
      fontSize="small"
      onClick={() => onClick()}
      style={styles} />
  );
};

export default EditIcon;
