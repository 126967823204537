import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { t } from 'src/lib/language';
import { BASE_URL } from 'src/environments/environment';

interface OwnProps {
  contactId: number;
}

type Props = OwnProps & ButtonProps;

const ShowPaymentsForContactButton = (props: Props) => {
  const { disabled, contactId } = props;
  const lang = useSelector((state: Store) => state.language.language);

  const onClick = () => {
    if (!contactId) return;
    window.open(`${BASE_URL}/payments/contact/${contactId}`);
  };

  return (
    <Tooltip
      title={t(lang, 'menus.show_payment_contact')}
      placement="top-start">
      <span>
        <ActionButton
          category={MENU_BUTTON_CATEGORY.presets}
          onClick={onClick}
          disabled={disabled}>
          <DescriptionOutlinedIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default ShowPaymentsForContactButton;
