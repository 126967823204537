const login = {
  sign_in: 'Se connecter',
  language: 'Language',
  remember_me: 'Me connecter automatiquement',
  dont_have_account: 'Pas inscrit?',
  forget_password: 'Mot de passe oublié?',
  forget_password_text: 'SVP entrez le courriel que vous avez utilisé pour vous connecter. Nous vous enverrons un courriel avec un lien pour changer votre mot de passe',
  send: 'Envoyer',
  have_account: 'Vous avez déjà un compte?',
  sign_up: 'Créer un compte',
  login: 'Connexion',
  policy: 'En créant un compte vous accepter nos',
  privacy_policy: 'politique de confidentialité',
  tos: 'conditions de service',
  your_email: 'Votre courriel',
  password: 'Mot-de-passe',
  confirm_password: 'Confirmation mot-de-passe',
};

export default login;
