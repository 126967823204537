import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteOrganisationsUserData,
  getOrganisationData,
  getOrganisationFromHash,
  getOrganisationListData,
  getOrganisationsUserData,
  normalizeOrganisationData,
  normalizeOrganisationListData,
  normalizeOrganisationsUserData,
  postOrganisationExistsData,
  postOrganisationsUserData,
  putOrganisationAddressData,
  putOrganisationInfoData,
  shareInfoOrganisationsUserData,
} from 'src/apis/OrganisationAPI';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { OrganisationAddressPayload, OrganisationInfoPayload } from 'src/models/OrganisationModel';

export const SET_ORGANISATION_DATA = 'SET_ORGANISATION_DATA';
export const SET_ORGANISATION_LIST_DATA = 'SET_ORGANISATION_LIST_DATA';
export const SET_ORGANISATIONS_USER_DATA = 'SET_ORGANISATIONS_USER_DATA';
export const SET_CURRENT_ORGANISATION = 'SET_CURRENT_ORGANISATION';

export const setOrganisationData = makeActionCreator(SET_ORGANISATION_DATA);
export const setOrganisationListData = makeActionCreator(SET_ORGANISATION_LIST_DATA);
export const setOrganisationsUserData = makeActionCreator(SET_ORGANISATIONS_USER_DATA);
export const setCurrentOrganisation = makeActionCreator(SET_CURRENT_ORGANISATION);

export const getOrganisationAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id || 'aic';
      const response = await getOrganisationData(organisation);
      if (response.success) {
        const organisationData = normalizeOrganisationData(response.data);
        dispatch(setOrganisationData(organisationData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getOrganisationFromHashAction:
(
  organisationHash: string
) => ThunkedAction<State, string> =
  (
    organisationHash: string,
  ) => async (dispatch: any) => {
    try {
      const response = await getOrganisationFromHash(organisationHash);
      if (response.success) {
        const organisationData = normalizeOrganisationData(response.data);
        dispatch(setCurrentOrganisation(organisationData));
        return organisationData.id;
      }
    } catch (e) { /* Log the error here */
      return '';
    }
    return '';
  };

export const getOrganisationListAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getOrganisationListData();
      if (response.success) {
        const organisationListData = normalizeOrganisationListData(response.data);
        dispatch(setOrganisationListData(organisationListData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getOrganisationsUserAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getOrganisationsUserData();
      if (response.success) {
        const organisationUserList = normalizeOrganisationsUserData(response.data);
        dispatch(setOrganisationsUserData(organisationUserList));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const postOrganisationsUserAction: (organisation: string) => ThunkedAction<State> =
  (organisation: string) => async (dispatch: any, getState: any) => {
    const organisationName = getState().organisationList[organisation].nameEn;
    try {
      dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.processing }));
      const response = await postOrganisationsUserData(organisation);
      if (response.success) {
        const organisationUserList = normalizeOrganisationsUserData(response.data);
        dispatch(setOrganisationsUserData(organisationUserList));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.organisation_added',
          value: { organisation: organisationName },
        }));
        dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.success }));
      } else {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.organisation_added_error',
          value: { organisation: organisationName },
        }));
        dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.error }));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const deleteOrganisationsUserAction: (organisation: string) => ThunkedAction<State> =
  (organisation: string) => async (dispatch: any, getState: any) => {
    const organisationName = getState().organisationList[organisation].nameEn;
    try {
      dispatch(setFormStatus({ [FORM.user_organisation_delete]: FORM_STATUS.processing }));
      const response = await deleteOrganisationsUserData(organisation);
      if (response.success) {
        const organisationUserList = normalizeOrganisationsUserData(response.data);
        dispatch(setOrganisationsUserData(organisationUserList));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.organisation_removed',
          value: { organisation: organisationName },
        }));
        dispatch(setFormStatus({ [FORM.user_organisation_delete]: FORM_STATUS.success }));
      }
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.user_organisation_delete]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.organisation_updating_error',
        value: { organisation: organisationName },
      }));
    }
  };

export const shareInfoOrganisationsUserAction:
(organisation: string, shareInfo: boolean) => ThunkedAction<State> =
  (organisation: string, shareInfo: boolean) => async (dispatch: any, getState: any) => {
    const organisationName = getState().organisationList[organisation].nameEn;
    try {
      dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.processing }));
      const response = await shareInfoOrganisationsUserData(organisation, shareInfo);
      if (response.success) {
        const organisationUserList = normalizeOrganisationsUserData(response.data);
        dispatch(setOrganisationsUserData(organisationUserList));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.sharing_info_updated',
          value: { organisation: organisationName },
        }));
        dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.success }));
      }
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.user_organisation_share]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.sharing_info_updated_error',
        value: { organisation: organisationName },
      }));
    }
  };

export const putOrganisationInfoAction:(data: OrganisationInfoPayload) => ThunkedAction<State> =
  (data: OrganisationInfoPayload) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    try {
      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.processing }));
      const response = await putOrganisationInfoData(organisation, data);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.success }));
        const organisationData = normalizeOrganisationData(response.data);
        dispatch(setOrganisationData(organisationData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.organisation_updated',
        }));
      }
      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.organisation_info_updated_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.organisation_info_updated_error',
      }));
    }
  };

export const postOrganisationExistsAction:
(id: string) => ThunkedAction<State, boolean> =
  (id: string) => async () => {
    const response = await postOrganisationExistsData(id);
    return !!response.data;
  };

export const putOrganisationAddressAction:
(data: OrganisationAddressPayload) => ThunkedAction<State> =
  (data: OrganisationAddressPayload) => async (dispatch: any, getState: any) => {
    const organisation = getState().currentOrganisation.id;
    try {
      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.processing }));
      const response = await putOrganisationAddressData(organisation, data);
      if (response.success) {
        dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.success }));
        const organisationData = normalizeOrganisationData(response.data);
        dispatch(setOrganisationData(organisationData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.organisation_updated',
        }));
        dispatch(getOrganisationAction());
        return;
      }

      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors).join(),
      }));
    } catch (e) { /* Log the error here */
      dispatch(setFormStatus({ [FORM.edit_organisation_info]: FORM_STATUS.error }));
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.organisation_address_updated_error',
      }));
    }
  };
