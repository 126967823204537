const payments = {
  deductible: 'Rabais',
  processed_date: 'Date de traitement',
  scheduled_date: 'Date prévue',
  edit_payment: 'Modifier paiement',
  make_payment: 'Effectuer un paiement',
  cc: 'Cartes de Crédits',
  contact_cc: 'Selectionner Carte de Crédit',
  gateways: 'Passerrelles de paiements',
  eft: 'Compte Bancaires',
  contact_eft: 'Selectionner Compte Bancaire',
  check: 'Chèque',
  'in-kind': 'En nature',
  cash: 'Espèce',
  terminal: 'Terminal',
  wired: 'Virement',
  interac: 'Interac',
  'user-balance': 'Balance utilisateur',
  issue_tax_receipt: "Générer un reçu d'impot lorsqu'applicable",
  paid_date: 'Date de paiement',
  start_date: 'Date début',
  cancel_payment: 'Annuler les paiement(s)',
  refund_payment: 'Rembourser le(s) paiement(s)',
  refund_date: 'Date de remboursement',
  cancel_date: 'Date d\'annulation',
  number: 'Nombre de paiements',
  contact: 'Contact',
  amount: 'Montant à payer',
  balance: 'Balance',
  payment_type: 'Type de paiement',
  is_installment: 'Arrangement',
  installments: 'Arrangements',
  single_payment: 'Paiement unique',
  multiple_payment: 'Paiement multiple',
  payer: 'Nom du payeur',
  add_credit_card: 'Ajout carte de crédit',
  add_bank_account: 'Ajout compte bancaire',
  payment_accounts: 'Compte paiement',
  organisation_bank_accounts: "Compte bancaire de l'organisme",
  invoices_has_different_contacts: 'Ces factures appartiennent à différents contacts',
  payments_of: 'paiements de',
  starting: 'debutant',
  seller: 'Vendeur',
  make_donation: 'Faire une donation',
  select_cc: 'Selectionner une carte de credit',
  not_paid: 'Impayée',
  paid: 'Payée',
  scheduled: 'Prévue',
  scheduled_due: 'Schédulée due',
  partially_paid: 'Partiellement payée',
  refund_date_validation: 'La date de remboursement doit etre superieure a aujourdhui',
  cancel_date_validation: 'La date d\'annulation doit etre superieure a aujourdhui',
  required_paid_payment: 'Au moins un paiement reussie (PAID) ou annulé est requis pour télécharger un PDF.',
};

export default payments;
