import { TextField } from '@material-ui/core';
import { EmptyFilter, SingleOperand } from 'src/models/AdvanceSearchModel';
import { FILTER_POSITION } from 'src/constants/FilterPosition';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { getOperatorAndFilters } from 'src/lib/QueryBuilderHelper';
import { useState } from 'react';
import { FORM } from 'src/constants/Form';
import { setAdvancedSearch } from 'src/redux/actions/advancedSearchAction';

interface Props {
  entity: ENTITIES;
  index: number;
  isEmpty?: boolean;
}

const OperandText = (props: Props) => {
  const {
    index, entity, isEmpty,
  } = props;

  const dispatch = useAppDispatch();

  const error: string =
    useSelector(
      (state: Store) => {
        if (state.errors[FORM.query_builder]) {
          if (state.errors[FORM.query_builder][index]) {
            return state.errors[FORM.query_builder][index][FILTER_POSITION.operands];
          }
          return '';
        }
        return '';
      },
    );

  const filters = useSelector(
    (state: Store) => state.advancedSearch[entity] || EmptyFilter,
  );
  const [operator, filterList] = getOperatorAndFilters(filters);

  const [value, setValue] = useState(filterList[index][FILTER_POSITION.operands] as SingleOperand || '');

  const handleChange = (operand: SingleOperand) => {
    setValue(operand);
  };

  const handleOnBlur = () => {
    filterList[index][FILTER_POSITION.operands] = value;
    dispatch(setAdvancedSearch({
      entity,
      filters: {
        [operator]: [
          ...filterList,
        ],
      },
    }));
  };

  return (
    <TextField
      disabled={filterList[index][FILTER_POSITION.operator] === ''}
      inputProps={{ style: { height: '14px' } }}
      style={{ margin: 0 }}
      margin="dense"
      size="small"
      variant="outlined"
      value={isEmpty ? '' : value}
      onChange={(e) => handleChange(e.target.value as SingleOperand)}
      onBlur={handleOnBlur}
      error={!!error}
      helperText={error}
      id={`operand-text-${index}`} />
  );
};

export default OperandText;
