import { ENTITIES, GridColumns, QuerybuilderOrders } from 'src/models/QuerybuilderModel';
import { Filters } from 'src/models/AdvanceSearchModel';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum PRESET_TYPES {
  layout = 'layout',
  filter = 'filter',
}

export interface PresetItem {
  id: number,
  name?: string,
  filter: Filters | GridColumns;
  order: QuerybuilderOrders,
  is_shared: boolean;
  is_default: boolean;
}

export interface Preset {
  id: number,
  name: string,
  filter: Filters | GridColumns,
  order: QuerybuilderOrders,
  is_shared: boolean,
  is_default: boolean,
}

export interface PresetPayload {
  entity: ENTITIES,
  type: PRESET_TYPES,
}

export interface GetPresetsPayload {
  entity: ENTITIES,
}

export interface CreatePresetPayload extends PresetPayload {
  entity: ENTITIES,
  type: PRESET_TYPES,
  name: string,
  filter: {
    filter: Filters | GridColumns,
    order: QuerybuilderOrders
  },
  is_shared: boolean,
  is_default: boolean,
}

export interface UpdatePresetPayload {
  id: number,
  entity: ENTITIES,
  type: PRESET_TYPES,
  filter: {
    filter: Filters | GridColumns,
    order: QuerybuilderOrders
  },
  name: string,
  is_shared: boolean,
  is_default: boolean,
}

export interface DeletePresetPayload {
  id: number,
  entity: ENTITIES,
  type: PRESET_TYPES,
}

export type EntityPreset = {
  [type in PRESET_TYPES]: any[];
};

export type PresetModel = {
  [entity in ENTITIES]: EntityPreset;
};

export type SelectedPreset = {
  [entity in ENTITIES]: {
    [type in PRESET_TYPES]: {
      default: number,
      selected: number,
    }
  }
};
