import * as Yup from 'yup';
import { t } from 'src/lib/language';
import { ORGANISATION_TYPES_LIST } from 'src/constants/OrganisationTypes';

const CreateOrganisationValidator = (lang:string) => Yup.object().shape({
  name_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  name_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  legal_name: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  registration_number: Yup.number().required(),
  website: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  email: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  timezone_id: Yup.number()
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer()
    .required(),
  type: Yup.string()
    .oneOf(ORGANISATION_TYPES_LIST)
    .required(),
  is_npo: Yup.boolean(),
  is_jewish: Yup.boolean(),
  address: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  city: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  state: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  country_id: Yup.number()
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer()
    .required(),
  zip_code: Yup.string()
    .max(6, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  phone: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
  extension: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
  tollfree_phone: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
  tollfree_extension: Yup.string()
    .matches(/^\d+$|^$/)
    .nullable(),
});

export default CreateOrganisationValidator;
