import { forEach, get, keys } from 'lodash';
import translation from 'src/i18n/translation';

export const getLanguage = (locale:string) => locale.split('_')[0];

const extrapolatedTranslation = (raw: string, values:any) => {
  let result = raw;
  forEach(keys(values), (key) => {
    const pattern = `%${key}%`;
    result = result.replace(pattern, values[key] as string);
  });
  return result;
};

export const t = (lang: string, path: string, values?: any, count?: number) => {
  const raw = get(translation[lang ?? 'en'], path) || path;
  if (count) {
    // TODO
    return '';
  }
  if (values) {
    return extrapolatedTranslation(raw, values);
  }
  return raw;
};

export const f = (lang: string, field: string) => {
  const path = `fields.${field}`;
  return t(lang, path);
};
