import * as Yup from 'yup';
/* eslint-disable import/no-extraneous-dependencies */
import { t } from 'src/lib/language';

const InvoiceValidator = (lang:string) => Yup.object().shape({
  contact_id: Yup.number()
    .required(t(lang, 'validations.invoice.field_required'))
    .integer(),
  department_id: Yup.number()
    .required(t(lang, 'validations.invoice.field_required'))
    .min(1)
    .integer(),
  account_id: Yup.number()
    .required(t(lang, 'validations.invoice.field_required'))
    .min(1)
    .integer(),
  program_id: Yup.number()
    .required(t(lang, 'validations.invoice.field_required'))
    .min(1)
    .integer(),
  bank_account_id: Yup.number()
    .required(t(lang, 'validations.invoice.field_required'))
    .min(1)
    .integer(),
  issued_date: Yup.date().required('This field is required'),
  due_date: Yup.date().required('This field is required'),
  title: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_required', { max: 150 })).required(),
  billing_address: Yup.string()
    .max(250, t(lang, 'validations.invoice.field_maximum', { max: 250 }))
    .nullable(),
  billing_suite: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_city: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_state: Yup.string()
    .max(150, t(lang, 'validations.invoice.field_maximum', { max: 150 }))
    .nullable(),
  billing_country_id: Yup.number()
    .min(0, 'billing_country_required').max(999)
    .integer()
    .nullable(),
  billing_zip_code: Yup.string()
    .max(7, t(lang, 'validations.invoices.field_maximum', { max: 7 }))
    .nullable(),
  shipping_address: Yup.string()
    .max(250, t(lang, 'validations.invoices.field_maximum', { max: 250 }))
    .nullable(),
  shipping_suite: Yup.string()
    .max(150, t(lang, 'validations.invoices.field_maximum', { max: 150 }))
    .nullable(),
  shipping_city: Yup.string()
    .max(150, t(lang, 'validations.invoices.field_maximum', { max: 150 }))
    .nullable(),
  shipping_state: Yup.string()
    .max(150, t(lang, 'validations.invoices.field_maximum', { max: 150 }))
    .nullable(),
  shipping_country_id: Yup.number()
    .min(0, 'billing_country_required').max(999)
    .integer()
    .nullable(),
  shipping_zip_code: Yup.string()
    .max(7, t(lang, 'validations.invoices.field_maximum', { max: 7 }))
    .nullable(),
});

export default InvoiceValidator;
