const register = {
  register: 'Register',
  email: 'Email address',
  password: 'Password',
  confirm_password: 'Confirm password',
  language: 'Language',
  different_account: 'Want to login with a different account?',
  login: 'login',
  policy: 'By creating an account, you agree to our',
  privacy_policy: 'privacy policy',
  tos: 'terms of service',
};

export default register;
