import { CountryListModel } from 'src/models/CountryListModel';
import { DepartmentListModel } from 'src/models/DepartmentListModel';
import { ProgramListModel } from 'src/models/ProgramListModel';
import { AccountListModel } from 'src/models/AccountListModel';
import { ContactRelationListModel } from 'src/models/ContactModel';
import { combineReducers, Reducer } from 'redux';
import { SalutationListModel } from 'src/models/SalutationListModel';
import {
  SET_ACCOUNTS,
  SET_COUNTRIES,
  SET_CURRENCIES,
  SET_DEPARTMENTS,
  SET_PROGRAMS,
  SET_RELATIONS,
  SET_SALUTATIONS,
  SET_TZ,
} from 'src/redux/actions/dataActions';
import { TimeZoneListModel } from 'src/models/TimeZoneListModel';
import { CurrencyListModel } from 'src/models/CurrencyListModel';

export type CountriesState = CountryListModel;
const DEFAULT_COUNTRIES: CountriesState = [] as CountriesState;
const countriesReducer: Reducer<CountriesState> = (state = DEFAULT_COUNTRIES, action) => {
  switch (action.type) {
    case SET_COUNTRIES:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export type CurrenciesState = CurrencyListModel;
const DEFAULT_CURRENCIES: CurrenciesState = [] as CurrenciesState;
const currenciesReducer: Reducer<CurrenciesState> = (state = DEFAULT_CURRENCIES, action) => {
  switch (action.type) {
    case SET_CURRENCIES:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export type SalutationsState = SalutationListModel;
const DEFAULT_SALUTATION: SalutationsState = [] as SalutationsState;
const salutationsReducer: Reducer<SalutationsState> = (state = DEFAULT_SALUTATION, action) => {
  switch (action.type) {
    case SET_SALUTATIONS:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export type RelationsState = ContactRelationListModel;
const DEFAULT_RELATION: RelationsState = [] as RelationsState;
const relationsReducer: Reducer<RelationsState> = (state = DEFAULT_RELATION, action) => {
  switch (action.type) {
    case SET_RELATIONS:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export type DepartmentsState = DepartmentListModel;
const DEFAULT_DEPARTMENT: DepartmentsState = [] as DepartmentsState;
const departmentsReducer: Reducer<DepartmentsState> = (state = DEFAULT_DEPARTMENT, action) => {
  switch (action.type) {
    case SET_DEPARTMENTS:
      return [
        ...action.payload,
      ];

    default:
      return state;
  }
};

export type AccountsState = AccountListModel;
const DEFAULT_ACCOUNT: AccountsState = [] as AccountsState;
const accountsReducer: Reducer<AccountsState> = (state = DEFAULT_ACCOUNT, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      return [
        ...action.payload,
      ];

    default:
      return state;
  }
};

export type ProgramsState = ProgramListModel;
const DEFAULT_PROGRAM: ProgramsState = [] as ProgramsState;
const programsReducer: Reducer<ProgramsState> = (state = DEFAULT_PROGRAM, action) => {
  switch (action.type) {
    case SET_PROGRAMS:
      return [
        ...action.payload,
      ];

    default:
      return state;
  }
};

export type TimeZoneState = TimeZoneListModel;
const DEFAULT_TZ: TimeZoneState = {} as TimeZoneState;
const TimezonesReducer: Reducer<TimeZoneState> = (state = DEFAULT_TZ, action) => {
  switch (action.type) {
    case SET_TZ:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export interface DataState {
  accounts: AccountsState,
  countries: CountriesState,
  departments: DepartmentsState,
  programs: ProgramsState,
  salutations: SalutationsState,
  timezones: TimeZoneState,
  relations: RelationsState,
  currencies: CurrenciesState,
}

const dataReducer = combineReducers<DataState>({
  accounts: accountsReducer,
  currencies: currenciesReducer,
  countries: countriesReducer,
  departments: departmentsReducer,
  programs: programsReducer,
  salutations: salutationsReducer,
  timezones: TimezonesReducer,
  relations: relationsReducer,
});

export default dataReducer;
