const payment = {
  id: 'id',
  amount: 'Montant',
  contact_id: 'Contact',
  seller_id: 'Vendeur',
  deductible: 'Déductible',
  scheduled_date: 'Date prévue',
  paid_date: 'Date paiement',
  processed_date: 'Date de traitement',
  payment_action: 'Action (paiement)',
  payment_type: 'Type de paiement',
  payment_status: 'Statut de paiement',
  title: 'Titre',
  description: 'description',
  reference: 'Réference',
  reference_type: 'Type de référence',
  is_expense: 'Dépense',
  is_refund: 'Remboursement',
  issue_tax_receipt: 'Recu d\'impot',
  tax_receipt_issued: 'Tax Receipt Issued',
  payment_receipt_send: 'Recu d\'impot envoyer',
  payment_receipt_printed: 'Recu d\'impot imprimer',
  billing_address: 'Addresse (facturation)',
  billing_city: 'Ville (facturation)',
  bank_account_id: 'Compte bancaire',
  billing_suite: 'Suite (facturation)',
  billing_state: 'Etat (facturation)',
  billing_zip_code: 'Code postal (facturation)',
  billing_country_id: 'Pays (facturation)',
  shipping_address: 'Addresse (expédition)',
  shipping_suite: 'Suite  (expédition)',
  shipping_city: 'Ville (expédition)',
  shipping_state: 'État (expédition)',
  shipping_country_id: 'Pays (expédition)',
  shipping_zip_code: 'Code Postal (expédition)',
  contact_company_name: 'Nom de la Compagnie',
  contact_salutation_id: 'Salutation',
  contact_first_name: 'Contact Prénom',
  contact_last_name: 'Contact Nom',
  account_id: 'Comptes',
  program_id: 'Programmes',
  department_id: 'Départements',
};

export default payment;
