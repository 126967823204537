// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum BANK_ACCOUNT_TYPE {
  checking = 'PERSONNAL_CHECKING',
  saving = 'PERSONNAL_SAVING',
}

export const BANK_ACCOUNT_TYPE_LIST = [
  BANK_ACCOUNT_TYPE.checking,
  BANK_ACCOUNT_TYPE.saving,
];

// eslint-disable-next-line @typescript-eslint/naming-convention
export const enum BANK_ACCOUNT_COUNTRY {
  us = 'us',
  ca = 'ca',
}
