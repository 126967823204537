import BaseDownloadDialog from 'src/components/Dialogs/BaseDownloadDialog';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { DIALOGS } from 'src/models/DialogModel';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  EXTENSION,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { useState } from 'react';
import { closeDialog } from 'src/redux/actions/dialogActions';
import {
  postDownloadEntitesAction,
  postEmailEntitesAction,
} from 'src/redux/actions/querybuilderActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { setError } from 'src/redux/actions/errorsActions';
import { FORM } from 'src/constants/Form';

const DownloadContactResultDialog = () => {
  const opened =
    useSelector((state: Store) => state.dialog[DIALOGS.contactDownloadResult]?.opened || false);
  const count =
    useSelector(
      (state: Store) => (state.querybuilder[ENTITIES.contacts]
        && (state.querybuilder[ENTITIES.contacts].result?.count || 0)),
    );
  const dispatch = useAppDispatch();
  dispatch(setError({ [FORM.download]: {} }));

  const allowDownload = count < 1000;

  const [options, setOptions] = useState({
    file: 'contacts',
    orientation: ORIENTATION.portrait,
    format: FORMAT.pdf,
    delivery: allowDownload ? DELIVERY_TYPE.download : DELIVERY_TYPE.email,
    all: false,
  } as DownloadOptionsModel);

  const handleDownloadAllChange = (value: boolean) => {
    setOptions(
      {
        ...options,
        all: value,
      },
    );
  };

  const handleCancel = () => {
    dispatch(closeDialog({ dialog: DIALOGS.contactDownloadResult }));
  };

  const handleDownload = () => {
    dispatch(setError({ [FORM.download]: {} }));
    if (!options.file) {
      dispatch(setError({
        [FORM.download]: {
          file: ['Required'],
        },
      }));
      return;
    }
    const extension = options.format === FORMAT.csv ? EXTENSION.csv : EXTENSION.pdf;
    if (!options.file) {
      options.file = 'contacts';
    }
    options.file = `${options.file.split('.')[0]}.${extension}`;
    if (count > 1000 || options.all) {
      dispatch(postEmailEntitesAction(ENTITIES.contacts, options));
      dispatch(closeDialog({ dialog: DIALOGS.contactDownloadResult }));
      return;
    }
    if (options.delivery === DELIVERY_TYPE.download) {
      dispatch(postDownloadEntitesAction(ENTITIES.contacts, options));
    } else {
      dispatch(postEmailEntitesAction(ENTITIES.contacts, options));
    }
    dispatch(closeDialog({ dialog: DIALOGS.contactDownloadResult }));
  };

  const onChange = (value: DownloadOptionsModel) => {
    setOptions(value);
  };

  return (
    <BaseDownloadDialog
      handleCancel={handleCancel}
      handleDownload={handleDownload}
      handleDownloadAllChange={handleDownloadAllChange}
      onChange={onChange}
      opened={opened}
      options={options}
      allowDownload={count < 1000} />
  );
};

export default DownloadContactResultDialog;
