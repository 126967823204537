import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  maxWidth?: string,
}

const Form = (props: Props) => {
  const {
    children,
    maxWidth,
  } = props;

  const styles: CSSProperties = {
    width: '100%', // Fix IE 11 issue.
    maxWidth: maxWidth ?? '640px',
  };
  return (
    <form style={styles} noValidate>
      {children}
    </form>
  );
};

export default Form;
