import * as Yup from 'yup';
import { t } from 'src/lib/language';
import {
  BANK_ACCOUNT_COUNTRY,
  BANK_ACCOUNT_TYPE_LIST,
} from 'src/constants/BankAccount';

const BankAccountValidator = (lang:string) => Yup.object().shape({
  bank_country: Yup.string()
    .oneOf(['us', 'ca'])
    .required(),
  contact_id: Yup.lazy(() => Yup.number()
    .when('organisation_id', {
      is: (organisation: string) => !organisation,
      then: (
        schemas,
      ) => schemas.min(1, t(lang, 'validations.creditcard.field_required'))
        .required(t(lang, 'validations.contact.required_if_no_organisation_id')),
      otherwise: (schema) => schema.nullable(),
    })),
  organisation_id: Yup.lazy(() => Yup.string()
    .when('contact_id', {
      is: (contactId: string) => !contactId,
      then: (
        schemas,
      ) => schemas.required(t(lang, 'validations.contact.required_if_no_contact_id')),
      otherwise: (schema) => schema.nullable(),
    })),
  account: Yup.string()
    .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
    .min(5, t(lang, 'validations.creditcard.between_5_20_digit'))
    .max(20, t(lang, 'validations.creditcard.between_5_20_digit'))
    .required(),
  bank: Yup.string()
    .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
    .min(3, t(lang, 'validations.creditcard.exact_digit_3'))
    .max(3, t(lang, 'validations.creditcard.exact_digit_3'))
    .required(),
  type: Yup.string()
    .oneOf(['us', 'ca']),
  branch: Yup.string()
    .when('bank_country', {
      is: (bank_country: BANK_ACCOUNT_COUNTRY) => bank_country === 'ca',
      then: (
        schema,
      ) => schema.matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
        .min(5, t(lang, 'validations.creditcard.exact_digit_5'))
        .max(5, t(lang, 'validations.creditcard.exact_digit_5'))
        .required(),
    })
    .when('bank_country', {
      is: (bank_country: BANK_ACCOUNT_COUNTRY) => bank_country === 'us',
      then: (
        schema,
      ) => schema.matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
        .min(5, t(lang, 'validations.creditcard.between_5_20_digit'))
        .max(20, t(lang, 'validations.creditcard.between_5_20_digit'))
        .required(),
    }),
});

export const BankAccountPayloadValidator = (lang:string) => Yup.object().shape({
  account: Yup.string()
    .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
    .min(5, t(lang, 'validations.creditcard.between_5_20_digit'))
    .max(20, t(lang, 'validations.creditcard.between_5_20_digit'))
    .required(),
  bank: Yup.string()
    .matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
    .min(3, t(lang, 'validations.creditcard.exact_digit_3'))
    .max(3, t(lang, 'validations.creditcard.exact_digit_3'))
    .required(),
  bank_country: Yup.string()
    .oneOf(['us', 'ca'])
    .required(),
  bank_name: Yup.string()
    .required(),
  bank_account_type: Yup.string()
    .oneOf(BANK_ACCOUNT_TYPE_LIST),
  type: Yup.string()
    .oneOf(['us', 'ca']),
  branch: Yup.string()
    .when('bank_country', {
      is: (bank_country: BANK_ACCOUNT_COUNTRY) => bank_country === 'ca',
      then: (
        schema,
      ) => schema.matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
        .min(5, t(lang, 'validations.creditcard.exact_digit_5'))
        .max(5, t(lang, 'validations.creditcard.exact_digit_5'))
        .required(),
    })
    .when('bank_country', {
      is: (bank_country: BANK_ACCOUNT_COUNTRY) => bank_country === 'us',
      then: (
        schema,
      ) => schema.matches(/^[0-9]+$/, t(lang, 'validations.creditcard.only_digit'))
        .min(5, t(lang, 'validations.creditcard.between_5_20_digit'))
        .max(20, t(lang, 'validations.creditcard.between_5_20_digit'))
        .required(),
    }),
});

export default BankAccountValidator;
