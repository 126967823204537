import contact from 'src/i18n/fr/validations/contact';
import creditcard from 'src/i18n/fr/validations/creditcard';
import login from 'src/i18n/fr/validations/login';
import invoice from 'src/i18n/fr/validations/invoice';

const validations = {
  contact,
  creditcard,
  invoice,
  login,
};

export default validations;
