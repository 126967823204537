import forms from 'src/i18n/fr/forms';
import misc from 'src/i18n/fr/misc';
import validations from 'src/i18n/fr/validations';
import pages from 'src/i18n/fr/pages';
import messages from 'src/i18n/fr/messages';
import fields from 'src/i18n/fr/fields';
import search from 'src/i18n/fr/search';
import menus from 'src/i18n/fr/menus';
import errors from 'src/i18n/fr/errors';

const fr = {
  errors,
  fields,
  forms,
  pages,
  messages,
  misc,
  search,
  validations,
  menus,
};

export default fr;
//
