import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { ContactGroupModel } from 'src/models/ContactGroupModel';

export const normalizeGroups = (data:any[]) => {
  const groups = {};
  data.forEach((group:any) => {
    groups[group.id] = {
      id: group.id,
      name_fr: group.name_fr,
      name_en: group.name_en,
      description_en: group.description_en,
      description_fr: group.description_fr,
    };
  });
  return groups;
};

export const getContactGroupData = (organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.contact_groups}`,
  organisation,
});

export const putContactGroupData =
  (organisation: string, payload: ContactGroupModel) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.contact_groups}/${payload.id}`,
    organisation,
    data: payload,
  });

export const deleteContactGroupData =
  (organisation: string, payload: ContactGroupModel) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.contact_groups}/${payload.id}`,
    organisation,
  });

export const postContactGroupData =
  (organisation: string, payload: ContactGroupModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact_group}`,
    organisation,
    data: payload,
  });
