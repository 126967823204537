const organisation = {
  add_a_tax: 'Ajouter une taxe',
  account_type: 'Type de compte',
  filter: 'Filtrer',
  name_en: 'Nom anglais',
  name_fr: 'Nom français',
  legal_name: 'Nom légal',
  registration_number: 'Numéro enregistrement',
  organisationType: 'Type organisation',
  is_npo: 'is_npo',
  is_jewish: 'is_jewish',
  organisation_type: 'Type organisation',
  website: 'Site internet',
  email: 'Courriel',
  address: 'Addresse',
  city: 'Ville',
  suite: 'Suite',
  state: 'État',
  zipCode: 'Code postal',
  phone: 'Téléphone',
  extension: 'Extension',
  tollfreePhone: 'Numéro sans frais',
  tollfreeExtension: 'Extention du numéro sans frais',
  timezone: 'Fuseau horaire',
  upload_tax_receipt_background: 'Enregistrer l\'arrière plan du reçu d\'impot',
  tax_receipt_background: 'Arrière plan du reçu d\'impot',
  upload_logo: 'Enregistrer le logo',
  upload_signature: 'Enregistrer la signature',
  validation_errors: 'Erreur(s) de validation. Svp revoir vos données.',
  user_validation_errors: 'Erreur(s) de validation. Svp revoir vos données.',
};

export default organisation;
