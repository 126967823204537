import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { MODALS_SIZE } from 'src/models/modal';
import { MouseEvent, useEffect } from 'react';
import { getPaymentAction, refundPaymentAction } from 'src/redux/actions/paymentActions';
import { closeDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import Form from 'src/components/UI/Form';
import Row from 'src/components/UI/Row';
import BottomRow from 'src/components/UI/BottomRow';
// eslint-disable-next-line import/no-extraneous-dependencies

const RefundPaymentForm = () => {
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const paymentId = useSelector(
    (state: Store) => {
      if (!state.dialog[DIALOGS.refundPayments]) return 0;
      if (!state.dialog[DIALOGS.refundPayments].payload) return 0;
      return state.dialog[DIALOGS.refundPayments].payload;
    },
  );

  useEffect(() => {
    dispatch(getPaymentAction(paymentId as number));
  }, []);

  const handleClose = () => {
    dispatch(closeDialog({ dialog: DIALOGS.refundPayments }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dispatch(refundPaymentAction(paymentId));
    return true;
  };

  return (
    <Form maxWidth={MODALS_SIZE.large}>
      <Row>
        Are you sure you want to refund this payment?
      </Row>
      <BottomRow>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          onClick={handleClose}>
          {t(lang, 'misc.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '24px 0px 16px' }}
          onClick={handleSubmit}>
          {t(lang, 'misc.refund_payments')}
        </Button>
      </BottomRow>
    </Form>
  );
};

export default RefundPaymentForm;
