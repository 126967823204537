const customfields = {
  display_en: 'Nom en',
  display_fr: 'Nom fr',
  type: 'Type du champ',
  list: 'Valeur de la liste',
  required: 'Requis',
  boolean: 'Booléen',
  date: 'Date',
  number: 'Nombre',
  'single-list': 'Liste à sélection unique',
  string: 'Texte',
};

export default customfields;
