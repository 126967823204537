import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';

export const getGooglePredictionsData =
  (guess: string) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.google}`,
    organisation: 'aic',
    data: { data: { guess } },
  });

export const getGooglePlaceData =
  (placeId: string) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.google}`,
    organisation: 'aic',
    data: { data: { place_id: placeId } },
  });
