import {
  DeleteCustomfieldFormType,
  EditCustomfieldFormType,
  EditGroupFormType,
  FORM,
  FORM_STATUS,
} from 'src/constants/Form';
import { AUTOCOMPLETE_ID } from 'src/constants/Autocomplete';
import { ENTITIES } from 'src/models/QuerybuilderModel';

// eslint-disable-next-line @typescript-eslint/naming-convention
export type FORM_ID = FORM
| DeleteCustomfieldFormType
| EditCustomfieldFormType
| EditGroupFormType
| AUTOCOMPLETE_ID;

export type FormStatus = Record<FORM_ID, FORM_STATUS>;

export enum EntityStatus {
  error = 'error',
  ok = 'ok',
  processing = 'processing',
}

export type EntityStatusState = {
  [entity in ENTITIES]: {
    id : {
      status: EntityStatus;
    }
  }
};
