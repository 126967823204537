import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { MODALS } from 'src/models/ModalModel';
import { openModal } from 'src/redux/actions/modalActions';
import { t } from 'src/lib/language';

const EditEventButton = (props: ButtonProps) => {
  const { disabled } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleOpen = () => {
    dispatch(openModal({ modal: MODALS.editEvent }));
  };

  return (
    <Tooltip
      title={t(lang, 'menus.edit_selected_event')}
      placement="top-start">
      <span>
        <ActionButton
          variant="outlined"
          category={MENU_BUTTON_CATEGORY.action}
          onClick={handleOpen}
          disabled={disabled}>
          <EditIcon />
        </ActionButton>
      </span>
    </Tooltip>
  );
};

export default EditEventButton;
