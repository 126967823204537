import { Tooltip } from '@material-ui/core';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { t } from 'src/lib/language';
import SendIcon from '@material-ui/icons/Send';
import {
  DELIVERY_TYPE,
  DownloadOptionsModel,
  FORMAT,
  ORIENTATION,
} from 'src/models/DownloadModel';
import { postEmailSelectedEntitesAction } from 'src/redux/actions/querybuilderActions';

interface OwnProps {
  invoiceId?: number;
}

type Props = OwnProps & ButtonProps;

const SendInvoiceByEmailButton = (props: Props) => {
  const { invoiceId, disabled } = props;
  const dispatch = useAppDispatch();
  const lang = useSelector((state: Store) => state.language.language);
  const selections = useSelector(
    (state: Store) => state.selected[ENTITIES.invoices] || [],
  );

  const handleOnClick = () => {
    const ids = invoiceId ? [invoiceId] : selections;
    dispatch(postEmailSelectedEntitesAction(ENTITIES.invoices, ids, {
      file: `invoices_${invoiceId}.pdf`,
      orientation: ORIENTATION.portrait,
      format: FORMAT.pdf,
      delivery: DELIVERY_TYPE.contact,
      export_to_grid: false,
    } as DownloadOptionsModel));
  };

  return (
    <>
      <Tooltip
        title={t(lang, 'menus.send_invoice_by_email')}
        placement="top-start">
        <span>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.download}
            onClick={handleOnClick}
            disabled={disabled}>
            <SendIcon />
          </ActionButton>
        </span>
      </Tooltip>
    </>
  );
};

export default SendInvoiceByEmailButton;
