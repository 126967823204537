import {
  FundraisingMatchingdAsset, Slider, Sliders, Video,
} from 'src/models/FundraisingModel';
import { TABS } from 'src/components/Pages/AddFundraisingPage';
import { MouseEvent, useState } from 'react';
import { Store, useAppDispatch } from 'src/redux/Store';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import Debug from 'src/lib/Debug';
import ImageSliderManager from 'src/components/Elements/Fundraiser/ImageSliderManager';
import { Paper, Tab, Tabs } from '@material-ui/core';
import { FORM } from 'src/constants/Form';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import VideoChooser from 'src/components/Elements/VideoChooser';
import { SliderErrors } from 'src/validations/FundraisingAssetsValidation';
import { useSelector } from 'react-redux';
import { t } from 'src/lib/language';
import FundraiserHeader from 'src/components/UI/FundraiserHeader';
import Row from 'src/components/UI/Row';

interface Props {
  onChange: (assets: FundraisingMatchingdAsset) => void;
  onNextStep: (step:number) => void;
  setErrors: (tab:TABS, hasError: boolean) => void;
  languages: FundraisingLanguages[];
  slug: string,
  initialState: FundraisingMatchingdAsset;
  setHasChanged: (hasChanged: boolean) => void;
}

const FundraisingMatchingAssetsForm = (props: Props) => {
  const {
    onChange,
    onNextStep,
    setErrors,
    slug,
    languages,
    initialState,
    setHasChanged,
  } = props;

  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language);

  const getInitialAssets = () => {
    if (Object.keys(initialState).length) return initialState;
    const initAssets = {} as FundraisingMatchingdAsset;
    languages.forEach((language) => {
      initAssets[language] = {
        slider_main_portrait: [[''] as Slider] as Sliders,
        slider_main_landscape: [[''] as Slider] as Sliders,
        slider_secondary_landscape: [[''] as Slider] as Sliders,
        slider_secondary_portrait: [[''] as Slider] as Sliders,
        slider_matcher: [[''] as Slider] as Sliders,
        slider_sponsor: [[''] as Slider] as Sliders,
        video_main: {} as Video,
        video_secondary: {} as Video,
        campaign_message: '',
        button_label: '',
      } as FundraisingMatchingdAsset;
    });
    return initAssets;
  };

  const [currentLanguage, setCurrentLanguage] = useState(languages[0] || FundraisingLanguages.en);
  const [fundraisingAsset, setFundraisingAsset] = useState(getInitialAssets());

  const onValueChange = (value: any, field: any) => {
    setHasChanged(true);
    setFundraisingAsset({
      ...fundraisingAsset,
      [field]: value,
    });
  };

  const onSlidersChange = (language: FundraisingLanguages, name: string, sliders: Sliders) => {
    const newAssets = { ...fundraisingAsset };
    newAssets[language][name] = sliders;
    setFundraisingAsset(newAssets);
  };

  const DisplayTabs = () => (
    <>
      {languages.map((language) => (
        <Tab
          key={language}
          value={language}
          label={t(lang, `form.fundraising.${language}`)} />
      ))}
    </>
  );

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onNextStep(6);
    onChange(fundraisingAsset);
  };

  Debug.log([dispatch, setErrors, handleSubmit], true);

  const getVideo = (name: string) => {
    if (!!fundraisingAsset[currentLanguage][name]) {
      return fundraisingAsset[currentLanguage][name];
    }
    return {} as Video;
  };

  return (
    <>
      <Paper square>
        <Tabs
          value={currentLanguage}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setCurrentLanguage(value)}
          aria-label="disabled tabs example">
          <DisplayTabs />
        </Tabs>
      </Paper>
      <Paper square>
        <FundraiserHeader>
          {t(lang, 'forms.fundraising.button_label')}
        </FundraiserHeader>
        <FormTextField
          form={FORM.fundraising_notification}
          name="button_label"
          onChange={onValueChange}
          value={fundraisingAsset.button_label}
          label="Button Label" />
        <FundraiserHeader>
          {t(lang, 'forms.fundraising.campaign_message')}
        </FundraiserHeader>
        <div style={{ width: '100%' }}>
          <FormRichTextEditor
            label={t(lang, 'forms.fundraising.campaign_message')}
            form={FORM.fundraising_matching_assets}
            name="campaign_message"
            onChange={onValueChange}
            value={fundraisingAsset.campaign_message ?? ''} />
        </div>
        <ImageSliderManager
          name="main_landscape_slider"
          title={t(lang, 'forms.fundraising.main_landscape_slider')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].main_landscape_slider}
          errors={[] as SliderErrors} />
        <ImageSliderManager
          name="secondary_landscape_slider"
          title={t(lang, 'forms.fundraising.secondary_landscape_slider')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].main_portrait_slider}
          errors={[] as SliderErrors} />
        <ImageSliderManager
          name="secondary_portrait_slider"
          title={t(lang, 'forms.fundraising.secondary_landscape_slider')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].secondary_portrait_slider}
          errors={[] as SliderErrors} />
        <ImageSliderManager
          name="slider_matcher"
          title={t(lang, 'forms.fundraising.slider_matcher')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].slider_matcher}
          errors={[] as SliderErrors} />
        <ImageSliderManager
          name="slider_sponsor"
          title={t(lang, 'forms.fundraising.slider_matcher')}
          onChange={onSlidersChange}
          slug={slug}
          language={currentLanguage || FundraisingLanguages.en}
          height={1600}
          width={1150}
          value={fundraisingAsset[currentLanguage].slider_sponsor}
          errors={[] as SliderErrors} />
        <FundraiserHeader>
          {t(lang, 'forms.fundraising.video_main')}
        </FundraiserHeader>
        <Row height="auto">
          <VideoChooser
            onChange={(video:Video) => onValueChange(video, 'video_main')}
            value={getVideo('video_main')}
            name="video_main"
            slug={slug}
            language={currentLanguage || FundraisingLanguages.en} />
        </Row>
        <FundraiserHeader>
          {t(lang, 'forms.fundraising.video_secondary')}
        </FundraiserHeader>
        <Row height="auto">
          <VideoChooser
            onChange={(video:Video) => onValueChange(video, 'video_secondary')}
            value={getVideo('video_secondary')}
            name="video_secondary"
            slug={slug}
            language={currentLanguage || FundraisingLanguages.en} />
        </Row>
      </Paper>
    </>
  );
};

export default FundraisingMatchingAssetsForm;
