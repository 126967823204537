export default class Debug {
  static log = (message:any, hide = false) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      if (!hide) console.log(message);
    }
  };

  static errorLog = (message:any) => {
    const header = '+++++++++++++++++++++++++++ Error +++++++++++++++++++++++++++';
    const line = '++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++';

    // eslint-disable-next-line no-console
    console.error(`${line}\n${header}\n${line}\n`, message, `${line}`);
  };
}
