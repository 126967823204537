import { SettingListModel } from 'src/models/SettingListModel';
import { Reducer } from 'redux';
import { SET_SETTINGS, RESET_SETTINGS } from 'src/redux/actions/settingActions';

export type SettingsState = SettingListModel;
const DEFAULT_SETTINGS: SettingsState = [] as SettingsState;
const settingsReducer: Reducer<SettingsState> = (state = DEFAULT_SETTINGS, action) => {
  switch (action.type) {
    case SET_SETTINGS: {
      return [
        ...Object.values(action.payload.data) as SettingListModel,
      ];
    }
    case RESET_SETTINGS:
      return [];
    default:
      return state;
  }
};

export default settingsReducer;
