import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { SalutationListModel } from 'src/models/SalutationListModel';
import { CountryListModel } from 'src/models/CountryListModel';
import ContactModel, { ContactPayload, RelationModel } from 'src/models/ContactModel';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import { InvoiceModel, InvoicesModel } from 'src/models/InvoiceModel';
import { mapKeys } from 'lodash';
import { CurrencyListModel } from 'src/models/CurrencyListModel';

export const getSalutationsData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.salutations}`,
  organisation: 'aic',
});

export const normalizeSalutationListData =
  (data: any): SalutationListModel => data as SalutationListModel;

export const normalizeCountryListData =
  (data: any): CountryListModel => data as CountryListModel;

export const normalizeTimeZonesData =
  (data: any): CountryListModel => data as CountryListModel;

export const normalizeCurrenciesData =
  (data: any): CurrencyListModel => data as CurrencyListModel;

export const normalizeContact = (data: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { groups, custom_fields, ...contact } = data;
  return {
    contact,
    customfields: custom_fields,
    groups,
  } as ContactPayload;
};

export const normalizeUserContact = (data: any) => data;

export const getCountriesData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.countries}`,
  organisation: 'aic',
});

export const getTimeZoneData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.timezones}`,
  organisation: 'aic',
});

export const transformDataForApi = (contact: ContactModel) => {
  const data = {} as ContactModel;
  const fields = Object.keys(contact);
  fields.map((field) => {
    const value = contact[field];
    switch (field) {
      case 'phone_home':
      case 'phone_office':
      case 'phone_mobile':
        data[field] = value;
        if (!!data[field]) {
          data[field] = value.replace(/\D/g, '');
        }
        break;
      default:
        data[field] = contact[field];
    }
    return contact;
  });
  return data;
};

export const postUserContactData =
  (contact: ContactModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact}`,
    organisation: 'aic',
    data: { contact },
  });

export const putUserContactData = (contact: ContactModel) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.contact}`,
  organisation: 'aic',
  data: { contact },
});

export const getUserContactData = () => makeApiRequest({
  method: 'GET',
  url: `${API_URL.contact}`,
  organisation: 'aic',
});

export const postContactData =
  (organisation: string, payload: ContactPayload) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact}`,
    organisation,
    data: payload,
  });

export const postRelationData =
  (organisation: string, payload: RelationModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.contact_relation}`,
    organisation,
    data: payload,
  });

export const putRelationData =
  (id: number, organisation: string, payload: RelationModel) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.contact_relation}/${id}`,
    organisation,
    data: {
      ...payload,
    },
  });

export const putContactData =
  (id: number, organisation: string, payload: ContactPayload) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.contact}/${id}`,
    organisation,
    data: {
      ...payload,
      contact: transformDataForApi(payload.contact),
    },
  });

export const getContactData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.contact}/${id}?include-deleted=1`,
    organisation,
  });

export const deleteContactData =
  (ids: number[], organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.contact}/${ids.join(',')}`,
    organisation,
  });

export const deleteRelationData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.contact_relation}/${id}`,
    organisation,
  });

export const mergeContactData =
  (
    contact_id: number,
    contact_id_to_merge: number,
    organisation: string,
    payload: ContactPayload,
  ) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.contact}/${contact_id}/merge/${contact_id_to_merge}`,
    organisation,
    data: {
      ...payload,
      contact: transformDataForApi(payload.contact),
    },
  });

export const normalizeInvoicesForContact = (data:any) => {
  const invoices = {} as InvoicesModel;
  data.forEach((invoice:any) => {
    const normalizedInvoice = mapKeys(invoice, (value: any, key:string) => key.split('.')[1]);
    invoices[normalizedInvoice.id] = <InvoiceModel>normalizedInvoice;
  });
  return invoices;
};

export const getInvoicesForContactData = (
  contact_id: number,
  organisation: string,
) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.querybuilder}${ENTITIES.invoices}`,
  organisation,
  data: {
    filters: {
      or: [
        ['invoices.contact_id', SEARCH_OPERATORS_PRIMITIVE.eq, contact_id],
      ],
    },
  },
});
