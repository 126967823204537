import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { AdminModel } from 'src/models/UserModel';
import { useEffect, useState } from 'react';
import {
  getAdminsAction,
  getCreateAdminAction,
  putAdminsAction,
} from 'src/redux/actions/userActions';
import UserSelector from 'src/components/Control/UserSelector';
import { UserOption } from 'src/models/AutocompleteModel';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FORM } from 'src/constants/Form';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import Subtitle from 'src/components/UI/Subtitle';
import { t } from 'src/lib/language';
import { Pagination } from '@material-ui/lab';
import Scroller from 'src/components/UI/Scroller';
import Item from 'src/components/Elements/Item';
import Cursor from 'src/components/UI/Cursor';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';

export enum RIGHT {
  none = 0,
  admin = 1,
  superadmin = 2,
}

const ManageUserAdmins = () => {
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  const admins = useSelector((state: Store) => state.admins);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const locale = useSelector((state: Store) => state.language.locale);

  const [option, setOption] = useState({} as UserOption);

  const [email, setEmail] = useState('' as string);

  useEffect(() => {
    dispatch(getAdminsAction());
  }, []);

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageUserAdmins }));
  };

  const handleUpdateRight = (userId: number, right: RIGHT) => {
    setOption({} as UserOption);
    dispatch(putAdminsAction(userId, right));
  };

  const handleInvite = (value: string) => {
    dispatch(getCreateAdminAction(value, locale));
  };

  const handleChange = (e:any, newPage:number) => {
    setPage(newPage);
  };

  const getEmail = (admin: AdminModel) => ((!!admin.last_name || !!admin.first_name)
    ? (`${admin.email} (${admin.last_name} ${admin.first_name})`)
    : admin.email);

  const getRowStyle = (index: number) => {
    const style = {} as any;
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems =
    (adminList: AdminModel[]) => adminList.map((admin: AdminModel, index: number) => (
      <Item
        key={`admin-${admin.id}-${index}`}
        style={getRowStyle(index)}>
        <span><b>{getEmail(admin)}</b></span>
        <span style={{ marginLeft: 'auto' }}>
          <Cursor>
            <SupervisorAccountIcon
              color={admin.is_super_admin ? 'primary' : 'disabled'}
              onClick={
              () => handleUpdateRight(
                admin.id, admin.is_super_admin ? RIGHT.admin : RIGHT.superadmin,
              )
            } />
          </Cursor>
        </span>
        <Cursor>
          <HighlightOffIcon onClick={() => handleUpdateRight(admin.id, RIGHT.none)} />
        </Cursor>
      </Item>
    ));

  const displayItems = () => {
    const MAX_ITEM = 13;
    if (admins.length <= MAX_ITEM) {
      return (<Scroller height="500px">{getItems(admins)}</Scroller>
      );
    }
    const count = Math.ceil(admins.length / MAX_ITEM);
    const start = (page - 1) * MAX_ITEM;
    const adminList = admins.slice(start, start + MAX_ITEM);
    return (
      <>
        <Pagination page={page} count={count} onChange={handleChange} size="small" />
        <div style={{ marginBottom: '8px', marginTop: '8px' }}>
          <Scroller height="500px">{getItems(adminList)}</Scroller>
        </div>
        <Pagination page={page} count={count} onChange={handleChange} size="small" />
      </>
    );
  };

  return (
    <>
      <Header>
        <Row>
          <Title>{t(lang, 'misc.manage_admins')}</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      <div style={{
        paddingTop: '8px',
        paddingBottom: '14px',
        display: 'flex',
        alignItems: 'center',
      }}>
        <UserSelector onChange={setOption} currentValue={option} />
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!option.id}
          onClick={() => handleUpdateRight(option.id, RIGHT.admin)}>
          {t(lang, 'misc.add')}
        </Button>
      </div>
      { displayItems() }
      <Subtitle>{t(lang, 'misc.send_invite_to_become_admin')}r</Subtitle>
      <Row>
        <FormTextField
          form={FORM.invite_admin}
          label="Email"
          onChange={(value:string) => setEmail(value)}
          required
          value={email}
          name="" />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleInvite(email)}>
          {t(lang, 'misc.invite')}
        </Button>
      </Row>
    </>
  );
};

export default ManageUserAdmins;
