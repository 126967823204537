import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { CustomFieldsPayload, CustomFieldType } from 'src/models/CustomfieldModel';

export const normalizeUserCustomfields = (data: any) => {
  const result:Array<any> = [];
  data.forEach((customfield:any) => {
    if (
      customfield.type_id !== CustomFieldType.multipleList ||
      customfield.type_id !== CustomFieldType.singleList
    ) {
      result[customfield.name] = {
        id: customfield.id,
        display_fr: customfield.display.fr_CA,
        display_en: customfield.display.en_CA,
        name: customfield.name,
        type_id: customfield.type_id,
        required: !!customfield.required,
      };
    }
  });
  return result;
};

export const getCustomFieldsData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.custom_fields}`,
    organisation,
  });

export const postCustomFieldsData =
  (payload: CustomFieldsPayload, organisation: string) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.custom_field}`,
    organisation,
    data: payload,
  });

export const putCustomFieldsData =
  (id: number, payload: CustomFieldsPayload, organisation: string) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.custom_field}/${id}`,
    organisation,
    data: payload,
  });

export const deleteCustomFieldsData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.custom_field}/${id}`,
    organisation,
  });
