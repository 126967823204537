const contact = {
  field_required: 'Ce champ est requis',
  field_maximum: 'Ce champ accepte un maximum de %max% charactères',

  required_if_no_company_name: 'Les champs prénom et nom de famille sont requis sans un nom de compagnie',
  required_if_no_name: 'Le champ nom de compagnie est requis sans les champs prénom et nom de famille',
  required_if_no_contact_id: 'Le champ organisation id est requis sans le champ contact id',
  required_if_no_organisation_id: 'Le champ contact id est requis sans les champs organisation id',
  //
  invalid_email: 'Courriel invalide',
  //
  phone_number_format: 'Format de numéro de téléphone invalide',
  at_least_one_phone_required: 'Au moins un numéro de téléphone est requis',
};

export default contact;
