import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import UserAddCreditCardForm from 'src/components/Forms/User/UserAddCreditCardForm';

const UserAddCreditCardModal = () => (
  <ModalBase
    name={MODALS.addUserCreditCard}
    size={MODALS_SIZE.small}
    height="48vh">
    <UserAddCreditCardForm />
  </ModalBase>
);

export default UserAddCreditCardModal;
