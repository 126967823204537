const not_verified = {
  activate: 'Activate your account',
  sent_email: 'We sent you an email',
  confirmation_email: 'We sent you a confirmation email to confirm your identity. Please check your email and click on the verification link in the email to verify your account.',
  received_email: 'You didn\'t received a verification email?',
  check_spam: 'Check your spam folder or click the button below.',
  resend_email: 'Resend the verification email',
  different_account: 'Want to login with a different account?',
  login: 'Login',
};

export default not_verified;
