const login = {
  invalid_email: 'Le courriel n\'est pas valide',
  email_required: 'Le courriel est requis',
  password_required: 'Le mot de passe est requis',
  password_min: 'Le mot de passe doit avoir un minimum de 6 charactères',
  password_must_match: 'Le mot de passe et la confirmation du mot doivent être identique',
  confirm_password_required: 'Le champ de confirmation du mot de passe est requis',
  locale_required: 'Vous devez choisir votre langue',
};

export default login;
