import { Route, Routes } from 'react-router';
import routes from 'src/constants/routes';
import Page from 'src/components/Pages/Page';
import { v4 as uuid } from 'uuid';

const RoutesConfig = (props: any) => {
  const { children } = props;
  const components: Array<JSX.Element> = [];
  Object.keys(routes).forEach(
    (name: string) => {
      components.push(
        <Route
          path={routes[name].path}
          key={uuid()}
          element={(<Page content={routes[name].content} />)} />,
      );
      return components;
    },
  );
  return (
    <div>
      <Routes>
        {components}
      </Routes>
      {children}
    </div>
  );
};

export default RoutesConfig;
