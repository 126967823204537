import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import EditCustomfieldForm from 'src/components/Forms/EditCustomfieldForm';
import { Customfield } from 'src/models/CustomfieldModel';
import { useEffect, useState } from 'react';
import AddCustomfieldForm from 'src/components/Forms/AddCustomfieldForm';
import { t } from 'src/lib/language';
import { getCustomFields } from 'src/redux/actions/customfieldActions';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Header from 'src/components/UI/Header';
import Title from 'src/components/UI/Title';
import Row from 'src/components/UI/Row';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';
import Scroller from 'src/components/UI/Scroller';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageCustomfields = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  const customfieldsData = useSelector((state: Store) => state.customfields);
  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageCustomField }));
  };

  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const [currentCustomField, setCurrentCustomField] = useState({} as Customfield);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [currentFilter, setCurrentFilter] = useState('');

  const handleDelete = (id:number) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteCustomfield, payload: id }));
  };

  const handleEdit = (customfield: Customfield) => {
    setCurrentCustomField(customfield);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentCustomField({} as Customfield);
    setCurrentState(STATE.add);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_customfield');
      case STATE.edit:
        return t(lang, 'menus.edit_customfield');
      default:
        return t(lang, 'menus.manage_customfield');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const ListItems = () => {
    const list = Object.keys(customfieldsData)
      .map((key:string, index: number) => {
        const customfield = customfieldsData[key];
        if (currentFilter) {
          if (!customfield[`display_${lang}`].includes(currentFilter)) return null;
        }
        return (
          <Item
            key={`customfield-${key}`}
            style={getStyle(index)}>
            <span><b>{ customfield[`display_${lang}`] }</b></span>
            <EditButton onClick={() => handleEdit(customfield)} title={t(lang, 'menus.edit_customfield')} />
            <DeleteButton onClick={() => handleDelete(customfield.id)} title={t(lang, 'menus.menus.delete_customfield')} />
          </Item>
        );
      });
    return (<>{list}</>);
  };

  const EditForm = () => (
    <EditCustomfieldForm
      customfield={currentCustomField}
      onClose={() => setCurrentState(STATE.list)} />
  );

  const AddForm = () => (
    <AddCustomfieldForm
      onClose={() => setCurrentState(STATE.list)} />
  );

  return (
    <>
      <Header height="48px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            Close
          </Button>
        </Row>
      </Header>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'menus.add_account')}
          onChange={setCurrentFilter} />
      )}
      <Scroller height="507px">
        { currentState === STATE.list && <ListItems /> }
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </Scroller>
    </>
  );
};

export default ManageCustomfields;
//
