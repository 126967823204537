import { FormStatus } from 'src/models/FormModel';
import { Reducer } from 'redux';
import { RESET_FORM_STATUS, SET_FORM_STATUS } from 'src/redux/actions/formActions';

export type FormStatusState = FormStatus;

const DEFAULT: FormStatusState = {} as FormStatusState;

const formStatusReducer: Reducer<FormStatusState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_FORM_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_FORM_STATUS:
      return DEFAULT;
    default:
      return state;
  }
};

export default formStatusReducer;
