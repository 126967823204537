import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import MakeDonationForm from 'src/components/Forms/MakeDonationForm';

const AdminDonationModal = () => (
  <ModalBase
    name={MODALS.adminDonation}
    size={MODALS_SIZE.small}>
    <MakeDonationForm />
  </ModalBase>
);

export default AdminDonationModal;
