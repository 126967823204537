interface Props {
  children: any,
  key: string,
}

const PaymentInfo = (props: Props) => {
  const { children, key } = props;
  return (
    <div
      style={{
        fontSize: '0.875rem',
        fontStyle: 'italic',
        display: 'flex',
        alignItems: 'center',
      }}
      key={key}>
      {children}
    </div>
  );
};

export default PaymentInfo;
