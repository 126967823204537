import { OrderColumn } from 'src/components/Elements/Guess/GuessOrderDisplayer';
import { t } from 'src/lib/language';
import { useSelector } from 'react-redux';
import { Store } from 'src/redux/Store';
import { useMediaQuery } from 'react-responsive';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const OrderDisplayerHeader = () => {
  const lang = useSelector((state: Store) => state.language.language);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' });

  const Divider = () => (
    <div style={{
      width: '1px',
      height: '20px',
      backgroundColor: 'rgba(0, 0, 0, .75)',
    }} />
  );

  const getColumnStyle = (column: string) => {
    if (column === 'amount') {
      return {
        textAlign: 'right',
        minWidth: OrderColumn[column],
        width: OrderColumn[column],
        fontWeight: 'bold',
        padding: '0 8px',
      } as CSSProperties;
    }
    return {
      minWidth: OrderColumn[column],
      width: OrderColumn[column],
      fontWeight: 'bold',
      padding: '0 8px',
    } as CSSProperties;
  };

  const showDesktop = () => (
    <div style={{
      marginTop: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)',
      height: '40px',
    }}>
      <div style={getColumnStyle('product')}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <Divider />
      <div style={getColumnStyle('deductible')}>
        {t(lang, 'forms.invoices.deductible')}
      </div>
      <Divider />
      <div style={getColumnStyle('price')}>
        {t(lang, 'forms.invoices.price')}
      </div>
      <Divider />
      <div style={getColumnStyle('amount')}>
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  const showTablet = () => (
    <div style={{
      marginTop: '16px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.1)',
      height: '40px',
    }}>
      <div style={getColumnStyle('product')}>
        {t(lang, 'forms.invoices.product')}
      </div>
      <div style={getColumnStyle('amount')}>
        {t(lang, 'forms.invoices.amount')}
      </div>
    </div>
  );

  return isTabletOrMobile ? showTablet() : showDesktop();
};

export default OrderDisplayerHeader;
