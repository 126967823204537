import { State } from 'src/redux/reducers/RootReducer';
import {
  getCountriesData,
  getSalutationsData,
  normalizeCountryListData,
  normalizeSalutationListData,
} from 'src/apis/ContactAPI';
import {
  deleteAccountsData,
  deleteDepartmentsData,
  deleteProgramsData,
  getAccountsData,
  getCurrenciesData,
  getDepartmentsData,
  getProgramsData,
  getRelationsData,
  getTimeZoneData,
  normalizeAccountListData,
  normalizeDepartmentListData,
  normalizeProgramListData,
  normalizeRelationsData,
  normalizeTimeZonesData,
  postAccountsData,
  postDepartmentsData,
  postProgramsData,
  putAccountsData,
  putDepartmentsData,
  putProgramsData,
} from 'src/apis/DataApi';
import makeActionCreator from 'src/lib/makeActionCreator';
import { DepartmentItemModel } from 'src/models/DepartmentListModel';
import { AccountItemModel } from 'src/models/AccountListModel';
import { ProgramItemModel } from 'src/models/ProgramListModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';

export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_CURRENCIES = 'SET_CURRENCIES';
export const SET_SALUTATIONS = 'SET_SALUTATIONS';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const SET_TZ = 'SET_TZ';
export const SET_RELATIONS = 'SET_RELATIONS';

export const setCountriesData = makeActionCreator(SET_COUNTRIES);
export const setCurrenciesData = makeActionCreator(SET_CURRENCIES);
export const setSalutationsData = makeActionCreator(SET_SALUTATIONS);
export const setAccountsData = makeActionCreator(SET_ACCOUNTS);
export const setDepartmentsData = makeActionCreator(SET_DEPARTMENTS);
export const setProgramsData = makeActionCreator(SET_PROGRAMS);
export const setTimeZone = makeActionCreator(SET_TZ);
export const setRelationsData = makeActionCreator(SET_RELATIONS);

export const getCountriesAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getCountriesData();
      if (response.success) {
        const countryListData = normalizeCountryListData(response.data);
        dispatch(setCountriesData(countryListData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getCurrenciesAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getCurrenciesData();
      if (response.success) {
        const countryListData = normalizeCountryListData(response.data);
        dispatch(setCurrenciesData(countryListData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getTimeZoneAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getTimeZoneData();
      if (response.success) {
        const tzData = normalizeTimeZonesData(response.data);
        dispatch(setTimeZone(tzData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getRelationsAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getRelationsData(organisation);
      if (response.success) {
        const relationListData = normalizeRelationsData(response.data);
        dispatch(setRelationsData(relationListData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getSalutationsAction: () => ThunkedAction<State> =
  () => async (dispatch: any) => {
    try {
      const response = await getSalutationsData();
      if (response.success) {
        const salutationsData = normalizeSalutationListData(response.data);
        dispatch(setSalutationsData(salutationsData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const getDepartmentsAction: () => ThunkedAction<State> =
() => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await getDepartmentsData(organisation);
    if (response.success) {
      const departmentsData = normalizeDepartmentListData(response.data);
      dispatch(setDepartmentsData(departmentsData));
    }
  } catch (e) { /* Log the error here */
  }
};

export const postDepartmentsAction: (department: DepartmentItemModel) => ThunkedAction<State> =
(department: DepartmentItemModel) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await postDepartmentsData(organisation, department);
    if (response.success) {
      const departmentsData = normalizeDepartmentListData(response.data);
      dispatch(setDepartmentsData(departmentsData));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.department_saved',
      }));
      return;
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_saved_error',
    }));
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_saved_error',
    }));
  }
};

export const putDepartmentsAction: (department: DepartmentItemModel) => ThunkedAction<State> =
(department: DepartmentItemModel) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await putDepartmentsData(organisation, department);
    if (response.success) {
      const departmentsData = normalizeDepartmentListData(response.data);
      dispatch(setDepartmentsData(departmentsData));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.department_saved',
      }));
      return;
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_saved_error',
    }));
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_saved_error',
    }));
  }
};

export const deleteDepartmentsAction: (id: number) => ThunkedAction<State> =
(id: number) => async (dispatch: any, getState: any) => {
  try {
    const organisation = getState().currentOrganisation.id;
    const response = await deleteDepartmentsData(organisation, id);
    if (response.success) {
      const departmentsData = normalizeDepartmentListData(response.data);
      dispatch(setDepartmentsData(departmentsData));
      dispatch(setAlert({
        type: ALERT_TYPE.success,
        code: 'messages.department_deleted',
      }));
      return;
    }
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_deleted_error',
    }));
  } catch (e) { /* Log the error here */
    dispatch(setAlert({
      type: ALERT_TYPE.error,
      code: 'messages.department_deleted_error',
    }));
  }
};

export const getAccountsAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getAccountsData(organisation);
      if (response.success) {
        const accountsData = normalizeAccountListData(response.data);
        dispatch(setAccountsData(accountsData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const postAccountsAction: (account: AccountItemModel) => ThunkedAction<State> =
  (department: AccountItemModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postAccountsData(organisation, department);
      if (response.success) {
        const accountsData = normalizeAccountListData(response.data);
        dispatch(setAccountsData(accountsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.account_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_saved_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_saved_error',
      }));
    }
  };

export const putAccountsAction: (account: AccountItemModel) => ThunkedAction<State> =
  (account: AccountItemModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putAccountsData(organisation, account);
      if (response.success) {
        const accountsData = normalizeAccountListData(response.data);
        dispatch(setAccountsData(accountsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.account_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_saved_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_saved_error',
      }));
    }
  };

export const deleteAccountsAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteAccountsData(organisation, id);
      if (response.success) {
        const accountsData = normalizeAccountListData(response.data);
        dispatch(setAccountsData(accountsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.account_deleted',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_deleted_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.account_deleted_error',
      }));
    }
  };

export const getProgramsAction: () => ThunkedAction<State> =
  () => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await getProgramsData(organisation);
      if (response.success) {
        const programsData = normalizeProgramListData(response.data);
        dispatch(setProgramsData(programsData));
      }
    } catch (e) { /* Log the error here */
    }
  };

export const postProgramsAction: (program: ProgramItemModel) => ThunkedAction<State> =
  (program: ProgramItemModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postProgramsData(organisation, program);
      if (response.success) {
        const programsData = normalizeProgramListData(response.data);
        dispatch(setProgramsData(programsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.program_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_saved_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_saved_error',
      }));
    }
  };

export const putProgramsAction: (program: ProgramItemModel) => ThunkedAction<State> =
  (program: ProgramItemModel) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putProgramsData(organisation, program);
      if (response.success) {
        const programsData = normalizeProgramListData(response.data);
        dispatch(setProgramsData(programsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.program_saved',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_saved_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_saved_error',
      }));
    }
  };

export const deleteProgramsAction: (id: number) => ThunkedAction<State> =
  (id: number) => async (dispatch: any, getState: any) => {
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteProgramsData(organisation, id);
      if (response.success) {
        const programsData = normalizeProgramListData(response.data);
        dispatch(setProgramsData(programsData));
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.program_deleted',
        }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_deleted_error',
      }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.program_deleted_error',
      }));
    }
  };
