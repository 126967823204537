import contact from 'src/i18n/en/validations/contact';
import login from 'src/i18n/en/validations/login';
import invoice from 'src/i18n/en/validations/invoice';
import creditcard from 'src/i18n/en/validations/creditcard';

const validations = {
  contact,
  creditcard,
  invoice,
  login,
};

export default validations;
