const payment = {
  id: 'Id',
  amount: 'Amount',
  contact_id: 'Contact',
  seller_id: 'Seller',
  deductible: 'Deductible',
  scheduled_date: 'Scheduled Date',
  bank_account_id: 'Bank account',
  paid_date: 'Paid Date',
  processed_date: 'Processed Date',
  payment_action: 'Payment Action',
  payment_type: 'Payment Type',
  payment_status: 'Payment Status',
  title: 'Title',
  description: 'Description',
  reference: 'Reference',
  reference_type: 'Reference Type',
  contact_first_name: 'Contact Firstname',
  contact_last_name: 'Contact Lastname',
  contact_company_name: 'Contact Company Name',
  billing_address: 'Billing Address',
  billing_city: 'Billing City',
  billing_suite: 'Billing Suite',
  billing_state: 'Billing State',
  billing_zip_code: 'Billing Zip Code',
  billing_country_id: 'Billing Country',
  shipping_address: 'Shipping Address',
  shipping_suite: 'Shipping Suite',
  shipping_city: 'Shipping City',
  shipping_state: 'Shipping State',
  shipping_country_id: 'Shipping Country',
  shipping_zip_code: 'Shipping Zip Code',
  is_expense: 'Is expense',
  is_refund: 'Is refund',
  issue_tax_receipt: 'Tax Receipt',
  tax_receipt_issued: 'Tax Receipt Issued',
  payment_receipt_sent: 'Receipt Sent',
  payment_receipt_printed: 'Receipt Printed',
  contact_salutation_id: 'Contact Salutation',
  account_id: 'Account',
  program_id: 'Program',
  department_id: 'Department',
};

export default payment;
