import CssBaseline from '@material-ui/core/CssBaseline';
import { Route } from 'src/constants/routes';
import Content from 'src/components/Layout/Content';
import Main from 'src/components/Layout/Main';
import GuessInvoicePage from 'src/components/Pages/GuessInvoicePage';

interface Props {
  content: string
}

export default function GuessLayout(props: Props) {
  const { content } = props;

  const displayContent = () => {
    switch (content) {
      case Route.GUESS_INVOICE:
        return <GuessInvoicePage />;
      default:
        return (
          <></>
        );
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', height: '100vh' }}>
        <CssBaseline />
        <Main>
          <Content style={{
            flex: 1,
            background: '#FFF',
            width: '100%',
            marginTop: '38px',
          }}>
            { displayContent() }
          </Content>
        </Main>
      </div>
    </div>
  );
}
//
