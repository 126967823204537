const contact = {
  id: 'id',
  locale_id: 'Language',
  salutation_id: 'Salutation',
  email: 'Courriel',
  email_status: 'Statut du Courriel',
  email_allow_notification: 'Notification',
  secondary_email: 'Courriel Secondaire',
  secondary_email_allow_notification: 'Notification du Courriel Secondaire',
  company_name: 'Compagnie',
  first_name: 'Prénom',
  last_name: 'Nom',
  gender: 'Genre',
  billing_address: 'Addresse (Facturation)',
  billing_city: 'Ville (Facturation)',
  billing_state: 'État/Province (Facturation)',
  billing_suite: 'Suite (Facturation)',
  billing_zip_code: 'Zipcode (Facturation)',
  billing_country_id: 'Pays (Facturation)',
  shipping_address: 'Addresse (Expédition)',
  shipping_city: 'Ville (Expédition)',
  shipping_state: 'État/Province (Expédition)',
  shipping_suite: 'Suite (Expédition)',
  shipping_zip_code: 'Zipcode (Expédition)',
  shipping_country_id: 'Pays (Expédition)',
  phone_home: 'Téléphone (maison)',
  phone_home_allow_notification: 'Notification Téléphone (maison)',
  phone_mobile: 'Téléphone (mobile)',
  phone_mobile_allow_notification: 'Notification Téléphone (mobile)',
  phone_office: 'Téléphone (bureau)',
  phone_office_allow_notification: 'Notification Téléphone (bureau)',
  created_at: 'Créer',
  updated_at: 'Mise-à-jour',
  deleted_at: 'Supprimer',
};

export default contact;
