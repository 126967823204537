import { makeStyles } from '@material-ui/core/styles';

const useStyles = (disabled:boolean) => makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    opacity: disabled ? 0.5 : 1,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

interface Props {
  onClick?: () => void,
  children: any,
  disabled?: boolean,
}

const TextLink = (props: Props) => {
  const { onClick, children, disabled } = props;
  const classes = useStyles(!!disabled)();

  const onClickHandler = () => {
    if (!onClick) return;
    onClick();
  };
  return (
    <span
      className={classes.link}
      onClick={onClickHandler}>
      {children}
    </span>
  );
};

export default TextLink;
