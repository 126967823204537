import * as Yup from 'yup';
import { t } from 'src/lib/language';

const ProgramCreationValidator = (lang:string) => Yup.object().shape({
  name_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  name_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .required(),
  description_en: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  description_fr: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
});

export default ProgramCreationValidator;
