import * as Yup from 'yup';
import { t } from 'src/lib/language';

const OrganisationContactValidator = (lang:string) => Yup.object().shape({
  website: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  email: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  address: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  state: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  zipCode: Yup.string()
    .max(6, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  phone: Yup.number(),
  extension: Yup.number(),
  tollfreePhone: Yup.number(),
  tollfreeExtension: Yup.number(),
});

export default OrganisationContactValidator;
