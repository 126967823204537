import * as Yup from 'yup';
import { t } from 'src/lib/language';

const FullContactShippingValidator = (lang: string) => Yup.object().shape({
  shipping_address: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 })),
  shipping_suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_city: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  shipping_state: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 })),
  shipping_country_id: Yup.number()
    .required(t(lang, 'validations.contact.field_required'))
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer(),
  shipping_zip_code: Yup.string()
    .required(t(lang, 'validations.contact.field_required'))
    .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 })),
});

export default FullContactShippingValidator;
