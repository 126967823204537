import makeActionCreator from 'src/lib/makeActionCreator';
import { State } from 'src/redux/reducers/RootReducer';
import {
  deleteTaxData, getTaxData, postTaxData, putTaxData,
} from 'src/apis/TaxAPI';
import { setFormStatus } from 'src/redux/actions/formActions';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import { TaxItemModel } from 'src/models/TaxListModel';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { setError } from 'src/redux/actions/errorsActions';

export const SET_TAXES = 'SET_TAXES';

export const setTaxesData = makeActionCreator(SET_TAXES);

export const getTaxesAction: () => ThunkedAction<State> =
() => async (dispatch: any, getState: any) => {
  try {
    dispatch(setFormStatus({ [FORM.get_taxes]: FORM_STATUS.processing }));
    const organisation = getState().currentOrganisation.id;
    const response = await getTaxData(organisation);
    if (response.success) {
      const { data } = response;
      dispatch(setTaxesData({ data }));
      dispatch(setFormStatus({ [FORM.get_taxes]: FORM_STATUS.success }));
    } else {
      dispatch(setFormStatus({ [FORM.get_taxes]: FORM_STATUS.error }));
    }
  } catch (e) { /* Log the error here */
    dispatch(setFormStatus({ [FORM.get_taxes]: FORM_STATUS.error }));
  }
};

export const putTaxAction: (tax:TaxItemModel) => ThunkedAction<State> =
  (tax:TaxItemModel) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.put_tax]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await putTaxData(organisation, tax);
      if (response.success) {
        dispatch(getTaxesAction());
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.tax_saved',
        }));
        dispatch(setFormStatus({ [FORM.put_tax]: FORM_STATUS.success }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors).join('\n'),
      }));
      dispatch(setFormStatus({ [FORM.put_tax]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.tax_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.put_tax]: FORM_STATUS.error }));
    }
  };

export const postTaxAction: (tax:TaxItemModel) => ThunkedAction<State> =
  (tax:TaxItemModel) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.post_tax]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await postTaxData(organisation, tax);
      if (response.success) {
        dispatch(getTaxesAction());
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.tax_saved',
        }));
        dispatch(setFormStatus({ [FORM.post_tax]: FORM_STATUS.success }));
        return;
      }
      if (response.status === 400) {
        dispatch(setAlert({
          type: ALERT_TYPE.error,
          code: 'messages.tax_saved_error',
        }));
        dispatch(setError({ [FORM.post_tax]: response.errors }));
        dispatch(setFormStatus({ [FORM.post_tax]: FORM_STATUS.error }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: Object.values(response.errors).join('\n'),
      }));
      dispatch(setFormStatus({ [FORM.post_tax]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.tax_saved_error',
      }));
      dispatch(setFormStatus({ [FORM.post_tax]: FORM_STATUS.error }));
    }
  };

export const deleteTaxAction: (tax:TaxItemModel) => ThunkedAction<State> =
  (tax:TaxItemModel) => async (dispatch: any, getState: any) => {
    dispatch(setFormStatus({ [FORM.delete_tax]: FORM_STATUS.processing }));
    try {
      const organisation = getState().currentOrganisation.id;
      const response = await deleteTaxData(organisation, tax);
      if (response.success) {
        dispatch(getTaxesAction());
        dispatch(setAlert({
          type: ALERT_TYPE.success,
          code: 'messages.deleted_tax',
        }));
        dispatch(setFormStatus({ [FORM.delete_tax]: FORM_STATUS.success }));
        return;
      }
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.deleted_tax_error',
      }));
      dispatch(setFormStatus({ [FORM.delete_tax]: FORM_STATUS.error }));
    } catch (e) { /* Log the error here */
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        code: 'messages.deleted_tax_error',
      }));
      dispatch(setFormStatus({ [FORM.delete_tax]: FORM_STATUS.error }));
    }
  };
