const products = {
  price: 'Prix',
  cost: 'Cout',
  deductible: 'Déductible',
  quantity: 'Quantité',
  picture: 'Photo',
  is_managed: 'Est géré',
  is_public: 'Est publique',
  is_modifiable: 'Est modifiable',
  allow_installment: 'Permettre paiement échelonnées',
  discount: 'Rabais',
  filter: 'Filtre',
};

export default products;
