import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { SettingModel } from 'src/models/SettingListModel';

export const getSettingsData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: `${API_URL.settings}`,
    organisation,
  });

export const postSettingData =
  (organisation: string, setting:SettingModel) => makeApiRequest({
    method: 'POST',
    url: `${API_URL.setting}`,
    organisation,
    data: setting,
  });
