import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import ManageRelations from 'src/components/Elements/ManageRelations';

const RelationsManagementModal = () => (
  <ModalBase
    name={MODALS.manageRelations}
    size={MODALS_SIZE.small}>
    <ManageRelations />
  </ModalBase>
);

export default RelationsManagementModal;
