const contact = {
  add: 'Ajouter',
  filters: 'Filtres',
  clear: 'Effacer',
  add_custom_fields: 'Ajouter champs personnalisés',
  add_bank_accounts: 'Ajouter un compte bancaire',
  validation_errors: 'Erreur de validation, SVP vérifiez vos informations.',
  salutations: 'Salutation',
  first_name: 'Prénom',
  groups: 'Groupes',
  custom_fields: 'Champs personnalisés',
  last_name: 'Nom de famille',
  company_name: 'Compagnie (si applicable)',
  primary_email: 'Principal',
  email_notification: 'Authoriser l\'envoie de courriels à cette addresse',
  secondary_email: 'Secondaire',
  address: 'Addresse (ex: 371 Dupuis)',
  suite: 'Suite (si applicable)',
  city: 'Ville',
  state: 'Etat/Province',
  country: 'Pays',
  currencies: 'Devises',
  zip_code: 'Code Postal',
  same_as_billing: 'Cocher si l\'addresse de facturation est la meme que l\'addresse d\'expédition',
  phone_home: 'Maison',
  phone_mobile: 'Mobile',
  phone_office: 'Bureau',
  phone_notification: 'Authoriser l\'envoie de notifications SMS à ce numéro',
  header_name: 'Noms',
  payer_name: 'Information du payeur',
  header_emails: 'Courriels',
  header_billing_address: 'Addresse de facturation',
  header_shipping_address: 'Addresse d\'expédition',
  header_phones: 'Telephones',
  merge_contact_title: 'Combiner le contact %firstId% avec le contact %secondId%',
  contact: 'Contact',
  contact_to_merge: 'Contact à etre combiné',
  merge_contact: 'Combiner les contacts',
  contact_information: 'Informations du contact',
  meta_information: 'Informations des métas',
  issue_tax_receipt: 'Émettre recu d\'impot',
  payment_information: 'Informations de paiement',
  relationships: 'Relations',
  credit_cards: 'Carte de crédits',
  add_credit_card: 'Ajouter une carte de crédit',
  bank_accounts: 'Comptes bancaires',
  add_bank_account: 'Ajouter un compte bancaire',
  add_relationship: 'Ajouter relations',
  is: 'est',
  of: 'de',
  invoices_for: 'Factures pour',
  payments_for: 'Paiements pour',
};

export default contact;
