import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddBankAccountForm from 'src/components/Forms/AddBankAccountForm';
import { useAppDispatch } from 'src/redux/Store';
import { closeModal } from 'src/redux/actions/modalActions';

const AddBankAccountModal = () => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(closeModal({ modal: MODALS.addBankAccount }));
  };

  return (
    <ModalBase
      name={MODALS.addBankAccount}
      size={MODALS_SIZE.small}
      height="38vh">
      <AddBankAccountForm onClose={() => onClose()} />
    </ModalBase>
  );
};

export default AddBankAccountModal;
