import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { CancelPaymentItem, PaymentModel, PurchasePayload } from 'src/models/PaymentModel';

export const postPaymentData =
(organisation: string, payload: PaymentModel) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.payment}`,
  organisation,
  data: payload,
});

export const postPurchaseData =
(organisation: string, payload: PurchasePayload) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.payment_purchase}`,
  organisation,
  data: payload,
});

export const cancelPaymentData =
  (payments: CancelPaymentItem[], organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.payments}`,
    organisation,
    data: { payments },
  });

export const getPaymentData =
(id: number, organisation: string) => makeApiRequest({
  method: 'GET',
  url: `${API_URL.payment}/${id}`,
  organisation,
});

export const putPaymentData =
(id: number, payment: PaymentModel, organisation: string) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.payment}/${id}`,
  organisation,
  data: payment,
});

export const refundPaymentData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.payment}/${id}/refund`,
    organisation,
  });
