import { ErrorsModel } from 'src/models/ErrorModel';
import { Reducer } from 'redux';
import { RESET_ERRORS, SET_ERRORS } from 'src/redux/actions/errorsActions';
import { AdvancedSearchErrors } from 'src/models/AdvanceSearchModel';

export type ErrorsState = ErrorsModel | AdvancedSearchErrors;

const DEFAULT: ErrorsState = {} as ErrorsState;

const errorReducer: Reducer<ErrorsState> = (state = DEFAULT, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_ERRORS:
      return DEFAULT;
    default:
      return state;
  }
};

export default errorReducer;
