import { ENTITIES, ORDER, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import Datagrid from 'src/components/Datagrid/Datagrid';
import InvoiceForContactSearchbar from 'src/components/DatagridSearch/InvoiceForContactSearchbar';
import InvoiceForContactToolbar
  from 'src/components/DatagridToolbar/entities/InvoiceForContactToolbar';
import {
  getEntitiesAction,
  setColumns,
  setFilters,
  setOrders,
  setQueryBuilderResult,
} from 'src/redux/actions/querybuilderActions';
import DEFAULT_COLUMNS from 'src/constants/columns';
import { SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import { resetSelections } from 'src/redux/actions/selectionActions';
import { Store, useAppDispatch } from 'src/redux/Store';
import { useSelector } from 'react-redux';
import { t } from 'src/lib/language';
import { useEffect } from 'react';
import { getContactByIdAction } from 'src/redux/actions/contactActions';

const InvoiceForContactDatagrid = () => {
  const dispatch = useAppDispatch();

  const getContactId = () => {
    const pathSegments = window.location.pathname.split('/');
    return Number(pathSegments[pathSegments.length - 1]);
  };

  const contact = useSelector((state: Store) => state.contacts[getContactId()]?.contact);
  const lang = useSelector((state: Store) => state.language.language);

  const getName = () => {
    if (!contact) return '';
    if (contact.company_name) {
      return contact.company_name;
    }
    return `${contact.first_name} ${contact.last_name}`;
  };

  useEffect(() => {
    dispatch(getContactByIdAction(getContactId()));
    dispatch(setColumns({
      entity: ENTITIES.invoices,
      columns: DEFAULT_COLUMNS.invoicesContact,
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity: ENTITIES.invoices,
      order: [['invoices.due_date', ORDER.desc]],
    }));
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: {
        and: [['invoices.contact_id', SEARCH_OPERATORS_PRIMITIVE.eq, getContactId()]],
      },
    }));
    dispatch(resetSelections({ entity: ENTITIES.invoices }));
    dispatch(setQueryBuilderResult({
      entity: ENTITIES.invoices,
      result: {
        count: 0,
        list: [],
      },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  }, [getContactId()]);

  useEffect(() => {
    document.title = `${t(lang, 'forms.contact.invoices_for')}: ${getName()}`;
  }, [contact]);

  return (
    <div>
      <InvoiceForContactSearchbar contactId={getContactId()} />
      <InvoiceForContactToolbar />
      <Datagrid entity={ENTITIES.invoices} dontReloadEntities />
    </div>
  );
};

export default InvoiceForContactDatagrid;
