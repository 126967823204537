const login = {
  sign_in: 'Sign in',
  language: 'Language',
  remember_me: 'Remember me',
  dont_have_account: 'Don\'t have an account?',
  forget_password: 'Forget password?',
  forget_password_text: 'Please enter the email you used to signin. We will send you an email with a link to change your password.',
  send: 'Send',
  have_account: 'Already have an account?',
  sign_up: 'Sign up',
  login: 'login',
  policy: 'By signing in you agree to our',
  privacy_policy: 'privacy policy',
  tos: 'terms of service',
  your_email: 'Your email address',
  password: 'Password',
  confirm_password: 'Confirm Password',
};

export default login;
