import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { RootReducer } from 'src/redux/reducers/RootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'querybuilder',
    'modals',
    'selected',
    'dialogs',
    'formStatus',
    'errors',
    'alert',
  ],
};

const rootReducer = combineReducers(RootReducer);
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(thunk),
  },
);

export const persistor = persistStore(store);

export type Store = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
