import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Props {
  children: any,
  style?: CSSProperties,
}

const BottomRow = (props: Props) => {
  const {
    children,
    style,
  } = props;

  const styles: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '10px',
    ...style,
  };
  return (
    <div style={styles}>
      {children}
    </div>
  );
};

export default BottomRow;
