interface Props {
  children: any,
}

const Spacer = (props: Props) => {
  const { children } = props;
  return (
    <div style={{ maxWidth: '640px' }}>
      { children }
    </div>
  );
};

export default Spacer;
