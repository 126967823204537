import { PersistGate } from 'redux-persist/integration/react';
import { Provider as StoreProvider } from 'react-redux';
import { persistor, store } from 'src/redux/Store';
import theme from 'src/theme/PerfectDeedTheme';
import { BrowserRouter } from 'react-router-dom';
import RoutesConfig from 'src/components/Routes/RoutesConfig';
import { MuiThemeProvider, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LocalizedAlert from 'src/components/UI/LocalizedAlert';
import ModalsContainer from 'src/components/Modals/ModalsContainer';
import DialogContainer from 'src/components/Dialogs/DialogContainer';
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_SECRET } from 'src/environments/environment';

const Root = () => (
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} clientSecret={GOOGLE_CLIENT_SECRET}>
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <RoutesConfig />
              <LocalizedAlert />
              <ModalsContainer />
              <DialogContainer />
            </BrowserRouter>
          </ThemeProvider>
        </MuiThemeProvider>
      </PersistGate>
    </StoreProvider>
  </GoogleOAuthProvider>
);
//
export default Root;
