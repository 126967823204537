import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import { FORM } from 'src/constants/Form';
import { t } from 'src/lib/language';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { INVOICE_PAYMENT_STATUS, INVOICE_STATUS } from 'src/constants/Invoices';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Button from '@material-ui/core/Button';
import { Filter } from 'src/models/AdvanceSearchModel';
import { SEARCH_OPERATORS, SEARCH_OPERATORS_PRIMITIVE } from 'src/constants/SearchOperators';
import FormMultipleSelect from 'src/components/Control/FormControls/FormMultipleSelect';
import { ENTITIES, ORDER, QuerbuilderPayloadColumns } from 'src/models/QuerybuilderModel';
import {
  getAccountsAction,
  getDepartmentsAction,
  getProgramsAction,
} from 'src/redux/actions/dataActions';
import DEFAULT_COLUMNS from 'src/constants/columns';
import {
  setFilters,
  getEntitiesAction,
  setColumns,
  setOrders,
} from 'src/redux/actions/querybuilderActions';
import { DateType, getDateValue } from 'src/lib/DateHelper';
import { Moment } from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontSize: '1.8rem',
    fontFamily: 'Qanelas-Bold',
    height: '48px',
  },
  title: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    fontSize: '18px',
  },
  noMargin: {
    height: '32px',
    marginBottom: '0 !important',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  col2: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  col3: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
  },
  col6: {
    width: '66%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    backgroundColor: '#f8f8f8',
    border: '1px solid #eee',
    padding: theme.spacing(1, 1),
  },
  text: {
    padding: theme.spacing(0, 1),
  },
}));

interface SearchState {
  title?: string,
  paymentStatus: INVOICE_PAYMENT_STATUS[],
  invoiceStatus: INVOICE_STATUS[],
  programs: number[],
  departments: number[],
  accounts: number[],
  start_date?: Moment,
  end_date?: Moment,
}

interface Props {
  contactId: number,
}

const InvoiceForContactSearchbar = (props: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { contactId } = props;

  const lang = useSelector((state: Store) => state.language.language);

  const contact = useSelector((state: Store) => state.contacts[contactId]?.contact);

  const departments = useSelector((state: Store) => state.data.departments);
  const programs = useSelector((state: Store) => state.data.programs);
  const accounts = useSelector((state: Store) => state.data.accounts);

  const getDefaultStartDate = () => moment().subtract(18, 'months').startOf('day');

  const getDefaultEndDate = () => moment().add(6, 'months').endOf('day');

  useEffect(() => {
    dispatch(getProgramsAction());
    dispatch(getDepartmentsAction());
    dispatch(getAccountsAction());

    dispatch(setColumns({
      entity: ENTITIES.invoices,
      columns: DEFAULT_COLUMNS.invoicesContact,
    } as QuerbuilderPayloadColumns));
    dispatch(setOrders({
      entity: ENTITIES.invoices,
      order: [['invoices.due_date', ORDER.desc]],
    }));
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: {
        and: [
          ['invoices.contact_id', SEARCH_OPERATORS_PRIMITIVE.eq, contactId],
          ['invoices.state', SEARCH_OPERATORS.is_in, [
            INVOICE_STATUS.final, INVOICE_STATUS.draft, INVOICE_STATUS.error,
          ]],
          ['invoices.issued_date', SEARCH_OPERATORS.is_between, [
            getDefaultStartDate(),
            getDefaultEndDate(),
          ]],
        ],
      },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  }, []);

  const initialSearch = {
    title: '',
    paymentStatus: [] as INVOICE_PAYMENT_STATUS[],
    invoiceStatus: [
      INVOICE_STATUS.final, INVOICE_STATUS.draft, INVOICE_STATUS.error,
    ] as INVOICE_STATUS[],
    programs: [] as number[],
    departments: [] as number[],
    accounts: [] as number[],
    start_date: getDefaultStartDate(),
    end_date: getDefaultEndDate(),
  } as SearchState;

  const [state, setState] = useState(initialSearch);

  const getDepartmentItems = () => departments.map(
    (department) => ({ id: department.id, label: department.name_en }),
  );

  const getProgramItems = () => programs.map(
    (program) => ({ id: program.id, label: program.name_en }),
  );

  const getAccountItems = () => accounts.map(
    (account) => ({ id: account.id, label: account.name_en }),
  );

  const getInvoiceStatusItem = () => ([
    { label: INVOICE_STATUS.draft, id: INVOICE_STATUS.draft },
    { label: INVOICE_STATUS.final, id: INVOICE_STATUS.final },
    { label: INVOICE_STATUS.legacy, id: INVOICE_STATUS.legacy },
    { label: INVOICE_STATUS.error, id: INVOICE_STATUS.error },
    { label: INVOICE_STATUS.canceled, id: INVOICE_STATUS.canceled },
    { label: INVOICE_STATUS.abandoned, id: INVOICE_STATUS.abandoned },
  ]);

  const getPaymentStatus = () => ([
    { label: INVOICE_PAYMENT_STATUS.not_paid, id: INVOICE_PAYMENT_STATUS.not_paid },
    { label: INVOICE_PAYMENT_STATUS.paid, id: INVOICE_PAYMENT_STATUS.paid },
    { label: INVOICE_PAYMENT_STATUS.scheduled, id: INVOICE_PAYMENT_STATUS.scheduled },
    { label: INVOICE_PAYMENT_STATUS.scheduled_due, id: INVOICE_PAYMENT_STATUS.scheduled_due },
    { label: INVOICE_PAYMENT_STATUS.partially_paid, id: INVOICE_PAYMENT_STATUS.partially_paid },
  ]);

  const getFilterFromState = (invoiceSearch: SearchState) => {
    const filters = [
      ['invoices.contact_id', SEARCH_OPERATORS_PRIMITIVE.eq, contactId],
    ] as Filter[];
    if (invoiceSearch.invoiceStatus && invoiceSearch.invoiceStatus.length) {
      filters.push(
        ['invoices.state', SEARCH_OPERATORS.is_in, invoiceSearch.invoiceStatus],
      );
    }
    if (invoiceSearch.paymentStatus && invoiceSearch.paymentStatus.length) {
      filters.push(
        ['invoices.payment_status', SEARCH_OPERATORS.is_in, invoiceSearch.paymentStatus],
      );
    }
    if (invoiceSearch.title) {
      filters.push(
        ['invoices.title', SEARCH_OPERATORS.like, `%${invoiceSearch.title}%`],
      );
    }
    if (invoiceSearch.departments && invoiceSearch.departments.length) {
      filters.push(
        ['invoices.department_id', SEARCH_OPERATORS.is_in, invoiceSearch.departments],
      );
    }
    if (invoiceSearch.programs && invoiceSearch.programs.length) {
      filters.push(
        ['invoices.program_id', SEARCH_OPERATORS.is_in, invoiceSearch.programs],
      );
    }
    if (invoiceSearch.accounts && invoiceSearch.accounts.length) {
      filters.push(
        ['invoices.account_id', SEARCH_OPERATORS.is_in, invoiceSearch.accounts],
      );
    }
    if (invoiceSearch.start_date && invoiceSearch.end_date) {
      const start = state.start_date || '';
      const end = state.end_date || '';
      filters.push(
        ['invoices.issued_date', SEARCH_OPERATORS.is_between, [
          moment(start).format('YYYY-MM-DD'),
          moment(end).format('YYYY-MM-DD'),
        ]],
      );
    }
    return filters;
  };

  const getName = () => {
    if (!contact) return '';
    if (contact.company_name) {
      return contact.company_name;
    }
    return `${contact.first_name} ${contact.last_name}`;
  };

  const handleOnChange = (value: Value, field: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleSearch = () => {
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: { and: getFilterFromState(state) },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  };

  const handleClear = () => {
    setState({
      title: '',
      paymentStatus: [] as INVOICE_PAYMENT_STATUS[],
      invoiceStatus: [] as INVOICE_STATUS[],
      programs: [] as number[],
      departments: [] as number[],
      accounts: [] as number[],
      start_date: getDefaultStartDate(),
      end_date: getDefaultEndDate(),
    });
    const filters = [
      ['invoices.contact_id', '=', contactId],
    ] as Filter[];
    dispatch(setFilters({
      entity: ENTITIES.invoices,
      filters: { and: filters },
    }));
    dispatch(getEntitiesAction(ENTITIES.invoices));
  };

  return (
    <>
      <div className={classes.header}>
        <div className={`${classes.row} ${classes.noMargin}`}>
          <Typography
            variant="h5"
            color="secondary"
            gutterBottom
            className={classes.header}>
            {t(lang, 'forms.contact.invoices_for')} {getName()}
          </Typography>
        </div>
      </div>
      <div className={classes.container}>
        <div style={{ maxWidth: '1080px' }}>
          <div className={classes.row}>
            <span className={classes.text}> Between </span>
            <FormDatePicker
              form={FORM.invoice_contact}
              label={t(lang, 'forms.invoices.start_date')}
              name="start_date"
              onChange={
                (date: MaterialUiPickersDate) => handleOnChange(
                  getDateValue(date, DateType.start), 'start_date',
                )
              }
              value={state.start_date} />
            <span className={classes.text}> And </span>
            <FormDatePicker
              form={FORM.invoice_contact}
              label={t(lang, 'forms.invoices.end_date')}
              name="end_date"
              onChange={
                (date: MaterialUiPickersDate) => handleOnChange(
                  getDateValue(date, DateType.end), 'end_date',
                )
              }
              value={state.end_date} />
          </div>
          <div className={classes.row}>
            <span className={classes.col3}>
              <FormMultipleSelect
                form={FORM.invoice_contact}
                name="programs"
                label="Programs"
                onChange={handleOnChange}
                value={state.programs}
                items={getProgramItems()}
                margin="dense"
                noMarginTop />
            </span>
            <span className={classes.col3}>
              <FormMultipleSelect
                form={FORM.invoice_contact}
                name="departments"
                label="Departments"
                onChange={handleOnChange}
                value={state.departments}
                items={getDepartmentItems()}
                margin="dense"
                noMarginTop />
            </span>
            <span className={classes.col3}>
              <FormMultipleSelect
                form={FORM.invoice_contact}
                name="accounts"
                label="Accounts"
                onChange={handleOnChange}
                value={state.accounts}
                items={getAccountItems()}
                margin="dense"
                noMarginTop />
            </span>
          </div>
          <div className={classes.row}>
            <span className={classes.col3}>
              <FormTextField
                form={FORM.invoice_contact}
                label={t(lang, 'forms.invoices.title')}
                name="title"
                onChange={handleOnChange}
                required
                value={state.title}
                margin="dense" />
            </span>
            <span className={classes.col3}>
              <FormMultipleSelect
                form={FORM.invoice_contact}
                name="invoiceStatus"
                label={t(lang, 'forms.invoices.invoice_status')}
                onChange={handleOnChange}
                value={state.invoiceStatus}
                items={getInvoiceStatusItem()}
                margin="dense"
                noMarginTop />
            </span>
            <span className={classes.col3}>
              <FormMultipleSelect
                form={FORM.invoice_contact}
                name="paymentStatus"
                label={t(lang, 'forms.invoices.payment_status')}
                onChange={handleOnChange}
                value={state.paymentStatus}
                items={getPaymentStatus()}
                margin="dense"
                noMarginTop />
            </span>
          </div>
          <div className={classes.row}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleClear}
              key="clear-button"
              style={{ marginLeft: 'auto' }}>
              {t(lang, 'forms.contact.clear')}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleSearch}
              key="search-button">
              {t(lang, 'forms.contact.filters')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceForContactSearchbar;
//
