import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MouseEvent, useEffect, useState } from 'react';
import {
  InstallmentModel,
  PAYMENT_FREQUENCY,
  PAYMENT_FREQUENCY_LIST,
  PAYMENT_TYPE,
  PaymentInfoModel,
  PaymentModel,
} from 'src/models/PaymentModel';
import { FORM } from 'src/constants/Form';
import { setError } from 'src/redux/actions/errorsActions';
import FormTextField from 'src/components/Control/FormControls/FormTextField';
import GuessPaymentValidator from 'src/validations/GuessPaymentValidator';
import { setAlert } from 'src/redux/actions/alertActions';
import { ALERT_TYPE } from 'src/constants/AlertType';
import { ErrorBag } from 'src/models/ErrorModel';
import {
  FormControl, FormControlLabel, FormLabel, Radio, RadioGroup,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormDatePicker from 'src/components/Control/FormControls/FormDatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { capitalize } from 'lodash';
import { InvoiceModel } from 'src/models/InvoiceModel';
import Subtitle from 'src/components/UI/Subtitle';
import SalutationSelector from 'src/components/Control/SalutationSelector';
import AddressSearch from 'src/components/Control/AddressSearch';
import { AddressModel, AddressType } from 'src/models/AddressModel';
import CountriesSelector from 'src/components/Control/CountriesSelector';
import { postPurchaseAction } from 'src/redux/actions/paymentActions';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';
import UserPaymentAccountSelector from 'src/components/Control/UserPaymentAccountSelector';
import TextLink from 'src/components/UI/TextLink';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
import Col2 from 'src/components/UI/Col2';
// import ButtonLink from 'src/components/Elements/ButtonLink';

const GuessPaymentForm = () => {
  const dispatch = useAppDispatch();

  const invoice =
    useSelector((state: Store) => state.modals[MODALS.guessPayment].payload as InvoiceModel);
  const isLogged = useSelector((store: Store) => !!store.auth.accessToken);
  const userContact = useSelector((store: Store) => store.userContact);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const getMaxAmount = () => {
    if (!invoice) return 0;
    return (invoice.total ?? 0) - (invoice.scheduled ?? 0) - (invoice.paid ?? 0);
  };

  const formatMonetaryAmount = (amount: number, locale = 'en-US', decimals = 2) => new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount);

  const initializePaymentState = () => ({
    payment_type: PAYMENT_TYPE.cc,
    scheduled_date: moment().startOf('day'),
    is_installment: !!invoice.installments,
    contact_id: invoice.contact_id,
    amount: formatMonetaryAmount(getMaxAmount()),
    bank_account_id: invoice.bank_account_id,
    issue_tax_receipt: invoice.issue_tax_receipt,
    payment_gateway_id: invoice.payment_gateway_cc_id ?? 0,
    contact_first_name: invoice.contact_first_name,
    contact_last_name: invoice.contact_last_name,
    contact_salutation_id: invoice.contact_salutation_id,
    contact_company_name: invoice.contact_company_name,
    billing_address: invoice.billing_address,
    billing_state: invoice.billing_state,
    billing_city: invoice.billing_city,
    billing_suite: invoice.billing_suite,
    billing_country_id: invoice.billing_country_id,
    billing_zip_code: invoice.billing_zip_code,
    account_id: invoice.account_id,
    program_id: invoice.program_id,
    department_id: invoice.department_id,
    seller_id: invoice.seller_id,
  } as PaymentModel);

  const [paymentState, setPaymentState] = useState(initializePaymentState());

  const [installmentState, setInstallmentState] = useState({
    number: 1,
    start_date: moment(),
    frequency: PAYMENT_FREQUENCY.monthly,
  } as InstallmentModel);

  const [paymentInfoState, setPaymentInfoState] = useState({
    first_name: invoice.contact_first_name,
    last_name: invoice.contact_first_name,
    card_number: '',
    expiration_month: '',
    expiration_year: '',
    cvv: '',
  } as PaymentInfoModel);

  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.cc);

  useEffect(() => {
    if (!!userContact) {
      setPaymentState({
        ...paymentState,
        contact_salutation_id: userContact.salutation_id || undefined,
        contact_first_name: userContact.first_name,
        contact_last_name: userContact.last_name,
        contact_company_name: userContact.company_name,
      });
    }
  }, [userContact]);

  useEffect(() => {
    setPaymentState(initializePaymentState());
  }, [invoice]);

  const getMonth = (value:string) => {
    if (Number(value) === 0) {
      return value;
    }
    if (Number(value) < 10 && value.length < 2) {
      return `0${value}`;
    }
    return value;
  };

  const getCardType = (number: string) => {
    const cardsRegexp = {
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      amex: /^3[47][0-9]{13}$/,
      diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    };
    // eslint-disable-next-line
    for (const card in cardsRegexp) {
      if (cardsRegexp[card].test(number)) {
        return card;
      }
    }

    return 'unknown';
  };

  const getData = () => {
    if (isLogged) {
      return {
        ...paymentState,
      };
    }
    return {
      ...paymentState,
      ...paymentInfoState,
      expiration_month: getMonth(paymentInfoState.expiration_month),
    };
  };

  const setAmount = (value: Value) => {
    if (Number(value) > getMaxAmount()) return;
    if (!value) {
      setPaymentState({
        ...paymentState,
        amount: value as string,
      });
      return;
    }
    const stringValue = value as string || '';
    if (!stringValue.match(/^[0-9.]+$/)) return;
    const splittedString = stringValue.split('.');
    if (splittedString.length > 2) return;
    if (splittedString.length === 2) {
      if (splittedString[1].length > 2) return;
    }
    setPaymentState({
      ...paymentState,
      amount: value as string,
    });
  };

  const setAddressFromGoogle = (address: AddressModel, type: AddressType) => {
    setPaymentState({
      ...paymentState,
      [`${type}_address`]: address.address,
      [`${type}_city`]: address.city,
      [`${type}_state`]: address.state,
      [`${type}_country_id`]: address.country_id,
      [`${type}_zip_code`]: address.zip_code,
    });
  };

  const onFieldChange = (value: Value, field: string) => {
    if (field === 'amount') {
      setAmount(value);
      return;
    }
    setPaymentState({
      ...paymentState,
      [field]: value,
    });
  };

  const onAddressChange = (address: AddressModel) => {
    setAddressFromGoogle(address, AddressType.billing);
  };

  const onInstallmentChange = (value: Value, field: string) => {
    setInstallmentState({
      ...installmentState,
      [field]: value,
    });
  };

  const onPaymentInfoChange = (value: string, field: string) => {
    if (!!value) {
      switch (field) {
        case 'card_number':
          if (value.length > 16) return;
          break;
        case 'expiration_month':
          if (value.length > 2) return;
          if (Number(value) > 12) return;
          setPaymentInfoState({
            ...paymentInfoState,
            expiration_month: getMonth(value),
          });
          break;
        case 'expiration_year':
          if (value.length > 2) return;
          break;
        case 'cvv':
          if (value.length > 4) return;
          break;
        default:
      }
    }
    setPaymentInfoState({
      ...paymentInfoState,
      [field]: value,
    });
  };

  const openAddCreditCardModal = () => {
    dispatch(openModal({
      modal: MODALS.addUserCreditCard,
    }));
  };

  const displayAddCreditCard = () => {
    if (paymentType !== PAYMENT_TYPE.cc) return (<></>);
    return (
      <TextLink onClick={() => openAddCreditCardModal()}>{t(lang, 'forms.payments.add_credit_card')}</TextLink>
    );
  };

  const displayInstallmentDetails = () => {
    const step = (Number(paymentState.amount) ?? 0) / (installmentState.number ?? 1);
    if (!Number.isNaN(step) && !!installmentState.number) {
      return (
        <p>
          <strong>{installmentState.number} {capitalize(installmentState.frequency)} {t(lang, 'forms.payments.payments_of')} {step.toFixed(2)}$</strong>
        </p>
      );
    }
    return <></>;
  };

  const displayInstallmentFrequencyOptions = () => (
    PAYMENT_FREQUENCY_LIST.map((frequency: PAYMENT_FREQUENCY, index: number) => (
      <MenuItem value={frequency} key={`frequency-${index}`}>{frequency}</MenuItem>
    ))
  );

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.guessPayment }));
  };

  const handleSubmit = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const data = getData();
    if ([PAYMENT_TYPE.cc, PAYMENT_TYPE.eft].includes(paymentState.payment_type)) {
      data.scheduled_date = data.paid_date;
    }
    dispatch(setError({ [FORM.make_payment]: {} }));
    try {
      GuessPaymentValidator(lang, isLogged).validateSync(data, { abortEarly: false });
    } catch (validationErrors: any) {
      dispatch(setAlert({
        type: ALERT_TYPE.error,
        message: t(lang, 'forms.contact.validation_errors'),
      }));
      const errorBag = {} as ErrorBag;
      validationErrors.inner.forEach((e: any) => {
        if (!errorBag[e.path]) {
          errorBag[e.path] = [];
        }
        errorBag[e.path].push(e.message);
      });
      dispatch(setError({ [FORM.make_payment]: errorBag }));
      return false;
    }

    // const payment_gateway_id =

    const payload = {
      ...paymentState,
      payment_type: paymentType,
      invoices: [{
        invoice_id: invoice.id,
        amount: data.amount,
      }],
      orders: {},
      installment: paymentState.is_installment ? installmentState : undefined,
      seller_id: paymentState.seller_id ? paymentState.seller_id : undefined,
      is_deductible: !!invoice.deductible,
      payment_gateway_id: paymentType === PAYMENT_TYPE.cc
        ? invoice.payment_gateway_cc_id
        : invoice.payment_gateway_eft_id,
    } as any;

    if (!isLogged) {
      payload.payment_info = {
        ...paymentInfoState,
        first_name: paymentState.contact_first_name,
        last_name: paymentState.contact_last_name,
        name: paymentState.contact_last_name,
        payment_gateway_id: paymentState.payment_gateway_id,
        contact_id: paymentState.contact_id,
        card_type: getCardType(paymentInfoState.card_number),
      };
    }

    if (invoice.order) {
      payload.orders = invoice.order.map(
        (orderItem) => (
          {
            paid: orderItem.paid,
            product_id: orderItem.product_id,
            quantity: orderItem.quantity,
          }
        ),
      );
    }

    dispatch<any>(postPurchaseAction(payload, MODALS.guessPayment));
    return true;
  };

  const CloseButton = () => (
    <Button
      style={{ marginLeft: 'auto' }}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClose}>
      Close
    </Button>
  );

  return (
    <>
      <Header height="50px">
        <Row alignItems="start">
          <Title>{t(lang, 'forms.invoices.make_payment')}</Title>
          <CloseButton />
        </Row>
      </Header>
      <Scroller height="608px">
        <Form>
          <Row alignItems="start">
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={`${t(lang, 'forms.payments.amount')}(max:${formatMonetaryAmount(getMaxAmount())})`}
                name="amount"
                onChange={onFieldChange}
                required
                value={paymentState.amount ?? '00.00'} />
            </Col2>
            <Col2 responsive>
              {!!invoice.installments &&
              (
                <FormControl
                  component="fieldset"
                  style={{ marginTop: '8px' }}>
                  <FormLabel component="legend">{t(lang, 'forms.payments.installments')}</FormLabel>
                  <RadioGroup
                    defaultValue={0}
                    row
                    aria-label={t(lang, 'forms.payments.installments')}
                    name="is_installment"
                    value={paymentState.is_installment ? 1 : 0}
                    onChange={(e) => {
                      onFieldChange(e.target.value !== '0', 'is_installment');
                    }}>
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label={t(lang, 'forms.payments.single_payment')} />
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label={t(lang, 'forms.payments.multiple_payment')} />
                  </RadioGroup>
                </FormControl>
              )}
            </Col2>
          </Row>
          {!!paymentState.is_installment && (
          <>
            <Row alignItems="start">
              <Col2 responsive>
                <FormControl style={{ width: '100%', minWidth: 120, marginTop: '8px' }}>
                  <InputLabel id="frequency">Frequence</InputLabel>
                  <Select
                    labelId="frequency-label-id-"
                    id="demo-simple-select"
                    value={installmentState.frequency || ''}
                    onChange={(e) => onInstallmentChange(e.target.value as string, 'frequency')}>
                    {displayInstallmentFrequencyOptions()}
                  </Select>
                </FormControl>
              </Col2>
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.payments.number')}
                  name="number"
                  onChange={onInstallmentChange}
                  required
                  value={installmentState.number || ''} />
              </Col2>
            </Row>
            {displayInstallmentDetails()}
            <Row alignItems="start">
              <Col2 responsive>
                <FormDatePicker
                  form={FORM.make_payment}
                  required
                  label={t(lang, 'forms.payments.start_date')}
                  name="start_date"
                  onChange={
                    (value: MaterialUiPickersDate) => onInstallmentChange(
                      moment(value).format('YYYY-MM-DD'), 'start_date',
                    )
                  }
                  value={installmentState.start_date || moment()} />
              </Col2>
            </Row>
          </>
          )}
          <Subtitle>{t(lang, 'forms.contact.payer_name')}</Subtitle>
          <Row alignItems="start">
            <Col2 responsive>
              <SalutationSelector
                form={FORM.make_payment}
                name="contact_salutation_id"
                value={paymentState.contact_salutation_id || 0}
                onChange={(id) => onFieldChange(id, 'contact_salutation_id')} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.first_name')}
                name="contact_first_name"
                onChange={onFieldChange}
                value={paymentState.contact_first_name} />
            </Col2>
          </Row>
          <Row alignItems="start">
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.last_name')}
                name="contact_last_name"
                onChange={onFieldChange}
                value={paymentState.contact_last_name} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.company_name')}
                name="contact_company_name"
                onChange={onFieldChange}
                value={paymentState.contact_company_name} />
            </Col2>
          </Row>
          <Row alignItems="start">
            <Col2 responsive>
              <AddressSearch
                onChange={onFieldChange}
                onAddressChange={onAddressChange}
                value={{ description: paymentState.billing_address || '' }}
                name="billing_address"
                form={FORM.make_payment} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.suite')}
                name="billing_suite"
                onChange={onFieldChange}
                value={paymentState.billing_suite || ''} />
            </Col2>
          </Row>
          <Row alignItems="start">
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.city')}
                name="billing_city"
                onChange={onFieldChange}
                value={paymentState.billing_city || ''} />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.state')}
                name="billing_state"
                onChange={onFieldChange}
                value={paymentState.billing_state || ''} />
            </Col2>
          </Row>
          <Row alignItems="start">
            <Col2 responsive>
              <CountriesSelector
                form={FORM.make_payment}
                onChange={(id) => onFieldChange(id, 'billing_country_id')}
                value={paymentState.billing_country_id || undefined}
                name="billing_country_id" />
            </Col2>
            <Col2 responsive>
              <FormTextField
                form={FORM.make_payment}
                label={t(lang, 'forms.contact.zip_code')}
                name="billing_zip_code"
                onChange={onFieldChange}
                value={paymentState.billing_zip_code || ''} />
            </Col2>
          </Row>
          <Subtitle>{t(lang, 'forms.contact.payment_information')}</Subtitle>
          {isLogged && (
          <>
            <Row alignItems="start">
              <FormControl component="fieldset" style={{ marginTop: '8px' }}>
                <RadioGroup
                  row
                  aria-label="bank_country"
                  name="bank_country"
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value as PAYMENT_TYPE)}>
                  <FormControlLabel
                    value={PAYMENT_TYPE.cc}
                    control={<Radio />}
                    label="Credit Card" />
                  <FormControlLabel
                    value={PAYMENT_TYPE.eft}
                    control={<Radio />}
                    label="Bank Account" />
                </RadioGroup>
              </FormControl>
            </Row>
            <UserPaymentAccountSelector
              type={paymentType}
              form={FORM.make_payment}
              name="payment_info_id"
              onChange={(id: number) => onFieldChange(id, 'payment_info_id')}
              value={paymentState.payment_info_id} />
            {displayAddCreditCard()}
          </>
          )}
          {!isLogged && (
          <>
            <Row alignItems="start">
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.creditcards.card_number')}
                  name="card_number"
                  onChange={onPaymentInfoChange}
                  required
                  value={paymentInfoState.card_number}
                  inputProps={{
                    type: 'number',
                  }} />
              </Col2>
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.creditcards.expiration_month')}
                  name="expiration_month"
                  onChange={onPaymentInfoChange}
                  required
                  value={paymentInfoState.expiration_month}
                  inputProps={{
                    type: 'number',
                  }} />
              </Col2>
            </Row>
            <Row alignItems="start">
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.creditcards.expiration_year')}
                  name="expiration_year"
                  onChange={onPaymentInfoChange}
                  required
                  value={paymentInfoState.expiration_year}
                  inputProps={{ type: 'number' }} />
              </Col2>
              <Col2 responsive>
                <FormTextField
                  form={FORM.make_payment}
                  label={t(lang, 'forms.creditcards.cvv')}
                  name="cvv"
                  onChange={onPaymentInfoChange}
                  required
                  value={paymentInfoState.cvv}
                  inputProps={{ type: 'number' }} />
              </Col2>
            </Row>

          </>
          )}
          <Row alignItems="start" style={{ marginTop: '8px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              key="submit-close-button">
              {t(lang, 'misc.pay')}
            </Button>
          </Row>
        </Form>
      </Scroller>
    </>
  );
};

export default GuessPaymentForm;
