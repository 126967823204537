import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { closeModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { openDialog } from 'src/redux/actions/dialogActions';
import { DIALOGS } from 'src/models/DialogModel';
import { useEffect, useState } from 'react';
import { t } from 'src/lib/language';
import { ContactGroupModel } from 'src/models/ContactGroupModel';
import EditGroupForm from 'src/components/Forms/EditGroupForm';
import AddGroupForm from 'src/components/Forms/AddGroupForm';
import { getContactGroupAction } from 'src/redux/actions/contactGroupActions';
import Typography from '@material-ui/core/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import InfiniteScroll from 'react-infinite-scroll-component';
import { sortBy } from 'lodash';
import Item from 'src/components/Elements/Item';
import EditButton from 'src/components/UI/EditButton';
import DeleteButton from 'src/components/Elements/DeleteButton';
import Scroller from 'src/components/UI/Scroller';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Header from 'src/components/UI/Header';
import ItemSearchBox from 'src/components/Elements/ItemSearchBox';

export enum STATE {
  list = 'list',
  edit = 'edit',
  add = 'add',
}

const ManageGroups = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContactGroupAction());
  }, []);

  const groups = useSelector((state: Store) => state.contactGroup);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.manageGroup }));
  };

  const [currentGroup, setCurrentGroup] = useState({} as ContactGroupModel);
  const [currentState, setCurrentState] = useState(STATE.list);
  const [items, setItems] = useState(Object.values(groups).slice(0, 20));
  const [currentFilter, setCurrentFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setItems(Object.values(groups).slice(0, 20));
  }, [groups]);

  const handleDelete = (group: ContactGroupModel) => {
    dispatch(openDialog({ dialog: DIALOGS.deleteGroup, payload: group }));
  };

  const handleEdit = (customfield: ContactGroupModel) => {
    setCurrentGroup(customfield);
    setCurrentState(STATE.edit);
  };

  const handleAdd = () => {
    setCurrentGroup({} as ContactGroupModel);
    setCurrentState(STATE.add);
  };

  const handleNext = () => {
    let filtered = Object.values(groups);
    if (currentFilter) {
      filtered = filtered.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(currentFilter.toLowerCase()),
      );
    }
    const newItems = filtered.slice(page * 20, (page + 1) * 20);
    setItems((prevItems) => [...prevItems, ...newItems]);
    setPage((prevPage) => prevPage + 1);
    setHasMore(filtered.length > (page + 1) * 20);
  };

  const handleSearch = (term: string) => {
    const sorted = sortBy(groups, (group) => group[`name_${lang}`]);
    let result = sorted;
    if (term) {
      result = result.filter(
        (item) => item[`name_${lang}`]
          .toLowerCase()
          .includes(term.toLowerCase()),
      );
    }
    setItems(result.slice(0, 20));
    setPage(1);
    setHasMore(result.length > 40);
    setCurrentFilter(term);
  };

  const getTitle = () => {
    switch (currentState) {
      case STATE.add:
        return t(lang, 'menus.add_group');
      case STATE.edit:
        return t(lang, 'menus.edit_group');
      default:
        return t(lang, 'menus.list_group');
    }
  };

  const getStyle = (index: number) => {
    const style = {} as any;
    if (!index) {
      style.borderTop = '1px solid rgba(0, 0, 0, 0.25)';
    }
    if (index % 2) {
      style.backgroundColor = '#eee';
    }
    return style;
  };

  const getItems = () => items
    .map((group: ContactGroupModel, index: number) => (
      <Item
        key={`group-${group.id}-${index}`}
        style={getStyle(index)}>
        <span><b>{ group[`name_${lang}`] }</b></span>
        <EditButton onClick={() => handleEdit(group)} title={t(lang, 'menus.edit_group')} />
        <DeleteButton onClick={() => handleDelete(group)} title={t(lang, 'menus.delete_group')} />
      </Item>
    ));

  const ListItems = () => (
    <InfiniteScroll
      dataLength={items.length}
      next={handleNext}
      hasMore={hasMore}
      loader={<Typography>Loading...</Typography>}
      endMessage={<Typography>All items displayed.</Typography>}
      height={590}>
      {getItems()}
    </InfiniteScroll>
  );

  const EditForm = () => (
    <Scroller height="597px">
      <EditGroupForm
        group={currentGroup}
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  const AddForm = () => (
    <Scroller height="597px">
      <AddGroupForm
        onClose={() => setCurrentState(STATE.list)} />
    </Scroller>
  );

  return (
    <>
      <Header height="56px">
        <Row>
          <Title>{ getTitle() }</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
      </Header>
      { currentState === STATE.list && (
        <ItemSearchBox
          onAdd={handleAdd}
          title={t(lang, 'menus.add_group')}
          onChange={handleSearch} />
      )}
      <div>
        { currentState === STATE.list && <ListItems /> }
        { currentState === STATE.add && <AddForm /> }
        { currentState === STATE.edit && <EditForm /> }
      </div>
    </>
  );
};

export default ManageGroups;
//
