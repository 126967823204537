import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import Button from '@material-ui/core/Button';
import { t } from 'src/lib/language';
import { closeModal, openModal } from 'src/redux/actions/modalActions';
import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import { PAYMENT_STATUS, PAYMENT_STATUS_COLOR, PAYMENT_TYPE } from 'src/models/PaymentModel';
import { FORM, FORM_STATUS } from 'src/constants/Form';
import moment from 'moment-timezone-all';
import TextLink from 'src/components/UI/TextLink';
import UserDownloadPaymentButton
  from 'src/components/DatagridToolbar/buttons/UserDownloadPaymentButton';
import { CreditCardModel } from 'src/models/CreditCardModel';
import { BankAccountModel } from 'src/models/BankAccountModel';
import { setSelections } from 'src/redux/actions/selectionActions';
import { ENTITIES } from 'src/models/QuerybuilderModel';
import { getInvoiceAction } from 'src/redux/actions/invoiceActions';
import Header from 'src/components/UI/Header';
import Row from 'src/components/UI/Row';
import Title from 'src/components/UI/Title';
import Scroller from 'src/components/UI/Scroller';
import Form from 'src/components/UI/Form';
// import { postPurchaseAction } from 'src/redux/actions/paymentActions';

const UserEditPaymentForm = () => {
  const dispatch = useAppDispatch();

  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const getStatus = useSelector((state: Store) => state.formStatus[FORM.get_payment]);
  const putStatus = useSelector((state: Store) => state.formStatus[FORM.put_payment]);
  const processing = () => putStatus === FORM_STATUS.processing
    || getStatus === FORM_STATUS.processing;

  const payment =
    useSelector((state: Store) => state.modals[MODALS.editUserPayment].payload);

  const creditCards = useSelector(
    (state: Store) => {
      if (!!state.userCreditCards) {
        return state.userCreditCards as CreditCardModel[];
      }
      return [] as CreditCardModel[];
    },
  );

  const bankAccounts = useSelector(
    (state: Store) => {
      if (!!state.userBankAccounts) {
        return state.userBankAccounts as BankAccountModel[];
      }
      return [] as BankAccountModel[];
    },
  );

  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'CAD',
  });

  const openEditCreditCard = () => {
    dispatch(openModal({
      modal: MODALS.editUserCreditCard,
      payload: payment.payment_info_id,
    }));
  };

  const openInvoice = (id:number) => {
    dispatch(getInvoiceAction(id));
    dispatch(setSelections({
      entity: ENTITIES.invoices,
      selections: [id],
    }));
    dispatch(openModal({ modal: MODALS.viewInvoice }));
  };

  // const handleRetry = () => {
  //   const payload = {
  //     ...payment,
  //     scheduled_date: moment().startOf('day'),
  //     is_deductible: !!Number(payment.deductible),
  //     payment_gateway_id: payment.payment_gateway_id ?? undefined,
  //     seller_id: payment.seller_id ?? undefined,
  //   };
  //   dispatch<any>(postPurchaseAction(payload, MODALS.guessPayment));
  // };

  const getCreditCard = (id: number) => {
    if (!id) return 'N/A';
    const result = creditCards.find((creditCard) => creditCard.id === id);
    if (!result) return 'N/A';
    return result.masked_card_number;
  };

  const getBankAccount = (id: number) => {
    if (!id) return 'N/A';
    const result = bankAccounts.find((bankAccount) => bankAccount.id === id);
    if (!result) return 'N/A';
    return result.account.slice(-4);
  };

  const DisplayPaymentInfo = () => {
    if (payment.payment_type === PAYMENT_TYPE.cc) {
      return (
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Credit Card Used:</span>
          {getCreditCard(payment.payment_info_id as number)}
        </div>
      );
    }
    if (payment.payment_type === PAYMENT_TYPE.eft) {
      return (
        <div>
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Credit Card Used:</span>
          ********{getBankAccount(payment.payment_info_id as number)}
        </div>
      );
    }
    return (<></>);
  };

  const DisplayPaymentStatus = () => {
    const color = PAYMENT_STATUS_COLOR[payment.payment_status ?? PAYMENT_STATUS.paid];
    return (
      <div><b>Status: </b>
        <span style={{ color }}>
          {payment.payment_status }
        </span>
      </div>
    );
  };

  const DisplayScheduledDate = () => {
    if (payment.payment_status !== PAYMENT_STATUS.scheduled) return (<></>);
    if (!payment.scheduled_date) return (<></>);
    const date = moment(payment.scheduled_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Scheduled Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const DisplayPaidDate = () => {
    if (!payment.paid_date) return (<></>);
    const date = moment(payment.paid_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Paid Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const DisplayProcessedDate = () => {
    if (!payment.processed_date) return (<></>);
    const date = moment(payment.processed_date).format('YYYY-MM-DD');
    return (
      <>
        <b>Processed Date:</b>&nbsp;{date}<br />
      </>
    );
  };

  const DisplayUpdateCreditCard = () => {
    if (![
      PAYMENT_STATUS.declined,
      PAYMENT_STATUS.error,
      PAYMENT_STATUS.scheduled,
    ].includes(payment.payment_status)) return (<></>);
    if (payment.payment_type !== PAYMENT_TYPE.cc) return (<></>);
    return (
      <>
        <br />
        <TextLink onClick={() => openEditCreditCard()}>Update Credit Card</TextLink>
      </>
    );
  };

  const DisplayInvoices = () => {
    if (!payment.invoices) return (<></>);
    const items = payment.invoices.map((invoice: any) => (
      <TextLink onClick={() => openInvoice(invoice.id)}><i>{invoice.reference}</i></TextLink>
    ));
    return (<>{items}</>);
  };

  // const displayRefundButton = () => {
  //   if ([
  //     PAYMENT_STATUS.declined,
  //     PAYMENT_STATUS.error,
  //   ].includes(payment.payment_status)) return (<></>);
  //   if (![
  //     PAYMENT_TYPE.cc,
  //     PAYMENT_TYPE.eft,
  //   ].includes(payment.payment_type)) return (<></>);
  //
  //   return (
  //     <div style={{ width: '100%', textAlign: 'right' }}>
  //       <Button
  //         style={{ marginLeft: 'auto', marginRight: '16px' }}
  //         variant="contained"
  //         color="primary"
  //         size="small"
  //         onClick={handleRetry}
  //         disabled={processing()}
  //       >
  //         Retry
  //       </Button>
  //     </div>
  //   );
  // };

  const handleClose = () => {
    dispatch(closeModal({ modal: MODALS.editUserPayment }));
  };

  return (
    <>
      <Header height="86px">
        <Row>
          <Title>{t(lang, 'forms.payments.edit_payment')}&nbsp;#{payment.id}</Title>
          <Button
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClose}
            disabled={processing()}>
            {t(lang, 'misc.close')}
          </Button>
        </Row>
        <Row>
          <UserDownloadPaymentButton
            disabled={payment.payment_status !== PAYMENT_STATUS.paid}
            paymentId={payment.id} />
        </Row>
      </Header>
      <DisplayPaymentStatus />
      <Scroller height="86px">
        <Form maxWidth={MODALS_SIZE.large}>
          <b>Title:</b>&nbsp;{payment.title}<br />
          <b>Amount:</b>&nbsp;{formatter.format(payment.amount ?? 0)}<br />
          <DisplayPaidDate />
          <DisplayScheduledDate />
          <DisplayProcessedDate />
          <b>Deductible:</b>&nbsp;{formatter.format(payment.deductible ?? 0)}<br />
          <b>Payment Type:</b>&nbsp;{payment.payment_type ?? 0}<br />
          <DisplayPaymentInfo />
          <b>Tax receipt issued:</b>&nbsp;{payment.issue_tax_receipt ? 'yes' : 'no'}<br />
          <b>Payment receipt sent:</b>&nbsp;{payment.payment_receipt_sent ? 'yes' : 'no'}<br />
          {!!payment.note && (
          <b>
            <b>Note:</b>&nbsp;{payment.payment_receipt_sent ? 'yes' : 'no'}<br />
          </b>
          )}
          <DisplayUpdateCreditCard />
          <br />
          <b>Invoices:</b><br />
          <DisplayInvoices />
          {/* {displayRefundButton()} */}
        </Form>
      </Scroller>
    </>
  );
};

export default UserEditPaymentForm;
