import * as Yup from 'yup';
import { t } from 'src/lib/language';

const SimpleContactShippingValidator = (lang: string) => Yup.object().shape({
  shipping_address: Yup.string()
    .max(250, t(lang, 'validations.contact.field_maximum', { max: 250 }))
    .nullable(),
  shipping_suite: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_city: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_state: Yup.string()
    .max(150, t(lang, 'validations.contact.field_maximum', { max: 150 }))
    .nullable(),
  shipping_country_id: Yup.number()
    .min(1, t(lang, 'validations.contact.field_required')).max(999)
    .integer()
    .nullable(),
  shipping_zip_code: Yup.string()
    .max(7, t(lang, 'validations.contact.field_maximum', { max: 7 }))
    .nullable(),
});

export default SimpleContactShippingValidator;
