import { Reducer } from 'redux';
import { ContactCreditCardModel } from 'src/models/CreditCardModel';
import {
  ADD_CREDITCARD,
  DELETE_CREDITCARD,
  SET_CREDITCARDS,
} from 'src/redux/actions/paymentInfoAction';

export type ContactCreditCardState = ContactCreditCardModel;

const DEFAULT = {} as ContactCreditCardState;

const ContactCreditCardReducer: Reducer<ContactCreditCardState> = (state = DEFAULT, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_CREDITCARDS:
      return {
        ...state,
        [payload.id]: payload.creditCards,
      };
    case ADD_CREDITCARD:
      return {
        ...state,
        [payload.id]: [
          ...state[payload.id],
          payload.card,
        ],
      };
    case DELETE_CREDITCARD: {
      const cards = state[payload.contactId];
      const newCards = cards.filter(
        (card) => card.id !== payload.cardId,
      );
      return {
        ...state,
        [payload.contactId]: newCards,
      };
    }
    default:
      return state;
  }
};

export default ContactCreditCardReducer;
