import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import FormImageCropper from 'src/components/Control/FormControls/FormImageCropper';

const UploadImageModal = () => (
  <ModalBase
    name={MODALS.uploadImage}
    size={MODALS_SIZE.large}>
    <FormImageCropper />
  </ModalBase>
);

export default UploadImageModal;
