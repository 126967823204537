import { CreditCardModel } from 'src/models/CreditCardListModel ';
import { Reducer } from 'redux';
import { SET_CONTACT_CREDIT_CARDS } from 'src/redux/actions/creditCardActions';
import { keyBy } from 'lodash';

export type CreditCardState = CreditCardModel;
const DEFAULT_CREDIT_CARDS: CreditCardState = [] as CreditCardState;
const creditCardsReducer: Reducer<CreditCardState> = (state = DEFAULT_CREDIT_CARDS, action) => {
  switch (action.type) {
    case SET_CONTACT_CREDIT_CARDS: {
      return keyBy(action.payload.data, 'id');
    }
    default:
      return state;
  }
};

export default creditCardsReducer;
