import { IMAGES_TYPE, ImageSizeType, IMAGES_KEYS } from 'src/constants/Images';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import {
  ChangeEvent,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useDebounceEffect } from 'src/lib/useDebounceEffect';
import { canvasPreview } from 'src/lib/canvasPreview';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { ArrowBack } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import { useSelector } from 'react-redux';
import { Store, useAppDispatch } from 'src/redux/Store';
import { uploadFile } from 'src/redux/actions/fileAction';
import Resizer from 'react-image-file-resizer';
import { t } from 'src/lib/language';
import { postSettingsAction } from 'src/redux/actions/settingActions';
import { isEmpty } from 'lodash';
import { SettingItemModel } from 'src/models/SettingListModel';
import { getS3URL } from 'src/lib/S3File';
import Row from 'src/components/UI/Row';

interface Props {
  type: IMAGES_TYPE;
  onBack: () => void;
}

const ImageCropper = (props: Props) => {
  const dispatch = useAppDispatch();
  const { type, onBack } = props;

  const size = ImageSizeType[type];
  const InitialCrop: PixelCrop = {
    unit: 'px',
    x: 25,
    y: 25,
    width: size.width,
    height: size.height,
  };
  const organisation = useSelector((state: Store) => state.currentOrganisation.id);
  const lang = useSelector((state: Store) => state.language.language ?? 'en');
  const settings = useSelector((state: Store) => state.settings);
  const [crop, setCrop] = useState<Crop>(InitialCrop);
  const [imgSrc, setImgSrc] = useState('');
  const [image, setImage] = useState({} as File);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [isSettingDefined, setIsSettingDefined] = useState(true);

  useEffect(() => {
    if (isEmpty(settings)) {
      setIsSettingDefined(false);
    } else {
      let settingValues = [] as any[];
      settings.forEach(
        (setting) => setting.values.forEach((value: SettingItemModel) => {
          settingValues = [
            ...settingValues,
            ...Object.keys(value),
          ];
        }),
      );

      if (IMAGES_TYPE.organisation_logo === type) {
        setIsSettingDefined(settingValues.includes(IMAGES_KEYS.organisation_logo));
      } else if (IMAGES_TYPE.tax_receipt_signature === type) {
        setIsSettingDefined(settingValues.includes(IMAGES_KEYS.tax_receipt_signature));
      } else if (IMAGES_TYPE.tax_receipt_header === type) {
        setIsSettingDefined(settingValues.includes(IMAGES_KEYS.tax_receipt_header));
      }
    }
  }, []);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(InitialCrop); // Makes crop preview update between images.
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.onload = () => setImgSrc(reader.result?.toString() || '');
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const getNormalizedCrop = (pixelCrop: PixelCrop) => {
    if (imgRef.current) {
      const ratio = imgRef.current?.width / imgRef.current?.naturalWidth;
      const { width, height } = pixelCrop;
      return {
        ...pixelCrop,
        width: width * ratio,
        height: height * ratio,
      } as PixelCrop;
    }
    return InitialCrop;
  };

  const onImageLoad = () => {
    setCrop(getNormalizedCrop(InitialCrop));
    setCompletedCrop(getNormalizedCrop(InitialCrop));
  };

  const handleSettingImage = () => {
    if (isSettingDefined) return 0;
    if (IMAGES_TYPE.organisation_logo === type) {
      dispatch(postSettingsAction({
        type: 'organisation_logo',
        values: [
          {
            organisation_logo: `${getS3URL()}${organisation}/images/organisation_logo.png?${performance.now()}`,
          },
        ],
      }));
    } else if (IMAGES_TYPE.tax_receipt_signature === type) {
      dispatch(postSettingsAction({
        type: 'tax_receipt_signature',
        values: [
          {
            tax_receipt_signature: `${getS3URL()}${organisation}/images/tax_receipt_signature.png?${performance.now()}`,
          },
        ],
      }));
    } else if (IMAGES_TYPE.tax_receipt_header === type) {
      dispatch(postSettingsAction({
        type: 'tax_receipt_header',
        values: [
          {
            tax_receipt_header: `${getS3URL()}${organisation}/images/tax_receipt_header.png?${performance.now()}`,
          },
        ],
      }));
    }
    return 0;
  };

  const resizeAndUpload = (result: any) => {
    const commaPosition = result.indexOf(',');
    dispatch(uploadFile(
      result.substring(commaPosition) as string,
      type,
      'png',
      t(lang, 'messages.successfully_uploaded'),
      t(lang, 'messages.error_upload'),
    ));

    handleSettingImage();
  };

  const onSave = () => {
    if (previewCanvasRef.current) {
      previewCanvasRef.current?.toBlob((blob:Nullable<Blob>) => {
        if (blob) {
          const file = new File([blob], type);
          Resizer.imageFileResizer(
            file, // Is the file of the image which will resized.
            size.width, // Is the maxWidth of the resized new image.
            size.height, // Is the maxHeight of the resized new image.
            'PNG',
            100,
            0, // Is the compressFormat of the resized new image.
            resizeAndUpload,
            'base64', // Is the output type of the resized new image.
          );
        }
      });
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0,
        );
      }
    },
    100,
    [completedCrop, crop],
  );

  const Header = (p: any) => {
    const { children } = p;
    return (
      <div style={{
        fontSize: '1.2rem',
        fontFamily: 'Qanelas-Bold',
        marginTop: '16px',
      }}>
        {children}
      </div>
    );
  };

  return (
    <div>
      <Header>Cropping</Header>
      <Row>
        <Button variant="contained" color="primary" onClick={onBack}>
          <ArrowBack />Back
        </Button>
        <Button variant="contained" component="label" color="primary">
          <AddIcon /> Choose a file
          <input type="file" hidden accept="image/*" onChange={onSelectFile} />
        </Button>
        <span style={{ padding: '8px' }}>{image.name}</span>
      </Row>
      { Boolean(imgSrc) && (
        <ReactCrop
          crop={crop}
          minHeight={getNormalizedCrop(InitialCrop).height}
          minWidth={getNormalizedCrop(InitialCrop).width}
          onChange={(pixelCrop) => setCrop(pixelCrop)}
          onComplete={(pixelCrop) => setCompletedCrop(pixelCrop)}
          aspect={size.ratio}>
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            onLoad={() => onImageLoad()} />
        </ReactCrop>
      )}
      { Boolean(completedCrop) && (
      <div>
        <Header>Preview</Header>
        <canvas
          ref={previewCanvasRef}
          width={size.width}
          height={size.height}
          style={{
            border: '1px solid black',
            objectFit: 'contain',
            maxWidth: size.width,
            minWidth: size.width,
            maxHeight: size.height,
            minHeight: size.height,
            marginTop: '8px',
          }} />
        <Row>
          <Button variant="contained" color="primary" onClick={onBack}>
            <ArrowBack />Back
          </Button>
          <Button variant="contained" color="primary" onClick={onSave}>
            <SaveIcon />Save
          </Button>
        </Row>
      </div>
      ) }
    </div>
  );
};

export default ImageCropper;
