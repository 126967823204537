import { Store } from 'src/redux/Store';
import { ENTITIES, GridColumns } from 'src/models/QuerybuilderModel';
import { f } from 'src/lib/language';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone-all';

export const getFields = (state: Store, entity: ENTITIES) => {
  const { customfields } = state;
  const lang = state.language.language || 'en';
  const columns = (
    state.querybuilder[entity]
    && state.querybuilder[entity].columns
  ) || [] as GridColumns;

  return columns.reduce((result, column) => {
    const [table, property] = column.field.split('.');
    if (column.hide) return result;
    const caption = (table === 'custom_fields')
      ? customfields[property][`display_${lang}`]
      : f(lang, column.field);
    result.push(`${column.field}@${caption}`);
    return result;
  }, [] as string[]);
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const getJSLocale = (value:string) => {
  const locale = value || 'en_CA';
  return locale.replace('_', '-');
};

export const formatDate = (dateValue: string | null, locale: string) => {
  if (!dateValue) {
    return '';
  }
  const date = moment(dateValue).toDate();
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const formatDatetime = (dateValue: string | Date | undefined, locale: string) => {
  if (!dateValue) {
    return '';
  }
  const UTC = moment.utc(dateValue).toDate();
  return UTC.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};
