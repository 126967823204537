import { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Texts } from 'src/models/FundraisingModel';
import { FundraisingLanguages } from 'src/constants/FundraisingLanguages';
import { FORM } from 'src/constants/Form';
import ActionButton, { MENU_BUTTON_CATEGORY } from 'src/components/UI/ActionButton';
import FormRichTextEditor from 'src/components/Control/FormControls/FormRichTextEditor';
import { TextsErrors } from 'src/validations/FundraisingAssetsValidation';
import Error from 'src/components/UI/Error';
import Row from 'src/components/UI/Row';
import Frame from 'src/components/UI/Frame';
import SliderHeader from 'src/components/UI/SliderHeader';
import SliderContent from 'src/components/UI/SliderContent';

interface Props {
  errors?: TextsErrors;
  language: FundraisingLanguages,
  name: string;
  onChange: (language: FundraisingLanguages, texts: Texts) => void;
  title: string;
  value: Texts,
}

interface PanelState {
  [panel: string]: boolean,
}

const TextSliderManager = (props: Props) => {
  const {
    errors,
    language,
    name,
    onChange,
    title,
    value,
  } = props;

  const [texts, setTexts] = useState(value ?? [] as Texts);
  const [panelState, setPanelState] = useState({} as PanelState);

  const getTitle = () => {
    if (errors && errors.length) {
      if (errors[0] === 1) {
        return (
          <Error>{title}
            (<span style={{ fontStyle: 'italic' }}>Can not be empty</span>)
          </Error>
        );
      }
      return (
        <Error>{title}</Error>
      );
    }
    return (<span>{title}</span>);
  };

  const onDeleteText = (e: any, index: number) => {
    e.stopPropagation();
    const newTexts = [...texts];
    newTexts.splice(index, 1);
    setTexts(newTexts);
  };

  const onTextChange = (index: number, text: string) => {
    const newTexts = [...texts];
    newTexts[index] = text;
    setTexts(newTexts);
    onChange(language, newTexts);
  };

  const onAddText = () => {
    const panelName = `panel-text-${texts.length}`;
    const newPanelState = {
      ...panelState,
      [panelName]: true,
    };
    setPanelState(newPanelState);
    const newTexts = [...texts];
    newTexts.push('');
    setTexts(newTexts);
  };

  const onPanelClick = (panelName: string, expanded: boolean) => {
    const newPanelState = {
      ...panelState,
      [panelName]: expanded,
    };
    setPanelState(newPanelState);
  };

  const getItemStyle = (index: number) => {
    if (!errors || !errors[index]) {
      return {
        marginTop: '-16px',
        width: '100%',
      } as CSSProperties;
    }
    return {
      marginTop: '-16px',
      width: '100%',
      border: 'solid 1px #f00',
    };
  };

  const getErrorText = (index: number) => {
    if (!errors || !errors[index]) return (<></>);
    return (
      <Error>{errors[index]}&nbsp;</Error>
    );
  };

  const displaySliders = () => texts.map((text: string, index: number) => {
    const panelName = `${title}-${index}`;
    return (
      <Accordion
        expanded={!!panelState[panelName]}
        onChange={(e, expanded) => onPanelClick(panelName, expanded)}
        key={`${panelName}-${index}`}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls={panelName}
          id={panelName}>
          <Row>
            <span>{title} {index + 1}</span>
            <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <span style={{ width: '8px' }}>&nbsp;</span>
              <ActionButton
                variant="outlined"
                category={MENU_BUTTON_CATEGORY.action}
                onClick={(e) => onDeleteText(e, index)}
                onFocus={(e) => e.stopPropagation()}>
                <span style={{ padding: '0px 8px' }}>Delete Text</span>
                <DeleteIcon />
              </ActionButton>
            </span>
          </Row>
        </AccordionSummary>
        <AccordionDetails>
          <div style={getItemStyle(index)}>
            {getErrorText(index)}
            <FormRichTextEditor
              label=""
              form={FORM.fundraising_info}
              name={name}
              onChange={(textValue: string) => onTextChange(index, textValue)}
              value={texts[index] ?? ''} />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Frame>
      <SliderHeader>
        {getTitle()}
        <span style={{ marginLeft: 'auto', marginRight: '0px' }}>
          <ActionButton
            variant="outlined"
            category={MENU_BUTTON_CATEGORY.action}
            onClick={() => onAddText()}>
            <span style={{ padding: '0px 8px' }}>Add a Text</span><AddIcon />
          </ActionButton>
        </span>
      </SliderHeader>
      <SliderContent>
        {displaySliders()}
      </SliderContent>
    </Frame>
  );
};

export default TextSliderManager;
