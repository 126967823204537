import { darken, lighten } from '@material-ui/core/styles/colorManipulator';

const palette = {
  primary: {
    main: '#0A4DF2',
    light: lighten('#0A4DF2', 0.3),
    dark: darken('#0A4DF2', 0.3),
    contrastText: '#FFF',
  },
  secondary: {
    main: '#A51760',
    light: lighten('#A51760', 0.3),
    dark: darken('#A51760', 0.3),
    contrastText: '#FFF',
  },
  action: {
    main: '#18a90f',
    light: lighten('#18a90f', 0.3),
    dark: darken('#18a90f', 0.3),
    contrastText: '#FFF',
  },
  disabled: {
    main: '#18a90f',
    light: lighten('#18a90f', 0.3),
    dark: darken('#18a90f', 0.3),
    contrastText: '#FFF',
  },
  warning: {
    main: '#ea6410',
    light: lighten('#ea6410', 0.3),
    dark: darken('#ea6410', 0.3),
    contrastText: '#FFF',
  },
  background: {
    paper: '#FFF',
    default: '#eaeff1',
  },
};

export default palette;
