import { MODALS } from 'src/models/ModalModel';
import { MODALS_SIZE } from 'src/models/modal';
import ModalBase from 'src/components/Modals/ModalBase';
import InvoicesForPaymentDatagrid from 'src/components/EntityDatagrids/InvoicesForPaymentDatagrid';

const InvoicesForPaiementModal = () => (
  <ModalBase
    name={MODALS.invoicesForPayment}
    size={MODALS_SIZE.large}>
    <InvoicesForPaymentDatagrid />
  </ModalBase>
);

export default InvoicesForPaiementModal;
