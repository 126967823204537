import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { Team } from 'src/models/FundraisingModel';

export const getFundraisingsData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: API_URL.fundraisings,
    organisation,
  });

export const postFundraisingsData =
  (organisation: string, data: any) => makeApiRequest({
    method: 'POST',
    url: API_URL.fundraising,
    organisation,
    data,
  });

export const putFundraisingsData =
  (organisation: string, data: any) => makeApiRequest({
    method: 'PUT',
    url: API_URL.fundraising,
    organisation,
    data,
  });

export const getTeamsData =
  (organisation: string) => makeApiRequest({
    method: 'GET',
    url: API_URL.teams,
    organisation,
  });

export const postTeamData =
  (organisation: string, data: Team) => makeApiRequest({
    method: 'POST',
    url: API_URL.team,
    organisation,
    data,
  });

export const putTeamData =
  (id: number, organisation: string, data: Team) => makeApiRequest({
    method: 'PUT',
    url: `${API_URL.team}/${id}`,
    organisation,
    data,
  });

export const deleteTeamData =
  (id: number, organisation: string) => makeApiRequest({
    method: 'DELETE',
    url: `${API_URL.team}/${id}`,
    organisation,
  });
