import { MODALS } from 'src/models/ModalModel';
import ModalBase from 'src/components/Modals/ModalBase';
import { MODALS_SIZE } from 'src/models/modal';
import AddCreditCardForm from 'src/components/Forms/AddCreditCardForm';

const AddCreditCardModal = () => (
  <ModalBase
    name={MODALS.addCreditCard}
    size={MODALS_SIZE.small}
    height="48vh">
    <AddCreditCardForm />
  </ModalBase>
);

export default AddCreditCardModal;
