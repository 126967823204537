import { Reducer } from 'redux';
import { UserBankAccountModel } from 'src/models/BankAccountModel';
import {
  ADD_USER_BANK_ACCOUNT,
  DELETE_USER_BANK_ACCOUNT,
  SET_USER_BANK_ACCOUNTS,
} from 'src/redux/actions/paymentInfoAction';

export type UserBankAccountState = UserBankAccountModel;

const DEFAULT = [] as UserBankAccountState;

const UserBankAccountReducer: Reducer<UserBankAccountState> = (state = DEFAULT, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_BANK_ACCOUNTS:
      return [...payload];
    case ADD_USER_BANK_ACCOUNT:
      return [
        ...state,
        ...payload,
      ];
    case DELETE_USER_BANK_ACCOUNT: {
      const accounts = Object.values(state).filter((card) => (card.id !== payload));
      return [...accounts];
    }
    default:
      return state;
  }
};

export default UserBankAccountReducer;
