interface ContactModel {
  billing_address?: string,
  billing_suite?: string,
  billing_city?: string,
  billing_state?: string,
  billing_country_id?: number,
  billing_zip_code?: string,
  company_name: string,
  id?: number,
  email?: string,
  email_allow_notification?: boolean,
  email_status?: string,
  first_name: string,
  last_name: string,
  locale_id?: string,
  phone_home_allow_notification?: boolean,
  phone_mobile_allow_notification?: boolean,
  phone_office_allow_notification?: boolean,
  phone_home?: Nullable<string>,
  phone_mobile?: Nullable<string>,
  phone_office?: Nullable<string>,
  salutation_id: Nullable<number | ''>,
  secondary_email?: string,
  secondary_email_allow_notification?: boolean,
  shipping_address?: string,
  shipping_suite?: string,
  shipping_city?: string,
  shipping_state?: string,
  shipping_country_id?: number,
  shipping_zip_code?: string,
  custom_fields: any,
  groups: number[],
  deleted_at?: Date,
  created_at?: Date,
  updated_at?: Date,
  user_id?: number,
  issue_tax_receipt?: number,
  contact_relations?: Array<ContactRelation>
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum EMAIL_STATUS {
  healthy = 'HEALTHY',
  bounce_temporary = 'BOUNCE-TEMPORARY',
  bounce_permanent = 'BOUNCE-PERMANENT',
  complain = 'COMPLAIN',
}

export const EMAIL_STATUS_LIST = [
  EMAIL_STATUS.healthy,
  EMAIL_STATUS.bounce_temporary,
  EMAIL_STATUS.bounce_permanent,
  EMAIL_STATUS.complain,
];

export type ContactRelation = {
  related_contact_id: number,
  type_id: number,
  reciprocity_id: number,
  contact_id: number,
  company_name: string,
  first_name: string,
  last_name: string,
};

export type RelationModel = {
  id?: number,
  name_fr: string,
  name_en: string,
};

export type ContactRelationListModel = ContactRelation[];

export type CustomfieldModel = {
  [name: string] :Value
};

export interface ContactPayload {
  contact: ContactModel,
  customfields?: CustomfieldModel,
  groups?: number[],
  contact_relations?: Array<ContactRelation>
}

export interface APIContactPayload {
  contact: ContactModel,
  custom_fields?: CustomfieldModel,
  contact_relations?: Array<ContactRelation>
  groups?: number[],
}

export default ContactModel;

export type ContactsModel = {
  [id:number] :ContactPayload
};
