const contact = {
  id: 'id',
  locale_id: 'Language',
  salutation_id: 'Salutation',
  email: 'Email',
  email_status: 'Email status',
  email_allow_notification: 'Allow notification',
  secondary_email: 'Secondary Email',
  secondary_email_allow_notification: 'Second Email Allow notification',
  company_name: 'Company Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  gender: 'Gender',
  billing_address: 'Billing Address',
  billing_city: 'Billing City',
  billing_state: 'Billing State',
  billing_suite: 'Billing Suite',
  billing_zip_code: 'Billing Zipcode',
  billing_country_id: 'Billing Country',
  shipping_address: 'Shipping Address',
  shipping_city: 'Shipping City',
  shipping_state: 'Shipping State',
  shipping_suite: 'Shipping Suite',
  shipping_zip_code: 'Shipping Zipcode',
  shipping_country_id: 'Shipping Country',
  phone_home: 'Phone Home',
  phone_home_allow_notification: 'Home Phone Allow Notification',
  phone_mobile: 'Phone Mobile',
  phone_mobile_allow_notification: 'Mobile Phone Allow Notification',
  phone_office: 'Phone Office',
  phone_office_allow_notification: 'Office Phone Allow Notification',
  created_at: 'Created',
  updated_at: 'Updated',
  deleted_at: 'Deleted',
};

export default contact;
