const reset_password = {
  reset_password: 'Reset your password',
  init_password: 'Initialize your password',
  language: 'Language',
  password: 'Password',
  confirm_password: 'Confirm password',
  policy: 'By signing in you agree to our',
  privacy_policy: 'privacy policy',
  tos: 'terms of service',
};

export default reset_password;
//
