import makeApiRequest from 'src/lib/makeApiRequest';
import { API_URL } from 'src/constants/Endpoints';
import { ProductList, ProductModel, ProductPayload } from 'src/models/ProductModel';
import { ProductType } from 'src/constants/Products';

export const normalizeProductListData = (data: any): ProductList => data.map((product:any) => {
  const newProduct = { ...product };
  if (typeof newProduct.taxes === 'string') {
    newProduct.taxes = product.taxes.split(',').map((str:string) => Number(str));
  }
  return newProduct as ProductModel;
}) as ProductList;

export const getProductListData =
(organisation: string, type?: ProductType) => {
  const query = type ? `?type=${type}` : '';
  return makeApiRequest({
    method: 'GET',
    url: `${API_URL.products}${query}`,
    organisation,
  });
};

export const postProductData =
(organisation: string, payload: ProductPayload) => makeApiRequest({
  method: 'POST',
  url: `${API_URL.product}`,
  organisation,
  data: payload,
});

export const putProductData =
(organisation: string, payload: ProductPayload) => makeApiRequest({
  method: 'PUT',
  url: `${API_URL.product}/${payload.id}`,
  organisation,
  data: payload,
});

export const deleteProductData =
(organisation: string, id: number) => makeApiRequest({
  method: 'DELETE',
  url: `${API_URL.product}/${id}`,
  organisation,
});
